import AboutUs from './AboutUs.react';
import Archive from './Archive.react';
import Contact from './Contact.react';
import Faq from './Faq.react';
import Features from './Features.react';
import MarketingAndCommunication from './MarketingAndCommunication.react';
import Policy from './Policy.react';
import StaticPage from './StaticPage.react';
import WithCalculator from './WithCalculator.react';

export default {
  AboutUs,
  Archive,
  Contact,
  Faq,
  Features,
  MarketingAndCommunication,
  Policy,
  StaticPage,
  WithCalculator,
};
