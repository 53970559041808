import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class MainContactItem extends StyledComponent {
  static styleRoot = 'MainContactItem'

  static propTypes = {
    kind: RPT.oneOf(['email', 'phone', 'chat']),
    value: RPT.string,
  };

  formatValue() {
    const { cnt, kind, value } = this.props;
    const style = this.getStyle();

    if (kind === 'chat') {
      return <span style={style.content}>{cnt('contentful.contactPage.mainContacts.openChat')}</span>;
    }

    return (<a href={`${kind === 'email' ? 'mailto:' : 'tel:'}${value}`} style={style.content}>
      {value}
    </a>);
  }

  render() {
    const { cnt, kind } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={[style.image, style.image[kind]]} />

        <div style={style.contentWrapper}>
          <strong style={style.header}>
            {cnt(`contentful.contactPage.mainContacts.${kind}`)}
            <div style={[style.decoration, style.decoration[kind]]} />
          </strong>

          {this.formatValue()}
        </div>
      </div>
    );
  }

  static style = ({ mediaQueries }, { value }) => ({
    wrapper: {
      visibility: !value ? 'hidden' : 'visible',
      order: !value ? 4 : 1,
      margin: '0 60px',

      [mediaQueries.breakpointTablet]: {
        margin: '0 30px',
        display: !value ? 'none' : 'flex',
      },
    },

    contentWrapper: {
      [mediaQueries.breakpointTablet]: {
        left: '30px',
        position: 'relative',
      },
    },

    header: {
      margin: '15px 0 25px',
      fontSize: '26px',
      display: 'block',
      position: 'relative',
      minHeight: '60px',
      fontWeight: 800,
      lineHeight: '28px',
    },

    decoration: {
      position: 'absolute',
      left: '-30px',
      bottom: '-15px',
      height: '3px',
      width: '30px',

      [mediaQueries.breakpointTablet]: {
        left: '-15px',
      },

      chat: {
        backgroundColor: '#00abd6',
      },

      email: {
        backgroundColor: '#ffd500',
      },

      phone: {
        backgroundColor: '#d60020',
      },
    },

    content: {
      padding: '10px 0',
      display: 'block',
    },

    image: {
      width: '90px',
      height: '120px',
      backgroundImage: 'url("/assets/img/contact-icons.png")',
      backgroundRepeat: 'none',
      flexShrink: 0,

      [mediaQueries.breakpointTablet]: {
        marginTop: '8px',
      },

      email: {
        backgroundPosition: '-90px 0',
      },

      phone: {
        backgroundPosition: '-176px 0',
      },
    },
  })
}
