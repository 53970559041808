import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, StyledComponent } from '4finance-components';

@translate
@Radium
export default class AdditionalImportantInfo extends StyledComponent {
  static styleRoot = 'AdditionalImportantInfo';

  static propTypes = {
    msg: RPT.func.isRequired,
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <article style={style.wrapper}>
        <Heading kind="h5" inheritedStyle={style.heading}>
          {msg('additionalAmount.importantInfo.heading')}
        </Heading>
        <p style={style.textBlock}>
          {msg('additionalAmount.importantInfo.text.deadline')}
        </p>
        <p style={style.textBlock}>
          {msg('additionalAmount.importantInfo.text.extension')}
        </p>
      </article>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      maxWidth: '280px',

      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    heading: {
      color: colors.darkFont,
      lineheight: '17px',
      marginBottom: '16px',
    },

    textBlock: {
      color: colors.darkFont,
      fontSize: '14px',
      lineheight: '20px',
      marginBottom: '24px',
    },
  })
}

