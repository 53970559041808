import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, StyledComponent } from '4finance-components';
import { connectPrefetch, connectState } from '../connect';

@connectPrefetch([
  ['api', 'fetchClient'],
  ['approval', 'fetchOfferFromApplication'],
  ['api', 'fetchApplication'],
])
@connectState({
  application: ['api', 'fetch', 'client', 'application', 'query', 'data'],
})
@translate
@Radium
export default class ApprovalCompleted extends StyledComponent {
  static styleRoot = 'ApprovalCompleted';

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    riskRuleResults: RPT.object,
  }

  render() {
    const { cnt, msg, application } = this.props;
    const style = this.getStyle();

    return (
      <Jumbotron
        icon="resolution-like"
        title={cnt('approval.completed.title')}
        textAlign="center"
        kind="big"
        inheritedStyle={style.jumbotron}
      >
        <div style={style.paragraph}>
          {cnt('approval.completed.description')}
        </div>

        <div style={style.paragraph}>
          {cnt('approval.completed.contact')}
        </div>
        <ButtonLink
          to={application.get('resolution') === 'APPROVED' ? routesList.dashboard : routesList.approvalProcessing}
        >
          {msg('approval.completed.button')}
        </ButtonLink>
      </Jumbotron>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    jumbotron: {
      paddingBottom: '50px',
      paddingLeft: '15%',
      paddingRight: '15%',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },

      title: {
        fontWeight: 'bold',
      },
    },

    paragraph: {
      fontSize: '16px',
      lineHeight: '25px',
      marginTop: '35px',
      marginBottom: '25px',
      color: colors.grayFont,
    },
  })
}
