import Footer from '../app/Footer.react';
import Header from '../app/Header.react';
import NotificationPanel from '../components/NotificationPanel.react';
import Radium from 'radium';
import React from 'react';
import routeList from '../routesList';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

const ROUTES_WITH_MOBILE_BACK_BUTTON = [
  routeList.autoRepayment,
  routeList.extension,
  routeList.payment,
  routeList.paymentExtension,
  routeList.paymentFull,
  routeList.identificationApproveApplication,
  routeList.settings,
  routeList.settingsCard,
  routeList.settingsCardAdd,
  routeList.additionalAmount,
];

@Radium
export default class CommonLayout extends StyledComponent {
  static styleRoot = 'CommonLayout'

  static propTypes = {
    children: RPT.any,
    location: RPT.object,
    isRegistration: RPT.bool,
  }

  render() {
    const { children, location: { pathname } = {}, isRegistration } = this.props;
    const showBackButton = ROUTES_WITH_MOBILE_BACK_BUTTON.includes(pathname);
    const style = this.getStyle();

    return (
      <div style={style.pageWrapper}>
        <Header
          pathname={pathname}
          isRegistration={isRegistration}
          showBackButton={showBackButton}
        />
        <NotificationPanel />
        <div style={style.contentWrapper}>{children}</div>
        <Footer />
      </div>
    );
  }

  static style = {
    pageWrapper: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
    },

    contentWrapper: {
      minHeight: '220px',
      flex: '1 0 auto',
    },
  }
}
