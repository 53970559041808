import getApiResponseCreator from './store/getApiResponse';
import Promise from 'bluebird';

export default function createValidateGuards(dispatch, getState) {
  const getApiResponse = getApiResponseCreator(getState, dispatch);

  return guards => (nextState, replace, callback) => {
    const executeGuard = async (index) => {
      if (index >= guards.length) {
        callback();
      } else {
        const result = await Promise.resolve(guards[index]({
          dispatch,
          getApiResponse,
          getState,
          nextState,
          replace: (pathname) => replace({ pathname, state: { status: 302 } }),
        }));

        if (result) {
          executeGuard(index + 1);
        } else {
          callback();
        }
      }
    };

    executeGuard(0);
  };
}
