import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, StyledComponent, Column } from '4finance-components';
import Ribon from '../../components/Ribon.react';
import StylizedAmount from '../../components/StylizedAmount.react';
import { connectSelectors } from '../../connect';
import { RPTcalculatorSlidersSelector } from '../../../common/calculator/selectors';

@connectSelectors({
  calculatorSlidersSelector: ['calculator', 'calculatorSlidersSelector'],
})
@translate
@Radium
export default class AdditionalHeading extends StyledComponent {
  static styleRoot = 'AdditionalHeading';

  static propTypes = {
    msg: RPT.func.isRequired,
    calculatorSlidersSelector: RPTcalculatorSlidersSelector,
  }

  shouldComponentUpdate = (nextProps) => {
    const { amountInterval: nextAmountInterval } = nextProps.calculatorSlidersSelector;
    const { amountInterval } = this.props.calculatorSlidersSelector;

    if (amountInterval.get('max') !== nextAmountInterval.get('max')) return true;

    return false;
  }


  render() {
    const { amountInterval } = this.props.calculatorSlidersSelector;
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Column phone={6} inheritedStyle={{ paddingLeft: 0, paddingRight: 0 }}>
          <div style={style.heading}>
            <Heading kind="h3">{msg('additionalAmount.heading.title')}</Heading>
          </div>
        </Column>
        <Column phone={6} inheritedStyle={{ paddingLeft: 0, paddingRight: 0 }}>
          <Ribon>
            <div style={style.ribonWrapper}>
              <StylizedAmount amount={amountInterval.get('max')} size="phone" />
              <div style={style.ribonLabel}>{msg('additionalAmount.heading.max')}</div>
            </div>
          </Ribon>
        </Column>
      </div>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    wrapper: {
      display: 'none',
      padding: '0 24px',

      [mediaQueries.breakpointTablet]: {
        display: 'block',
      },
    },

    heading: {
      padding: '0 0 24px',
    },

    ribonWrapper: {
      paddingTop: '14px',
    },

    ribonLabel: {
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: '500',
      marginTop: '4px',
      marginLeft: '2px',
      color: colors.monotone3,
    },
  })
}
