import Icon from './Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent, Heading } from '4finance-components';

import { connectActions } from '../connect';

@connectActions({
  updatePath: ['routing', 'updatePath'],
})
@Radium
export default class ActionPanel extends StyledComponent {
  static styleRoot = 'ActionPanel'

  static propTypes = {
    disabled: RPT.bool,
    title: RPT.string.isRequired,
    content: RPT.string.isRequired,
    children: RPT.element,
    icon: RPT.string,
    link: RPT.string,
    updatePath: RPT.func,
    inheritedStyle: RPT.oneOfType([
      RPT.object,
      RPT.array,
    ]),
    onClick: RPT.func,
    hasBorder: RPT.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  handleClick = () => {
    const { updatePath, link, onClick, disabled } = this.props;

    if (disabled) {
      return false;
    }

    return onClick ? onClick() : updatePath(link);
  }

  render() {
    const { disabled, icon, inheritedStyle, content, title, hasBorder, children } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.wrapper, inheritedStyle, disabled && style.disabled.wrapper, hasBorder && style.hasBorder]} onClick={this.handleClick}>
        <Icon kind={icon} style={[style.icon, disabled && style.disabled.icon]} size={40} />
        <div style={style.content}>
          <Heading kind="h3" inheritedStyle={[style.heading, disabled && style.disabled.heading]}>
            {title}
          </Heading>
          {content &&
            <p>{content}</p>
          }
          {children}
        </div>
        <Icon kind="arrow-back" style={[style.arrow, disabled && style.disabled.arrow]} size={16} />
      </div>
    );
  }

  static style = ({ mediaQueries, colors }, { icon }) => ({
    wrapper: {
      backgroundColor: colors.white,
      width: 'auto',
      overflow: 'hidden',
      position: 'relative',
      marginBottom: '2px',
      transition: 'background-color 0.3s',
      paddingTop: !icon ? '20px' : '10px',
      paddingBottom: !icon ? '20px' : '10px',

      ':active': {
        backgroundColor: colors.lightBlue,
      },
    },

    hasBorder: {
      borderBottom: `1px solid ${colors.monotone1}`,
    },

    content: {
      marginLeft: !icon ? '5px' : '90px',
      paddingRight: '20%',
      paddingBottom: !icon ? 0 : '20px',
    },

    heading: {
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
      textTransform: 'uppercase',
      fontSize: '18px',
      paddingTop: !icon ? 0 : '20px',
      paddingRight: '10%',
      fontWeight: '600',
      lineHeight: '20px',

      [mediaQueries.breakpointLaptop]: {
        marginBottom: !icon ? 0 : '10px',
      },
    },

    icon: {
      position: 'absolute',
      top: '30px',
      left: '25px',
      fill: colors.primary,
    },

    link: {
      fontSize: '19px',
      fontWeight: 'bold',
      marginTop: '20px',
    },

    arrow: {
      transform: 'rotate(180deg)',
      position: 'absolute',
      right: '20px',
      top: '50%',
      marginTop: '-8px',
    },

    disabled: {
      wrapper: {
        backgroundColor: 'transparent',
        color: colors.monotone2,
      },
      heading: {
        color: colors.monotone2,
      },
      icon: {
        fill: colors.monotone2,
      },
      arrow: {
        fill: colors.monotone2,
      },
    },
  })
}
