import config from '4finance-configuration';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { autobind } from 'core-decorators';
import { Button, StyledComponent } from '4finance-components';

@Radium
export default class RandomFill extends StyledComponent {
  static styleRoot = 'RandomFill'

  static propTypes = {
    randomFill: RPT.func,
    pending: RPT.bool,
    submit: RPT.func,
    submitOnly: RPT.bool,
    center: RPT.bool,
  }

  static defaultProps = {
    randomFill: () => {},
  }

  componentDidMount() {
    document.addEventListener('keypress', this.onDocumentKeypress);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.onDocumentKeypress);
  }

  @autobind
  onDocumentKeypress(event) {
    const { randomFill } = this.props;

    // random fill on ctrl+shift+x
    if (event.shiftKey && event.ctrlKey && event.keyCode === 24) {
      randomFill();
    }
  }

  onFillAndSubmitClick = () => {
    const { randomFill, submit } = this.props;

    const promise = randomFill();

    if ((promise || {}).then) {
      return promise.then(submit);
    }

    return submit();
  }

  render() {
    const { submitOnly, randomFill } = this.props;
    const style = this.getStyle();

    if (!config.faker.enabled) return null;

    return (
      <span style={style.container}>
        {!submitOnly && <Button kind="secondary" size="small" onClick={randomFill}>Random Fill</Button>}
        <Button kind="secondary" size="small" onClick={this.onFillAndSubmitClick}>Random Fill & Submit</Button>
      </span>
    );
  }

  static style = (x, { center }) => ({
    container: center
      ? {
        display: 'block',
        textAlign: 'center',
        marginBottom: '0',
      }
      : {
        marginBottom: '25px',
      },
  });
}
