import { StyledComponent } from '4finance-components';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';

@translate
@Radium
export default class ModalCloseLink extends StyledComponent {
  static styleRoot = 'ModalCloseLink'

  static propTypes = {
    marginTop: RPT.bool,
    msg: RPT.func.isRequired,
    onClose: RPT.func.isRequired,
  }

  static defaultProps = {
    marginTop: false,
  }

  render() {
    const { onClose, msg, marginTop } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.wrapper, marginTop && style.wrapper.marginTop]}>
        <span style={style.link} onClick={onClose}>
          {msg('modals.partials.closeLink')}
        </span>
      </div>
    );
  }

  static style = ({ font, colors }) => ({
    wrapper: {
      textAlign: 'center',
      marginTop: {
        marginTop: '15px',
      },
    },

    link: {
      color: colors.primary,
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: font.bold,
      lineHeight: '22px',
    },
  });
}
