import Calculator from './Calculator.react';
import CalculatorOffer from './CalculatorOffer.react';
import CalculatorOfferCertificateInfo from './CalculatorOfferCertificateInfo.react.js';
import CalculatorLoanInfoLink from './CalculatorLoanInfoLink.react';
import Icon from '../Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState, connectActions } from '../../connect';
import { Heading, StyledComponent } from '4finance-components';

@connectActions({
  toggleCalculatorOpened: ['calculator', 'toggleCalculatorOpened'],
  setTouched: ['calculator', 'setCalculatorTouched'],
})
@connectState({
  isCalculatorOpened: ['calculator', 'isCalculatorOpened'],
})
@translate
@Radium
export default class CalculatorEditable extends StyledComponent {
  static styleRoot = 'CalculatorEditable'

  static propTypes = {
    isCalculatorOpened: RPT.bool,
    toggleCalculatorOpened: RPT.func,
    children: RPT.node,
    inheritedStyle: RPT.object,
    showInfoLink: RPT.bool,
  }

  static defaultProps = {
    showInfoLink: true,
  }

  toggle = () => {
    const { setTouched, toggleCalculatorOpened } = this.props;

    toggleCalculatorOpened();
    setTouched(false);
  }

  render() {
    const { children, inheritedStyle, isCalculatorOpened, msg, showInfoLink } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.wrapper, inheritedStyle]}>
        <div style={[style.header.wrapper, isCalculatorOpened && style.header.wrapper.toggled]}>
          <Heading kind="h3" noMargin bold style={{ base: style.header.text }}>
            {msg('calculator.title')}
          </Heading>
          <button
            className="calculator-edit-button"
            data-id="editToggle"
            onClick={() => this.toggle()}
            style={style.headerButton}
          >
            <div style={style.headerButton.wrapper}>
              <Icon
                kind={isCalculatorOpened ? 'check' : 'loan-edit'}
                size={11}
                style={style.headerButton.icon}
              />
              <span style={style.headerButton.text}>
                {isCalculatorOpened ? msg('calculator.buttons.close') : msg('calculator.buttons.edit')}
              </span>
            </div>
          </button>
        </div>
        <div style={[style.calculatorWrapper, isCalculatorOpened && style.calculatorWrapper.opened]}>
          <Calculator toggled noBorderRadius />
        </div>
        <div style={[style.overviewWrapper, !showInfoLink && isCalculatorOpened && style.overviewWrapper.extend]}>
          <CalculatorOffer hasInterestRateAndAnnualPercentageRate hasTotalRepayableAmount />
          {showInfoLink && <CalculatorLoanInfoLink style={style.loanInfoLink} />}
        </div>
        <CalculatorOfferCertificateInfo />
        {children}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { showInfoLink }) => ({

    wrapper: {
      backgroundColor: colors.white,
      marginBottom: '30px',
      marginTop: '45px',
      borderRadius: '8px 8px',
      boxShadow: '0 1px 5px 0 rgba(0,0,0,.25)',
      maxHeight: '650px',
      overflow: 'hidden',

      [mediaQueries.breakpointSmall]: {
        marginBottom: 0,
      },
    },

    header: {
      wrapper: {
        padding: '24px 24px 12px 24px',
        color: colors.darkFont,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: colors.secondary,
        borderRadius: '8px 8px 0 0',
        transition: '.4s',

        toggled: {
          background: colors.white,
          transition: '.4s',
        },
      },

      text: {
        color: colors.darkFont,
        lineHeight: '28px',
      },
    },

    loanInfoLink: {
      wrapper: {
        padding: '15px 0 10px',
        textAlign: 'left',
      },

      link: {
        fontSize: '14px',
        color: colors.darkFont,
      },
    },

    headerButton: {
      fontSize: '14px',
      backgroundColor: colors.primary,
      color: colors.white,
      padding: '9px 15px',
      lineHeight: '1.5rem',
      outline: 'none',
      cursor: 'pointer',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: '3px',
      boxShadow: '0 1px 2px rgba(0 , 0, 0, 0.3)',
      transition: 'background-color .2s ease-in-out',
      height: '35px',
      minWidth: '130px',

      ':hover': {
        backgroundColor: colors.hoverBlue,
      },

      icon: {
        fill: colors.white,
      },

      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      text: {
        marginLeft: '10px',
        whiteSpace: 'nowrap',
      },
    },

    overviewWrapper: {
      color: colors.darkFont,
      background: colors.secondary,
      paddingTop: '12px',
      paddingBottom: showInfoLink ? '12px' : '20px',
      paddingLeft: '24px',
      paddingRight: '24px',

      extend: {
        paddingBottom: '60px',
      },
    },

    calculatorWrapper: {
      height: 0,
      overflow: 'hidden',
      transition: 'height .4s',

      opened: {
        height: '275px',
      },
    },

    infoFormLink: {
      margin: '15px 0',
    },
  })
}
