import PageContainer from '../components/PageContainer.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import Title from '../contentful/components/Title.react';
import translate from '4finance-translate';

@translate
export default class StaticPages extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
    location: RPT.shape({
      query: RPT.shape({
        amount: RPT.number.isRequired,
        term: RPT.number.isRequired,
      }),
    }),
  }

  static defaultProps = {
    location: {
      query: {
        amount: 800,
        term: 24,
      },
    },
  };

  render() {
    const { location, msg } = this.props;
    const { amount, term } = location.query;

    return (
      <div>
        <Title decorated text={msg('loanContract.title')} />
        <PageContainer width="wide">
          <article className="static-page">
            <iframe src={`/webapi/client/agreements/preliminary?amount=${amount}&term=${term}`} style={{ width: '100%', height: '1000px' }} />
          </article>
        </PageContainer>
      </div>
    );
  }
}
