import contentfulClient, { getEntityName } from '../../common/lib/contentful';
import Loading from './Loading.react';
import NotFound from './NotFound.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import templates from './templates';
import { connectState, connectPrefetch } from '../connect';
import { createActions as createContentfulActions, Router as ContentfulRouter } from '4finance-contentful';

const { fetchContentfulEntries, fetchContentfulEntry } = createContentfulActions(contentfulClient);

@connectState(({ params }) => ({
  pages: ['contentful', 'entry', getEntityName(params.splat)],
  redirects: ['contentful', 'entries', 'redirect'],
}))
@connectPrefetch([
  ({ params }) => fetchContentfulEntry(getEntityName(params.splat), `/${params.splat}`),
  () => fetchContentfulEntries('redirect'),
])
export default class Router extends Component {
  static propTypes = {
    dispatch: RPT.func,
    history: RPT.object,
    pages: RPT.object,
    params: RPT.object,
    redirects: RPT.object,
  }


  componentDidUpdate({ params: { splat: prevSplat } }) {
    const { dispatch, params: { splat } } = this.props;

    if (prevSplat !== splat) {
      dispatch(fetchContentfulEntry(getEntityName(splat), `/${splat}`));
    }
  }

  render() {
    const { history, params, pages, redirects } = this.props;

    return (
      <ContentfulRouter
        history={history}
        pages={pages}
        params={params}
        redirects={redirects}
        templates={templates}
        Loading={Loading}
        NotFound={NotFound}
      />
    );
  }
}
