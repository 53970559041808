import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent, Heading, Tooltip, Icon } from '4finance-components';

@translate
@Radium
export default class BankAccountNumber extends StyledComponent {
  static styleRoot = 'BankAccountNumber';

  static propTypes = {
    onClick: RPT.func,
    value: RPT.string.isRequired,
  }

  render() {
    const { value, msg, cnt } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <Heading kind="h5" noMargin inheritedStyle={style.heading}>{msg('bankAccountNumber.heading')}</Heading>
        <div style={style.block}>
          <div style={style.number}>{value.replace(/(.{4})/g, '$1 ')}</div>
          <Tooltip
            inheritedStyle={style.tooltipStyle}
            tooltip={cnt('bankAccountNumber.message')}
            icon={
              <div>
                <Icon kind="info" size={14} color={style.iconColor} />
              </div>
            }
          />
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({

    heading: {
      marginBottom: '16px',
    },

    block: {
      marginBottom: '25px',

      [mediaQueries.breakpointSmall]: {
        display: 'flex',
        alignItems: 'center',
      },
    },

    iconColor: colors.primary,

    number: {
      display: 'inline-block',
      fontWeight: '200',
      letterSpacing: '1px',
      textTransform: 'uppercase',

      [mediaQueries.breakpointSmall]: {
        marginBottom: '3px',
        letterSpacing: 0,
      },
    },

    tooltipStyle: {
      padding: '0px 10px',

      arrow: {
        [mediaQueries.breakpointSmall]: {
          right: '15px',
          left: 'auto',
        },
      },

      content: {
        [mediaQueries.breakpointSmall]: {
          transform: 'translateX(25px)',
        },
      },
    },

    link: {
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 500,
      marginLeft: '16px',

      [mediaQueries.breakpointSmall]: {
        marginLeft: 0,
      },
    },
  });
}
