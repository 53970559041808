const googleProvince = {
  ARABA: 'Araba',
  ILLES_BALEARES: 'Illes Balears',
  ISLAS_BALEARES: 'Islas Baleares',
  GIPUZKOA: 'Gipuzkoa',
  LERIDA: 'Lérida',
  OURENSE: 'Ourense',
  BIZKAIA: 'Bizkaia',
};

const provinceMapping = {
  [googleProvince.ARABA]: 'Álava',
  [googleProvince.GIPUZKOA]: 'Guipúzcoa',
  [googleProvince.LERIDA]: 'Lleida',
  [googleProvince.OURENSE]: 'Orense',
  [googleProvince.BIZKAIA]: 'Vizcaya',
  [googleProvince.ISLAS_BALEARES]: 'Baleares',
  [googleProvince.ILLES_BALEARES]: 'Baleares',
};

export default provinceMapping;
