import faker from '../lib/faker';
import IBAN from 'iban';
import dayjs from 'dayjs';
import personalId from '../lib/faker/personalId';
import { OWNER_SHIP_OPTIONS, PURPOSES } from '../../browser/registration/fields/data';

export function registration() {
  const dateOfBirth = faker.date.between(dayjs().subtract(60, 'years').format('YYYY-MM-DD'), dayjs().subtract(65, 'years').format('YYYY-MM-DD'));

  return {
    title: 'Sr.',
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    lastNameSecond: faker.name.lastName(),
    personalId: personalId(),
    dateOfBirth: dayjs(dateOfBirth).startOf('day').format('YYYY-MM-DD'),
    mobilePhone: faker.helpers.replaceSymbolWithNumber('6########'),
    email: faker.internet.email().replace(/^([^@]*)@.*$/, '$1@hotmail.com'),
    password: 'parole123',
    acceptAgreement: true,
    agreeGetNews: true,
  };
}

export function aboutYou() {
  return {
    postalCode: faker.address.zipCode(),
    city: 'Barcelona',
    province: 'Barcelona',
    roadType: 'Avenida',
    streetName: faker.address.streetName(),
    streetNumber: faker.random.number({ min: 10, max: 1000 }),
    streetFloor: faker.random.number({ min: 1, max: 10 }),
    streetAppartment: '',
    ownerShip: faker.random.arrayElement(OWNER_SHIP_OPTIONS),
  };
}

export function otherDetails() {
  return {
    employmentStatus: 'Permanent contract (private sector)',
    totalIncome: faker.random.number({ min: 4000, max: 7000 }),
    loansExpenses: faker.random.number({ min: 100, max: 200 }),
    loanPurpose: faker.random.arrayElement(PURPOSES),
  };
}

export function chooseBank() {
  return {
    bank: 'banco_santader',
  };
}

export function confirmLoan() {
  return {
    webCode: '1-2-3-4',
  };
}

export function bankAccount() {
  // Based on specification for Spain
  // https://docs.oracle.com/cd/E18727_01/doc.121/e13483/T359831T498954.htm

  const bankNumberList = ['0049', '2095', '2108', '2104', '1465', '2013', '0075', '0182', '2103', '0128', '2038', '3134', '3160', '1209', '0198', '2105', '0182', '3059', '1491'];
  const bankNumber = bankNumberList[faker.random.number({ min: 0, max: bankNumberList.length - 1 })];
  const branchNumber = faker.helpers.replaceSymbolWithNumber('####');
  const accountNumber = faker.helpers.replaceSymbolWithNumber('##########');

  const factorBank = bankNumber[0] * 4 + bankNumber[1] * 8 + bankNumber[2] * 5 + bankNumber[3] * 10;
  const factorBranch = branchNumber[0] * 9 + branchNumber[1] * 7 + branchNumber[2] * 3 + branchNumber[3] * 6;

  const factorSum = factorBank + factorBranch;
  const factorMod = factorSum % 11;

  const cd1 = 11 - factorMod;

  const accountFactor = accountNumber[0] * 1 + accountNumber[1] * 2 + accountNumber[2] * 4 + accountNumber[3] * 8 + accountNumber[4] * 5 + accountNumber[5] * 10 + accountNumber[6] * 9 + accountNumber[7] * 7 + accountNumber[8] * 3 + accountNumber[9] * 6;
  const accountMod = accountFactor % 11;
  const cd2 = 11 - accountMod;

  const checkDigits = `${cd1}${cd2}`;

  const bban = `${bankNumber}${branchNumber}${checkDigits}${accountNumber}`;

  try {
    return { bankAccountNumber: IBAN.fromBBAN('ES', bban).match(/.{1,4}/g).join('-') };
  } catch (exception) {
    return bankAccount();
  }
}

export function proposal() {
  return {
    acceptAgreement: true,
  };
}

export async function document() {
  const getImageContent = (uri) => ({
    bytes: uri.split(',')[0].indexOf('base64') >= 0
      ? atob(uri.split(',')[1])
      : unescape(uri.split(',')[1]),
    mimeType: uri.split(',')[0].split(':')[1].split(';')[0],
  });

  const getImageFormUrl = (url, callback) => {
    const img = new Image();

    img.crossOrigin = 'anonymous';
    img.onload = function () { // eslint-disable-line func-names
      const canvas = window.document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = this.width;
      canvas.height = this.height;
      ctx.drawImage(this, 0, 0);

      const { bytes, mimeType } = getImageContent(canvas.toDataURL('image/jpg'));
      const ia = new Uint8Array(bytes.length);

      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }

      return callback(new File([ia], `${faker.random.word()}.jpg`, { type: mimeType }));
    };

    img.src = url;
  };

  return new Promise((resolve) => {
    getImageFormUrl('/assets/img/backSideDni-good.jpg', image => resolve(image));
  });
}
