import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

export default class DetailContainer extends StyledComponent {
  static styleRoot = 'DetailContainer'

  static propTypes = {
    translateNamespace: RPT.string,
  }

  render() {
    const { children, translateNamespace } = this.props;
    const childrenLength = children.length - 1;
    const style = this.getStyle();

    return (
      <ul style={style.list}>
        {React.Children.map(children, (child, index) => React.cloneElement(child, {
          translateNamespace,
          isLast: childrenLength === index,
        }))}
      </ul>
    );
  }

  static style = {
    list: {
      listStyle: 'none',
      marginTop: '1rem',
    },
  }
}
