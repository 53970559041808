import Icon from '../../components/Icon.react';
import React from 'react';
import RPT from 'prop-types';
import StaticPage from '../../layouts/StaticPage.react';
import Title from '../components/Title.react';
import { connectState, connectPrefetch } from '../../connect';
import { Container, Row, Column, Loader, StyledComponent } from '4finance-components';
import { FaqList } from '4finance-components/lib/faq';
import { withRouter } from 'react-router';

@connectState({
  faqs: ['contentful', 'entries', 'faq', 'data'],
})
@connectPrefetch([
  ['faqs', 'prefetchFaqs'],
])
@withRouter
export default class Faq extends StyledComponent {
  static styleRoot = 'Faq'

  static propTypes = {
    faqs: RPT.object,
    location: RPT.object,
    page: RPT.object,
    textBlock: RPT.object,
  }

  render() {
    const { page: { fields: { url, pageHeading } }, faqs, textBlock, location: { hash } } = this.props;
    const style = this.getStyle();

    return (
      <StaticPage location={{ pathname: url }} >
        <Title text={pageHeading} decorated />
        <Container>
          <Row>
            <Column large={7} medium={8} small={12} phone={12} lgOffset={1}>
              <div dangerouslySetInnerHTML={{ __html: textBlock }} />
              {faqs ? <FaqList faqs={faqs} selectedItemId={hash.substring(1)} withAnimations /> : <Loader />}
            </Column>
            <Column large={4} medium={4} inheritedStyle={style.faqIconWrapper}>
              <Icon kind="faq" size={200} />
            </Column>
          </Row>
        </Container>
      </StaticPage>
    );
  }

  static style = ({ mediaQueries }) => ({

    faqIconWrapper: {
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
    },
  })
}
