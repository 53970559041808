import routesList from '../../browser/routesList';
import { nextApplicationFunnelPath } from '../application/actions';

export default async function forbidBank({ replace, dispatch, getApiResponse }) {
  const { bankAccountNumber } = await getApiResponse(['fetch', 'client', 'bankAccountNumber']);

  if (bankAccountNumber) {
    dispatch({ type: 'GUARD_FORBID_ADDED_BANK_ACCOUNT_FAILED' });
    const path = dispatch(nextApplicationFunnelPath(routesList.applicationCardAdd)).payload;

    replace(path);

    return false;
  }

  return true;
}
