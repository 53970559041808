import CalculatorOffer from '../../components/Calculator/CalculatorOffer.react';
import Icon from '../../components/Icon.react';
import PageContainer from '../../components/PageContainer.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, Heading, StyledComponent } from '4finance-components';
import { connectPrefetch, connectState, connectSelectors } from '../../connect';
import { Link } from 'react-router';
import { Map } from 'immutable';
import { Wrapper as JumbotronWrapper, YellowBox } from '../../components/Jumbotron';

@connectPrefetch([
  ['approval', 'fetchOfferFromApplication'],
  ['api', 'fetchClientAttachments'],
])
@connectState({
  client: ['api', 'fetch', 'client', 'query', 'data'],
})
@connectSelectors({
  hasBankAccountNumber: ['client', 'hasBankAccountNumber'],
  hasCardNumber: ['client', 'hasCardNumber'],
  hasIdUploaded: ['client', 'hasIdUploaded'],
  cardExpired: ['client', 'cardExpired'],
})
@translate
@Radium
export default class UnfinishedApplication extends StyledComponent {
  static styleRoot = 'UnfinishedApplication';

  static propTypes = {
    children: RPT.object.isRequired,
    client: RPT.object,
    cnt: RPT.func.isRequired,
    location: RPT.object.isRequired,
    params: RPT.object,
    offer: RPT.object,
    offerFetched: RPT.bool,
    hasBankAccountNumber: RPT.bool,
    hasCardNumber: RPT.bool,
    inheritedStyle: RPT.object,
    cardExpired: RPT.bool,
  }

  static defaultProps = {
    client: Map({
      firstName: '',
    }),
    inheritedStyle: {},
  };

  renderListItem(type, value, url) {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <span style={style.listItem}>
        <Icon kind={value ? 'uploaded' : 'unfinished-app'} size={25} />
        {value
          ? <span style={style.listItem.text}>{msg(`dashboard.unfinished.list.${type}`)}</span>
          : <Link to={url} style={style.listItem.text}>{msg(`dashboard.unfinished.list.${type}`)}</Link>
        }
      </span>
    );
  }

  render() {
    const { client, cardExpired, hasBankAccountNumber, hasCardNumber, msg, inheritedStyle, hasIdUploaded } = this.props;
    const style = this.getStyle();
    const nextUrl = hasIdUploaded ? routesList.applicationBankAccount : routesList.approvalDocumentUploadWay;

    return (
      <PageContainer width="narrowest" mobileFullScreen>
        <JumbotronWrapper
          hasPadding={false}
          inheritedStyle={inheritedStyle.wrapper}
        >
          <YellowBox
            hasPadding
            inheritedStyle={style.yellowBox}
            inheritedIconStyle={style.yellowBoxIcon}
            icon="info-verification"
            title={msg('dashboard.unfinished.title', { name: client.get('firstName') })}
          >
            <p>{msg('dashboard.unfinished.description')}</p>
          </YellowBox>

          <JumbotronWrapper hasPadding inheritedStyle={{ margin: 0 }}>
            <div style={style.offer}>
              <div style={style.list}>
                {client.get('newClient') && this.renderListItem('idCard', hasIdUploaded, nextUrl)}
                {this.renderListItem('bankAccount', hasBankAccountNumber, nextUrl)}
                {this.renderListItem('cardNumber', hasCardNumber && !cardExpired, nextUrl)}
              </div>

              <Heading kind="h5" inheritedStyle={style.offer.heading}>
                {msg('calculator.title')}
              </Heading>

              <CalculatorOffer
                hasInterestRateAndAnnualPercentageRate
                hasTotalRepayableAmount
              />
            </div>

            <ButtonLink to={nextUrl} centered>
              {msg('common.continue')}
            </ButtonLink>
          </JumbotronWrapper>
        </JumbotronWrapper>
      </PageContainer>
    );
  }

  static style = {
    offer: {
      textAlign: 'left',
      marginBottom: '30px',

      heading: {
        textTransform: 'uppercase',
      },
    },

    list: {
      marginBottom: '30px',
    },

    yellowBoxIcon: {
      marginTop: '-20px',
    },

    listItem: {
      display: 'flex',
      marginBottom: '10px',
      alignItems: 'center',

      text: {
        marginLeft: '10px',
        fontSize: '15px',
      },
    },
  };
}
