import Contact from './Contact.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class SendDocuments extends StyledComponent {
  static styleRoot = 'SendDocuments';

  static propTypes = {
    msg: RPT.func.isRequired,
    documentType: RPT.oneOf(['identification', 'incomeValidation']),
  };

  renderDivider() {
    const style = this.getStyle();

    return (
      <div
        style={style.divider}
      >
        <div style={style.divider.line} />
        <div style={style.divider.center} />
        <div style={style.divider.line} />
      </div>
    );
  }

  renderTriangle() {
    const style = this.getStyle();

    return (
      <div style={style.triangle}>
        <div style={style.triangle.innerBorder} />
        <div style={style.triangle.fill} />
      </div>
    );
  }

  render() {
    const { msg, documentType } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.documents}>
          <div style={style.documents.title}>{msg('identification.emailOrWhatsapp.send')}</div>
          <div style={style.documents.container}>
            <div style={style.documents[documentType]} />
          </div>
        </div>

        {this.renderTriangle()}

        <div style={style.methods}>
          <Contact
            type="mail"
            value={msg('identification.emailOrWhatsapp.email')}
          />

          {this.renderDivider()}

          <Contact
            type="whatsapp"
            value={msg('identification.emailOrWhatsapp.phone')}
          />
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      display: 'flex',
      alignItems: 'stretch',

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
        background: colors.white,
      },
    },

    documents: {
      width: '40%',
      backgroundColor: colors.lighterBackground,
      borderBottomLeftRadius: '10px',

      [mediaQueries.breakpointLaptop]: {
        width: '35%',
      },

      [mediaQueries.breakpointTablet]: {
        width: '100%',
        borderBottomLeftRadius: 0,
      },

      title: {
        textAlign: 'center',
        marginTop: '30px',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '22px',
      },

      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '15px',
        marginRight: '5px',
        height: '49%',

        [mediaQueries.breakpointTablet]: {
          marginLeft: '10vw',
          marginRight: '10vw',
          marginBottom: '20px',
        },
      },

      identification: {
        backgroundImage: 'url(/assets/img/documents_manual.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        maxWidht: '170px',
        maxHeight: '127px',
        width: '10vw',
        height: '7.47vw',

        [mediaQueries.breakpointTablet]: {
          width: '30vw',
          height: '22.41vw',
          maxWidth: '170px',
          maxHeight: '127px',
        },
      },

      incomeValidation: {
        backgroundImage: 'url(/assets/img/bank_manual.png)',
        maxWidth: '98px',
        maxHeight: '129px',
        width: '10vw',
        height: '13.38vw',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',

        [mediaQueries.breakpointTablet]: {
          width: '30vw',
          height: '22.79vw',
          maxWidth: '98px',
          maxHeight: '129px',
        },
      },
    },

    triangle: {
      position: 'relative',
      width: 0,
      borderTop: 'solid 130px transparent',
      borderBottom: 'solid 130px transparent',
      borderLeft: 'solid 50px #E3E6ED',

      [mediaQueries.breakpointTablet]: {
        transform: 'translateY(-100%) rotate(90deg)',
        transformOrigin: 'left bottom',
        borderBottom: 'solid 50vw transparent',
        borderTop: 'solid 50vw transparent',
        borderLeft: 'solid 70px #E3E6ED',
      },

      innerBorder: {
        position: 'absolute',
        top: '-130px',
        left: '-51px',
        background: colors.lighterBackground,
        width: '1px',
        height: '260px',

        [mediaQueries.breakpointTablet]: {
          height: '100vw',
          top: '-50vw',
          left: '-71px',
        },
      },

      fill: {
        position: 'absolute',
        top: '-129px',
        left: '-51px',
        width: 0,
        borderTop: 'solid 129px transparent',
        borderBottom: 'solid 129px transparent',
        borderLeft: `solid 49px ${colors.lighterBackground}`,

        [mediaQueries.breakpointTablet]: {
          borderBottom: 'solid calc(50vw - 1px) transparent',
          borderTop: 'solid calc(50vw - 1px) transparent',
          borderLeft: `solid 69px ${colors.lighterBackground}`,
          top: 'calc(-50vw + 1px)',
          left: '-71px',
        },
      },
    },

    methods: {
      width: '60%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 40px 0 25px',

      [mediaQueries.breakpointLaptop]: {
        width: '65%',
      },

      [mediaQueries.breakpointLaptopSmall]: {
        padding: '0px 15px 0 5px',
      },

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '0 0 30px 0',
        marginTop: 'calc(-100vw + 85px)',
      },
    },

    divider: {
      height: '100%',
      width: '2px',

      [mediaQueries.breakpointTablet]: {
        transform: 'translateY(-100%) translateX(-50vw) rotate(90deg)',
        transformOrigin: 'left bottom',
        display: 'inline-block',
        height: '100vw',
        marginBottom: 'calc(-100vw + 16px)',
        marginTop: '30px',
      },

      line: {
        height: 'calc(50% - 32px)',
        width: '2px',
        background: '#D7DCE2',
        marginLeft: '6px',
      },

      center: {
        width: '14px',
        height: '14px',
        borderRadius: '7px',
        border: '1px solid #9a9a9a',
        marginTop: '25px',
        marginBottom: '25px',
      },
    },
  });
}
