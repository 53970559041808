import { actions as api } from '../api';
import { actions as form } from '4finance-onion-form';
import * as actions from './actions';
import setFormApiError from '../lib/setFormApiError';

export const submitPasswordChange = (password) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.patchClientPassword(password));

    if (error) {
      dispatch(setFormApiError('changePassword', payload, payload));
      dispatch(form.setFieldError('changePassword', 'password', error));

      throw Error('Change password failed');
    }
  };

  return {
    type: actions.PROCESS_SUBMIT_PASSWORD_CHANGE,
    payload: getPromise(),
  };
};

export const setPasswordChangeDone = () => ({
  type: actions.PROCESS_SET_PASSWORD_CHANGE_DONE,
});
