import Icon from '../../components/Icon.react';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column, Heading, StyledComponent } from '4finance-components';

@translate
@Radium
export default class FeaturesStepBlock extends StyledComponent {
  static styleRoot = 'FeaturesStepBlock';

  static propTypes = {
    isLast: RPT.bool,
    index: RPT.number,
    source: RPT.object.isRequired,
  };

  static defaultProps = {
    isLast: false,
  }

  contentfulImage = ({ fields }) => ({
    src: fields.file.url,
    alt: fields.title,
  })

  render() {
    const { isLast, source, index, msg } = this.props;
    const image = source.image && this.contentfulImage(source.image) || undefined;
    const style = this.getStyle();

    const hasIndex = index !== undefined;

    return (
      <Column large={4} medium={4} small={4} phone={12}>
        <div style={style.wrapper}>
          <div style={style.headWrapper}>
            {image &&
              <div>
                <div style={style.imageWrapper}>
                  <img src={image.src} alt={image.alt} style={style.image} />
                </div>
                <div style={style.shadow} />
              </div>
            }
            {hasIndex && <div>
              <Style scopeSelector=".arrowIcon" rules={style.arrow.arrowColor} />
              {!isLast && <div className="arrowIcon"><Icon kind="arrow" size={28} wrapperStyle={style.arrow} /></div>}
            </div>}
          </div>
          <div style={style.bodyWrapper}>
            <Heading
              decorated
              decorationStyle={[style.titleDecoration, style.titleDecoration[source.ribbonColor]]}
              inheritedStyle={style.title}
              kind="h3"
            >
              {hasIndex ? `${msg('contentful.featuresPage.stepPrefix')} ${index + 1} - ${source.title}` : source.title}
            </Heading>
            <p style={style.content}>
              {source.content}
            </p>
          </div>
        </div>
      </Column>
    );
  }

  static style = ({ colors, mediaQueries }, { image }) => ({
    wrapper: {
      [mediaQueries.breakpointTablet]: {
        display: 'flex',
        marginBottom: '16px',
      },

      [mediaQueries.breakpointSmall]: {
        display: 'block',
      },
    },

    imageWrapper: {
      borderRadius: '99px',
      height: '150px',
      margin: '0 0 20px',
      overflow: 'hidden',
      width: '150px',
      border: '3px white solid',

      [mediaQueries.breakpointSmall]: {
        margin: '0 auto 20px',
      },
    },

    headWrapper: {
      position: 'relative',
      userSelect: 'none',
      zIndex: '1',
    },

    bodyWrapper: {
      marginBottom: '40px',
      maxWidth: '80%',

      [mediaQueries.breakpointTablet]: {
        marginBottom: '16px',
        maxWidth: '100%',
        paddingLeft: image ? '24px' : 0,
      },
    },

    shadow: {
      backgroundColor: colors.lightFont,
      borderRadius: '99px',
      filter: 'blur(10px)',
      height: '6px',
      marginLeft: '25px',
      width: '100px',

      [mediaQueries.breakpointSmall]: {
        margin: '0 auto',
      },
    },

    arrow: {
      left: '180px',
      position: 'absolute',
      top: '60px',

      arrowColor: {
        path: {
          fill: colors.lightFont,
        },
      },

      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    image: {
      width: '100%',
    },

    content: {
      fontSize: '18px',
      lineHeight: '24px',
      padding: '10px 0',
    },

    title: {
      fontSize: '26px',
    },

    titleDecoration: {
      backgroundColor: colors.error,
      left: '-26px',
      maxWidth: '20%',
      minWidth: '10%',
      top: '8px',
      width: '20px',

      [mediaQueries.breakpointTablet]: {
        left: '-8px',
      },

      red: {
        backgroundColor: colors.error,
      },
      yellow: {
        backgroundColor: colors.secondary,
      },
      blue: {
        backgroundColor: colors.primary,
      },
      gray: {
        backgroundColor: colors.black,
      },
    },
  });
}
