import React from 'react';
import { StyledComponent } from '4finance-components';

export default class Ribon extends StyledComponent {
  static styleRoot = 'Ribon';

  render() {
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.arrow} />
        <div style={style.content}>
          {this.props.children}
        </div>
      </div>
    );
  }

  static style = {
    wrapper: {
      float: 'right',
      position: 'relative',
      right: '-24px',
      top: '20px',
    },

    arrow: {
      borderBottom: '30px solid transparent',
      borderRight: '20px solid white',
      borderTop: '30px solid transparent',
      height: 0,
      left: '-20px',
      position: 'absolute',
      top: '0px',
      width: 0,
    },

    content: {
      backgroundColor: 'white',
      height: '60px',
      position: 'relative',
      userSelect: 'none',
      width: '116px',
    },
  };
}
