import CalculatorOffer from '../components/Calculator/CalculatorOffer.react';
import Jumbotron from '../components/Jumbotron';
import Link from '../components/Link.react';
import PageContainer from '../components/PageContainer.react';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPrefetch, connectSelectors, connectState } from '../connect';
import { Heading, StyledComponent } from '4finance-components';
import { Map } from 'immutable';

@connectPrefetch([
  ['api', 'fetchClient'],
  ['approval', 'fetchOfferFromApplication'],
  ['identification', 'getRiskRules'],
])
@connectState({
  client: ['api', 'fetch', 'client', 'query', 'data'],
})
@connectSelectors({
  showUploadLink: ['client', 'showUploadLink'],
})
@translate
export default class Processing extends StyledComponent {
  static styleRoot = 'Processing';

  static propTypes = {
    cnt: RPT.func.isRequired,
    params: RPT.object.isRequired,
    title: RPT.string,
    subtitle: RPT.object,
    contact: RPT.string,
    showUploadLink: RPT.bool,
  }

  static defaultProps = {
    client: Map({
      bankAccount: '',
      firstName: '',
    }),
  };

  renderSubtitle = () => {
    const { showUploadLink, subtitle, cnt } = this.props;

    if (subtitle) {
      return subtitle;
    }

    if (showUploadLink) {
      return (
        <div>
          {cnt('approval.reviewing.description')}
          <Link to={routesList.approvalDocumentUpload}>
            {cnt('approval.reviewing.uploadLink')}
          </Link>.
        </div>
      );
    }

    return cnt('approval.processing.description');
  };

  render() {
    const { cnt, contact, msg, client, title, showUploadLink } = this.props;
    const style = this.getStyle();
    const { bankAccount, firstName } = client.toJS();

    return (
      <PageContainer width="narrow" mobileFullScreen style={style.container}>
        <Jumbotron
          icon="waitting-clock"
          title={title || msg('approval.processing.title', { name: firstName })}
          textAlign="center"
          kind="big"
          inheritedStyle={style.jumbotron}
        >
          {this.renderSubtitle()}

          <div style={style.bank}>
            <Heading kind="h5" inheritedStyle={style.bank.heading}>
              {cnt('approval.processing.bank.title')}
            </Heading>
            <div style={style.bank.description}>
              {cnt('approval.processing.bank.description')}
            </div>
            <div style={style.bank.account}>
              <span style={style.bank.stars}>
                {bankAccount.substring(0, bankAccount.length - 4).replace(/./g, '*')}
              </span>
              <span style={style.bank.number}>
                {bankAccount.substring(bankAccount.length - 4)}
              </span>
            </div>
          </div>

          <div style={style.offer}>
            <Heading kind="h5" inheritedStyle={style.offer.heading}>
              {msg('calculator.title')}
            </Heading>

            <CalculatorOffer />
          </div>
          {showUploadLink &&
            <div style={style.contact}>
              {contact || cnt('approval.processing.contact')}
            </div>
          }

        </Jumbotron>
      </PageContainer>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    jumbotron: {
      paddingLeft: '85px',
      paddingRight: '85px',
      paddingBottom: '40px',
      paddingTop: '40px',
      margin: '0 30px 30px 0',

      [mediaQueries.breakpointTablet]: {
        margin: 0,
      },

      title: {
        marginBottom: '15px',
      },
    },
    bank: {
      textAlign: 'left',
      borderBottom: `1px solid ${colors.inputBorder}`,
      paddingBottom: '15px',
      marginBottom: '35px',
      marginTop: '35px',

      heading: {
        fontWeight: 700,
      },

      description: {
        marginTop: '15px',
        marginBottom: '15px',
        fontSize: '14px',
      },

      account: {
        display: 'flex',
        flexDirection: 'row',
      },

      number: {
        fontSize: '20px',
        letterSpacing: '1px',
        marginLeft: '2px',
      },

      stars: {
        fontSize: '30px',
        marginTop: '1px',
        letterSpacing: '2px',
      },
    },
    offer: {
      textAlign: 'left',

      heading: {
        textTransform: 'uppercase',
      },
    },
    contact: {
      marginTop: '25px',
      textAlign: 'left',
    },
  });
}
