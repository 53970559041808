import CommonLayout from '../layouts/Common.react';
import PageContainer from '../components/PageContainer.react';
import ProgressBar from '../components/ProgressBar.react';
import Radium from 'radium';
import React from 'react';
import routeList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class Page extends StyledComponent {
  static styleRoot = 'ApprovalPage';

  static propTypes = {
    children: RPT.object.isRequired,
    cnt: RPT.func.isRequired,
    location: RPT.object.isRequired,
    params: RPT.object.isRequired,
  }

  render() {
    const { children, location } = this.props;
    const hasProgressBar = !~location.pathname.indexOf(routeList.approvalProposal);

    return (
      <CommonLayout location={location} isRegistration>
        {hasProgressBar && <ProgressBar step={3} />}

        <PageContainer width="narrow" mobileFullScreen>
          <div>
            {children}
          </div>
        </PageContainer>
      </CommonLayout>
    );
  }
}
