import AmountField from './AmountField.react';
import CheckboxWithHtmlText from './CheckboxWithHtmlText.react';
import GoogleAddress from './GoogleAddress.react';
import isName from '../../../common/validations/name';
import personalId from '../../../common/validations/personalId';
import PhoneNumber from './PhoneNumber.react';
import React from 'react';
import SmallAddressField from './SmallAddressField.react';
import translatedField from '../../lib/translatedField';
import TranslatedSelect from './TranslatedSelect.react';
import VerboseDateField from './VerboseDateField.react';
import withErrorBackground from '../../components/withErrorBackground';
import { EMPLOYMENT_STATUSES, OWNER_SHIP_OPTIONS, PROVINCES, PURPOSES, ROAD_TYPES, TITLE_OPTIONS } from './data';
import { Button, Select, TextField } from '4finance-components';
import { connectField, connectSubmit } from '4finance-onion-form';
import { email, isRequired, maxLength, minLength, number, password, regex, validAge } from '4finance-validation';
import { employmentValueMapping } from './data/employment';
import { IoBlackBox as IoBlackBoxField } from '4finance-iovation';

const BackgroundedTextField = withErrorBackground(TextField);

export const Title = translatedField('title', {
  options: TITLE_OPTIONS.map(value => ({ value: value[1], text: `form.registration.title.options.${value[0]}` })),
  full: true,
  value: '',
}, [
  isRequired(),
])(withErrorBackground(TranslatedSelect));

export const FirstName = translatedField('firstName', {
  width: 'medium',
  bottomMargin: false,
}, [
  isName(),
  isRequired(),
  minLength(3),
  maxLength(99),
])(BackgroundedTextField);

export const LastName = translatedField('lastName', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
}, [
  isName(),
  isRequired(),
  minLength(3),
  maxLength(50),
])(BackgroundedTextField);

export const LastNameSecond = translatedField('lastNameSecond', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
}, [
  isName(),
  maxLength(49),
])(BackgroundedTextField);

export const PersonalId = translatedField('personalId', {
  width: 'medium',
}, [
  isRequired(),
  minLength(9),
  maxLength(9),
  personalId(),
])(BackgroundedTextField);

export const DateOfBirth = translatedField('dateOfBirth', {
  minimumAge: 25,
  maximumAge: 70,
}, [
  isRequired(),
  validAge(25, 70, 'YYYY-MM-DD'),
])(VerboseDateField);

export const Email = translatedField('email', {
  type: 'email',
  width: 'medium',
  id: 'email',
}, [
  isRequired(),
  email(),
])(BackgroundedTextField);

export const MobilePhone = translatedField('mobilePhone', {
  type: 'tel',
  width: 'medium',
}, [
  isRequired(),
  number(),
  minLength(9),
  maxLength(9),
  regex('^(?!70)(6|7)'),
])(withErrorBackground(PhoneNumber));

export const Password = translatedField('password', {
  type: 'password',
  width: 'medium',
}, [
  isRequired(),
  password(),
  minLength(8),
  maxLength(30),
])(BackgroundedTextField);

export const AcceptAgreement = translatedField('acceptAgreement', {
  style: {
    container: { marginBottom: '50px' },
  },
}, [
  isRequired(),
])(CheckboxWithHtmlText);

export const AgreeGetNews = translatedField('agreeGetNews', {
  style: {
    container: { marginBottom: '25px' },
  },
})(CheckboxWithHtmlText);

const otherDetailsSelectStyle = {
  wrapper: {
    size: {
      default: {
        minWidth: '350px',
      },
      medium: {
        minWidth: '350px',
      },
    },
  },
};

export const LoanPurpose = translatedField('loanPurpose', {
  options: PURPOSES.map(purpose => ({ value: purpose, text: `form.otherDetails.loanPurpose.options.${purpose}` })),
  value: '',
  style: otherDetailsSelectStyle,
}, [
  isRequired(),
])(withErrorBackground(TranslatedSelect));

export const EmploymentStatus = translatedField('employmentStatus', {
  options: EMPLOYMENT_STATUSES.map(status => ({ value: employmentValueMapping[status], text: `form.otherDetails.employmentStatus.options.${status}` })),
  value: '',
  style: otherDetailsSelectStyle,
}, [
  isRequired(),
])(withErrorBackground(TranslatedSelect));

export const TotalIncome = translatedField('totalIncome', {}, [
  isRequired(),
  minLength(2),
  maxLength(5),
  number(),
])(withErrorBackground(AmountField));

export const LoansExpenses = translatedField('loansExpenses', {}, [
  isRequired(),
  minLength(1),
  maxLength(5),
  number(),
])(withErrorBackground(AmountField));

export const IoBlackBox = connectField('ioBlackBox', {}, [])(IoBlackBoxField);

export const PostalCode = translatedField('postalCode', {
  width: 'medium',
}, [
  isRequired(),
  minLength(5),
  maxLength(5),
  number(),
])(BackgroundedTextField);

export const City = translatedField('city', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
}, [
  isRequired(),
  minLength(2),
  maxLength(99),
  isName(),
])(BackgroundedTextField);

export const Province = translatedField('province', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
  children: PROVINCES.map(province => <option key={province} value={province} disabled={!Boolean(province)}>{province}</option>),
  value: '',
}, [
  isRequired(),
])(withErrorBackground(Select));

export const RoadType = translatedField('roadType', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
  children: ROAD_TYPES.map(roadType => <option key={roadType} value={roadType}>{roadType}</option>),
  value: '',
}, [
  isRequired(),
])(Select);

export const StreetName = translatedField('streetName', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
}, [
  isRequired(),
  minLength(2),
  maxLength(85),
  isName(),
])(BackgroundedTextField);

export const StreetNumber = translatedField('streetNumber', {}, [
  isRequired(),
  minLength(1),
  maxLength(4),
])(withErrorBackground(SmallAddressField));

export const StreetFloor = translatedField('streetFloor', {}, [
  minLength(1),
  maxLength(4),
])(withErrorBackground(SmallAddressField));

export const StreetAppartment = translatedField('streetAppartment', {}, [
  minLength(1),
  maxLength(4),
])(withErrorBackground(SmallAddressField));

export const OwnerShip = translatedField('ownerShip', {
  options: OWNER_SHIP_OPTIONS.map(value => ({ value, text: `form.aboutYou.ownerShip.options.${value}` })),
  full: true,
  value: '',
}, [
  isRequired(),
])(withErrorBackground(TranslatedSelect));

export const LandlinePhone = translatedField('landLinePhone', {
  type: 'tel',
  width: 'medium',
}, [
  number(),
  minLength(9),
  maxLength(9),
  regex('^(8|9)'),
])(PhoneNumber);

export const Submit = connectSubmit(Button);

export const GoogleAutoCompleteInput = translatedField('streetName', {
  width: 'medium',
  bottomMargin: false,
  topMargin: false,
}, [
  isRequired(),
  minLength(2),
  maxLength(85),
])(withErrorBackground(GoogleAddress));

export const AcceptDataSharing = translatedField('acceptDataSharing', {
  style: {
    container: { marginBottom: '25px' },
  },
})(CheckboxWithHtmlText);
