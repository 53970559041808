import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ProgressBar as ProgressBarComponent } from '4finance-components';

@translate
export default class ProgressBar extends Component {
  static propTypes = {
    msg: RPT.func,
    step: RPT.number.isRequired,
  };

  render() {
    const { msg, step } = this.props;

    return (
      <ProgressBarComponent
        activeStep={msg(`registration.progressbar.step${step}`)}
        numbered
        sharp
        steps={[
          msg('registration.progressbar.step1'),
          msg('registration.progressbar.step2'),
          msg('registration.progressbar.step3'),
        ]}
      />
    );
  }
}
