import React, { Component } from 'react';
import RPT from 'prop-types';
import ModalHeading from './ModalHeading.react';
import ModalCloseLink from './ModalCloseLink.react';
import { Form } from '4finance-onion-form';

export default class ModalWrapper extends Component {
  static styleRoot = 'ModalWrapper'

  static propTypes = {
    children: RPT.node.isRequired,
    closeMarginTop: RPT.bool,
    footerChildren: RPT.node,
    formName: RPT.string,
    formSubmit: RPT.func,
    heading: RPT.string.isRequired,
    onClose: RPT.func.isRequired,
  }

  static defaultProps = {
    closeMarginTop: false,
  }

  render() {
    const { children, closeMarginTop, footerChildren, formName, formSubmit, heading, onClose } = this.props;

    return (
      <div style={!footerChildren ? { paddingBottom: '20px' } : {}}>
        <ModalHeading>{heading}</ModalHeading>
        {formName
          ? <Form name={formName} onSubmit={formSubmit}>
            {children}
          </Form>
          : children
        }
        <ModalCloseLink onClose={onClose} marginTop={closeMarginTop} />
        {footerChildren}
      </div>
    );
  }
}
