import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class StylizedAmount extends StyledComponent {
  static styleRoot = 'StylizedAmount'

  static propTypes = {
    size: RPT.oneOf(['small', 'big', 'phone']),
    amount: RPT.number,
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
  }

  static defaultProps = {
    size: 'big',
  }

  render() {
    const { amount, msg, size, inheritedStyle } = this.props;

    if (typeof amount === 'undefined') {
      return null;
    }

    const style = this.getStyle();
    const amountToString = amount.toFixed(2).toString().split(/,|\./g);
    const wholeAmount = amountToString[0];
    const decimalOfAmount = amountToString[1];

    return (
      <div style={[style.wrapper, style.wrapper[size], inheritedStyle]}>
        <p style={style.mainAmount}>{wholeAmount}</p>
        <p style={[style.amountDetails]}>
          <span>{msg('number.currency.format.unit')}</span>
          <span style={[!decimalOfAmount && style.hidden, style.decimal]}>
            <span style={style.separator}>,</span>
            {decimalOfAmount ? `${decimalOfAmount}` : '00'}
          </span>
        </p>
      </div>
    );
  }

  static style = {
    wrapper: {
      display: 'flex',

      big: {
        fontSize: '83px',
        lineHeight: '60px',
        height: '60px',
      },

      small: {
        fontSize: '44px',
        lineHeight: '30px',
        height: '30px',
      },

      phone: {
        fontSize: '30px',
        lineHeight: '20px',
        height: '20px',
      },
    },

    mainAmount: {
      fontWeight: 'bold',
      margin: 0,
    },

    decimal: {
      display: 'flex',
    },

    separator: {
      marginTop: '-0.55em',
    },

    amountDetails: {
      fontSize: '0.36em',
      lineHeight: '1em',
      marginLeft: '0.2em',
      marginBottom: 0,
      fontWeight: '400',
      textAlign: 'left',
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
    },
  }
}
