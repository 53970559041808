import Calculator from '../components/Calculator/Calculator.react';
import Header from '../app/Header.react';
import Icon from '../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectState } from '../connect';
import { Container, Heading, StyledComponent } from '4finance-components';
import { withRouter } from 'react-router';

@connectState({
  amount: ['calculator', 'amount'],
  loanLimit: ['api', 'fetch', 'application', 'constraints', 'query', 'data', 'loanLimits', '1'],
  term: ['api', 'fetch', 'application', 'constraints', 'query', 'data', 'termInterval', 'max'],
})
@connectActions({
  showZaploClosingPopup: ['settings', 'showZaploClosingPopup'],
})
@translate
@withRouter
@Radium
export default class Proposition extends StyledComponent {
  static styleRoot = 'Proposition'

  static propTypes = {
    amount: RPT.number,
    loanLimit: RPT.number,
    location: RPT.object.isRequired,
    msg: RPT.func.isRequired,
    showZaploClosingPopup: RPT.func.isRequired,
  }

  renderTextBlock() {
    const { msg, cnt } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.textBlock.base}>
        <div>
          <Heading className="propositionHeading" kind="h1" inheritedStyle={style.textBlock.heading}>
            {msg('home.proposition.title')}
          </Heading>

          <Heading kind="h2" thin inheritedStyle={style.textBlock.subheading}>
            {msg('home.proposition.subtitle')}
          </Heading>

          <ul style={style.advantages}>
            <li style={style.advantagesItem}>
              <Icon kind="check" size={13} style={style.icon} />
              {cnt('home.advantages.1')}
            </li>
            <li style={style.advantagesItem}>
              <Icon kind="check" size={13} style={style.icon} />
              {cnt('home.advantages.2')}
            </li>
            <li style={style.advantagesItem}>
              <Icon kind="check" size={13} style={style.icon} />
              {cnt('home.advantages.3')}
            </li>
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { location, showZaploClosingPopup } = this.props;
    const style = this.getStyle();

    return (
      <section style={style.wrapper}>
        <Header pathname={location && location.pathname} inverseColors kind="homepage" />

        <Container inheritedStyle={style.container}>
          <div style={style.innerWrapper}>
            {this.renderTextBlock()}

            <div style={style.calculator}>
              <Calculator homePage onApply={showZaploClosingPopup} />
            </div>
          </div>
        </Container>
      </section>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundImage: 'none',
      backgroundColor: colors.white,

      [mediaQueries.breakpointStartTablet]: {
        backgroundImage: 'url(/assets/img/background-couch.jpg)',
        backgroundPosition: '43% 0',
        backgroundSize: 'cover',
      },


      [mediaQueries.breakpointStartLaptop]: {
        backgroundPosition: '60% 0',
        backgroundSize: 'cover',
      },
    },

    innerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingBottom: '40px',
      paddingTop: '50px',
      minHeight: '600px',

      [mediaQueries.breakpointLaptopSmall]: {
        display: 'block',
        paddingTop: '30px',
      },
      [mediaQueries.breakpointTablet]: {
        paddingBottom: 0,
        paddingTop: '20px',
      },
    },

    textBlock: {
      base: {
        marginTop: '20px',
        maxWidth: '600px',
        display: 'flex',
        paddingRight: '20px',
        fontSize: '23px',

        [mediaQueries.breakpointLaptopSmall]: {
          padding: '20px',
          marginTop: '30px',
          marginBottom: '30px',
        },
        [mediaQueries.breakpointTablet]: {
          marginTop: '20px',
        },
        [mediaQueries.breakpointSmall]: {
          marginBottom: 0,
          textAlign: 'center',
          padding: '0 10px',
          marginTop: 0,
        },
      },

      heading: {
        color: colors.primary,
        fontSize: '72px',
        fontWeight: 'bold',
        marginLeft: 0,
        marginTop: 0,
        marginRight: 0,
        marginBottom: '30px',
        lineHeight: '55px',

        [mediaQueries.breakpointTablet]: {
          fontSize: '44px',
          color: colors.primary,
          fontWeight: 900,
          padding: '0 40px 0 0',
          lineHeight: '34px',
        },

        [mediaQueries.breakpointSmall]: {
          padding: '0 20px',
          fontSize: '40px',
        },
      },

      subheading: {
        color: colors.darkFont,
        fontSize: '25px',
        marginTop: '1rem',
        marginRight: 0,
        marginLeft: 0,
        marginBottom: '20px',
        fontWeight: 300,
        maxWidth: '500px',

        [mediaQueries.breakpointSmall]: {
          fontSize: '18px',
          marginBottom: '30px',
          marginLeft: 'auto',
          marginRight: 'auto',
          color: colors.black,
          fontWeight: '400',
          maxWidth: '300px',
        },
      },
    },


    advantages: {
      listStyle: 'none',
      fontSize: '17px',
      lineHeight: '23px',
      marginTop: '25px',

      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    advantagesItem: {
      marginBottom: '10px',
    },

    icon: {
      fill: colors.primary,
      marginRight: '10px',
    },

    calculator: {
      alignSelf: 'flex-start',
      width: '370px',
      [mediaQueries.breakpointLaptopSmall]: {
        margin: '0 20px',
      },
      [mediaQueries.breakpointTablet]: {
        width: '100%',
        margin: 0,
      },
    },

    container: {
      paddingTop: '10px',
      [mediaQueries.breakpointTablet]: {
        paddingTop: '20px',
        paddingRight: 0,
        paddingLeft: 0,
        width: '100%',
      },
    },
  });
}
