import Icon from '../../components/Icon.react';
import PageHeader from '../../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';
import Jumbotron from '../../components/Jumbotron';

@translate
@Radium
export default class InstantorLoginWrapper extends StyledComponent {
  static styleRoot = 'InstantorLoginWrapper'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    pending: RPT.bool,
  }

  addVideoRef = ref => {
    this.video = ref;
  }

  playVideo = () => {
    if (!this.video) return;

    const videoUrl = 'https://www.youtube.com/embed/AzalyXW-Wxg?rel=0&autoplay=1';

    this.video.innerHTML = `<iframe
                              src="${videoUrl}"
                              frameborder="0"
                              allowfullscreen
                              width="100%"
                              height="100%">
                            </iframe>`;
  }

  renderSecurity() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    const reasons = msg('identification.online.security.reasons').toJS();

    return (
      <div style={style.security}>
        <div style={style.security.header}>
          <Icon
            kind="instantor-shield"
            width={50}
            height={60}
            style={style.security.icon}
          />
          {msg('identification.online.security.title')}
        </div>

        <div style={style.security.description}>
          {cnt('identification.online.security.subtitle')}
        </div>

        <ul style={style.security.reasons}>
          {Object.keys(reasons).map(key => (
            <li style={style.security.reasons.item} key={key}>
              <Icon kind="check" size={20} style={style.security.reasons.item.icon} />
              {cnt(`identification.online.security.reasons.${key}`)}
            </li>
          ))}
        </ul>
        <div style={style.video} ref={this.addVideoRef}>
          <div style={style.video.description}>
            <p style={style.video.description.title}>{msg('identification.online.video.title')}</p>
            <p style={style.video.description.length}>{msg('identification.online.video.length')}</p>
          </div>
          <div style={style.video.button} onClick={this.playVideo}>
            <div style={style.video.button.wrapper} />
            <Icon kind="play" size={20} style={style.video.button.play} />
          </div>
        </div>
      </div>
    );
  }

  renderContentBox() {
    const { children, msg } = this.props;
    const style = this.getStyle();

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { ...this.props })
    );

    return (
      <div style={style.leftBox}>
        {childrenWithProps}

        <div style={style.leftBox.securityText}>
          <Icon
            kind="loan-lock"
            style={style.leftBox.icons.lock}
            size={19}
          />
          <div>{msg('identification.online.consent.security')}</div>
        </div>
        <div style={style.leftBox.securityIcons}>
          <Icon kind="instantor" width={120} style={style.leftBox.icons.instantor} />
          <Icon kind="verisign" width={95} style={style.leftBox.icons.verisign} />
        </div>
      </div>
    );
  }

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <PageHeader
          title={msg('identification.online.title')}
          subtitle={cnt('identification.online.subtitle')}
          padded
        />

        <Jumbotron inheritedStyle={style.container}>
          {this.renderContentBox()}
          {this.renderSecurity()}
        </Jumbotron>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      backgroundColor: colors.white,
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,

      [mediaQueries.breakpointTablet]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        boxShadow: 'unset',
        flexDirection: 'column',
      },
    },

    leftBox: {
      width: '50%',
      padding: '30px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 5,

      [mediaQueries.breakpointTablet]: {
        width: '100%',
      },

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '10px',
      },

      securityText: {
        display: 'flex',
        fontSize: '14px',
        lineHeight: '17px',
        color: colors.lightFont,
        width: '90%',
      },

      securityIcons: {
        marginTop: '20px',
        marginLeft: '5px',

        [mediaQueries.breakpointSmall]: {
          textAlign: 'center',
        },
      },

      icons: {
        lock: {
          flexShrink: 0,
          marginRight: '10px',
          marginTop: '3px',
        },

        verisign: {
          fill: colors.lightFont,
          marginLeft: '30px',
        },

        instantor: {
          fill: colors.lightFont,
        },
      },
    },

    security: {
      width: '50%',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      background: colors.secondary,
      padding: '30px',

      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
        width: '100%',
      },

      header: {
        fontSize: '28px',
        fontWeight: 'bold',
        lineHeight: '30px',
        display: 'flex',
        alignItems: 'center',

        [mediaQueries.breakpointSmall]: {
          fontSize: '25px',
        },
      },

      icon: {
        marginRight: '20px',
      },

      description: {
        marginTop: '20px',
        fontSize: '14px',
        lineHeight: '20px',
      },

      reasons: {
        listStyleType: 'none',
        marginTop: '35px',
        width: '85%',

        item: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          lineHeight: '20px',
          marginBottom: '20px',

          icon: {
            fill: colors.primary,
            flexShrink: 0,
            marginRight: '10px',
          },
        },
      },
    },

    video: {
      marginTop: '40px',
      maxWidth: '409px',
      height: '200px',
      backgroundImage: 'url(/assets/img/instantor-video-placeholder.jpg)',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',

      [mediaQueries.breakpointTablet]: {
        height: '25vw',
      },

      [mediaQueries.breakpointSmall]: {
        height: '45vw',
      },

      button: {
        cursor: 'pointer',

        play: {
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '50%',
        },

        wrapper: {
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '50%',
          width: '80px',
          height: '80px',
          borderRadius: '40px',
          background: colors.secondary,
          filter: 'alpha(opacity=50)',
          opacity: 0.5,
        },
      },

      description: {
        paddingTop: '15px',
        textAlign: 'right',
        width: '95%',

        title: {
          fontWeight: 700,
          color: colors.darkFont,
          margin: 0,
        },

        length: {
          fontWeight: 600,
          color: colors.lightFont,
          margin: 0,
          textTransform: 'uppercase',
        },
      },
    },
  });
}
