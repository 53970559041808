import React, { Component } from 'react';

export default function withInterval() {
  return WrappedComponent => class extends Component {
    intervals = {};

    removeIntervalById = (intervalId) => {
      clearInterval(this.intervals[intervalId]);
    }

    removeAllIntervals = () => {
      Object.keys(this.intervals).forEach((intervalId) => this.removeIntervalById(intervalId));
    }

    addInterval = (id, time, callback, execBefore = false) => {
      this.intervals = { [id]: setInterval(() => callback(), time), ...this.intervals };
      if (execBefore) { callback(); }
    }

    componentWillUnmount() {
      this.removeAllIntervals();
    }

    render() {
      return (
        <WrappedComponent
          addInterval={this.addInterval}
          removeIntervalById={this.removeIntervalById}
          removeAllIntervals={this.removeAllIntervals}
          {...this.props}
        />
      );
    }
  };
}
