import routesList from '../../browser/routesList';
import { actions as RFU } from '4finance-redux-file-upload';
import { push as updatePath } from 'react-router-redux';

export const PROCESS_SUBMIT_UPLOAD_INDENTIFICATION_FILES = 'PROCESS_SUBMIT_UPLOAD_INDENTIFICATION_FILES';
export const CLEAR_ALL_FILES = 'CLEAR_ALL_FILES';

export function uploadIdentificationFiles(redirect) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const currentFiles = getState().customDocumentUpload.get('currentFiles').toJS();

      await Promise.all( // eslint-disable-line no-unused-expressions
        Object.keys(currentFiles).map(
          (key) => dispatch(RFU.uploadPending(key, '/webapi/client/attachments', { type: key }))
        )
      );

      await dispatch(clearAllFiles()); // eslint-disable-line no-unused-expressions

      dispatch(updatePath(redirect || routesList.applicationIdentificationProcessing));
    };

    return {
      type: PROCESS_SUBMIT_UPLOAD_INDENTIFICATION_FILES,
      payload: getPromise(),
    };
  };
}


export function clearAllFiles() {
  return {
    type: CLEAR_ALL_FILES,
  };
}
