import config from '4finance-configuration';
import React from 'react';
import RPT from 'prop-types';
import { connectPrefetch, connectActions, connectState } from '../connect';
import { Map } from 'immutable';
import { MYMOID_CARD_ADD_ERROR, MYMOID_CARD_ADD_SUCCESS, MYMOID_ERROR } from '../../common/mymoid/actions';
import { StyledComponent as Component } from '4finance-components';

@connectState({
  card: ['api', 'send', 'paymentGateway', 'cards', ({}).toString(), 'mymoid', 'data'],
})
@connectActions({
  submitMymoidCard: ['mymoid', 'submitMymoidCard'],
})
@connectPrefetch([
  ['mymoid', 'prefetchMymoid'],
])
export default class Mymoid extends Component {
  static styleRoot = 'Mymoid';

  static propTypes = {
    card: RPT.object,
    root: RPT.string.isRequired,
    submitMymoidCard: RPT.func.isRequired,
    customRedirectUrl: RPT.string,
  }

  static defaultProps = {
    card: new Map(),
  }

  handleLoadMyMoid = (event) => {
    const { customRedirectUrl, submitMymoidCard, root } = this.props;
    const url = event.target.contentWindow ? event.target.contentWindow.location.href : '';

    if (~url.indexOf(MYMOID_CARD_ADD_SUCCESS)) {
      submitMymoidCard(root, customRedirectUrl);
    }
    if (~url.indexOf(MYMOID_CARD_ADD_ERROR)) {
      submitMymoidCard(root, customRedirectUrl, MYMOID_ERROR);
    }

    return null;
  }

  render() {
    const { card } = this.props;
    const style = this.getStyle();

    return (<div>
      {config.faker.enabled && <div>
        You can use testing card data:<br />
        Card Holder: any<br />
        Card Number: 4548812049400004<br />
        Expiration Date: 12/20<br />
        CVV: 123<br />
      </div>}
      {card && <iframe
        key={card.get('taskId')}
        src={card.get('payformUrl')}
        onLoad={this.handleLoadMyMoid}
        style={style.wrapper}
        scrolling="no"
      />}
    </div>);
  }

  static style = {

    wrapper: {
      border: 0,
      height: '550px',
      margin: 0,
      verticalAlign: 'bottom',
      width: '100%',
    },
  }
}
