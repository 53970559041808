/* eslint-disable no-unused-expressions */
import { actions as api } from '../api';
import getNextPayment from './getNextPayment';

export const LOANS_FETCH_LATEST = 'LOANS_FETCH_LATEST';
export const LOANS_FETCH_LATEST_SUCCESS = 'LOANS_FETCH_LATEST_SUCCESS';
export const LOANS_FETCH_LATEST_ERROR = 'LOANS_FETCH_LATEST_ERROR';

export const FETCH_NEXT_PAYMENT = 'FETCH_NEXT_PAYMENT';
export const FETCH_NEXT_PAYMENT_SUCCESS = 'FETCH_NEXT_PAYMENT_SUCCESS';

export function fetchPaymentSchedule(loan) {
  return ({ dispatch, getState }) => {
    async function getPromise() {
      await dispatch(api.fetchClientLoansPaymentScheduleByLoanid('latest'));

      const { api: { fetch } } = getState();
      const schedule = fetch.getIn(['client', 'loans', 'paymentSchedule', 'latest', 'data']);
      const nextPayment = getNextPayment(loan, schedule);
      const sheduleItems = schedule.toJS ? schedule.toJS().items : schedule;
      const unpaidInstalments = sheduleItems.filter((item) => item.status !== 'PAID');

      return {
        nextPayment,
        remainingInstalments: unpaidInstalments.length,
      };
    }

    return {
      type: FETCH_NEXT_PAYMENT,
      payload: getPromise(),
    };
  };
}

export function fetchLatestLoan() {
  return ({ dispatch, getState }) => {
    async function getPromise() {
      if (!getState().authentication.get('isLoggedIn')) {
        return {
          latestLoanFetched: true,
          hasLatestLoan: false,
          hasLatestPayment: false,
          isPaymentProcessing: false,
        };
      }

      const { payload: { _links: { latestLoan: hasLatestLoan }, clientLoanCount } } = await dispatch(api.fetchClientLoans());

      if (hasLatestLoan) {
        const { payload: latestLoan } = await dispatch(api.fetchClientLoansByLoanid('latest'));
        const { _links: { latestPayment: hasLatestPayment } } = latestLoan;
        const { api: { fetch } } = getState();
        const validationResolutionDetail = fetch.getIn(['client', 'query', 'data', 'validationResolutionDetail']);

        await dispatch(fetchPaymentSchedule(latestLoan));

        return {
          clientLoanCount,
          latestLoanFetched: true,
          hasLatestLoan: true,
          hasLatestPayment: !!hasLatestPayment,
          isPaymentProcessing: validationResolutionDetail === 'LOAN_IN_PROGRESS',
          latestLoan,
        };
      }

      return {
        latestLoanFetched: true,
        hasLatestLoan: false,
        hasLatestPayment: false,
      };
    }

    return {
      type: LOANS_FETCH_LATEST,
      payload: getPromise(),
    };
  };
}
