export const GTM_TRACK_PAGE_CHANGE = 'GTM_TRACK_PAGE_CHANGE';

export function gtmRouteHandler(getState, pathname) {
  const { api, ui } = getState();
  const client = getClientData(api);
  const application = getApplicationData(api);
  const latestLoan = getLatestLoanData(api);
  const referrer = (ui.prevPathname === undefined) ? '/' : ui.prevPathname;

  return {
    event: 'pageChange',
    page: { url: pathname, referrer },
    ...client && { client },
    ...application && { application },
    ...latestLoan && { latest_loan: latestLoan },
  };
}

function getClientData(api) {
  const client = api.getIn(['fetch', 'client', 'query', 'data']);
  const loansCount = api.getIn(['fetch', 'client', 'loans', 'query', 'data', 'clientLoanCount']) || 0;

  if (client) {
    const data = client.toJS();

    return {
      ...pick(data, [
        'age',
        { clientNumber: 'number' },
        { clientStatus: 'status' },
        'dateOfBirth',
        'id',
        'identifiedBy',
        'registeredBy',
      ]),
      loansCount,
      returningClient: !data.newClient,
    };
  }

  return null;
}

function getApplicationData(api) {
  const application = api.getIn(['fetch', 'client', 'application', 'query', 'data']);

  if (application) {
    const data = application.toJS();

    return pick(data, ['amount', 'id', 'term', 'type']);
  }

  return null;
}

function getLatestLoanData(api) {
  const latestLoan = api.getIn(['fetch', 'client', 'loans', 'latest', 'data']);

  if (latestLoan) {
    const data = latestLoan.toJS();
    const loansCount = api.getIn(['fetch', 'client', 'loans', 'query', 'data', 'clientLoanCount']);

    return {
      ...pick(data, [
        'agreementType',
        { id: 'loanNumber' },
        'interestAmount',
        'openAmount',
        'overDueDays',
      ]),
      transactionType: getTransactionType(loansCount, data.agreementType),
    };
  }

  return null;
}

const getTransactionType = (loansCount, agreementType) => {
  let transactionType = '';

  if (loansCount !== undefined) {
    if (loansCount > 1) {
      transactionType = 'old';
    } else {
      transactionType = 'new';
    }

    if (agreementType === 'MAIN') {
      transactionType += '_main';
    } else {
      if (agreementType === 'NEW_AMOUNT') {
        transactionType += '_additional';
      } else {
        transactionType += '_other';
      }
    }
  }

  return transactionType;
};

const pick = (obj, fields) =>
  fields.reduce((previous, field) => {
    if (typeof field === 'object') {
      const name = Object.keys(field)[0];

      return { ...previous, [name]: obj[field[name]] };
    }

    return { ...previous, [field]: obj[field] };
  }, {});
