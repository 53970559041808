import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { OfferRow } from '4finance-components';
import { connectState } from '../connect';

const LOAN_FIELDS = [
  'pay_now_amount',
  'repayable_amount',
  'penalty_amount',
  'loan_amount',
  'loan_already_paid',
  'loan_remaining_amount',
  'quotas_remaining',
  'loan_number',
  'due_date',
  'interest',
];

@connectState({
  remainingInstalments: ['loans', 'remainingInstalments'],
  nextPayment: ['loans', 'nextPayment'],
})
@translate
@Radium
export default class LoanOverview extends Component {
  static propTypes = {
    boldFields: RPT.number,
    fields: RPT.arrayOf(RPT.oneOf(LOAN_FIELDS)),
    formatDate: RPT.func,
    inverseColors: RPT.bool,
    loan: RPT.object,
    nextPayment: RPT.object,
    msg: RPT.func,
    preciseNumberToCurrency: RPT.func,
    withSubtitiles: RPT.bool,
    remainingInstalments: RPT.number,
  }

  static defaultProps = {
    boldFields: 1, // number of bold fields from the end
    fields: [
      'repayable_amount',
      'penalty_amount',
      'loan_amount',
      'loan_already_paid',
      'loan_remaining_amount',
      'quotas_remaining',
      'loan_number',
    ],
    loan: new Map(),
  }

  constructor(props) {
    super(props);

    const { preciseNumberToCurrency, formatDate, remainingInstalments, nextPayment } = this.props;

    // TODO: confirm which fields are actually needed
    // and move calculations to BE
    const fieldValues = {
      due_date: (loan) => [loan.get('dueDate'), formatDate(loan.get('dueDate'))],
      interest: (loan) => [loan.get('interestAmount'), preciseNumberToCurrency(loan.get('interestAmount'))],
      loan_remaining_amount: (loan) => [loan.get('payNowAmount'), preciseNumberToCurrency(loan.get('payNowAmount'))],
      loan_amount: (loan) => [loan.get('totalOutpaidAmount'), preciseNumberToCurrency(loan.get('totalOutpaidAmount'))],
      loan_number: (loan) => [loan.get('loanNumber'), loan.get('loanNumber')],
      loan_already_paid: (loan) => [loan.get('totalOutpaidAmount') - loan.get('principalAmount'), preciseNumberToCurrency(loan.get('totalOutpaidAmount') - loan.get('principalAmount'))],
      pay_now_amount: (loan) => [loan.get('payNowAmount'), preciseNumberToCurrency(loan.get('payNowAmount'))],
      penalty_amount: (loan) => [loan.get('penaltyAmount'), preciseNumberToCurrency(loan.get('penaltyAmount'))],
      quotas_remaining: () => [remainingInstalments, remainingInstalments],
      repayable_amount: () => [nextPayment.amount, preciseNumberToCurrency(nextPayment.amount)],
    };

    this.getFieldValue = (fieldName, loan) => fieldValues[fieldName](loan);
  }

  renderRow({ fieldName, index, value: [rawValue, value] }) {
    const { boldFields, fields, inverseColors, msg, withSubtitiles } = this.props;
    const boldFieldsIndex = fields.length - boldFields;
    const lastFieldIndex = fields.length - 1;

    if (!rawValue) {
      return null;
    }

    return (
      <div key={fieldName} style={[(fieldName === 'penalty_amount') && style.penalty]}>
        <OfferRow
          id={fieldName}
          title={msg(`loan.fields.${fieldName}`)}
          inverseColors={inverseColors}
          last={index >= boldFieldsIndex}
          underline={index >= boldFieldsIndex && index < lastFieldIndex}
          subtitle={withSubtitiles && msg(`loan.fields.subtitles.${fieldName}`)}
        >
          {value}
        </OfferRow>
      </div>
    );
  }

  render() {
    const { fields, loan } = this.props;

    return (
      <div>
        {fields.map((fieldName, index) =>
          this.renderRow({
            fieldName,
            index,
            value: this.getFieldValue(fieldName, loan),
          }))
        }
      </div>
    );
  }
}

const style = {
  penalty: { color: '#f00' },
};
