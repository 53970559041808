import * as actions from './actions';
import { Record } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

const InitialState = new Record({
  pathname: '/',
  prevPathname: '/',
  isMobileMenuOpen: false,
});
const initialState = new InitialState;

export default function uiReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.TOGGLE_MOBILE_MENU: {
      return state.update('isMobileMenuOpen', isMobileMenuOpen => !isMobileMenuOpen);
    }

    case LOCATION_CHANGE: {
      return state.merge({
        pathname: action.payload.pathname,
        prevPathname: state.get('pathname'),
        isMobileMenuOpen: false,
      });
    }
  }

  return state;
}
