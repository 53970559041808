export default {
  wrapper: {
    base: {
      width: '100%',
    },
  },
  base: {
    textTransform: 'uppercase',
    fontWeight: 400,
    fontSize: '18px',
    height: '60px',
    lineHeight: '40px',
    verticalAlign: 'middle',
    borderRadius: '3px',
    boxShadow: 'inset 0 -1px 1px 0 rgba(0,0,0,0.11), 0 2px 2px 0 rgba(0,0,0,0.12)',
  },
  disabled: {
    primary: {
      backgroundColor: '#CED0D1',
    },
  },
};
