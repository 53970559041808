import Jumbotron, { YellowBox } from '../Jumbotron';
import Radium from 'radium';
import RandomFill from '../../components/RandomFill.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import UploadField from './Field.react';
import { EXTRA } from '../../components/upload/consts';
import { Button, StyledComponent, Tooltip } from '4finance-components';
import { connectActions, connectPending } from '../../connect';
import { List } from 'immutable';
import { PROCESS_SUBMIT_UPLOAD_INDENTIFICATION_FILES } from '../../../common/documentUpload/actions';

@connectPending({
  isLoading: PROCESS_SUBMIT_UPLOAD_INDENTIFICATION_FILES,
})
@connectActions({
  randomFill: ['randomFill', 'uploadDocument'],
})
@translate
@Radium
export default class UploadContainer extends StyledComponent {
  static styleRoot = 'UploadContainer'

  static propTypes = {
    cnt: RPT.func,
    isLoading: RPT.bool,
    msg: RPT.func,
    onClick: RPT.func,
    fields: RPT.array.isRequired,
    randomFill: RPT.func.isRequired,
    heading: RPT.string,
  }

  state = {
    fieldOpened: 0,
    itemsUploaded: List(),
    uploadingCompleted: false,
  }

  randomFill = () => {
    const { fields, randomFill } = this.props;

    return randomFill(fields, this.changeOpenedField);
  };

  changeOpenedField = (fieldOpenedKey, uploaded) => () => {
    const { itemsUploaded } = this.state;

    this.setState({
      fieldOpened: fieldOpenedKey,
      itemsUploaded: uploaded ? itemsUploaded.set(fieldOpenedKey - 1, true) : itemsUploaded,
    }, this.validateFields);
  }

  async validateFields() {
    const { fields } = this.props;
    const { itemsUploaded } = this.state;

    if (!itemsUploaded.size) {
      await fields.map((fieldName, key) => {
        if (fieldName !== EXTRA) {
          return this.setState({
            itemsUploaded: itemsUploaded.set(key, false),
          });
        }

        return false;
      });
    } else {
      this.setState({
        uploadingCompleted: !itemsUploaded.includes(false),
      });
    }
  }

  render() {
    const { onClick, fields, msg, isLoading, heading } = this.props;
    const { fieldOpened, uploadingCompleted } = this.state;
    const style = this.getStyle();

    return (
      <div>
        <Jumbotron hasPadding={false} inheritedStyle={style.jumbotron}>
          <YellowBox
            style={style.yellowBoxMobile}
            title={heading}
            icon="info-verification"
          >
            {msg('identification.documentUpload.box.title')}
          </YellowBox>

          <YellowBox
            style={style.yellowBox}
            title={msg('identification.documentUpload.box.title')}
          >
            <Tooltip
              icon={<span style={style.tooltip}>{msg('common.why')}</span>}
              tooltip={msg('identification.documentUpload.box.tooltip')}
              inheritedStyle={style.tooltip.wrapper}
            />
          </YellowBox>

          <div style={style.upload}>
            {fields.map((fieldName, key) => <UploadField
              name={fieldName}
              opened={(fieldOpened === key)}
              onClick={this.changeOpenedField(key, false)}
              onFileSelect={this.changeOpenedField(key + 1, true)}
              isLast={key === fields.length - 1}
              onError={this.onError}
              key={key}
              optional={fieldName === EXTRA}
            />)}
          </div>

          <div style={style.button.wrapper}>
            <Button
              inheritedStyle={style.button}
              disabled={!uploadingCompleted}
              loading={isLoading}
              onClick={onClick}
            >
              {msg('common.submit')}
            </Button>
          </div>

        </Jumbotron>
        <RandomFill
          submit={onClick}
          randomFill={this.randomFill}
          loading={isLoading}
          center
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    jumbotron: {
      overflow: 'none',

      [mediaQueries.breakpointTablet]: {
        boxShadow: 'unset',
      },
    },

    yellowBox: {
      wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '18px 28px 17px 28px',
        marginBottom: 0,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',

        [mediaQueries.breakpointSmall]: {
          display: 'none',
        },
      },

      title: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        fontSize: '18px',
      },
    },

    yellowBoxMobile: {
      wrapper: {
        padding: '18px 15px 17px 15px',
        marginBottom: 0,

        [mediaQueries.breakpointStartSmall]: {
          display: 'none',
        },
      },

      icon: {
        marginRight: '10px',
      },

      title: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        fontSize: '18px',
      },
    },


    tooltip: {
      color: colors.primary,
      fontSize: '18px',
      textDecoration: 'underline',
      fontWeight: 'bold',

      wrapper: {
        width: 'auto',
      },
    },

    button: {
      marginTop: 0,
      marginRight: 0,

      [mediaQueries.breakpointTablet]: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
      },

      wrapper: {
        background: colors.lighterBackground,
        padding: '30px 0',
        borderRadius: '0 0 5px 5px',
        textAlign: 'center',

        [mediaQueries.breakpointTablet]: {
          borderRadius: '0 0 0 0',
          background: colors.background,
        },
      },
    },
  });
}
