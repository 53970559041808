export default ({ colors, mediaQueries }) => ({
  container: {
    color: colors.darkFont,
    backgroundColor: colors.grayLighter,
    [mediaQueries.breakpointTablet]: {
      backgroundColor: colors.lightBlue,
    },
  },

  arrow: {
    borderColor: `transparent transparent transparent ${colors.grayLighter}`,
    [mediaQueries.breakpointTablet]: {
      borderColor: colors.lightBlue,
    },
  },

  backgroundArrow: {
    borderColor: `transparent transparent transparent ${colors.background}`,
    [mediaQueries.breakpointTablet]: {
      borderColor: colors.lightBlue,
    },
  },

  bleed: {
    backgroundColor: colors.grayLighter,
    [mediaQueries.breakpointTablet]: {
      backgroundColor: colors.lightBlue,
    },
  },

  active: {
    backgroundColor: colors.secondary,
    color: colors.darkFont,
    [mediaQueries.breakpointTablet]: {
      backgroundColor: colors.primary,
    },
  },

  activeArrow: {
    borderColor: `transparent transparent transparent ${colors.secondary}`,
    [mediaQueries.breakpointTablet]: {
      borderColor: colors.primary,
    },
  },
});
