import NotificationBox from '../components/NotificationBox.react';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../components/RandomFill.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { StyledComponent } from '4finance-components';
import { connectActions, connectPending, connectPrefetch, connectState } from '../connect';
import { Form } from '4finance-onion-form';
import { SUBMIT_ABOUT_YOU } from '../../common/registration/actions';

import {
  PostalCode,
  City,
  Province,
  RoadType,
  StreetNumber,
  StreetFloor,
  StreetAppartment,
  OwnerShip,
  Submit,
  GoogleAutoCompleteInput,
} from './fields';

const FORM_NAME = 'aboutYou';

@connectPrefetch([
  ['api', 'fetchClientApplication'],
  ['calculator', 'fetchCalculatorWithOffer'],
  ['registration', 'prefetchAboutYouFormData'],
])
@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  submitAboutYou: ['registration', 'submitAboutYou'],
  updateCityAndProvince: ['registration', 'updateCityAndProvince'],
})
@connectPending({
  pending: SUBMIT_ABOUT_YOU,
})
@connectState({
  firstName: ['api', 'fetch', 'client', 'query', 'data', 'firstName'],
  floor: ['onionForm', 'fields', 'aboutYou', 'streetFloor', 'value'],
  letter: ['onionForm', 'fields', 'aboutYou', 'streetAppartment', 'value'],
  isLetterError: ['onionForm', 'fields', 'aboutYou', 'streetAppartment', 'error'],
  isFloorError: ['onionForm', 'fields', 'aboutYou', 'streetFloor', 'error'],
})
@translate
@Radium
export default class AboutYou extends StyledComponent {
  static styleRoot = 'AboutYou'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    pending: RPT.bool,
    randomFill: RPT.func,
    submitAboutYou: RPT.func.isRequired,
    isletterError: RPT.string,
    isFloorError: RPT.string,
    floor: RPT.string,
    letter: RPT.string,
  }

  @autobind
  async onSubmit({ values }) {
    const { submitAboutYou } = this.props;

    await submitAboutYou(values); // eslint-disable-line no-unused-expressions
  }

  @autobind
  updateCityAndProvince(value) {
    const { updateCityAndProvince } = this.props;

    updateCityAndProvince(value); // eslint-disable-line no-unused-expressions
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  @autobind
  setNotification(data) {
    const { floor, letter } = this.props;

    this.setState({
      apartmentNotificationActive: !floor || !letter,
      apartmentNotificationClosed: data ? this.state.apartmentNotificationClosed : false,
    });
  }

  @autobind
  apartmentNotificationClose() {
    this.setState({ apartmentNotificationClosed: true });
  }

  render() {
    const { cnt, msg, pending, firstName, isFloorError, isLetterError } = this.props;
    const isError = isFloorError || isLetterError;
    const { apartmentNotificationActive, apartmentNotificationClosed } = this.state;
    const showNotification = !isError && !apartmentNotificationClosed && apartmentNotificationActive;
    const style = this.getStyle();

    const title = (
      <span>
        {cnt('registration.aboutYou.title')} {firstName}
      </span>
    );

    return (
      <div>
        <PageHeader
          title={title}
          subtitle={cnt('registration.aboutYou.subtitle')}
          textAlign="left"
          size="small"
        />

        <Form
          ref={this.addFormRef}
          name={FORM_NAME}
          onSubmit={this.onSubmit}
        >
          <GoogleAutoCompleteInput inline onAutoFill={this.setNotification} />
          <City inline />
          <Province inline />
          <RoadType inline />
          <div style={style.addressWrapper}>
            <div style={style.address}>
              <StreetNumber inline onBlur={this.setNotification} />
              <div style={style.separator} />
              <StreetFloor
                inline
                onBlur={this.setNotification}
                inheritedStyle={[showNotification && style.notification]}
              />
              <div style={style.separator} />
              <StreetAppartment
                inline
                onBlur={this.setNotification}
                inheritedStyle={[showNotification && style.notification]}
              />
            </div>
            {showNotification && <NotificationBox onClose={this.apartmentNotificationClose} />}
          </div>
          <PostalCode onBlur={() => { this.updateCityAndProvince(); this.setNotification(); }} inline />
          <OwnerShip inline onBlur={this.setNotification} />
          <Submit
            disabled={pending}
            loading={pending}
            loadingText={msg('common.loading')}
          >
            {msg('common.submit')}
          </Submit>

          <RandomFill
            pending={pending}
            submit={this.submitRandomFill}
            randomFill={this.randomFill}
          />
        </Form>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({

    address: {
      display: 'flex',
      width: '320px',
      [mediaQueries.breakpointSmall]: {
        width: '100%',
      },
    },

    separator: {
      background: colors.grayLighter,
      width: '2px',
      marginBottom: '25px',
    },

    addressWrapper: {
      display: 'flex',

      [mediaQueries.breakpointSmall]: {
        display: 'inherit',
      },
    },

    notification: {
      backgroundColor: colors.warningBackground,
    },
  });
}
