import FeaturesContentBlock, { BG_COLOR_WHITE, BG_COLOR_TRANSPARENT } from '../components/FeaturesContentBlock.react';
import FeaturesStepBlock from '../components/FeaturesStepBlock.react';
import React, { PureComponent } from 'react';
import RequestLoan from '../components/RequestLoan.react';
import RPT from 'prop-types';
import StaticPage from '../../layouts/StaticPage.react';
import Title from '../components/Title.react';
import translate from '4finance-translate';
import { Column, Container, Row } from '4finance-components';

@translate
export default class Features extends PureComponent {
  static propTypes = {
    page: RPT.object,
    fields: RPT.object,
  }

  renderStepBlocks({ fields: source, sys: { id } }, key, { length }) {
    const isLast = length === (key + 1);

    return <FeaturesStepBlock source={source} isLast={isLast} index={key} key={id} />;
  }

  renderContentBlocks({ fields: source, sys: { id } }, key) {
    const isFirst = key === 0 || key === undefined;

    return <FeaturesContentBlock source={source} backgroundColor={isFirst ? BG_COLOR_WHITE : BG_COLOR_TRANSPARENT} index={key} key={id} />;
  }

  render() {
    const { page: { fields: { url, pageHeading, stepBlocks, contentBlocks, requestLoanContent } } } = this.props;

    return (
      <StaticPage location={{ pathname: url }} hasPaddingBottom={false}>
        <Title text={pageHeading} decorated />
        <Container>
          <Row>
            <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
              <Row>
                {stepBlocks.map(this.renderStepBlocks)}
              </Row>
            </Column>
          </Row>
        </Container>
        <div style={{ padding: '0 15px' }}>
          <Row>
            {contentBlocks.map(this.renderContentBlocks)}
          </Row>
          <Row>
            <RequestLoan source={requestLoanContent} />
          </Row>
        </div>
      </StaticPage>
    );
  }
}
