import PaymentHeader from './components/PaymentHeader.react';
import PaymentSelect from './components/PaymentSelect.react';
import PaymentWrapper from './components/PaymentWrapper.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
})
@translate
export default class PaymentFull extends StyledComponent {
  static styleRoot = 'PaymentFull';

  static propTypes = {
    loan: RPT.object.isRequired,
  }

  static defaultProps = {
    loan: new Map(),
  }

  renderHeader() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.heading}>
        {msg('payments.full.heading')}
      </div>
    );
  }

  render() {
    const { msg, loan } = this.props;
    const amountToPay = loan.get('payNowAmount');

    return (
      <PaymentWrapper
        type="full"
        title={msg('payments.full.title')}
      >
        <PaymentHeader
          amount={amountToPay}
          paymentDescription={msg('payments.full.amountInfo')}
          type="full"
        >
          {this.renderHeader()}
        </PaymentHeader>
        <PaymentSelect type="repayment" amount={amountToPay} />
      </PaymentWrapper>
    );
  }

  static style = {
    heading: {
      width: '60%',
      lineHeight: '1.8em',
    },
  };
}
