import Button from './extendedStyles/Button.extended';
import ButtonLink from './extendedStyles/ButtonLink.extended';
import Checkbox from './extendedStyles/Checkbox.extended';
import DateField from './extendedStyles/DateField.extended';
import extend from '4finance-components/lib/styles/extend';
import FieldContainer from './extendedStyles/FieldContainer.extended';
import Modal from './extendedStyles/Modal.extended';
import MultiFieldWrapper from './extendedStyles/MultiFieldWrapper.extended';
import normalizeColors from '4finance-components/lib/lib/normalizeColors';
import OfferRow from './extendedStyles/OfferRow.extended';
import PanelBlock from './extendedStyles/PanelBlock.extended';
import ProgressBar from './extendedStyles/ProgressBar.extended';
import ProgressBarStep from './extendedStyles/ProgressBarStep.extended';
import Select from './extendedStyles/Select.extended';
import Slider from './extendedStyles/Slider.extended';
import SliderInput from './extendedStyles/SliderInput.extended';
import SliderStep from './extendedStyles/SliderStep.extended';
import SliderSteps from './extendedStyles/SliderSteps.extended';
import Swiper from './extendedStyles/Swiper.extended';
import TextField from './extendedStyles/TextField.extended';
import theme from '4finance-components/lib/styles/onea';
import Tooltip from './extendedStyles/Tooltip.extended';
import VerticalMenu from './extendedStyles/VerticalMenu.extended';
import VerticalMenuItem from './extendedStyles/VerticalMenuItem.extended';

import { fromJS } from 'immutable';

const extendedTheme = extend(theme, fromJS({
  globals: {
    colors: normalizeColors({
      primary: '#00A9CE',
      secondary: '#FFD100',
      primaryLighter: '#67cae0',
      success: '#b5f40b',
      hoverBlue: '#00769B',
      darkerBlue: '#303A3D',
      error: '#ed4b28',
      darkFont: '#484848',
      lightFont: '#9B9EA2',
      lightestFont: '#C6C6C6',
      grayFont: '#606469',
      background: '#F3F3F3',
      lighterBackground: '#f7f7f7',
      black: '#000',
      errorMessage: '#DE0000',
      alternativeErrorMessage: '#ED420E',
      warningMessage: '#D05702',
      successMessage: '#A5CD38',
      lightBlue: '#CBE4EA',
      inputBorder: '#DCDFE2',
      warningBackground: '#FEFFDA',
    }),
    mediaQueries: {
      breakpointExtraSmall: '@media screen and (max-width: 360px)',
      breakpointStartTablet: '@media screen and (min-width: 769px)',
      breakpointStartLaptop: '@media screen and (min-width: 992px)',
      breakpointStartSmall: '@media screen and (min-width: 480px)',
      breakpointTabletScope: '(max-width: 768px)',
      breakpointSmallScope: '(max-width: 480px)',
      breakpointDesktop: '@media screen and (min-width: 1200px)',
    },
    font: {
      weight: {
        thin: 200,
        normal: 300,
        medium: 400,
        bold: 600,
        black: 800,
      },
    },
  },
  Button,
  ButtonLink,
  Checkbox,
  DateField,
  FieldContainer,
  Modal,
  MultiFieldWrapper,
  OfferRow,
  PanelBlock,
  ProgressBar,
  ProgressBarStep,
  Select,
  Slider,
  SliderInput,
  SliderStep,
  SliderSteps,
  Swiper,
  TextField,
  Tooltip,
  VerticalMenu,
  VerticalMenuItem,
}));

export default extendedTheme;
