import { StyledComponent } from '4finance-components';
import Icon from '../../../components/Icon.react';
import React from 'react';
import RPT from 'prop-types';
import Radium, { Style } from 'radium';

@Radium
export default class ModalFooterInformation extends StyledComponent {
  static styleRoot = 'ModalFooterInformation'
  static propTypes = {
    icon: RPT.string.isRequired,
    children: RPT.node.isRequired,
  }

  render() {
    const { icon, children } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Style scopeSelector=".messageScope" rules={style.messageScope} />
        <Icon kind={icon} size={31} wrapperStyle={style.icon} />
        <div className="messageScope" style={style.message}>
          {children}
        </div>
      </div>
    );
  }

  static style = ({ font, colors }) => ({
    wrapper: {
      backgroundColor: colors.white,
      display: 'flex',
      margin: '20px -20px 0',
      padding: '20px 10px 15px',
    },

    messageScope: {
      '>strong': {
        fontWeight: font.weight.bold,
      },
    },

    icon: {
      margin: '10px',
    },

    message: {
      color: colors.darkFont,
      fontSize: '14px',
      fontWeight: font.weight.thin,
      lineHeight: '20px',
      paddingTop: '7px',
    },
  });
}
