import Icon from './Icon.react';
import Link from './Link.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent, Heading } from '4finance-components';

@Radium
export default class InfoCard extends StyledComponent {
  static styleRoot = 'InfoCard'

  static propTypes = {
    disabled: RPT.bool,
    title: RPT.string.isRequired,
    content: RPT.string.isRequired,
    icon: RPT.string,
    link: RPT.string,
    linkTo: RPT.string,
    inheritedStyle: RPT.oneOfType([
      RPT.object,
      RPT.array,
    ]),
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    const { icon, disabled, inheritedStyle, content, linkTo, title, link } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.wrapper, inheritedStyle, disabled && style.disabled.wrapper]}>
        <Heading kind="h3" inheritedStyle={[style.heading, disabled && style.disabled.heading]}>
          {title}
        </Heading>
        <Icon kind={icon} style={[style.icon, disabled && style.disabled.icon]} size={50} />
        <div style={style.content}>
          <p>{content}</p>
          <Link
            inheritedStyle={[style.link, disabled && style.disabled.link]}
            to={!disabled ? linkTo : ''}
          >
            {link}
          </Link>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundColor: colors.white,
      width: '48%',
      height: '200px',
      borderRadius: '5px',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.07)',
      overflow: 'hidden',
      position: 'relative',
      transition: 'box-shadow 0.3s',

      ':hover': {
        boxShadow: '0 2px 6px 2px rgba(0,0,0,0.07)',
      },
    },

    heading: {
      width: '100%',
      minHeight: '75px',
      borderTopWidth: '6px',
      borderTopStyle: 'solid',
      borderColor: colors.secondary,
      marginTop: 0,
      textTransform: 'uppercase',
      fontSize: '18px',
      paddingTop: '30px',
      paddingLeft: '50px',
      paddingRight: '30%',
      fontWeight: '600',
      lineHeight: '20px',
    },

    icon: {
      position: 'absolute',
      top: '20px',
      right: '30px',
    },

    content: {
      padding: '10px 20% 40px 50px',
    },

    link: {
      fontSize: '19px',
      fontWeight: 500,
      marginTop: '10px',
      lineHeight: '20px',

      [mediaQueries.breakpointDesktop]: {
        marginTop: '25px',
      },
    },

    disabled: {
      wrapper: {
        backgroundColor: colors.monotone1,
        color: colors.monotone2,

        ':hover': {
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.07)',
        },
      },
      heading: {
        borderColor: colors.monotone2,
        color: colors.monotone2,
      },
      icon: {
        fill: colors.monotone2,
      },
      link: {
        color: colors.monotone2,
        pointerEvents: 'none',
      },
    },
  })
}
