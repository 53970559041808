import CalculatorLoanInfoLink from '../../components/Calculator/CalculatorLoanInfoLink.react';
import CalculatorOffer from '../../components/Calculator/CalculatorOffer.react';
import Radium from 'radium';
import React from 'react';
import translate from '4finance-translate';
import { Button, Heading, StyledComponent } from '4finance-components';
import { connectActions, connectPending, connectSelectors } from '../../connect';
import { RPTcalculatorOfferSelector } from '../../../common/calculator/selectors';
import { TAKE_ADDITIONAL_AMOUNT } from '../../../common/application/actions';

@translate
@connectActions({
  confirmApplication: ['application', 'takeAdditionalAmount'],
})
@connectSelectors({
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@connectPending({
  confirmApplicationPending: TAKE_ADDITIONAL_AMOUNT,
})
@Radium
export default class AdditionalCalculatorOffer extends StyledComponent {
  static styleRoot = 'AdditionalCalculatorOffer';

  static propTypes = {
    calculatorOfferSelector: RPTcalculatorOfferSelector,
  }

  render() {
    const { msg, confirmApplication, calculatorOfferSelector: { offerFetched }, confirmApplicationPending } = this.props;
    const isLoading = !offerFetched || confirmApplicationPending;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.block}>
          <Heading kind="h5" inheritedStyle={style.heading}>
            {msg('additionalAmount.offer.newLoan')}
          </Heading>
          <CalculatorOffer
            offerFields={['total_principal', 'additional_monthly_payment', 'term', 'next_installment_date']}
            hasInterestRateAndAnnualPercentageRate
            hasTotalRepayableAmount
          />
        </div>
        <div style={style.block}>
          <Heading kind="h5" inheritedStyle={style.heading}>
            {msg('additionalAmount.offer.actualCondition')}
          </Heading>
          <CalculatorOffer
            offerFields={['current_monthly_payment', 'current_term']}
          />
        </div>
        <div style={style.buttonWrapper}>
          <Button
            disabled={isLoading}
            fullWidth
            loading={isLoading}
            noMargin
            onClick={() => confirmApplication()}
          >
            {msg('additionalAmount.offer.confirmButton')}
          </Button>
        </div>
        <CalculatorLoanInfoLink style={style.loanInfoLink} useOfferValues />
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      padding: '24px 40px',

      [mediaQueries.breakpointTablet]: {
        padding: '24px 15px',
      },
    },

    heading: {
      marginTop: '1.5rem',
      marginLeft: 0,
      marginRight: 0,
      marginBottom: '.2rem',
    },

    block: {
      marginBottom: '24px',
    },

    buttonWrapper: {
      margin: '8px auto',
      maxWidth: '300px',
      textAlign: 'center',
    },
  });
}
