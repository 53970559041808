import CalculatorOfferRepaymentInfo from './CalculatorOfferRepaymentInfo.reac';
import Radium from 'radium';
import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import ValueLoader from '../ValueLoader.react';
import { connectPrefetch, connectSelectors } from '../../../browser/connect';
import { fromJS } from 'immutable';
import { OfferRow } from '4finance-components';
import { RPTcalculatorOfferSelector } from '../../../common/calculator/selectors';

const FAKE_OFFER = fromJS({
  agreementEndDate: new Date(),
  annualPercentageRate: 460.95,
  currentPrincipal: 450.55,
  interestRate: 390.99,
  isFake: true,
  loanExtensionFees: [{ fee: 50.65 }],
  monthlyPayment: 75.99,
  newPrincipal: 400.55,
  term: 24,
  totalPrincipal: 800.99,
  totalRepayableAmount: 1800.55,
  firstPaymentDate: new Date(),
});

const FAKE_LOAN = fromJS({
  schedulePayment: 450.55,
});

const FAKE_NEXT_PAYMENT = {
  amount: 75.99,
};

@connectSelectors({
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@connectPrefetch([
  ['calculator', 'fetchCalculatorWithOffer'],
])
@translate
@Radium
export default class CalculatorOffer extends Component {
  constructor(props) {
    super(props);
    this.blurCalculator(props);
  }

  static propTypes = {
    calculatorOfferSelector: RPTcalculatorOfferSelector,
    hasAgreementEndDate: RPT.bool,
    hasInterestRateAndAnnualPercentageRate: RPT.bool,
    hasTotalRepayableAmount: RPT.bool,
    inverseRow: RPT.bool,
    offerFields: RPT.arrayOf(RPT.oneOf(['current_term', 'total_principal', 'monthly_payment', 'amount', 'term', 'due_date', 'new_principal', 'minimum_payment', 'total_repayable_amount', 'schedule_payment', 'current_principal', 'current_monthly_payment', 'next_installment_date'])),
    preciseNumberToCurrency: RPT.func.isRequired,
    rowStyle: RPT.oneOfType([RPT.object, RPT.array, RPT.func]),
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.array, RPT.func]),
    hasCurrentTotalRepayableAmount: RPT.bool,
    homePage: RPT.bool,
  }

  static defaultProps = {
    offerFields: ['monthly_payment', 'amount', 'term', 'due_date'],
    hasAgreementEndDate: false,
    hasInterestRateAndAnnualPercentageRate: false,
    hasTotalRepayableAmount: false,
    homePage: false,
  };

  offer = FAKE_OFFER;
  loan = FAKE_LOAN;
  nextPayment = FAKE_NEXT_PAYMENT;

  getFieldValue = (fieldName) => {
    const { msg, preciseNumberToCurrency, formatDate, numberToPercentage } = this.props;
    const offer = this.offer;
    const loan = this.loan;
    const nextPayment = this.nextPayment;

    const fieldValues = {
      current_term: { content: msg('date.months', { count: loan.getIn(['loanTerm', 'value']) }) },
      schedule_payment: { content: preciseNumberToCurrency(loan.get('schedulePayment')) },
      due_date: { content: formatDate(offer.get('agreementEndDate')) },
      current_principal: { content: preciseNumberToCurrency(offer.get('currentPrincipal')) },
      total_principal: { content: preciseNumberToCurrency(offer.get('totalPrincipal')) },
      amount: { content: preciseNumberToCurrency(offer.get('totalPrincipal')) },
      total_repayable_amount: { content: preciseNumberToCurrency(offer.get('totalRepayableAmount')) },
      annual_percentage_rate: { content: numberToPercentage(offer.get('annualPercentageRate')) },
      interest_rate: { content: numberToPercentage(offer.get('interestRate')) },
      next_installment_date: { content: formatDate(offer.get('firstPaymentDate')) },
      term: { content: msg('date.months', { count: offer.get('term') }) },
      monthly_payment: { bold: true, content: preciseNumberToCurrency(offer.get('monthlyPayment')) },
      current_monthly_payment: { content: preciseNumberToCurrency(nextPayment.amount) },
      additional_monthly_payment: { content: preciseNumberToCurrency(offer.get('monthlyPayment')) },
      new_principal: { content: preciseNumberToCurrency(offer.get('newPrincipal')) },
      minimum_payment: {
        content: offer.get('loanExtensionFees')
          && offer.get('loanExtensionFees').size > 0
          && preciseNumberToCurrency(offer.get('loanExtensionFees').first().get('fee')),
      },
    };

    return fieldValues[fieldName] || { content: '' };
  }

  blurCalculator(props) {
    const { calculatorOfferSelector: { offer, loan, nextPayment } } = props;

    if (offer.size) this.offer = offer;
    if (loan.size) this.loan = loan;
    if (nextPayment) this.nextPayment = nextPayment;
  }

  shouldComponentUpdate = (nextProps) => {
    const { calculatorOfferSelector: { offerFetched: nextFetched, offer, loan, nextPayment } } = nextProps;
    const { calculatorOfferSelector: { offerFetched: thisFetched } } = this.props;

    if (!nextFetched && !thisFetched) return false;

    if (offer.size) this.offer = offer;
    if (loan.size) this.loan = loan;
    if (nextPayment) this.nextPayment = nextPayment;

    return true;
  }

  renderRow({ fieldName, content, bold, id }) {
    const { msg, inverseRow, rowStyle, calculatorOfferSelector: { offerFetched } } = this.props;
    const isOfferLoading = !offerFetched || this.offer.get('isFake');

    return (
      <OfferRow
        title={msg(`offer.fields.${fieldName}`)}
        key={fieldName}
        inverseColors={inverseRow}
        last={bold}
        style={rowStyle}
      >
        <ValueLoader id={id} value={content} isLoading={isOfferLoading} />
      </OfferRow>
    );
  }

  render() {
    const {
      hasAgreementEndDate,
      hasInterestRateAndAnnualPercentageRate,
      hasTotalRepayableAmount,
      offerFields,
      inheritedStyle,
      hasCurrentTotalRepayableAmount,
      homePage,
    } = this.props;
    const hasRepaymentInfo = hasAgreementEndDate || hasInterestRateAndAnnualPercentageRate || hasTotalRepayableAmount || hasCurrentTotalRepayableAmount;
    const offer = this.offer;
    const loan = this.loan;

    return (
      <div>
        <div style={inheritedStyle}>
          {offerFields.map((fieldName) => this.renderRow({
            fieldName,
            ...this.getFieldValue(fieldName, { offer, loan }),
            id: fieldName,
          }))}
        </div>
        {hasRepaymentInfo && <CalculatorOfferRepaymentInfo
          homePage={homePage}
          hasAgreementEndDate={hasAgreementEndDate}
          hasInterestRateAndAnnualPercentageRate={hasInterestRateAndAnnualPercentageRate}
          hasTotalRepayableAmount={hasTotalRepayableAmount}
          hasCurrentTotalRepayableAmount={hasCurrentTotalRepayableAmount}
        />}
      </div>
    );
  }
}
