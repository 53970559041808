import IBAN from 'iban';

export function serializeBankAccountNumber(bankAccountNumber = '') {
  const getBankAccountNumber = (sanitizedBankAccountNumber) => {
    if (!IBAN.isValid(sanitizedBankAccountNumber)) {
      try {
        return IBAN.fromBBAN('ES', sanitizedBankAccountNumber);
      } catch (exception) {} // eslint-disable-line no-empty
    }

    return sanitizedBankAccountNumber.toUpperCase();
  };

  return {
    bankAccountNumber: getBankAccountNumber(bankAccountNumber.replace(/(\s|-)/gi, '')),
  };
}
