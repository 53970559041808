import translatedField from '../../../lib/translatedField';
import { Checkbox, Button } from '4finance-components';
import { connectSubmit } from '4finance-onion-form';
import { isRequired } from '4finance-validation';

export const AgreeGetNews = translatedField('agreeGetNews', {}, [
  isRequired(),
])(Checkbox);

export const Submit = connectSubmit(Button);
