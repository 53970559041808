/* eslint-disable no-nested-ternary */
import ActionPanel from '../../components/ActionPanel.react';
import Link from '../../components/Link.react';
import MonthlyPayment from './MonthlyPayment.react';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';

@translate
@Radium
export default class LoanManagmentMobile extends Component {
  static styleRoot = 'LoanManagmentMobile'

  static propTypes = {
    extension: RPT.object,
    formatDate: RPT.func,
    loan: RPT.object,
    msg: RPT.func,
    nextPayment: RPT.object,
    activeCard: RPT.object,
    paymentAvailable: RPT.bool,
    isFintonicOrPrestaloProduct: RPT.bool,
    brokenLoan: RPT.bool,
    cardExpired: RPT.bool,
    autoRepayment: RPT.bool,
  }

  static defaultProps = {
    loan: new Map(),
  }

  renderLinksMobile() {
    const { msg, activeCard, cardExpired, autoRepayment } = this.props;

    if (autoRepayment && activeCard) {
      if (cardExpired) {
        return (<Link kind="primary" bold>
          {msg('overview.card.cardInfo.cardAdd')}
        </Link>
        )
      } else {
        return (<Link kind="primary" bold>
          {msg('overview.card.cardInfo.cardSettings')}
        </Link>
        )
      }
    } else {
      return (<Link kind="primary">
        {msg('loan.automaticPayment.activate')}
      </Link>
      )
    }
  }

  render() {
    const { msg, extension, activeCard, loan, paymentAvailable, formatDate, nextPayment, isFintonicOrPrestaloProduct, brokenLoan, cardExpired, autoRepayment } = this.props;
    let autoRepayStatus;
    if (cardExpired) {
      autoRepayStatus = 'expired'
    } else {
      if (loan.get('autoRepay') && activeCard) {
        autoRepayStatus = 'enabled'
      } else {
        autoRepayStatus = 'disabled'
      }
    }
    return (
      <div>
        <MonthlyPayment paymentAvailable={paymentAvailable} />
        <div>
          {!loan.get('debtCollectLoanAgreement') &&
            <div>
              <ActionPanel
                icon="business"
                title={autoRepayment && cardExpired ? msg('loan.automaticPayment.enabled.expired.title') : msg(`loan.automaticPayment.${autoRepayStatus}.title`)}
                content={autoRepayment && cardExpired ? msg('loan.automaticPayment.enabled.expired.text') : msg(`loan.automaticPayment.${autoRepayStatus}.text`, { date: formatDate(nextPayment.date) })}
                link={routesList.autoRepayment}
              >
                {this.renderLinksMobile()}
              </ActionPanel>
              {(!isFintonicOrPrestaloProduct && !brokenLoan) &&
                <div>
                  <ActionPanel
                    icon="postpone"
                    title={msg('loan.defer_payment.title')}
                    content={extension ? msg('loan.defer_payment.text', { fee: (extension || {}).fee }) : msg('loan.defer_payment.text_disabled')}
                    link={routesList.extension}
                    disabled={!extension}
                  />
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

// @Todo: Add diferent replies for Action Panel (Additional amount, Extend)
