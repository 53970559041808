import * as Modal from '../../settings/components/Modal';
import Radium from 'radium';
import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPending, connectActions, connectPrefetch } from '../../connect';
import { PROCESS_SUBMIT_BUDGET_INCOME_CHANGE } from '../../../common/client/actions';
import { TotalIncome } from '../../registration/fields';

const FORM_NAME = 'changeTotalIncome';

@connectActions({
  submitClientBudgetIncomeChange: ['client', 'submitClientBudgetIncomeChange'],
})
@connectPrefetch([
  ['client', 'prefetchChangeTotalIncomeForm'],
])
@connectPending({
  pendingSubmit: PROCESS_SUBMIT_BUDGET_INCOME_CHANGE,
})
@translate
@Radium
export default class ChangeTotalIncome extends Component {
  static propTypes = {
    onClose: RPT.func.isRequired,
    pendingSubmit: RPT.bool.isRequired,
    submitClientBudgetIncomeChange: RPT.func.isRequired,
  }

  handleSubmit = ({ values }) => {
    const { submitClientBudgetIncomeChange } = this.props;

    submitClientBudgetIncomeChange(values, FORM_NAME);
  }

  componentDidUpdate() {
    const { pendingSubmit, onClose } = this.props;

    if (!pendingSubmit) {
      this.onUpdateState();
    }
    if (this.state.isProcessing && !pendingSubmit) {
      onClose();
    }
  }

  onUpdateState() {
    this.setState({ isProcessing: true });
  }

  render() {
    const { msg, onClose, pendingSubmit } = this.props;

    return (
      <Modal.Wrapper
        formName={FORM_NAME}
        formSubmit={this.handleSubmit}
        heading={msg(`modals.${FORM_NAME}.heading`)}
        onClose={onClose}
      >
        <TotalIncome inline labelInside />
        <Modal.Submit disabled={pendingSubmit} loading={pendingSubmit}>
          {msg('modals.partials.submitButton')}
        </Modal.Submit>
      </Modal.Wrapper>
    );
  }
}
// @TODO: Invision design needed.
