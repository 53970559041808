export default function personalId() {
  return (value) => {
    const INVALID_PERSONAL_ID = 'invalid_personal_id';

    if (!value) {
      return INVALID_PERSONAL_ID;
    }

    if (value.match(/^[0-9]{1,7}[A-Z]$/i)) {
      return INVALID_PERSONAL_ID;
    }

    const controlLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const regexp = /^[\dXY]\d{7}[A-Z]$/;

    if (!value.match(regexp)) {
      return INVALID_PERSONAL_ID;
    }

    const controlLetter = value[value.length - 1];
    let firstCharacter = value[0];

    if (firstCharacter.match(/[XY]/)) {
      firstCharacter = firstCharacter.charCodeAt(0) - 'X'.charCodeAt(0);
    }

    const personalID = firstCharacter + value.slice(1);
    const digits = personalID.match(/\d+/)[0];
    const expectedControlDigit = parseInt(digits, 10) % 23;
    const expectedControlLetter = controlLetters[expectedControlDigit];

    return controlLetter === expectedControlLetter ? null : INVALID_PERSONAL_ID;
  };
}
