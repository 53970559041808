import Icon from '../components/Icon.react';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class NotificationBox extends StyledComponent {
  static styleRoot = 'NotificationBox';

  static propTypes = {
    msg: RPT.func,
    onClose: RPT.func,
  }

  render() {
    const { msg, onClose } = this.props;
    const style = this.getStyle();

    return (
      <div id="notification-box" style={style.notificationBox}>
        <Style rules={style.notificationBoxSelector} scopeSelector="#notification-box:after" />
        <Icon kind="close" size={8} onClick={() => onClose(true)} style={style.closeIcon} />
        <p style={style.content}>{msg('notifications.missing_flat_floor_details')}</p>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    notificationBox: {
      position: 'absolute',
      backgroundColor: `${colors.warningBackground}`,
      boxShadow: '1px 2px 8px 2px rgba(0,0,0,.1)',
      padding: '12px',
      zIndex: 1,
      left: '360px',
      width: '210px',
      marginTop: '-10px',

      [mediaQueries.breakpointSmall]: {
        position: 'inherit',
        top: '75px',
        zIndex: 1,
        margin: '25px 0 0',
        width: '100%',
        transform: 'translateY(-50%)',
      },
    },

    content: {
      fontSize: '12px',
    },

    closeIcon: {
      position: 'absolute',
      right: '7px',
      top: '7px',
      fill: colors.grayFont,
      height: '8px',
      width: '8px',
      cursor: 'pointer',
    },

    notificationBoxSelector: {
      width: 0,
      height: 0,
      position: 'absolute',
      left: '-20px',
      transform: 'translateY(-50%)',
      borderWidth: '10px',
      borderStyle: 'solid',
      top: '50%',
      content: '" "',
      borderColor: `transparent  ${colors.warningBackground} transparent transparent`,

      mediaQueries: {
        '(max-width: 480px)': {
          left: '50%',
          transform: 'translateX(-50%)',
          top: '-20px',
          borderColor: `transparent transparent ${colors.warningBackground} transparent`,
        },
      },
    },
  })
}

