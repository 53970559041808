/* global google*/
import config from '4finance-configuration';
import IPT from 'react-immutable-proptypes';
import React from 'react';
import Radium from 'radium';
import Script from 'react-load-script';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { connectActions } from '../../connect';
import { parseAddress } from '../../../common/lib/parseGoogleAddress';
import { PropTypes as RPT } from 'prop-types';
import { PROVINCE_MAPPING } from './data';
import { StyledComponent, TextField } from '4finance-components';

@connectActions({
  fillAddressFields: ['registration', 'fillAddressFields'],
  setMultipleFields: ['onionForm', 'setMultipleFields'],
})
@translate
@Radium
export default class GoogleAddress extends StyledComponent {
  static styleRoot = 'GoogleAddress'

  static propTypes = {
    address: RPT.object,
    name: RPT.string,
    msg: RPT.func,
    googleKey: RPT.string,
    onBlur: RPT.func,
    value: RPT.any,
    error: RPT.string,
    fillAddressFields: RPT.func,
    setMultipleFields: RPT.func,
    onAutoFill: RPT.func,
  }

  static contextTypes = {
    onionLiveValidate: RPT.func.isRequired,
    themeStyle: IPT.map,
  }

  state = {
    scriptLoaded: false,
  }

  buidGoogleConfig() {
    if (this.googleAddressConfig) return this.googleAddressConfig;

    const { urlQueryParams, ...result } = config.googleAutocomplete;
    const query = Object.entries(urlQueryParams).map(pair => pair.join('=')).join('&');

    result.urlQueryParams = query;
    this.googleAddressConfig = result;

    return result;
  }

  @autobind
  handleScriptLoad() {
    const { country, types } = this.buidGoogleConfig();

    this.autocomplete = new google.maps.places.Autocomplete(
      this.input,
      {
        types,
      }
    );
    this.autocomplete.setComponentRestrictions({ country });
    this.autocomplete.addListener('place_changed', this.fillInAddress);

    this.setState({ scriptLoaded: true });
  }

  loadScript() {
    const { scriptLoaded } = this.state;
    const { url, urlQueryParams } = this.buidGoogleConfig();

    if (scriptLoaded) return null;

    return (
      <Script
        url={`${url}?${urlQueryParams}`}
        onLoad={this.handleScriptLoad}
      />
    );
  }

  @autobind
  fillInAddress() {
    const { fillAddressFields, onAutoFill } = this.props;
    const { onionLiveValidate } = this.context;
    const parsedPlace = parseAddress(this.autocomplete.getPlace());
    const { postalCode = '', street: streetName = '', number: streetNumber = '', province: googleProvince = '', city } = parsedPlace;

    // according to the Business requirements the provinces returned by google needs to be mapped into the old province values
    const province = PROVINCE_MAPPING[googleProvince] || googleProvince;

    fillAddressFields({ postalCode, streetName, streetNumber, province, city });
    onAutoFill();
    onionLiveValidate();
  }

  render() {
    return (
      <div>
        {this.loadScript()}
        <TextField
          inputRef={node => { this.input = node; }}
          topMargin={false}
          bottomMargin={false}
          placeholder=""
          {...this.props}
        />
      </div>
    );
  }
}
