import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Button, StyledComponent } from '4finance-components';
import { connectActions, connectPending } from '../../connect';
import { INSTANTOR_CONSENT } from '../../../common/identification/actions';

@connectActions({
  accept: ['identification', 'sendInstantorConsent'],
})
@connectPending({
  pending: INSTANTOR_CONSENT,
})
@translate
@Radium
export default class InstantorConsent extends StyledComponent {
  static styleRoot = 'InstantorConsent'

  static propTypes = {
    msg: RPT.func,
    pending: RPT.bool,
    accept: RPT.func.isRequired,
  }

  accept = () => {
    const { accept } = this.props;

    accept();
  }

  render() {
    const { pending, msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.header}>{msg('identification.online.consent.title')}</div>
        <div style={style.subtitle}>{msg('identification.online.consent.subtitle')}</div>
        <div style={style.description}>{msg('identification.online.consent.description')}</div>
        <Button
          inheritedStyle={style.button}
          onClick={this.accept}
          disabled={pending}
        >
          {msg('common.accept')}
        </Button>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      marginBottom: '130px',

      [mediaQueries.breakpointSmall]: {
        marginBottom: '30px',
      },
    },

    header: {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '27px',
    },

    subtitle: {
      fontSize: '14px',
      lineHeight: '17px',
      color: colors.lightFont,
    },

    description: {
      fontSize: '14px',
      lineHeight: '20px',
      color: colors.grayFont,
      marginTop: '30px',
      marginBottom: '30px',
    },

    button: {
      textAlign: 'center',
      maxWidth: '300px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',

      [mediaQueries.breakpointTablet]: {
        width: '100%',
      },
    },
  });
}
