export default function getNextPayment(iLoan, iSchedule) {
  if (!iLoan) { return {}; }
  const loan = iLoan.toJS ? iLoan.toJS() : iLoan;

  let result = {
    amount: loan.payableAmount - (loan.penaltyAmount || 0),
    penalty: loan.penaltyAmount,
    total: loan.payableAmount,
    date: loan.nearestPaymentDate,
  };

  if (result.amount + result.penalty <= 0) {
    if (iSchedule) {
      const items = iSchedule.toJS ? iSchedule.toJS().items : iSchedule;

      if (items) {
        const nextItem = items.find((item) => !item.status);

        if (nextItem) {
          result = {
            amount: nextItem.totalAmount || 0,
            penalty: nextItem.penaltyAmount || 0,
            total: (nextItem.totalAmount || 0) + (nextItem.penaltyAmount || 0),
            date: nextItem.dueDate,
          };
        }
      }
    }
  }

  return result;
}
