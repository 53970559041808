import config from '4finance-configuration';
import getExtension from '../../lib/getExtension';
import Icon from '../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import ResponsiveMenuFaqItems from './ResponsiveMenuFaqItems.react';
import ResponsiveMenuHeader from './ResponsiveMenuHeader.react';
import ResponsiveMenuItem from './ResponsiveMenuItem.react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPrefetch, connectState, connectActions, connectSelectors } from '../../connect';
import { STAGE_COLA_LINK } from '../Header.react';
import { StyledComponent, VerticalMenu } from '4finance-components';

@connectPrefetch([
  ['loans', 'fetchLatestLoan'],
  ['client', 'fetchClientLatestLoanExtensions'],
])
@connectState({
  fullName: ['api', 'fetch', 'client', 'query', 'data', 'fullName'],
  clientId: ['api', 'fetch', 'client', 'query', 'data', 'id'],
  isLoggedIn: ['authentication', 'isLoggedIn'],
  isMobileMenuOpen: ['ui', 'isMobileMenuOpen'],
  isPaymentProcessing: ['loans', 'isPaymentProcessing'],
  extensions: ['api', 'fetch', 'client', 'loans', 'extensions', 'root', 'latest', 'data'],
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  activeCard: ['client', 'activeCard'],
  hasLatestLoan: ['loans', 'hasLatestLoan'],
})
@connectSelectors({
  additionalAmountAvailable: ['client', 'additionalAmountAvailable'],
  isFintonicOrPrestaloProduct: ['client', 'isFintonicOrPrestaloProduct'],
  isPrestaloProduct: ['client', 'isPrestaloProduct'],
})
@connectActions({
  fetchLatestLoan: ['loans', 'fetchLatestLoan'],
  fetchExtensions: ['client', 'fetchClientLatestLoanExtensions'],
})
@translate
@Radium
export default class ResponsiveMenu extends StyledComponent {
  static styleRoot = 'ResponsiveMenu'

  static propTypes = {
    isFintonicOrPrestaloProduct: RPT.bool,
    isPrestaloProduct: RPT.bool,
    additionalAmountAvailable: RPT.bool,
    clientId: RPT.number,
    extensions: RPT.object,
    fetchExtensions: RPT.func.isRequired,
    fetchLatestLoan: RPT.func.isRequired,
    fullName: RPT.string,
    isLoggedIn: RPT.bool.isRequired,
    isMobileMenuOpen: RPT.bool,
    isPaymentProcessing: RPT.bool,
    msg: RPT.func.isRequired,
    pathname: RPT.string.isRequired,
    loan: RPT.object,
    hasLatestLoan: RPT.bool,
  }

  componentDidUpdate({ isMobileMenuOpen }) {
    const { fetchLatestLoan, fetchExtensions } = this.props;

    if (!this.props.isMobileMenuOpen && isMobileMenuOpen) {
      fetchLatestLoan();
      fetchExtensions();
    }
  }

  renderMenuItem(key, to, { activeExactly = true, external, target } = {}) {
    const { msg, pathname } = this.props;

    return (
      <ResponsiveMenuItem
        activeExactly={activeExactly}
        external={external}
        key={key}
        label={msg(`menu.${key}`)}
        pathname={pathname}
        target={target}
        to={to}
      />
    );
  }

  renderUserMenuItem(key, to, { activeExactly = true, external, target } = {}) {
    const { msg, pathname } = this.props;
    const style = this.getStyle();

    const label = (
      <p>
        <Icon kind={key} size={20} style={style.icon} />
        {msg(`menu.${key}`)}
      </p>
    );

    return (
      <ResponsiveMenuItem
        activeExactly={activeExactly}
        external={external}
        key={key}
        label={label}
        pathname={pathname}
        target={target}
        to={to}
      />
    );
  }

  render() {
    const {
      extensions,
      isPaymentProcessing,
      isFintonicOrPrestaloProduct,
      isPrestaloProduct,
      additionalAmountAvailable,
      clientId,
      fullName,
      isLoggedIn,
      isMobileMenuOpen,
      msg,
      loan,
      activeCard,
      hasLatestLoan,
    } = this.props;
    const style = this.getStyle();
    const extension = getExtension(extensions);
    const debtCollectLoanAgreement = loan && loan.get('debtCollectLoanAgreement');
    const autoRepayStatus = loan && loan.get('autoRepay') && activeCard ? 'enabled' : 'disabled';

    return (
      <div id="responsiveMenu" style={[style.container, isMobileMenuOpen && style.container.opened]}>
        <div style={style.delimiter} />
        {isLoggedIn ?
          <div>
            <ResponsiveMenuHeader fullName={fullName} msg={msg} />
            <VerticalMenu>
              {this.renderUserMenuItem('dashboard', routesList.dashboard)}
              {hasLatestLoan &&
                [
                  !debtCollectLoanAgreement && autoRepayStatus !== 'enabled' && this.renderUserMenuItem(`automaticPayment_${autoRepayStatus}`, routesList.autoRepayment),
                  !debtCollectLoanAgreement && !isPaymentProcessing && this.renderUserMenuItem('monthly_payments', routesList.payment),
                  !debtCollectLoanAgreement && !isFintonicOrPrestaloProduct && additionalAmountAvailable && this.renderUserMenuItem('additional_amount', routesList.additionalAmount),
                  !debtCollectLoanAgreement && !isPrestaloProduct && extension && this.renderUserMenuItem('postpone_payment', routesList.extension),
                  this.renderUserMenuItem('full_refund', routesList.paymentFull),
                ]
              }
              {this.renderUserMenuItem('user_profile', routesList.settings, { activeExactly: false })}
              {config.faker.enabled && this.renderUserMenuItem('cola', STAGE_COLA_LINK.replace('{id}', clientId), { external: true, target: '_blank' })}
            </VerticalMenu>
          </div>
          :
          <VerticalMenu>
            {this.renderMenuItem('features', routesList.contentFeatures, { activeExactly: false })}
            {this.renderMenuItem('blog', routesList.blog, { external: true })}
            {this.renderMenuItem('contact', routesList.contentContact, { activeExactly: false })}
            {this.renderMenuItem('about_us', routesList.contentAboutUs, { activeExactly: false })}
            {this.renderMenuItem('faq', routesList.contentFaq)}
            {this.renderMenuItem('sign_in', routesList.signin, { type: 'button' })}
            <ResponsiveMenuFaqItems />
          </VerticalMenu>
        }
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      display: 'none',
      padding: '60px 0 0',
      background: colors.white,
      width: '100%',
      height: 0,
      position: 'relative',
      overflow: 'hidden',
      top: 0,
      bottom: 0,
      left: 0,
      transitionProperty: 'height',
      transitionDuration: '.5s',
      transitionTimingFunction: 'ease-in',

      opened: {
        height: '100vh',
        transitionDuration: '.2s',
        borderBottom: `1px solid ${colors.grayLighter}`,
        overflowY: 'auto',
      },

      [mediaQueries.breakpointTablet]: {
        padding: '50px 0 0',
      },

      [mediaQueries.breakpointLaptopSmall]: {
        display: 'block',
      },
    },

    delimiter: {
      height: '1px',
      width: '100%',
      background: colors.monotone1,
    },

    icon: {
      fill: colors.primary,
      marginBottom: '-4px',
      marginRight: '15px',
    },
  })
}
