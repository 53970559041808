import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { Style } from 'radium';

export default class InlineFonts extends Component {
  static propTypes = {
    fontFamily: RPT.string.isRequired,
    fonts: RPT.array.isRequired,
    formats: RPT.array.isRequired,
  }

  render() {
    const { fontFamily, fonts, formats } = this.props;

    return (
      <div>
        {fonts.map((font) => (
          <Style
            key={font.name}
            rules={{
              '@font-face': {
                fontFamily,
                fontStyle: 'normal',
                fontWeight: font.weight,
                src: formats
                  .map((formatExtension) => {
                    const format = formatExtension === 'ttf' ? 'truetype' : formatExtension;

                    return `url("/assets/fonts/${font.name}.${formatExtension}") format("${format}")`;
                  })
                  .join(','),
              },
            }}
          />
        ))}
      </div>
    );
  }
}
