import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectSelectors, connectPrefetch } from '../connect';
import { StyledComponent, Link } from '4finance-components';

@connectSelectors({
  orderedFaqsSelector: ['faqs', 'orderedFaqsSelector'],
})
@connectPrefetch([
  ['faqs', 'prefetchFaqs'],
])
@translate
@Radium
export default class FaqLinkItems extends StyledComponent {
  static styleRoot = 'FaqLinkItems';

  static propTypes = {
    orderedFaqsSelector: RPT.array.isRequired,
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
    limit: RPT.number.isRequired,
  }

  static defaultProps = {
    inheritedStyle: {},
  }

  renderFaq = (faq) => {
    const styles = this.getStyle();
    const { fields: { title, anchorId } } = faq;
    const { inheritedStyle } = this.props;

    return (
      <li key={anchorId} style={[styles.item, inheritedStyle.item]}>
        <Link inheritedStyle={[styles.link, inheritedStyle.link]} to={`${routesList.contentFaq}#${anchorId}`}>
          {title}
        </Link>
      </li>
    );
  };

  render() {
    const { limit, orderedFaqsSelector } = this.props;

    return (<div>{orderedFaqsSelector
      .map(this.renderFaq)
      .slice(0, limit)}</div>);
  }

  static style = {
    item: {
      padding: '10px 0',
      fontSize: '18px',
    },
  }
}
