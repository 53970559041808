import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { Checkbox } from '4finance-components';

export default class CheckboxWithHtmlText extends Component {
  static propTypes = {
    label: RPT.string,
  }

  render() {
    const { label, ...props } = this.props;

    return (
      <Checkbox
        {...props}
        label={label && <span className="cnt" dangerouslySetInnerHTML={{ __html: label }} />}
      />
    );
  }
}
