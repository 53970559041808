import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { connectActions, connectContext } from '../../connect';
import { MultiField, StyledComponent } from '4finance-components';

@connectActions({
  setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
})
@connectContext({
  formName: 'onionFormName',
  validate: 'onionLiveValidate',
})
@Radium
export default class BankAccountNumber extends StyledComponent {
  static styleRoot = 'BankAccountNumber'

  static propTypes = {
    setFormFieldProperty: RPT.func.isRequired,
    formName: RPT.string.isRequired,
    validate: RPT.func.isRequired,
  }

  onChange = ({ name, value }) => {
    const { formName, validate, setFormFieldProperty } = this.props;

    setFormFieldProperty(formName, name, 'value', value, '');
    validate();
  }

  render() {
    const style = this.getStyle();

    return (
      <div id="bank-account-number">
        <Style scopeSelector="#bank-account-number" rules={style.static} />
        <MultiField
          {...this.props}
          style={style}
          inheritedStyle={style.inherited}
          onChange={this.onChange}
          prefix="ES"
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    inherited: {
      input: {
        width: 'calc(4ch + 15px)',
        marginLeft: '3px',
        padding: '0 0 15px 0',

        [mediaQueries.breakpointSmall]: {
          width: '6ch',
        },
      },
    },

    static: {
      'input::-webkit-input-placeholder': {
        color: colors.lightestFont,
      },

      'input::-moz-placeholder': {
        color: colors.lightestFont,
      },

      'input:-ms-input-placeholder': {
        color: colors.lightestFont,
      },

      'input:-moz-placeholder': {
        color: colors.lightestFont,
      },
    },

    label: {
      positioned: {
        top: '20px',
        left: '10px',
        fontSize: '14px',

        [mediaQueries.breakpointSmall]: {
          top: '7px',
          left: '10px',
          textAlign: 'left',
          fontSize: '11px',
        },
      },
    },

    wrapper: {
      full: {
        [mediaQueries.breakpointStartSmall]: {
          borderTop: `solid 1px ${colors.inputBorder}`,
          borderLeft: `solid 1px ${colors.inputBorder}`,
          borderRight: `solid 1px ${colors.inputBorder}`,
          borderBottom: `solid 3px ${colors.inputBorder}`,
          height: '80px',
        },
      },

      padded: {
        paddingRight: '5px',
        paddingLeft: '5px',
        paddingTop: '48px',

        [mediaQueries.breakpointSmall]: {
          paddingTop: '19px',
        },
      },
    },

    indicator: {
      top: '30%',
      [mediaQueries.breakpointTablet]: {
        transform: 'translateY(-40%) scale(0.8)',
        right: '10px',
      },
      [mediaQueries.breakpointSmall]: {
        top: '25%',
        right: '2%',
      },
    },

    fieldGroup: {
      display: 'flex',
    },

    container: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: '-5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    char: {
      fontSize: '18px',
      letterSpacing: '2px',
      fontFamily: '"ProximaNova", "Lucida Grande", "sans-serif"',

      [mediaQueries.breakpointSmall]: {
        fontSize: '3.5vw',
        letterSpacing: '2px',
        marginTop: '10px',
      },
    },

    teeth: {
      display: 'none',
    },

    delimiter: {
      height: '20px',
      background: colors.inputBorder,
      width: '2px',
      display: 'inline-block',
      margin: '13px 0 0 0',

      [mediaQueries.breakpointSmall]: {
        height: '15px',
        margin: '18px 0 0 0',
      },
    },
  })
}
