import DifferentMethod from '../identification/components/DifferentMethod.react';
import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, Heading, List, StyledComponent } from '4finance-components';

@translate
@Radium
export default class InstantorConsent extends StyledComponent {
  static styleRoot = 'InstantorConsent'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    pending: RPT.bool,
  }

  render() {
    const { cnt, msg, routeParams: { error = 'process-error' } } = this.props;
    const style = this.getStyle();
    const reasons = msg('identification.online.errors.reasons.items');
    const solutions = msg('identification.online.errors.solutions.items');

    return (
      <div style={style.wrapper}>
        <Jumbotron
          icon="resolution-sadface"
          title={cnt(`identification.online.errors.${error}.title`)}
          textAlign="center"
          kind="big"
          inheritedStyle={style.jumbotron}
        >
          <div style={style.innerWrapper}>
            <Heading kind="h3" inheritedStyle={style.heading}>
              {msg('identification.online.errors.reasons.title')}
            </Heading>

            <List
              items={reasons}
              style={style.list}
            />

            <Heading kind="h3" inheritedStyle={style.heading}>
              {msg('identification.online.errors.solutions.title')}
            </Heading>

            <List
              items={solutions}
              style={style.list}
            />

            {error !== 'too-many-retries' &&
              <ButtonLink
                to={routesList.applicationIdentificationInstantorConsent}
                inheritedStyle={style.button}
              >
                {msg('identification.online.errors.buttons.tryAgain')}
              </ButtonLink>
            }

            {cnt('identification.online.errors.contact')}
          </div>
        </Jumbotron>

        <DifferentMethod
          types={['upload', 'whatsapp']}
        />
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      [mediaQueries.breakpointTablet]: {
        marginBottom: '80px',
      },
    },

    jumbotron: {
      paddingBottom: '50px',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },

      title: {
        fontWeight: 'bold',
      },
    },

    heading: {
      fontSize: '18px',
      marginBottom: '15px',
    },

    list: {
      list: {
        marginBottom: '30px',
        fontSize: '14px',
      },

      item: {
        marginTop: 0,
      },
    },


    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '300px',
      marginTop: 0,
      marginBottom: '30px',
    },

    paragraph: {
      fontSize: '14px',
      lineHeight: '20px',
    },

    innerWrapper: {
      textAlign: 'left',
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  })
}
