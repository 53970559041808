import Jumbotron from '../../components/Jumbotron';
import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import PageHeader from '../../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../../components/RandomFill.react';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectFunnels } from '../../connect';
import { Form } from '4finance-onion-form';
import { Loader, StyledComponent } from '4finance-components';
import { OTHER_OPTION } from '../../components/BankRadioInputGroup.react';

import {
  Bank,
  Submit,
} from './fields';

const FORM_NAME = 'chooseBank';

@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  push: ['routing', 'push'],
})
@connectFunnels
@translate
@listenWindowResize
@Radium
export default class InstantorChooseBank extends StyledComponent {
  static styleRoot = 'InstantorChooseBank'

  static propTypes = {
    device: RPT.instanceOf(Device).isRequired,
    cnt: RPT.func,
    msg: RPT.func,
    randomFill: RPT.func.isRequired,
    banksLoaded: RPT.bool,
    banks: RPT.array,
    push: RPT.func,

  }

  onSubmit = ({ values: { bank } }) => {
    const { onBankSelect, push } = this.props;

    if (bank === OTHER_OPTION) {
      push(routesList.applicationIdentificationDocumentUpload);

      return;
    }

    onBankSelect(bank);
  }

  sendOtherOption = () => this.props.push(this.props.funnelPaths[routesList.applicationIdentificationDocumentUpload]);;

  randomFill = () => this.onSubmit({ values: { bank: 'es-lacaixa' } });

  render() {
    const { banksLoaded, banks, cnt, device, msg } = this.props;
    const style = this.getStyle();

    const isScreenWidthUnderPhone = device.atMost('phone');

    if (!banksLoaded) {
      return <Loader />;
    }

    return (
      <div>
        <PageHeader
          title={msg('identification.online.title')}
          subtitle={cnt('identification.online.subtitle')}
        />

        <Jumbotron inheritedStyle={style.wrapper}>
          <Form
            ref={this.addFormRef}
            name={FORM_NAME}
            onSubmit={this.onSubmit}
          >
            <div style={style.form}>
              <Bank
                isPhone={isScreenWidthUnderPhone}
                options={banks}
                fromInstantor
                onSubmit={this.onSubmit}
              />
            </div>
            {!isScreenWidthUnderPhone &&
              <div>
                <Submit
                  loadingText={msg('common.loading')}
                  inheritedStyle={style.button}
                >
                  {msg('common.continue')}
                </Submit>
                <a
                  onClick={this.sendOtherOption}
                  style={style.link}
                >
                  {msg('identification.online.notListed')}
                </a>
              </div>
            }
          </Form>
        </Jumbotron>
        <RandomFill
          submit={this.randomFill}
          submitOnly
          center
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      [mediaQueries.breakpointSmall]: {
        backgroundColor: 'inherit',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
      },
    },

    form: {
      backgroundColor: colors.white,

      [mediaQueries.breakpointSmall]: {
        marginBottom: '30px',
      },
    },

    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',

      [mediaQueries.breakpointSmall]: {
        width: '90%',
      },
    },

    link: {
      display: 'block',
      textAlign: 'center',
      textDecoration: 'underline',
      fontSize: '18px',
      marginTop: '25px',
      cursor: 'pointer',
    },
  });
}
