import { createSelector } from '4finance-connect';

export const COMPLETED_PAYMENT_COOKIE = 'onlinePaymentCreatedOn';

const apiSelector = state => state.api;
const persistenceSelector = state => state.persistence;

export const duplicatedPayment = createSelector(
  [apiSelector],
  (api) => api.getIn(['send', 'cardpayments', 'query', 'data', 'status']) === 'ERROR'
);

export const paymentPending = createSelector(
  [persistenceSelector],
  (persistence) => !!persistence.get(COMPLETED_PAYMENT_COOKIE)
);
