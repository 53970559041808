import PhoneCodeComponent from '../../PhoneCode.react';
import translatedField from '../../../lib/translatedField';
import { Button } from '4finance-components';
import { connectSubmit } from '4finance-onion-form';
import { isRequired } from '4finance-validation';

export const PhoneCode = translatedField('webCode', {}, [
  isRequired(),
])(PhoneCodeComponent);

export const Submit = connectSubmit(Button);
