import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPrefetch, connectState } from '../connect';
import { Link } from 'react-router';

const routes = {
  '/sitemap': 'Sitemap',
};

@connectState({
  pages: ['contentful', 'sitemap', 'data'],
})
@connectPrefetch([
  ['contentful', 'fetchContentfulSitemap'],
])
@translate
@Radium
export default class Sitemap extends Component {
  static propTypes = {
    msg: RPT.func,
    pages: RPT.array,
  }

  renderRouteLink(link, name) {
    return (
      <li key={link}>
        <Link to={link}>{name}</Link>
      </li>
    );
  }

  renderPageLink({ fields: { url, pageTitle } }) {
    return (
      <li key={url}>
        <Link to={url}>{pageTitle}</Link>
      </li>
    );
  }

  render() {
    const { pages } = this.props;

    return (
      <div>
        {Object.keys(routes).map(route => this.renderRouteLink(route, routes[route]))}
        {pages && pages.map(page => this.renderPageLink(page))}
      </div>
    );
  }
}
