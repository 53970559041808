export default {
  column: {
    padding: '10px 0',
  },
  row: {
    last: {
      borderWidth: '0 0 1px 0',
    },
  },
  text: {
    bold: {
      fontWeight: 'bold',
      fontSize: '17px',
    },
  },
};
