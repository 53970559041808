import { Record } from 'immutable';
import * as actions from './actions';

const InitialState = Record({
  isPaymentProcessing: false,
  paymentNotificationVisible: false,
  paymentStatus: null,
});
const initialState = new InitialState;

export default function paymentReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case actions.PREFETCH_PAYMENT_DETAILS_SUCCESS: {
      const { clientData } = action.payload;

      return state
        .set('isPaymentProcessing', clientData.validationResolutionDetail === 'LOAN_IN_PROGRESS');
    }

    case actions.MAKE_CARD_PAYMENT_SUCCESS: {
      return state
        .set('paymentNotificationVisible', true)
        .set('paymentStatus', 'success');
    }

    case actions.MAKE_CARD_PAYMENT_ERROR: {
      return state
        .set('paymentNotificationVisible', true)
        .set('paymentStatus', 'error');
    }

    case actions.DISMISS_PAYMENT_NOTIFICATION: {
      return state
        .set('paymentNotificationVisible', false);
    }
  }

  return state;
}
