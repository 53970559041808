import marked from 'marked';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StaticPageLayout from '../../layouts/StaticPage.react';
import Title from '../components/Title.react';
import translate from '4finance-translate';
import { StyledComponent, Column, Row, Container } from '4finance-components';

@translate
@Radium
export default class MarketingAndCommunication extends StyledComponent {
  static styleRoot = 'MarketingAndCommunication'

  static propTypes = {
    page: RPT.object.isRequired,
  }

  renderTable = () => {
    const { msg, page: { fields } } = this.props;
    const style = this.getStyle();
    const { purpose, recipients, responsable, legitimation, rights } = fields;
    const tableList = { responsable, purpose, legitimation, recipients, rights };

    return Object.keys(tableList).map((key) => (
      <tr>
        <th style={style.tableHead}>
          {msg(`contentful.marketingAndCommunicationPage.${key}`)}
        </th>
        <td style={style.tableContent}>
          <div dangerouslySetInnerHTML={{ __html: marked(tableList[key]) }} />
        </td>
      </tr>
    ));
  }

  render() {
    const { page: { fields } } = this.props;
    const { url, pageHeading, textBlock } = fields;
    const style = this.getStyle();

    return (
      <StaticPageLayout location={{ pathname: url }} >
        <Style scopeSelector=".marketingAndCommunication-page" rules={{ ...style.marketingAndCommunicationStyle }} />
        <Title decorated text={pageHeading} />

        <Container>
          <Row>
            <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
              <article className="marketingAndCommunication-page" dangerouslySetInnerHTML={{ __html: marked(textBlock) }} />
            </Column>
          </Row>
          <Row>
            <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
              <table className="marketingAndCommunication-page" style={style.tableWrapper}>
                <tbody>
                  {this.renderTable()}
                </tbody>
              </table>
            </Column>
          </Row>
        </Container>
      </StaticPageLayout >
    );
  }

  static style = ({ colors }) => ({
    marketingAndCommunicationStyle: {
      p: {
        marginBottom: '8px',
      },
    },
    tableWrapper: {
      margin: '30px 0',
      borderCollapse: 'collapse',
      tableLayout: 'auto',
    },
    tableHead: {
      verticalAlign: 'top',
      textAlign: 'left',
      padding: '7px',
      backgroundColor: colors.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.gray,
      whiteSpace: 'nowrap',
    },
    tableContent: {
      verticalAlign: 'top',
      padding: '9px',
      backgroundColor: colors.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.gray,
      fontSize: '14px',
    },
  });
}
