import forbidAuth from './forbidAuth';
import requireAuth from './requireAuth';
import requireExtension from './requireExtension';
import forbidBankAccount from './forbidBankAccount';
import forbidCardAdded from './forbidCardAdded';
import forbidFintonic from './forbidFintonic';

export default {
  forbidAuth,
  requireAuth,
  requireExtension,
  forbidBankAccount,
  forbidCardAdded,
  forbidFintonic,
};
