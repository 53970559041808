export default [
  'Avenida',
  'Barrio',
  'Calle',
  'Camino',
  'Carrer',
  'Carretera',
  'Pasaje',
  'Paseo',
  'Plaza',
  'Rambla',
  'Ronda',
  'Rúa',
  'Travesía',
  'Urbanización',
];
