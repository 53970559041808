import DifferentMethod from '../components/DifferentMethod.react';
import PageContainer from '../../components/PageContainer.react';
import PageHeader from '../../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import UploadBox from '../../components/upload';
import { EXTRA, BANK_RECEIPT } from '../../components/upload/consts';
import { StyledComponent } from '4finance-components';
import { connectActions, connectFunnels, connectPrefetch } from '../../connect';

const PHOTO_FIELDS = [BANK_RECEIPT, EXTRA];

@connectActions({
  uploadIdentificationFiles: ['documentUpload', 'uploadIdentificationFiles'],
  clearAllFiles: ['documentUpload', 'clearAllFiles'],
})
@connectPrefetch([
  ['identification', 'getRiskRules'],
])
@connectFunnels
@translate
@Radium
export default class DocumentUpload extends StyledComponent {
  static styleRoot = 'DocumentUpload'

  static propTypes = {
    cnt: RPT.func,
    funnelPaths: RPT.object.isRequired,
    msg: RPT.func,
  }

  handleUpload = () => {
    const { funnelPaths, uploadIdentificationFiles } = this.props;

    uploadIdentificationFiles(funnelPaths[routesList.applicationIdentificationProcessing]);
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <PageContainer width="narrow" mobileFullScreen style={style.container}>
        <PageHeader
          title={msg('identification.title')}
          textAlign="center"
          style={style.heading}
        />

        <UploadBox
          fields={PHOTO_FIELDS}
          onClick={this.handleUpload}
          heading={msg('identification.title')}
        />

        <DifferentMethod
          types={['instantor', 'whatsapp']}
        />
      </PageContainer>
    );
  }

  static style = ({ mediaQueries }) => ({
    heading: {
      wrapper: {
        [mediaQueries.breakpointSmall]: {
          display: 'none',
        },
      },
    },

    container: {
      mobileFullScreen: {
        [mediaQueries.breakpointTablet]: {
          marginBottom: '20px',
        },
      },
    },
  });
}
