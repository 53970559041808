import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { connectActions } from '../../browser/connect';
import { FUNNEL_ROUTES } from '../routesList';

export default function connectFunnels(WrappedComponent) {
  class WithFunnels extends Component {
    static propTypes = {
      getFunnelPath: RPT.func.isRequired,
    };

    paths = null;

    constructor(props) {
      super(props);

      this.funnelPaths();
    }

    funnelPaths = () => {
      if (!this.paths) this.paths = FUNNEL_ROUTES.reduce((acc, path) => ({ ...acc, [path]: this.props.getFunnelPath(path).payload }), {});

      return this.paths;
    };

    render() {
      return (<WrappedComponent
        {...this.props}
        funnelPaths={this.paths}
      />);
    }
  }

  return connectActions({
    getFunnelPath: ['application', 'nextApplicationFunnelPath'],
  })(WithFunnels);
}
