import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class OtherContactItem extends StyledComponent {
  static styleRoot = 'OtherContactItem'

  static propTypes = {
    kind: RPT.string.isRequired,
    title: RPT.string,
  };

  render() {
    const { msg, children, kind, title } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <strong
          style={style.strong}
          dangerouslySetInnerHTML={{ __html: title || msg(`contentful.contactPage.otherContacts.${kind}`) }}
        />
        <div style={style.content.wrapper}>
          <div style={[style.icon, style.icon[kind]]} />
          <div style={style.content}>{children}</div>
        </div>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      marginBottom: '30px',
    },

    strong: {
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: '20px',
      display: 'block',

      [mediaQueries.breakpointTablet]: {
        textAlign: 'center',
      },
    },

    content: {
      fontSize: '18px',
      fontWeight: 400,

      wrapper: {
        display: 'flex',

        [mediaQueries.breakpointTablet]: {
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        },
      },
    },

    icon: {
      width: '42px',
      height: '42px',
      marginRight: '10px',

      [mediaQueries.breakpointTablet]: {
        marginRight: 0,
        marginBottom: '10px',
      },

      location: {
        background: 'url("/assets/img/location-icon.png") no-repeat',
      },

      openingHours: {
        background: 'url("/assets/img/calendar-icon.png") no-repeat',
      },

      complaintsPhone: {
        background: 'url("/assets/img/phone-icon.png") no-repeat',
      },
    },
  })
}
