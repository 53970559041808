import AlternativeProposal from '../components/AlternativeProposal.react';
import CalculatorOffer from '../components/Calculator/CalculatorOffer.react';
import Jumbotron, { YellowBox } from '../components/Jumbotron';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../components/RandomFill.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { AcceptAgreement, Submit } from '../registration/fields';
import { connectActions, connectPending, connectPrefetch, connectState } from '../connect';
import { Form } from '4finance-onion-form';
import { Heading, StyledComponent } from '4finance-components';
import { Map } from 'immutable';
import { SUBMIT_PROPOSAL } from '../../common/approval/actions';

const FORM_NAME = 'proposal';

@connectPrefetch([
  ['approval', 'fetchProposal'],
  ['api', 'fetchClientApplication'],
])
@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  submitProposal: ['approval', 'submitProposal'],
})
@connectPending({
  pending: SUBMIT_PROPOSAL,
})
@connectState({
  application: ['api', 'fetch', 'client', 'application', 'query', 'data'],
  proposal: ['api', 'fetch', 'client', 'application', 'proposal', 'query', 'data'],
})
@translate
@Radium
export default class ApprovalProposal extends StyledComponent {
  static styleRoot = 'ApprovalProposal';

  static propTypes = {
    submitProposal: RPT.func.isRequired,
    randomFill: RPT.func.isRequired,
    pending: RPT.bool,
    proposal: RPT.object,
  }

  getProposal = () => {
    const { application, msg, proposal } = this.props;
    const { term: proposalTerm, amount: proposalAmount } = (proposal || Map()).toJS();
    const { term: applicationTerm, amount: applicationAmount } = (application || Map()).toJS();

    const style = this.getStyle();
    const amountUnit = <span style={style.amount}>{msg('calculator.amountSlider.unit')}</span>;
    const termUnit = ` ${msg('calculator.term.unit')}`;

    const termDifferent = applicationTerm !== proposalTerm;
    const amountDifferent = applicationAmount !== proposalAmount;
    const reduction = {};

    if (termDifferent) {
      reduction.term = { from: { value: applicationTerm }, to: { value: proposalTerm, unit: termUnit } };
    }

    if (amountDifferent) {
      reduction.amount = { from: { value: applicationAmount, unit: amountUnit }, to: { value: proposalAmount, unit: amountUnit } };
    }

    const getTitle = () => {
      if (reduction.term && reduction.amount) return 'both';
      if (reduction.term) return 'term';

      return 'amount';
    };

    return {
      title: getTitle(),
      reduction,
    };
  }

  onSubmit = () => {
    const { submitProposal } = this.props;

    submitProposal();
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  render() {
    const { cnt, msg, pending, proposal } = this.props;
    const { title, reduction: { term, amount } } = this.getProposal();
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <PageHeader
          title={msg('approval.proposal.title')}
          textAlign="center"
          inheritedStyle={style.header}
        />
        <Jumbotron
          inheritedStyle={style.jumbotron}
          hasPadding={false}
        >
          <YellowBox
            title={msg(`approval.proposal.${title}`)}
            hasPadding
            style={style.yellowBox}
          >
            {amount && <AlternativeProposal from={amount.from} to={amount.to} />}
            {term && <AlternativeProposal from={term.from} to={term.to} />}

            <div style={style.paragraph}>
              {cnt('approval.proposal.description')}
            </div>

          </YellowBox>
          <div style={style.container}>
            <Heading kind="h3" inheritedStyle={style.heading}>
              {msg('calculator.title')}
            </Heading>

            <CalculatorOffer
              hasAgreementEndDate
              hasInterestRateAndAnnualPercentageRate
              hasTotalRepayableAmount
            />

            <Form
              ref={this.addFormRef}
              name={FORM_NAME}
              onSubmit={this.onSubmit}
            >
              <AcceptAgreement
                inline
                label={msg('form.registration.acceptAgreement.label', { amount: proposal.get('amount'), term: proposal.get('term') })}
                style={style.checkbox}
              />
              <Submit
                loadingText={msg('common.loading')}
                inheritedStyle={style.button}
              >
                {msg('common.accept')}
              </Submit>
            </Form>
          </div>
        </Jumbotron>

        <RandomFill
          pending={pending}
          submit={this.submitRandomFill}
          randomFill={this.randomFill}
          style={style.randomFill}
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      [mediaQueries.breakpointTablet]: {
        paddingTop: '40px',
        paddingBottom: '80px',
      },

      [mediaQueries.breakpointSmall]: {
        paddingTop: '20px',
      },
    },

    header: {
      [mediaQueries.breakpointSmall]: {
        paddingLeft: '15px',
      },
    },

    jumbotron: {
      paddingBottom: '50px',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },
    },

    container: {
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingTop: '20px',

      [mediaQueries.breakpointSmall]: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },

    checkbox: {
      container: {
        marginTop: '30px',
        marginBottom: '30px',
      },
      base: {
        borderLeft: `solid 1px ${colors.inputBorder}`,
        borderRight: `solid 1px ${colors.inputBorder}`,
        borderTop: `solid 1px ${colors.inputBorder}`,
        borderBottom: `solid 1px ${colors.inputBorder}`,
      },
    },

    heading: {
      textTransform: 'uppercase',
      fontSize: '16px',
    },

    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    yellowBox: {
      wrapper: {
        hasPadding: {
          paddingLeft: '50px',
          paddingRight: '50px',
          paddingTop: '30px',
          paddingBottom: '30px',

          [mediaQueries.breakpointSmall]: {
            paddingLeft: '15px',
            paddingRight: '15px',
          },
        },
      },
    },

    amount: {
      fontSize: '20px',
      marginTop: '7px',
      marginLeft: '3px',
    },

    paragraph: {
      fontSize: '16px',
      lineHeight: '20px',
      marginTop: '20px',
    },

    randomFill: {
      container: {
        display: 'block',
        textAlign: 'center',
        marginBottom: '0',
        [mediaQueries.breakpointSmall]: {
          padding: '5%',
        },
      },
    },
  })
}
