/* eslint-disable no-unused-vars */
import DocumentModal from './Document.react';
import CardModal from './Card.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { CARD_FRONT, CARD_BACK, BANK_RECEIPT, EXTRA } from '../consts';
import { Modal, StyledComponent } from '4finance-components';

@Radium
export default class ModalWindow extends StyledComponent {
  static styleRoot = 'ModalWindow';

  static propTypes = {
    isModalOpen: RPT.bool,
    onClose: RPT.func,
    kind: RPT.string.isRequired,
  };

  renderModalComponent() {
    const { kind } = this.props;

    if (kind === CARD_FRONT || kind === CARD_BACK) {
      return <CardModal {...this.props} kind={kind} />;
    }

    return <DocumentModal {...this.props} />;
  }

  render() {
    const { onClose, isModalOpen } = this.props;
    const style = this.getStyle();

    return (
      <Modal
        isModalOpen={isModalOpen}
        onClose={onClose}
        style={style.modal}
        blurSelector="app"
      >
        {this.renderModalComponent()}
      </Modal>
    );
  }

  static style = ({ mediaQueries }, { kind }) => ({
    modal: {
      innerWrapper: {
        width: (kind === BANK_RECEIPT || kind === EXTRA) ? '15vw' : '40vw',
        padding: '0 0 18px',
      },
    },
  });
}
