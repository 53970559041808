import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../components/RandomFill.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { connectActions, connectPending, connectState } from '../connect';
import { Form } from '4finance-onion-form';
import { SUBMIT_REGISTRATION } from '../../common/registration/actions';

import {
  Title,
  FirstName,
  LastName,
  LastNameSecond,
  PersonalId,
  DateOfBirth,
  Email,
  LandlinePhone,
  MobilePhone,
  Password,
  AcceptAgreement,
  AgreeGetNews,
  IoBlackBox,
  Submit,
  AcceptDataSharing,
} from './fields';

const FORM_NAME = 'registration';

@connectPending({
  pending: SUBMIT_REGISTRATION,
})
@connectActions({
  submitRegistration: ['registration', 'submitRegistration'],
  randomFill: ['randomFill', 'applyRandomFill'],
})
@connectState({
  term: ['calculator', 'term'],
  amount: ['calculator', 'amount'],
})
@translate
@Radium
export default class PersonalDetails extends Component {
  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    pending: RPT.bool,
    randomFill: RPT.func.isRequired,
    submitRegistration: RPT.func.isRequired,
    term: RPT.number.isRequired,
    amount: RPT.number.isRequired,
  }

  @autobind
  async onSubmit({ values }) {
    const { submitRegistration } = this.props;

    await submitRegistration(values); // eslint-disable-line no-unused-expressions
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  render() {
    const { cnt, msg, pending, amount, term } = this.props;

    return (
      <div>
        <PageHeader
          title={msg('registration.personalDetails.title')}
          subtitle={[
            cnt('registration.personalDetails.subtitle'),
            cnt('registration.personalDetails.subtitle_main'),
          ]}
          textAlign="left"
          size="small"
        />

        <Form
          ref={this.addFormRef}
          name={FORM_NAME}
          onSubmit={this.onSubmit}
        >
          <Title inline />
          <FirstName inline />
          <LastName inline />
          <LastNameSecond inline />
          <PersonalId inline />
          <DateOfBirth inline />
          <MobilePhone inline />
          <LandlinePhone inline />
          <Email inline />
          <Password inline />
          <AgreeGetNews inline />
          <AcceptDataSharing inline />
          <AcceptAgreement inline >
            {cnt('form.registration.acceptAgreement.label', { amount, term })}
          </AcceptAgreement>
          <IoBlackBox inline />
          <Submit
            disabled={pending}
            loading={pending}
            loadingText={msg('common.loading')}
          >
            {msg('common.submit')}
          </Submit>

          <RandomFill
            pending={pending}
            submit={this.submitRandomFill}
            randomFill={this.randomFill}
          />

        </Form>
      </div>
    );
  }
}
