import Icon from '../Icon.react';
import Radium from 'radium';
import RPT from 'prop-types';
import React from 'react';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class CalculatorOfferCertificateInfo extends StyledComponent {
  static styleRoot = 'CalculatorOfferCertificateInfo'

  static propTypes = {
    hasPadding: RPT.bool,
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
  }

  static defaultProps = {
    hasPadding: true,
    inheritedStyle: {},
  }

  render() {
    const { cnt, hasPadding, inheritedStyle } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.wrapper, hasPadding && style.wrapper.hasPadding, inheritedStyle.wrapper]}>
        <a href="https://www.confianzaonline.es/en/" target="_blank">
          <img src="/assets/img/sello-doble.png" alt="Sello Double logo" style={style.certificate.icon} />
        </a>
        <div style={style.infobox.wrapper}>
          <Icon kind="loan-lock" size={16} />
          <div style={style.infobox.text}>{cnt('calculator.certificateInfo')}</div>
        </div>
      </div>
    );
  }

  static style = ({ colors }) => ({
    wrapper: {
      background: colors.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '0 0 8px 8px',

      hasPadding: {
        padding: '18px 24px',
      },
    },

    certificate: {
      icon: {
        maxWidth: '120px',
        marginTop: '2px',
      },
    },

    infobox: {
      wrapper: {
        color: colors.darkFont,
        display: 'flex',
        alignItems: 'center',
      },

      text: {
        fontSize: '12px',
        borderBottom: `1px solid ${colors.darkFont}`,
        marginLeft: '9px',
      },
    },
  });
}
