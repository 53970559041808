import * as Modal from '../components/Modal';
import config from '4finance-configuration';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectPending, connectState } from '../../connect';
import { PROCESS_TOGGLE_CONSENTS_OPTIONS_CHANGE } from '../../../common/settings/actions';
import { StyledComponent } from '4finance-components';
import {
  AcceptNewsField,
  AcceptMarketingEmailsField,
  AcceptMarketingSmsField,
  AcceptMarketingCallsField,
  AcceptSharingConsent,
} from './fields';

export const FORM_NAME = 'changeConsentOptions';

@connectPending({
  pendingChange: PROCESS_TOGGLE_CONSENTS_OPTIONS_CHANGE,
})
@connectState({
  acceptMarketingCalls: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptMarketingCalls'],
  acceptMarketingEmails: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptMarketingEmails'],
  acceptMarketingSms: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptMarketingSms'],
  acceptNews: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptNews'],
  acceptNewsGrantedDate: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptNewsGrantedDate'],
  acceptDataSharing: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptDataSharing'],
})
@connectActions({
  setFieldValue: ['onionForm', 'setFieldValue'],
  toggleConsentsOptions: ['settings', 'toggleConsentsOptions'],
})
@translate
@Radium
export default class ChangeConsentOptions extends StyledComponent {
  static styleRoot = 'ChangeConsentOptions';

  static propTypes = {
    acceptMarketingCalls: RPT.bool,
    acceptMarketingEmails: RPT.bool,
    acceptMarketingSms: RPT.bool,
    acceptNews: RPT.bool.isRequired,
    acceptNewsGrantedDate: RPT.string,
    acceptDataSharing: RPT.bool.isRequired,
    onClose: RPT.func.isRequired,
    pendingChange: RPT.bool.isRequired,
    setFieldValue: RPT.func.isRequired,
    toggleConsentsOptions: RPT.func.isRequired,
  }


  handleChange = (field) => {
    const { toggleConsentsOptions } = this.props;

    toggleConsentsOptions(field);
  }

  updateFields(updateProps) {
    const { setFieldValue, acceptNews, acceptMarketingCalls, acceptMarketingEmails, acceptMarketingSms, acceptDataSharing } = updateProps;
    const updateList = { acceptNews, acceptMarketingCalls, acceptMarketingEmails, acceptMarketingSms, acceptDataSharing };

    Object.keys(updateList).map((key) => setFieldValue(FORM_NAME, key, updateList[key]));
  }

  render() {
    this.updateFields(this.props);
    const { cnt, msg, onClose, pendingChange, acceptNews, acceptNewsGrantedDate, acceptDataSharing } = this.props;
    const style = this.getStyle();
    const isAcceptGrantedDateOld = !acceptNewsGrantedDate || (acceptNews && (new Date(acceptNewsGrantedDate) <= new Date(config.marketingConsent.startDate)));
    const acceptNewsVisible = isAcceptGrantedDateOld ? false : acceptNews;

    return (
      <Modal.Wrapper
        formName={FORM_NAME}
        heading={msg(`modals.${FORM_NAME}.heading`)}
        onClose={onClose}
        closeMarginTop
      >
        <AcceptNewsField value={acceptNewsVisible} disabled={pendingChange} onChange={this.handleChange} >
          {cnt(`form.${FORM_NAME}.acceptNews.label`)}
        </AcceptNewsField>
        <div style={[style.wrapper, acceptNewsVisible && style.wrapper.isOpen]}>
          <strong style={style.subHeading}>{msg(`modals.${FORM_NAME}.subHeading`)}</strong>
          <AcceptMarketingEmailsField disabled={pendingChange} onChange={this.handleChange} />
          <AcceptMarketingSmsField disabled={pendingChange} onChange={this.handleChange} />
          <AcceptMarketingCallsField disabled={pendingChange} onChange={this.handleChange} />
        </div>
        <AcceptSharingConsent value={acceptDataSharing} disabled={pendingChange} onChange={this.handleChange} >
          {cnt(`form.${FORM_NAME}.acceptSharingConsent.label`)}
        </AcceptSharingConsent>
      </Modal.Wrapper>
    );
  }

  static style = () => ({
    wrapper: {
      display: 'none',
      isOpen: {
        display: 'block',
      },
    },
    subHeading: {
      display: 'block',
      margin: '15px 0 0 34px',
    },
  });
}
