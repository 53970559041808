export default ({ colors }) => ({
  link: {
    marginTop: '20px',
    padding: 0,
    position: 'relative',
    color: colors.darkFont,
    fontSize: '17px',
    fontWeight: '600',
  },

  item: {
    borderTop: 'none',
  },

  activeLink: {
    padding: 0,
    position: 'relative',
    backgroundColor: 'none',
    color: colors.primary,
  },
});
