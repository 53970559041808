export default ({ colors, mediaQueries }) => ({
  bleed: {
    backgroundColor: colors.grayLighter,
    primary: {
      backgroundColor: colors.secondary,
    },
    [mediaQueries.breakpointTablet]: {
      backgroundColor: colors.lightBlue,
    },
  },
});
