import * as actions from './actions';
import { Record } from 'immutable';

const InitialState = Record({
  hasLatestLoan: false,
  hasLatestPayment: null,
  isPaymentProcessing: null,
  latestLoanFetched: false,
  nextPayment: {},
  remainingInstalments: 0,
});
const initialState = new InitialState;

export default function loanReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case actions.LOANS_FETCH_LATEST_SUCCESS: {
      const { hasLatestLoan, hasLatestPayment, isPaymentProcessing } = action.payload;

      return state
        .set('latestLoanFetched', true)
        .set('hasLatestLoan', hasLatestLoan)
        .set('hasLatestPayment', hasLatestPayment)
        .set('isPaymentProcessing', isPaymentProcessing);
    }

    case actions.LOANS_FETCH_LATEST_ERROR: {
      return state.set('latestLoanFetched', true);
    }

    case actions.FETCH_NEXT_PAYMENT_SUCCESS: {
      return state.set('nextPayment', action.payload.nextPayment)
        .set('remainingInstalments', action.payload.remainingInstalments);
    }
  }

  return state;
}
