import React from 'react';
import RPT from 'prop-types';
import { Loader, StyledComponent } from '4finance-components';
import { CONTAINER_ID } from './Instantor.react';

export default class InstantorLogin extends StyledComponent {
  static styleRoot = 'InstantorLogin'

  static propTypes = {
    loadInstantor: RPT.func,
  };

  state = {
    instantorLoaded: false,
  };

  componentDidMount() {
    this.loadInstantor();
  }

  loadInstantor = () => {
    const { loadInstantor } = this.props;

    if (loadInstantor && !this.state.instantorLoaded) {
      this.setState({ instantorLoaded: true }, this.loadInstantorIframe);
    }
  }

  loadInstantorIframe = () => {
    const { loadInstantor } = this.props;

    loadInstantor();
  }

  render() {
    const { instantorLoaded } = this.state;
    const styles = this.getStyle();

    return (
      <div>
        {!instantorLoaded && <Loader />}
        <div
          id={CONTAINER_ID}
          dangerouslySetInnerHTML={{ __html: '' }}
          style={!instantorLoaded ? styles.hidden : {}}
        />
      </div>
    );
  }

  static style = {
    hidden: {
      visibility: 'hidden',
    },
  };
}

