import CommonLayout from '../layouts/Common.react';
import React from 'react';
import RPT from 'prop-types';
import PageContainer from '../components/PageContainer.react';
import { connectPrefetch, connectState } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  hasLatestLoan: ['loans', 'hasLatestLoan'],
})
@connectPrefetch([
  ['loans', 'fetchLatestLoan'],
  ['settings', 'fetchSettings'],
  ['payment', 'prefetchPaymentDetails'],
])
export default class Payment extends StyledComponent {
  static styleRoot = 'Payment';

  static propTypes = {
    children: RPT.object.isRequired,
    location: RPT.object.isRequired,
  }

  render() {
    const { location, children } = this.props;
    const style = this.getStyle();

    return (
      <CommonLayout location={location}>
        <PageContainer inheritedStyle={style.container} width="narrow" mobileFullScreen>
          {children}
        </PageContainer>
      </CommonLayout>
    );
  }

  static style = ({ mediaQueries }) => ({
    container: {
      paddingTop: '50px',
      marginBottom: '80px',

      [mediaQueries.breakpointTablet]: {
        paddingTop: 0,
        marginBottom: 0,
      },
    },
  });
}
