import getAffiliateInfo from '../lib/getAffiliateInfo';
import routesList from '../../browser/routesList';
import { actions as api } from '../api';
import { updateApplicationFunnelPath } from '../application/actions';
import { onAmountTermChange } from '../calculator/actions';

export const FETCH_PROPOSAL = 'FETCH_PROPOSAL';
export const SUBMIT_PROPOSAL = 'SUBMIT_PROPOSAL';
export const FETCH_OFFER_FROM_APPLICATION = 'FETCH_OFFER_FROM_APPLICATION';

export function fetchOfferFromApplication() {
  return ({ dispatch }) => {
    async function getPromise() {
      const { error, payload } = await dispatch(api.fetchClientApplication());

      if (error) {
        throw Error('No application');
      }

      const { amount, term } = payload;

      return await dispatch(onAmountTermChange(amount, term));
    }

    return {
      type: FETCH_PROPOSAL,
      payload: getPromise(),
    };
  };
}

export function fetchProposal() {
  return ({ dispatch }) => {
    async function getPromise() {
      const { error, payload } = await dispatch(api.fetchClientApplicationProposal());

      if (error) {
        throw Error('No proposal offered');
      }

      const { amount, term, expiryDate } = payload;

      if (new Date(expiryDate) < new Date()) {
        throw Error('Proposal expired');
      }

      return await dispatch(onAmountTermChange(amount, term));
    }

    return {
      type: FETCH_PROPOSAL,
      payload: getPromise(),
    };
  };
}

export function submitProposal() {
  return ({ dispatch, getApiResponse, getState }) => {
    const getPromise = async () => {
      const { term, amount } = await getApiResponse(['fetch', 'client', 'application', 'proposal']);
      const application = {
        amount,
        term,
        autoRepay: true,
        source: getState().device.get('type').toUpperCase(),
        ...getAffiliateInfo(),
      };

      const { error: applicationError } = await dispatch(api.sendClientApplication(application));

      if (applicationError) {
        throw Error('Error in sending client application');
      }

      const { error: confirmationError } = await dispatch(api.patchClientApplication({ acceptAgreement: true }));

      if (confirmationError) {
        throw Error('Error in patching client application');
      }

      dispatch(updateApplicationFunnelPath(routesList.applicationIdentificationProcessing));

      return true;
    };

    return {
      type: SUBMIT_PROPOSAL,
      payload: getPromise(),
    };
  };
}
