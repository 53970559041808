import collectMonitoringData from '4finance-monitoring';
import config from '4finance-configuration';
import configureStoreWithHistory from './configureStoreWithHistory';
import createRoutes from './createRoutes';
import React from 'react';
import ReactDOM from 'react-dom';
import useScroll from 'react-router-scroll/lib/useScroll';
import { Provider } from 'react-redux';
import { Provider as TranslateProvider } from '4finance-translate';
import { applyRouterMiddleware, match, Router } from 'react-router';
import { AppContainer } from 'react-hot-loader';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale(config.translate.selectedLocale);

const { store, history } = configureStoreWithHistory();

const createdRoutes = createRoutes(store.dispatch, store.getState);

const { pathname, search, hash } = window.location;
const location = `${pathname}${search}${hash}`;

const app = (routes) => (
  <AppContainer>
    <Provider store={store}>
      <TranslateProvider config={config.translate} pathname={pathname}>
        <Router history={history} render={applyRouterMiddleware(useScroll())}>
          {routes}
        </Router>
      </TranslateProvider>
    </Provider>
  </AppContainer>
);

match({ history, createdRoutes, location }, () => { // eslint-disable-line  no-unused-vars
  ReactDOM.render(app(createdRoutes), document.getElementById('app'));
});


if (module.hot) {
  module.hot.accept('./createRoutes', () => {
    const createdHotRoutes = require('./createRoutes')(store.dispatch, store.getState); // eslint-disable-line global-require

    ReactDOM.render(app(createdHotRoutes), document.getElementById('app'));
  });
}

window.onload = collectMonitoringData;
