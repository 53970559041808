import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import withInterval from '../../lib/withInterval';
import * as Modal from '../components/Modal';
import { connectPending, connectState, connectActions } from '../../connect';
import { getVerificationCode } from './fields/VerificationField';
import { VerificationField } from './fields';
import { PROCESS_RESEND_EMAIL_VERIFICATION, PROCESS_SUBMIT_EMAIL_VERIFICATION } from '../../../common/settings/actions';
import { FORM_NAME as CHANGE_EMAIL_FORM_NAME } from './ChangeEmail.react';

const FORM_NAME = 'confirmEmail';

@connectPending({
  pendingResend: PROCESS_RESEND_EMAIL_VERIFICATION,
  pendingSubmit: PROCESS_SUBMIT_EMAIL_VERIFICATION,
})
@connectState({
  countdown: ['settings', CHANGE_EMAIL_FORM_NAME, 'countdown'],
  isEmailProcessed: ['settings', CHANGE_EMAIL_FORM_NAME, 'processed'],
  newEmail: ['onionForm', 'fields', CHANGE_EMAIL_FORM_NAME, 'email', 'value'],
})
@connectActions({
  clearForm: ['onionForm', 'clearForm'],
  settingsAction: ['settings'],
})
@translate
@withInterval()
export default class ConfirmEmail extends Component {
  static propTypes = {
    addInterval: RPT.func.isRequired,
    clearForm: RPT.func.isRequired,
    countdown: RPT.instanceOf(Date).isRequired,
    isEmailProcessed: RPT.bool.isRequired,
    msg: RPT.func.isRequired,
    newEmail: RPT.string.isRequired,
    onClose: RPT.func.isRequired,
    pendingResend: RPT.bool.isRequired,
    pendingSubmit: RPT.bool.isRequired,
    settingsAction: RPT.object.isRequired,
  }

  state = {
    countdown: null,
  }

  componentDidMount() {
    this.props.addInterval('COUNTDOWN', 1000, this.tickCountdown, true);
  }

  componentDidUpdate() {
    const { settingsAction: { setEmailChangeDone }, onClose, isEmailProcessed, countdown } = this.props;

    this.onUpdateState(countdown);

    if (isEmailProcessed) {
      setEmailChangeDone();
      onClose();
    }
  }

  onUpdateState(propsCountdown) {
    this.setState({ countdown: this.getCountdown(propsCountdown) });
  }

  componentWillUnmount() {
    this.props.clearForm(FORM_NAME);
  }

  getCountdown(countdown) {
    return Math.floor(((countdown - new Date()) % 6e4) / 1000);
  }

  tickCountdown = () => {
    this.setState({ countdown: this.getCountdown(this.props.countdown) });
  }

  handleSubmit = ({ values: { verification } }) => {
    const { settingsAction: { submitEmailVerification } } = this.props;

    submitEmailVerification(getVerificationCode(verification));
  }

  handleResendCode = () => {
    const { settingsAction: { resendEmailVerification }, newEmail } = this.props;

    resendEmailVerification(newEmail);
  }

  render() {
    const { msg, onClose, pendingResend, pendingSubmit, newEmail } = this.props;
    const { countdown } = this.state;
    const isResendDisabled = pendingResend || countdown > 0;

    return (
      <Modal.Wrapper
        formName={FORM_NAME}
        formSubmit={this.handleSubmit}
        heading={msg(`modals.${FORM_NAME}.heading`)}
        onClose={onClose}
        footerChildren={
          <Modal.FooterInformation icon="email-sent">
            {msg(`modals.${FORM_NAME}.footerInfo`)} <strong>{newEmail}</strong>
          </Modal.FooterInformation>
        }
      >
        <VerificationField />
        <Modal.ResendButton onResendClick={this.handleResendCode} timeRemaining={countdown} disabled={isResendDisabled} />
        <Modal.Submit disabled={pendingSubmit} loading={pendingSubmit} >
          {msg(`modals.${FORM_NAME}.submitButton`)}
        </Modal.Submit>
        <Modal.Message>
          {msg(`modals.${FORM_NAME}.message`)}
        </Modal.Message>
      </Modal.Wrapper>
    );
  }
}
