import * as persistence from '../persistence/actions';
import * as calculator from '../calculator/actions';
import routesList from '../../browser/routesList';
import { actions as api } from '../api';
import { Base64 } from 'js-base64';
import { push as updatePath } from 'react-router-redux';

export const AUTHENTICATION_SET_IS_LOGGED_IN = 'AUTHENTICATION_SET_IS_LOGGED_IN';
export const AUTHENTICATION_SET_IS_FROM_TEMPORARY_PASSWORD = 'AUTHENTICATION_SET_IS_FROM_TEMPORARY_PASSWORD';
export const AUTHENTICATION_SHOW_CREDENTIALS_EXISTS = 'AUTHENTICATION_SHOW_CREDENTIALS_EXISTS';
export const AUTHENTICATION_DISMISS_CREDENTIALS_EXISTS = 'AUTHENTICATION_DISMISS_CREDENTIALS_EXISTS';

export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const AUTHENTICATION_LOGIN_START = 'AUTHENTICATION_LOGIN_START';
export const AUTHENTICATION_LOGIN_ERROR = 'AUTHENTICATION_LOGIN_ERROR';
export const AUTHENTICATION_LOGIN_SUCCESS = 'AUTHENTICATION_LOGIN_SUCCESS';

export function sendAuthentication(authHeaders) {
  return ({ httpRequest }) => ({
    type: 'SEND_AUTHENTICATION',
    payload: httpRequest('/token/basic', 'POST', {}, authHeaders),
  });
}

function getCorrectUsername(username) {
  if (username.search(/@|-/) > -1) {
    return username;
  }

  return username.replace(/(\d{6})(\d{4})/, '$1-$2');
}

export function login({ username, password, redirectTo, doNotRedirect }, isRegistration = false) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const basicToken = Base64.encode(`${getCorrectUsername(username)}:${password}`);
      const authHeaders = { Authorization: `Basic ${basicToken}` };
      const { payload, error } = await dispatch(sendAuthentication(authHeaders));

      if (error) throw payload;

      const { username: userNumber, token, fromTemporaryPassword } = payload;
      const encodedToken = Base64.encode(`${userNumber}:${token}`);

      dispatch(persistence.set('authorizationToken', encodedToken));
      dispatch(persistence.set('dismissAcceptNews', false));
      dispatch(persistence.remove('fromTemporaryPassword'));
      dispatch(setIsLoggedIn(true));
      if (!isRegistration) {
        dispatch(sendAnalyticsCookies());
      }
      if (doNotRedirect) return;

      dispatch({ type: calculator.CALCULATOR_RESET_DEFAULTS });

      if (fromTemporaryPassword) {
        dispatch(persistence.set('fromTemporaryPassword', true));
        dispatch(updatePath(routesList.passwordReset));
      } else {
        dispatch(updatePath(redirectTo || routesList.dashboard));
      }
    };

    return {
      type: AUTHENTICATION_LOGIN,
      payload: getPromise(),
    };
  };
}

export function setIsLoggedIn(state) {
  return {
    type: AUTHENTICATION_SET_IS_LOGGED_IN,
    isLoggedIn: state,
  };
}

function sendAnalyticsCookies() {
  return ({ dispatch, persistenceStore }) => {
    const getPromise = async (name) => {
      const value = persistenceStore.get(name);

      if (value) {
        await dispatch(api.sendClientCookies({ name, value })); // eslint-disable-line no-unused-expressions
      }
    };

    return {
      type: 'SEND_ANALYTICS_COOKIES',
      payload: Promise.all(['_ga'].map(getPromise)),
    };
  };
}
