import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { connectActions } from '../../connect';
import { VerticalMenuItem } from '4finance-components';

@connectActions({
  toggleSideMenu: ['ui', 'toggleSideMenu'],
})
@Radium
export default class ResponsiveMenuItem extends Component {
  static propTypes = {
    activeExactly: RPT.bool,
    external: RPT.bool,
    label: RPT.oneOfType([RPT.string, RPT.node]),
    onClick: RPT.func,
    pathname: RPT.string.isRequired,
    target: RPT.oneOf(['_blank', '_self', '_parent', '_top']),
    to: RPT.string,
    toggleSideMenu: RPT.func,
  }

  render() {
    const { activeExactly, external, label, onClick, pathname, target, to, toggleSideMenu } = this.props;

    return (
      <VerticalMenuItem
        activeExactly={activeExactly}
        external={external}
        label={label}
        onClick={onClick}
        pathname={pathname}
        target={target}
        to={to}
        toggleSideMenuAction={toggleSideMenu}
      />
    );
  }
}
