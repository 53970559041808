import { TextField } from '4finance-components';
import { isRequired, maxLength, minLength, number as isNumber } from '4finance-validation';
import translatedField from '../../../lib/translatedField';

export default translatedField('mobilePhone', {
  width: 'full',
  bottomMargin: false,
  autoFocus: true,
  type: 'tel',
  style: {
    marginTop: '0',
    marginBottom: '0',
    padding: '0',
    wrapper: { margin: '0', padding: '0', background: 'black' },
    hint: { fontSize: '12px' },
  },
}, [isRequired(), maxLength(9), minLength(9), isNumber()])(TextField);
