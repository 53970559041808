import RPT from 'prop-types';
import translate from '4finance-translate';
import * as Modal from '../components/Modal';
import React, { PureComponent as Component } from 'react';

@translate
export default class ChangeBankAccount extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
    onClose: RPT.func.isRequired,
  }

  render() {
    const { msg, onClose } = this.props;

    return (
      <Modal.Wrapper
        heading={msg('modals.changeBankAccount.heading')}
        onClose={onClose}
      >
        <Modal.Message>
          {msg('modals.changeBankAccount.message')}
        </Modal.Message>
      </Modal.Wrapper>
    );
  }
}
// @TODO: Invision design needed.
