import Icon from '../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectFunnels } from '../../connect';
import { Link } from 'react-router';
import { StyledComponent } from '4finance-components';

const URLS = {
  instantor: routesList.applicationIdentificationInstantorBank,
  upload: routesList.applicationIdentificationDocumentUpload,
  whatsapp: routesList.applicationIdentificationEmailOrWhatsapp,
  approvalUpload: routesList.approvalDocumentUploadWay,
  approvalWhatsapp: routesList.approvalEmailOrWhatsapp,
};

function getBaseType(name) {
  switch (name) {
    case 'approvalWhatsapp':
      return 'whatsapp';
    case 'approvalUpload':
      return 'upload';
    default:
      return name;
  }
}

@connectActions({
  changeMethod: ['identification', 'changeMethod'],
})
@connectFunnels
@translate
@Radium
export default class DifferentMethod extends StyledComponent {
  static styleRoot = 'DifferentMethod';

  static propTypes = {
    funnelPaths: RPT.object.isRequired,
    msg: RPT.func.isRequired,
    types: RPT.arrayOf(RPT.oneOf(['instantor', 'upload', 'whatsapp', 'approvalUpload', 'approvalWhatsapp'])).isRequired,
    changeMethod: RPT.func.isRequired,
  };

  renderType = (type) => {
    const { changeMethod, funnelPaths, msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.item} key={type}>
        <Icon
          kind={`mobile-verification-${getBaseType(type)}`}
          size={40}
          style={style.icon}
        />
        <Link
          to={funnelPaths[URLS[type]]}
          style={style.link}
          onClick={() => changeMethod(getBaseType(type))}
        >
          {msg(`identification.differentMethod.items.${getBaseType(type)}.title`)}
        </Link>
      </div>
    );
  };

  render() {
    const { msg, types } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <span style={style.header}>
          {msg('identification.differentMethod.title')}
        </span>
        <div style={style.container}>
          {types.map(this.renderType)}
        </div>
      </div>
    );
  }

  static style = ({ mediaQueries }, { types = [] }) => ({
    wrapper: {
      minWidth: '60%',
      textAlign: 'center',
      marginTop: '30px',
      marginLeft: 'auto',
      marginRight: 'auto',

      [mediaQueries.breakpointTablet]: {
        marginLeft: '5%',
        textAlign: 'left',
      },
    },

    container: {
      display: 'flex',
      maxWidth: '530px',
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: types.length === 1 ? 'center' : 'space-between',
      marginTop: '30px',

      [mediaQueries.breakpointTablet]: {
        width: '100%',
        flexDirection: 'column',
        marginTop: '5px',
      },
    },

    header: {
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '20px',
    },

    item: {
      display: 'flex',
      textAlign: 'left',
      maxWidth: '230px',

      [mediaQueries.breakpointTablet]: {
        marginTop: '20px',
      },
    },

    link: {
      marginLeft: '15px',
      fontSize: '16px',
      lineHeight: '20px',
    },

    icon: {
      width: '40px',
      height: '40px',
      flexShrink: 0,
    },
  });
}
