import React from 'react';
import { MultiField, StyledComponent } from '4finance-components';
import Radium, { Style } from 'radium';

@Radium
export default class PhoneCode extends StyledComponent {
  static styleRoot = 'PhoneCode';

  render() {
    const style = this.getStyle();

    return (
      <div className="webCodeMultifield">
        <Style rules={errorStyleRules} />
        <MultiField
          {...this.props}
          inheritedStyle={style}
          hasIndicator={false}
          separated
          style={extendedStyle}
          delimiter="-"
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: '10px',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderLeft: 0,
      width: 'calc(100% + 14px)',
    },

    input: {
      backgroundColor: colors.white,
      width: '64px',
      height: '80px',
      textAlign: 'center',
      fontFamily: 'inherit',
      borderWidth: '1px 1px 3px 1px',
      borderStyle: 'solid',
      borderColor: colors.grayLighter,
      borderRadius: '2px 2px 0 0',
      fontSize: '22px',

      [mediaQueries.breakpointSmall]: {
        width: 'calc(100% - 14px)',
        padding: 0,
        margin: '0 14px 0 0',
      },
    },
  });
}

const errorStyleRules = {
  '.webCodeMultifield .errorMessage': {
    textAlign: 'center!important',
  },
};

const extendedStyle = ({ mediaQueries }) => ({
  fieldGroup: {
    [mediaQueries.breakpointSmall]: {
      width: '25%',
    },
  },

  delimiter: {
    display: 'none',
  },

  wrapper: {
    full: {
      borderBottom: 'none',
      background: 'transparent',
      marginBottom: '15px',
    },
    focused: {
      borderBottom: 'none',
    },
    error: {
      borderBottom: 'none',
    },
    padded: {
      padding: 0,
    },
  },
});
