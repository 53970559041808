import CommonLayout from '../layouts/Common.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPrefetch } from '../connect';

@connectPrefetch([
  ['settings', 'fetchSettings'],
])
@translate
export default class Profile extends Component {
  static propTypes = {
    children: RPT.node.isRequired,
    cnt: RPT.func.isRequired,
    isLoading: RPT.bool,
    location: RPT.object.isRequired,
    params: RPT.object.isRequired,
  }

  render() {
    const { children, location } = this.props;

    return (
      <CommonLayout location={location}>
        {children}
      </CommonLayout>
    );
  }
}
