import * as Modal from '../components/Modal';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import withInterval from '../../lib/withInterval';
import { autobind } from 'core-decorators';
import { connectActions, connectPending, connectState } from '../../connect';
import { PhoneField } from './fields';
import { PROCESS_SUBMIT_PHONE_CHANGE } from '../../../common/settings/actions';

export const FORM_NAME = 'changePhone';

@connectPending({
  pendingSubmit: PROCESS_SUBMIT_PHONE_CHANGE,
})
@connectState({
  rateCountdown: ['settings', FORM_NAME, 'rateCountdown'],
  newPhone: ['onionForm', 'fields', FORM_NAME, 'phone', 'value'],
  originalPhone: ['api', 'fetch', 'client', 'phone', 'query', 'data', 'mobilePhone'],
})
@connectActions({
  setFieldValue: ['onionForm', 'setFieldValue'],
  submitPhoneChange: ['settings', 'submitPhoneChange'],
})
@translate
@withInterval()
export default class ChangePhone extends Component {
  static propTypes = {
    addInterval: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    newPhone: RPT.string,
    onClose: RPT.func.isRequired,
    originalPhone: RPT.string.isRequired,
    pendingSubmit: RPT.bool.isRequired,
    rateCountdown: RPT.instanceOf(Date).isRequired,
    removeIntervalById: RPT.func.isRequired,
    setFieldValue: RPT.func.isRequired,
    submitPhoneChange: RPT.func.isRequired,
  }

  state = {
    countdown: null,
  }

  componentDidMount() {
    const { originalPhone, setFieldValue, addInterval, rateCountdown } = this.props;

    if (rateCountdown) {
      addInterval('RATECOUNTDOWN', 1000, this.tickCountdown, true);
    }

    setFieldValue(FORM_NAME, 'mobilePhone', originalPhone);
  }

  @autobind
  async handleSubmit({ values: { mobilePhone } }) {
    const { submitPhoneChange, originalPhone } = this.props;

    if (mobilePhone !== originalPhone) {
      await submitPhoneChange(mobilePhone);
      this.input.focus();
    }
  }

  tickCountdown = () => {
    this.setState(
      { countdown: this.getCountdown(this.props.rateCountdown) },
      () => {
        if (this.state.countdown <= 0) {
          this.props.removeIntervalById('RATECOUNTDOWN');
        }
      }
    );
  }

  getCountdown(countdown) {
    return Math.floor(((countdown - new Date()) % 6e4) / 1000);
  }

  render() {
    const { msg, onClose, pendingSubmit, newPhone, originalPhone } = this.props;
    const { countdown } = this.state;
    const isDisabled = pendingSubmit || newPhone === originalPhone || countdown > 0;

    return (
      <Modal.Wrapper
        formSubmit={this.handleSubmit}
        onClose={onClose}
        formName={FORM_NAME}
        heading={msg(`modals.${FORM_NAME}.heading`)}
      >
        <PhoneField inputRef={(el) => (this.input = el)} />
        <Modal.Submit disabled={isDisabled} loading={pendingSubmit} >
          {msg(`modals.${FORM_NAME}.submitButton`)}{countdown > 0 && ` - ${countdown}`}
        </Modal.Submit>
      </Modal.Wrapper>
    );
  }
}
