import Notification from '../../components/Notification.react';
import React, { PureComponent as Component } from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { connectActions, connectState } from '../../connect';
import { withRouter } from 'react-router';

@connectActions({
  acceptNews: ['client', 'acceptNews'],
  setPersistence: ['persistence', 'set'],
})
@connectState({
  acceptedNews: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptNews'],
  dismissedAcceptNews: ['persistence', 'dismissAcceptNews'],
})
@withRouter
@translate
export default class AcceptNewsNotification extends Component {
  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    setPersistence: RPT.func,
    acceptNews: RPT.func,
    acceptedNews: RPT.bool,
    dismissedAcceptNews: RPT.bool,
    location: RPT.object,
  }

  @autobind
  dismissAcceptNews() {
    const expires = new Date();

    expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24 * 5); // 5 days
    this.props.setPersistence('dismissAcceptNews', true, { expires });
  }

  @autobind
  acceptNews() {
    this.props.acceptNews();
  }

  render() {
    const { cnt, msg, location: { pathname }, acceptedNews, dismissedAcceptNews } = this.props;
    const acceptNewsNotification = acceptedNews === false && !dismissedAcceptNews && pathname === routesList.dashboard;

    return (
      <Notification
        body={cnt('messages.accept_news.text')} actionText={msg('messages.accept_news.accept_action')}
        action={this.acceptNews}
        onClose={this.dismissAcceptNews}
        ignoreButton
        visible={acceptNewsNotification}
      />
    );
  }
}
