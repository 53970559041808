const mappings = {
  route: 'street',
  street_number: 'number',
  locality: 'city',
  administrative_area_level_2: 'province',
  country: 'country',
  postal_code: 'postalCode',
};

export function parseAddress(place) {
  return (place.address_components || [])
    .reduce((hash, value) => {
      const { types: [type], long_name: name } = value;
      const mappedInfo = mappings[type];

      if (mappedInfo) {
        const mappedKey = mappedInfo;

        // eslint-disable-next-line no-param-reassign
        hash[mappedKey] = name;
      }

      return hash;
    }, {});
}
