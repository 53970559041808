import { actions as api } from '../api';
import * as actions from './actions';
import setFormApiError from '../lib/setFormApiError';

export const setResendEmailCountdown = (countdown) => ({
  type: actions.SET_EMAIL_CHANGE_COUNTDOWN,
  payload: countdown,
});

export const setEmailRateLimitCountdown = (countdown) => ({
  type: actions.SET_EMAIL_RATE_COUNTDOWN,
  payload: countdown,
});

export const setEmailChangeDone = () => ({
  type: actions.PROCESS_SET_EMAIL_CHANGE_DONE,
});

export const submitEmailChange = (email) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.sendClientEmail({ email }));

    if (error) {
      dispatch(setFormApiError('changeEmail', payload));
      throw Error('Change email failed');
    }

    dispatch(setResendEmailCountdown(actions.setTimeCountdown()));
  };

  return {
    type: actions.PROCESS_SUBMIT_EMAIL_CHANGE,
    payload: getPromise(),
  };
};

export const resendEmailVerification = (email) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.sendClientEmail({ email }));

    if (error) {
      dispatch(setFormApiError('confirmEmail', payload));
      throw Error('Resend email verification code failed');
    }

    dispatch(setResendEmailCountdown(actions.setTimeCountdown()));
  };

  return {
    type: actions.PROCESS_RESEND_EMAIL_VERIFICATION,
    payload: getPromise(),
  };
};


export const submitEmailVerification = (verificationCode) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.patchClientEmailConfirmation({ verificationCode }));

    if (error) {
      dispatch(setFormApiError('confirmEmail', payload));
      throw Error('Submit email verification code failed');
    }

    dispatch(api.fetchClientEmail());

    const { payload: { rateLimitResetTime } } = await dispatch(api.fetchClientEmailRateLimitStatus());

    dispatch(setEmailRateLimitCountdown(actions.setTimeCountdown(rateLimitResetTime)));
  };

  return {
    type: actions.PROCESS_SUBMIT_EMAIL_VERIFICATION,
    payload: getPromise(),
  };
};
