import React, { Component } from 'react';
import RPT from 'prop-types';

export default class WithCalculator extends Component {
  static propTypes = {
    textBlock: RPT.string.isRequired,
  }

  render() {
    const { textBlock } = this.props;

    return (
      <div>
        <h2>With Calculator template</h2>
        <section dangerouslySetInnerHTML={{ __html: textBlock }} />
      </div>
    );
  }
}
