import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent as Component } from '4finance-components';

@Radium
export default class JumbotronGrayBox extends Component {
  static styleRoot = 'JumbotronGrayBox';

  static propTypes = {
    children: RPT.node.isRequired,
    textAlign: RPT.oneOf(['center', 'right', 'left']),
    hasArrow: RPT.bool,
    hasPadding: RPT.bool,
    inheritedStyle: RPT.object,
  }

  static defaultProps = {
    textAlign: 'center',
    hasArrow: false,
    hasPadding: false,
    inheritedStyle: {},
  }

  render() {
    const {
      children,
      hasArrow,
      hasPadding,
      inheritedStyle,
    } = this.props;
    const style = this.getStyle();

    return (
      <div
        style={[
          style.wrapper,
          hasArrow && style.wrapper.hasArrow,
          hasPadding && style.wrapper.hasPadding,
          inheritedStyle,
        ]}
      >
        {hasArrow &&
          <div
            style={[
              style.arrowDecorator,
              inheritedStyle.wrapper && inheritedStyle.arrowDecorator,
            ]}
          />}
        {children}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { textAlign }) => ({

    wrapper: {
      backgroundColor: colors.lighterBackground,
      borderRadius: '5px',
      overflow: 'hidden',
      position: 'relative',
      textAlign,

      hasPadding: {
        paddingBottom: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
      },
      hasArrow: {
        paddingTop: '24px',
      },

      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
        marginLeft: '-15px',
        marginRight: '-15px',
      },
    },

    arrowDecorator: {
      borderColor: `${colors.white} transparent transparent`,
      borderStyle: 'solid',
      borderWidth: '22px 42px 0px',
      height: 0,
      left: '50%',
      position: 'absolute',
      top: 0,
      transform: 'translateX(-50%)',
      width: 0,
    },
  });
}
