import config from '4finance-configuration';
import DifferentMethod from '../components/DifferentMethod.react';
import PageContainer from '../../components/PageContainer.react';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import Script from 'react-load-script';
import { Button, StyledComponent } from '4finance-components';
import { connectPrefetch, connectState, connectActions, connectFunnels } from '../../connect';

export const CONTAINER_ID = 'instantor-container';

@connectPrefetch([
  ['api', 'fetchClient'],
  ['identification', 'checkForTestDouble'],
])
@connectState({
  client: ['api', 'fetch', 'client', 'query', 'data'],
  isTestDouble: ['identification', 'isTestDouble'],
})
@connectActions({
  push: ['routing', 'push'],
  toggleTestDouble: ['identification', 'toggleTestDouble'],
  setInstantorError: ['identification', 'setInstantorError'],
  setInstantorSuccess: ['identification', 'setInstantorSuccess'],
})
@connectFunnels
export default class InstantorPage extends StyledComponent {
  static styleRoot = 'InstantorPage';

  static propTypes = {
    children: RPT.object,
    client: RPT.object,
    push: RPT.func.isRequired,
    toggleTestDouble: RPT.func.isRequired,
    isTestDouble: RPT.bool,
    setInstantorError: RPT.func.isRequired,
    setInstantorSuccess: RPT.func.isRequired,
    funnelPaths: RPT.object.isRequired,
  };

  state = {
    jqueryLoaded: false,
    banks: [],
    banksLoaded: false,
    instantorLoaded: false,
  }

  jqueryLoaded = () => {
    this.setState({ jqueryLoaded: true });
  }

  instantorLoaded = () => {
    const { client, isTestDouble } = this.props;

    const instantorConfig = config.instantor;
    const instantor = new window.Instantor(instantorConfig.product);

    this.instantor = instantor;
    instantor.userParam('environment', instantorConfig.environment);
    instantor.userParam('pn', 'true');

    if (isTestDouble) {
      instantor.userParam('instantorUrl', instantorConfig.urlTestDouble);
    }

    if (instantorConfig.callbackUrl) {
      instantor.userParam('callbackUrl', instantorConfig.callbackUrl);
    }

    instantor.userParam('firstName', client.get('firstName'));
    instantor.userParam('lastName', client.get('lastName'));
    instantor.userParam('first_name', client.get('firstName'));
    instantor.userParam('last_name', client.get('lastName'));
    instantor.userParam('personal_number', client.get('personalId'));
    instantor.userParam('identificationId', client.get('identificationNumber'));

    if (instantor.frameParam) {
      instantor.frameParam('width', '100%');
      instantor.frameParam('debug', 1);
    }

    instantor.getBankList(({ banks }) => {
      this.setState({ banks, banksLoaded: true });
    });

    this.setState({ instantorLoaded: true });
  }

  handleBankSelect = (bankId) => {
    const { funnelPaths, push } = this.props;

    this.instantor.frameParam('bankID', bankId);
    push(funnelPaths[routesList.applicationIdentificationInstantorConsent]);
  };

  loadInstantor = () => {
    this.instantor.listener(this.processInstantorResponse);

    if (this.instantor.on_load) {
      this.instantor.on_load(this.processInstantorResponse.bind(null, 'iframe-loaded'));
    } else {
      this.instantor.attachEventListener('load', this.processInstantorResponse.bind(null, 'iframe-loaded'));
    }

    this.instantor.load(`#${CONTAINER_ID}`);
  };

    addClassIframe = () => {
      document.getElementById(CONTAINER_ID).firstChild.setAttribute('data-hj-allow-iframe', '');
    }

  processInstantorResponse = (response) => {
    const { setInstantorError, setInstantorSuccess } = this.props;

    switch (response) {
      case 'iframe-loaded':
        this.addClassIframe();
        break;
      case 'paused':
        break;
      case 'process-finished':
        setInstantorSuccess();
        break;
      default:
        setInstantorError(response);
        break;
    }
  }

  renderDevelopmentOptions() {
    const { isTestDouble, toggleTestDouble } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.buttons}>
        <Button
          size="small"
          onClick={toggleTestDouble}
          kind="secondary"
          inheritedStyle={style.button}
        >
          {isTestDouble ? 'CLASSIC INSTANTOR' : 'TEST DOUBLE'}
        </Button>
      </div>
    );
  }

  render() {
    const { children, isTestDouble } = this.props;
    const { jqueryLoaded } = this.state;
    const style = this.getStyle();
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        banks: this.state.banks,
        banksLoaded: this.state.banksLoaded,
        onBankSelect: this.handleBankSelect,
        loadInstantor: this.state.instantorLoaded && this.loadInstantor,
        isTestDouble: this.props.isTestDouble,
      })
    );

    return (
      <div>
        <Script
          url="https://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js"
          onLoad={this.jqueryLoaded}
          onError={() => {}}
        />
        {jqueryLoaded &&
          <Script
            url={isTestDouble ? config.instantor.urlTestDouble : config.instantor.url}
            onLoad={this.instantorLoaded}
            onError={() => {}}
          />
        }
        <PageContainer width="wide" mobileFullScreen inheritedStyle={style.container}>
          <div>
            {childrenWithProps}
          </div>

          <DifferentMethod
            types={['upload', 'whatsapp']}
          />
        </PageContainer>

        {config.faker.enabled && this.renderDevelopmentOptions()}
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    container: {
      marginBottom: '40px',

      [mediaQueries.breakpointTablet]: {
        marginTop: '30px',
        marginBottom: '20px',
      },
    },

    buttons: {
      position: 'relative',
      left: '50%',
      transform: 'translate(-50%, 0)',
      display: 'inline-block',
      textAlign: 'center',
      marginBottom: '30px',
    },

    button: {
      [mediaQueries.breakpointStartSmall]: {
        minHeight: '60px',
        lineHeight: '38px',
      },
    },
  });
}
