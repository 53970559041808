export default ({ mediaQueries }) => ({
  innerWrapper: {
    minWidth: '300px',
    maxWidth: '800px',
    padding: '0 20px 18px',

    [mediaQueries.breakpointTablet]: {
      width: '80vw',
      maxWidth: '300px',
    },
  },

  content: {
    borderRadius: '3px',
    overflow: 'hidden',

    mediaQueries: {
      'screen and (max-width: 768px)': {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderRadius: '3px',
      },
    },
  },

  closeButton: {
    display: 'none',
  },
});
