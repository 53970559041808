import * as Modal from '../../settings/components/Modal';
import Radium from 'radium';
import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPending, connectActions, connectPrefetch } from '../../connect';
import { PROCESS_SUBMIT_EMPLOYMENT_CHANGE } from '../../../common/client/actions';
import { EmploymentStatus } from '../../registration/fields';

const FORM_NAME = 'changeEmployment';

@connectActions({
  submitClientEmploymentChange: ['client', 'submitClientEmploymentChange'],
})
@connectPending({
  pendingSubmit: PROCESS_SUBMIT_EMPLOYMENT_CHANGE,
})
@connectPrefetch([
  ['client', 'prefetchChangeEmploymentForm'],
])
@translate
@Radium
export default class ChangeEmployment extends Component {
  static propTypes = {
    onClose: RPT.func.isRequired,
    pendingSubmit: RPT.bool.isRequired,
    submitClientEmploymentChange: RPT.func.isRequired,
  }

  componentDidUpdate() {
    const { pendingSubmit, onClose } = this.props;

    if (!pendingSubmit) {
      this.onUpdateState();
    }
    if (this.state.isProcessing && !pendingSubmit) {
      onClose();
    }
  }

  onUpdateState() {
    this.setState({ isProcessing: true });
  }

  handleSubmit = ({ values }) => {
    const { submitClientEmploymentChange } = this.props;

    submitClientEmploymentChange(values, FORM_NAME);
  }

  render() {
    const { msg, onClose, pendingSubmit } = this.props;

    return (
      <Modal.Wrapper
        formName={FORM_NAME}
        formSubmit={this.handleSubmit}
        heading={msg(`modals.${FORM_NAME}.heading`)}
        onClose={onClose}
      >
        <EmploymentStatus
          inline
          width="full"
        />
        <Modal.Submit disabled={pendingSubmit} loading={pendingSubmit}>
          {msg('modals.partials.submitButton')}
        </Modal.Submit>
      </Modal.Wrapper>
    );
  }
}
// @TODO: Invision design needed.
