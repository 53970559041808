import React from 'react';
import RPT from 'prop-types';
import Radium from 'radium';
import { StyledComponent } from '4finance-components';

@Radium
export default class Tabs extends StyledComponent {
  static styleRoot = 'Tabs';

  static propTypes = {
    tabs: RPT.arrayOf(RPT.shape({
      key: RPT.string,
      name: RPT.string,
      content: RPT.object,
    })),
    inheritedStyle: RPT.oneOfType([RPT.array, RPT.object]),
  }

  state = {
    activeTab: {},
  }

  constructor(props, state) {
    super(props, state);

    this.tabs = {};
  }

  componentDidMount() {
    this.calculateBorderPosition(this.tabs[0]);
  }

  activateTab = (tab, el) => {
    this.calculateBorderPosition(el.target);

    this.setState({
      activeTab: tab,
    });
  }

  calculateBorderPosition = (tabElement) => {
    this.border.style.left = `${tabElement.offsetLeft}px`;
    this.border.style.width = `${tabElement.offsetWidth}px`;
    this.border.style.transition = '0.3s left, 0.3s width';
  }

  renderTabName(tab, index) {
    const style = this.getStyle();
    const activeTab = this.state.activeTab;
    const isActive = !activeTab.key ? (index === 0) : (activeTab.key === tab.key);

    return (
      <li
        key={`tab${index}`}
        style={[style.tabName, isActive && style.activeTab]}
        onClick={(el) => this.activateTab(tab, el)}
        ref={(el) => { this.tabs[index] = el; }}
      >
        {tab.name}
      </li>
    );
  }

  render() {
    const { tabs, inheritedStyle } = this.props;
    const style = this.getStyle();
    const activeTab = this.state.activeTab;

    return (
      <div style={[style.wrapper, inheritedStyle]}>
        <ul style={style.tabNames}>
          {tabs.map((tab, index) => this.renderTabName(tab, index))}
        </ul>
        <div
          style={style.decorativeBorder} ref={(el) => { this.border = el; }}
        />
        <div>
          {activeTab.content ? activeTab.content : tabs[0].content}
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      position: 'relative',

      [mediaQueries.breakpointSmall]: {
        marginTop: '10px',
        marginBottom: '10px',
      },
    },

    decorativeBorder: {
      position: 'absolute',
      left: 0,
      top: '60px',
      width: '100px',
      height: '2px',
      backgroundColor: colors.primary,

      [mediaQueries.breakpointSmall]: {
        top: '40px',
      },
    },

    tabNames: {
      display: 'flex',
      height: '60px',
      textTransform: 'uppercase',
      listStyle: 'none',
      borderBottom: `2px solid ${colors.monotone1}`,
      boxSizing: 'content-box',

      [mediaQueries.breakpointSmall]: {
        height: '40px',
        borderBottom: 'none',
      },
    },

    tabName: {
      padding: '20px 0',
      height: '60px',
      marginRight: '20px',
      cursor: 'pointer',
      [mediaQueries.breakpointSmall]: {
        fontSize: '13px',
        padding: '10px 0',
        height: '40px',
      },
    },

    activeTab: {
      color: colors.primary,
    },
  });
}
