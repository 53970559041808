import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, StyledComponent } from '4finance-components';
import { connectPrefetch, connectFunnels, connectState } from '../connect';

@connectPrefetch([
  ['api', 'fetchClientApplicationRiskRuleResults'],
])
@connectFunnels
@connectState({
  riskRuleResults: ['api', 'fetch', 'client', 'application', 'riskRuleResults', 'query', 'data'],
})
@translate
@Radium
export default class ApprovalPreApproved extends StyledComponent {
  static styleRoot = 'ApprovalPreApproved';

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    riskRuleResults: RPT.object,
    funnelPaths: RPT.object.isRequired,
  }

  render() {
    const { cnt, funnelPaths, msg, riskRuleResults } = this.props;
    const style = this.getStyle();
    const nextUrl = riskRuleResults.includes('idDocumentUpload') ? routesList.approvalDocumentUploadWay : routesList.applicationBankAccount;

    return (
      <Jumbotron
        icon="resolution-like"
        title={cnt('approval.approved.title')}
        textAlign="center"
        kind="big"
        inheritedStyle={style.jumbotron}
      >
        <div style={style.paragraph}>
          {cnt('approval.approved.description')}
        </div>
        <ButtonLink
          to={funnelPaths[nextUrl]}
          inheritedStyle={style.button}
        >
          {msg('common.continue')}
        </ButtonLink>
      </Jumbotron>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    jumbotron: {
      paddingBottom: '50px',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },
    },

    paragraph: {
      fontSize: '16px',
      lineHeight: '25px',
      marginTop: '35px',
      marginBottom: '25px',
      color: colors.lightFont,
    },

    button: {
      width: '200px',
    },
  })
}
