import App from './app/App.react';
import ApplicationBankAccount from './application/BankAccount.react';
import ApplicationCardAdd from './approval/Card.Add.react';
import ApplicationCardConfirm from './approval/Card.Confirm.react';
import ApplicationDetails from './identification/ApplicationDetails.react';
import ApplicationProcessing from './processing/Processing.react';
import ApplicationProcessingPage from './processing/Page.react';
import Approval from './approval/Page.react';
import ApprovalCompleted from './approval/Completed.react';
import ApprovalDocumentUpload from './approval/DocumentUpload.react';
import ApprovalEmailOrWhatsapp from './approval/EmailOrWhatsapp.react';
import ApprovalError from './approval/Error.react';
import ApprovalPreApproved from './approval/PreApproved.react';
import ApprovalProposal from './approval/Proposal.react';
import ApprovalRejected from './approval/Rejected.react';
import Authentication from './authentication/Page.react';
import AutoRepayment from './payment/AutoRepayment.react';
import AutoRepaymentEnabled from './payment/AutoRepaymentEnabled.react';
import CardExpired from './payment/CardExpired.react';
import Contentful from './contentful/Router.react';
import createValidateGuards from '../common/lib/createValidateGuards';
import Dashboard from './dashboard/Page.react';
import DashboardAdditionalAmount from './dashboard/AdditionalAmount.react';
import DashboardOverview from './dashboard/Overview.react';
import Extension from './dashboard/extension/Extension.react';
import GeneralTerms from './static/GeneralTerms.react.js';
import guard from '../common/routeGuards';
import Home from './home/Page.react';
import Identification from './identification/Page.react';
import IdentificationDocumentUpload from './identification/DocumentUpload';
import IdentificationEmailOrWhatsapp from './identification/EmailOrWhatsapp';
import IdentificationInstantor from './identification/Instantor';
import IdentificationInstantorBank from './identification/Instantor/ChooseBank.react';
import IdentificationInstantorConsent from './identification/Instantor/Consent.react';
import IdentificationInstantorLogin from './identification/Instantor/Login.react';
import IdentificationInstantorLoginWrapper from './identification/Instantor/LoginWrapper.react';
import IdentificationMethod from './identification/Method.react.js';
import IdentificationProcessing from './identification/Processing.react';
import LoanContract from './static/LoanContract.react';
import NewConfirmLoan from './dashboard/new/ConfirmLoan.react';
import PasswordRecovery from './authentication/PasswordRecovery.react';
import PasswordReset from './authentication/PasswordReset.react';
import Payment from './payment/Page.react';
import PaymentExtension from './payment/PaymentExtension.react';
import PaymentFull from './payment/PaymentFull.react';
import PaymentMonthly from './payment/PaymentMonthly.react';
import React from 'react';
import Registration from './registration/Page.react';
import RegistrationAboutYou from './registration/AboutYou.react';
import RegistrationChooseBank from './application/ChooseBank.react';
import RegistrationConfirmLoan from './registration/ConfirmLoan.react';
import RegistrationOtherDetails from './registration/OtherDetails.react';
import RegistrationPersonalDetails from './registration/PersonalDetails.react';
import RegistrationWithCalculator from './registration/PageWithCalculator.react';
import routesList, { getNewApplicationUrl } from './routesList';
import Settings from './settings/Page.react';
import SettingsCardAdd from './settings/cards/Card.Add.react';
import SettingsCardProcessing from './settings/cards/Card.Processing.react';
import SettingsOverview from './settings/Overview.react';
import Signin from './authentication/LoginForm.react';
import Sitemap from './sitemap/Page.react';
import StaticPage from './static/Page.react';

import { IndexRoute, Route, Redirect } from 'react-router';

export default function createRoutes(dispatch, getState) {
  const validateGuards = createValidateGuards(dispatch, getState);

  return (
    <Route component={App} path={routesList.homepage}>
      <IndexRoute component={Home} onEnter={validateGuards([guard.forbidAuth])} />
      <Route component={Sitemap} path={routesList.sitemap} />

      <Route path={routesList.callback} />

      <Route component={Authentication} >
        <Route component={Signin} path={routesList.signinOptional} onEnter={validateGuards([guard.forbidAuth])} />
        <Route component={PasswordRecovery} path={routesList.passwordRecovery} />
        <Route component={PasswordReset} path={routesList.passwordReset} />
      </Route>

      <Redirect from={routesList.passwordResetOptional} to={routesList.passwordReset} />
      <Redirect from={routesList.passwordRecoveryOptional} to={routesList.passwordRecovery} />

      <Route component={Registration}>
        <Route component={RegistrationWithCalculator}>
          <Route component={RegistrationPersonalDetails} path={routesList.accountRegistration} onEnter={validateGuards([guard.forbidAuth])} />
          <Route component={RegistrationAboutYou} path={routesList.accountAboutYou} onEnter={validateGuards([guard.requireAuth])} />
          <Route component={RegistrationOtherDetails} path={routesList.accountOtherDetails} onEnter={validateGuards([guard.requireAuth])} />
        </Route>

        <Route component={RegistrationChooseBank} path={routesList.accountBankProvider} onEnter={validateGuards([guard.requireAuth])} />
        <Route component={RegistrationConfirmLoan} path={routesList.applicationConfirm} onEnter={validateGuards([guard.requireAuth])} />
      </Route>

      {[(path) => path, getNewApplicationUrl].map(getPath => ([
        <Route component={Identification}>
          <Route component={IdentificationMethod} path={getPath(routesList.applicationIdentification)} />

          <Route component={IdentificationEmailOrWhatsapp} path={getPath(routesList.applicationIdentificationEmailOrWhatsapp)} />
          <Route component={IdentificationDocumentUpload} path={getPath(routesList.applicationIdentificationDocumentUpload)} />
          <Route component={IdentificationInstantor}>
            <Route component={IdentificationInstantorBank} path={getPath(routesList.applicationIdentificationInstantorBank)} />
            <Route component={IdentificationInstantorLoginWrapper}>
              <Route component={IdentificationInstantorConsent} path={getPath(routesList.applicationIdentificationInstantorConsent)} />
              <Route component={IdentificationInstantorLogin} path={getPath(routesList.applicationIdentificationInstantor)} />
            </Route>
          </Route>
          <Route component={IdentificationProcessing} path={getPath(routesList.applicationIdentificationProcessingOptional)} />
          <Route component={ApprovalEmailOrWhatsapp} path={getPath(routesList.approvalEmailOrWhatsapp)} />
        </Route>,
        <Route component={Approval}>
          <Route component={ApprovalRejected} path={getPath(routesList.approvalRejected)} />
          <Route component={ApprovalPreApproved} path={getPath(routesList.approvalPreApproved)} />
          <Route component={ApprovalError} path={getPath(routesList.applicationInstantorErrorOptional)} />
          <Route component={ApprovalProposal} path={getPath(routesList.approvalProposal)} />
          <Route component={ApplicationBankAccount} path={getPath(routesList.applicationBankAccount)} onEnter={validateGuards([guard.requireAuth, guard.forbidBankAccount])} />
          <Route component={ApplicationCardAdd} path={getPath(routesList.applicationCardAddOptional)} onEnter={validateGuards([guard.requireAuth, guard.forbidCardAdded])} />
          <Route component={ApplicationCardConfirm} path={getPath(routesList.applicationCardConfirm)} onEnter={validateGuards([guard.requireAuth])} />
          <Route component={ApprovalCompleted} path={getPath(routesList.approvalCompleted)} />
          <Route component={ApprovalDocumentUpload} path={getPath(routesList.approvalDocumentUploadWay)} />
        </Route>,

        <Route component={ApplicationProcessingPage}>
          <Route component={ApplicationProcessing} path={getPath(routesList.approvalProcessing)} />
        </Route>,
      ]))}

      <Redirect from={routesList.applicationCardProcessing} to={routesList.approvalCompleted} />
      <Redirect from={routesList.applicationCardProcessingAutoRepayment} to={routesList.settingsCardProcessingOptional} />

      <Route component={Dashboard} path={routesList.dashboard} onEnter={validateGuards([guard.requireAuth])}>
        <IndexRoute component={DashboardOverview} />
        <Route component={Extension} path={routesList.extension} onEnter={validateGuards([guard.requireExtension])} />
        <Route component={DashboardAdditionalAmount} path={routesList.additionalAmount} onEnter={validateGuards([guard.forbidFintonic])} />
        <Route component={ApplicationDetails} path={routesList.identificationApproveApplication} />
        <Route component={ApplicationDetails} path={routesList.newApplication} />
        <Route component={NewConfirmLoan} path={getNewApplicationUrl(routesList.applicationConfirm)} />
        <Route component={ApprovalDocumentUpload} path={routesList.approvalDocumentUpload} />
      </Route>

      <Route component={Settings} path={routesList.settings} onEnter={validateGuards([guard.requireAuth])}>
        <IndexRoute component={SettingsOverview} />
        <Route path={routesList.settingsCard} />
        <Route path={routesList.settingsCardRefresh} />
        <Route component={SettingsCardAdd} path={routesList.settingsCardAddOptional} />
        <Route component={SettingsCardProcessing} path={routesList.settingsCardProcessingOptional} />
      </Route>

      <Route component={Payment} onEnter={validateGuards([guard.requireAuth])}>
        <Route component={AutoRepayment} path={routesList.autoRepayment} />
        <Route component={AutoRepaymentEnabled} path={routesList.autoRepaymentEnabled} />
        <Route component={CardExpired} path={routesList.cardExpired} />
        <Route component={PaymentMonthly} path={routesList.payment} />
        <Route component={PaymentFull} path={routesList.paymentFull} />
        <Route component={PaymentExtension} path={routesList.paymentExtension} onEnter={validateGuards([guard.requireExtension])} />
      </Route>

      <Route component={StaticPage}>
        <Route component={GeneralTerms} path={routesList.contentAgreement} />
        <Route component={LoanContract} path={routesList.contentPreliminary} onEnter={validateGuards([guard.requireAuth])} />
      </Route>

      <Route component={Contentful} path="*" />
    </Route>
  );
}
