import Icon from '../../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import ValueLoader from '../../../components/ValueLoader.react';
import { StyledComponent, Tooltip, Modal } from '4finance-components';

const BULL_P = String.fromCharCode(0x25cf);

@translate
@Radium
export default class DetailRow extends StyledComponent {
  static styleRoot = 'DetailRow'

  static propTypes = {
    hasError: RPT.bool,
    isApproveApplication: RPT.bool,
    isLoading: RPT.bool,
    label: RPT.string.isRequired,
    msg: RPT.func.isRequired,
    pattern: RPT.oneOf(['password', 'bank', 'date', 'phone', 'news', 'currency']),
    translateNamespace: RPT.string.isRequired,
    value: RPT.any,
    withTooltip: RPT.bool,
  }

  static defaultProps = {
    isLoading: false,
  }

  state = {
    isApproveApplication: false,
    isModalOpen: false,
  }

  toggleModal = () => {
    const { isModalOpen } = this.state;

    this.setState({ isModalOpen: !isModalOpen });
  }

  handleEditClick = () => {
    this.toggleModal();
  }

  handleCloseModal = () => {
    this.toggleModal();
  }

  renderValueWithPattern = () => {
    const { formatDate, numberToCurrency, value, pattern } = this.props;

    const PATTERN_MASK = {
      password: `${BULL_P} ${BULL_P} ${BULL_P} ${BULL_P} ${BULL_P} ${BULL_P} ${BULL_P}`,
      bank: `${value}`.replace(/(.{4})/g, '$1 '),
      phone: `${`${value}`.replace(/(.{3})/g, '$1 ')}`,
      date: formatDate(value),
      currency: numberToCurrency(value),
    };

    return PATTERN_MASK[pattern] || value;
  }

  render() {
    const { label, value, withTooltip, isLast, msg, translateNamespace, pattern, children, isApproveApplication, isLoading, hasError } = this.props;
    const style = this.getStyle();

    return (
      <li style={[style.item, isLast && style.itemLast]}>
        <div style={[style.block, style.blockInfo]}>
          <div style={[style.label, isApproveApplication && style.label.isApproveApplication, hasError && style.label.hasError]}>{msg(`${translateNamespace}.${label}.label`)}</div>
          <div style={[style.value, isApproveApplication && style.value.isApproveApplication]}>
            <ValueLoader isLoading={isLoading}>{pattern ? this.renderValueWithPattern() : value}</ValueLoader>
          </div>
        </div>
        {!withTooltip && <div style={[style.block, style.blockEditable]} onClick={this.handleEditClick} >
          <Icon kind="loan-edit" size={12} color={style.iconColor} style={style.editIcon} />
        </div>}
        {withTooltip && <Tooltip
          inheritedStyle={style.tooltipStyle}
          tooltip={msg(`${translateNamespace}.${label}.tooltip`)}
          icon={
            <div style={[style.block, style.blockEditable, style.blockEditable.toltip]} >
              <Icon kind="info" size={13} color={style.iconColor} />
            </div>
          }
        />
        }
        {children && this.state.isModalOpen &&
          <Modal blurSelector="#app" onClose={this.handleCloseModal} isModalOpen={this.state.isModalOpen} style={style.modal}>
            {React.Children.map(children, child => React.cloneElement(child, {
              onClose: this.handleCloseModal,
            }))}
          </Modal>}
      </li>
    );
  }

  static style = ({ colors, font, mediaQueries, isApproveApplication }) => ({
    item: {
      borderBottom: `1px ${colors.monotone2} solid`,
      display: 'flex',
      padding: '5px 0',
    },

    itemLast: {
      borderBottom: 0,
    },

    block: {
      borderRight: `1px ${colors.monotone2} solid`,
      flex: '1 100%',
      paddingRight: '18px',
      paddingBottom: '5px',
    },

    blockEditable: {
      borderRight: 0,
      cursor: 'pointer',
      flexBasis: '25px',
      lineHeight: '32px',
      paddingRight: 0,
      textAlign: 'right',

      toltip: {
        width: '24px',
        height: '32px',
        paddingTop: '8px',
        lineHeight: 'auto',
      },
    },

    blockInfo: {
      display: 'flex',
    },

    label: {
      color: colors.darkFont,
      fontSize: isApproveApplication ? '12px' : '15px',
      fontWeight: isApproveApplication ? font.weight.bold : font.weight.thin,
      lineHeight: '32px',
      flex: '1 0 auto',

      isApproveApplication: {
        lineHeight: '18px',
        paddingTop: '6px',
      },

      hasError: {
        color: colors.error,
      },

      [mediaQueries.breakpointTablet]: {
        lineHeight: '22px',
        paddingTop: '6px',
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
      },
    },

    value: {
      color: colors.darkFont,
      flex: '1 1 100%',
      fontSize: isApproveApplication ? '14px' : '15px',
      lineHeight: '32px',
      textAlign: 'right',
      fontWeight: isApproveApplication ? 'normal' : font.weight.bold,

      isApproveApplication: {
        lineHeight: '22px',
        paddingTop: '6px',
      },

      [mediaQueries.breakpointTablet]: {
        lineHeight: '22px',
        paddingTop: '6px',
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '13px',
      },
    },

    tooltipStyle: {
      color: colors.white,
      fontSize: '14px',
      fontWeight: font.weight.bold,
      lineHeight: '20px',
      padding: 0,
      textAlign: 'center',
      verticalAlign: '-2px',
      width: 'auto',

      arrow: {
        [mediaQueries.breakpointTablet]: {
          right: '8px',
          left: 'auto',
        },
      },

      content: {
        bottom: 'calc(100%)',
        transform: 'translateX(calc(50% + 7px))',
        padding: '5px 20px 10px',
        width: '220px',

        [mediaQueries.breakpointTablet]: {
          transform: 'translateX(25px)',
        },
      },
    },

    editIcon: {
      cursor: 'pointer',
    },

    iconColor: colors.primary,

    modal: {
      innerWrapper: {
        maxWidth: '350px',
        backgroundColor: colors.background,
        paddingBottom: 0,

        [mediaQueries.breakpointSmall]: {
          width: '90vw',
        },
      },
    },
  });
}
