export function serializeRegistration({ personalId, lastName, lastNameSecond, ...params }) {
  return {
    ...params,
    lastName: [lastName, lastNameSecond].join(' ').trim(),
    personalId: personalId.toUpperCase(),
    passwordRepeat: params.password,
  };
}

export function serializeAboutYou({ postalCode, province, city, roadType, streetName, streetNumber, streetFloor, streetAppartment }) {
  return {
    postalCode,
    location1: province,
    location2: city,
    location3: `${roadType} ${(streetName || '').replace(roadType, '').trim()}`,
    location4: streetNumber,
    location5: streetFloor,
    location6: streetAppartment,
  };
}
