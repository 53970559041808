const ERROR = 'invalid_name';

export default function name() {
  return value => {
    if (value && !value.match(/^[áéíñóúüÁÉÍÑÓÊÚÜa-z-'çà\s]+$/i)) {
      return ERROR;
    }

    return null;
  };
}
