import Icon from '../../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, StyledComponent } from '4finance-components';
import { FileUpload } from '4finance-redux-file-upload';

@translate
@Radium
export default class Card extends StyledComponent {
  static styleRoot = 'Card';

  static propTypes = {
    msg: RPT.func.isRequired,
    fileUploadProps: RPT.object,
    onClose: RPT.func,
    kind: RPT.string,
  };

  render() {
    const { kind, fileUploadProps, onClose, msg } = this.props;
    const style = this.getStyle();

    return (
      <div id={`${kind}-modal-content`}>
        <div style={style.instructions}>
          <div style={style.example}>
            <img style={style.image} src={`/assets/img/${kind}-good.jpg`} alt={kind} />
            <Icon
              kind="check"
              style={[style.icon, style.correct]}
            />
          </div>
          <div style={style.example}>
            <img style={style.image} src={`/assets/img/${kind}-bad.jpg`} alt={kind} />
            <Icon
              kind="close-circle"
              style={style.icon}
            />
          </div>
        </div>

        <div style={style.textWrapper}>
          <div style={style.text}>
            {msg(`identification.documentUpload.modal.${kind}.correct`)}
          </div>
          <div style={style.text}>
            {msg(`identification.documentUpload.modal.${kind}.incorrect`)}
          </div>
        </div>
        <FileUpload {...fileUploadProps}>
          <ButtonLink
            inheritedStyle={style.button}
          >
            {msg('common.continue')}
          </ButtonLink>
        </FileUpload>
        <a
          onClick={onClose}
          style={style.link}
        >
          {msg('common.cancel')}
        </a>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    button: {
      display: 'block',
      position: 'relative',
      margin: '0 auto',

      [mediaQueries.breakpointStartTablet]: {
        minWidth: '200px',
        width: '90%',
      },

      [mediaQueries.breakpointTablet]: {
        minWidth: '100px',
        width: '90%',
      },
    },

    link: {
      display: 'block',
      textAlign: 'center',
      textDecoration: 'underline',
      fontSize: '18px',
      marginTop: '25px',
      cursor: 'pointer',
    },

    instructions: {
      width: '100%',
      display: 'flex',
    },

    image: {
      width: '100%',
      height: '100%',
    },

    textWrapper: {
      display: 'flex',
      marginTop: '10px',
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
      },
    },

    text: {
      width: '50%',
      padding: '20px',

      [mediaQueries.breakpointTablet]: {
        width: '100%',
      },
    },

    example: {
      width: '50%',
      position: 'relative',
    },

    icon: {
      position: 'absolute',
      bottom: '-12.5px',
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
      width: '25px',
      height: '25px',
      overflow: 'visible',
    },

    correct: {
      fill: colors.white,
      background: colors.successMessage,
      padding: '7px',
      borderRadius: '12.5px',
    },
  });
}
