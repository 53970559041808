import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import withInterval from '../../lib/withInterval';
import { connectActions } from '../../connect';
import { StyledComponent, Loader } from '4finance-components';

@connectActions({
  getStoreCardStatus: ['mymoid', 'getStoreCardStatus'],
  processStoreCardTaskId: ['mymoid', 'processStoreCardTaskId'],
  fetchClientCardsActive: ['api', 'fetchClientCardsActive'],
})
@withInterval()
export default class CardProcessing extends StyledComponent {
  static styleRoot = 'CardProcessing';

  static propTypes = {
    addInterval: RPT.func.isRequired,
    fetchClientCardsActive: RPT.func.isRequired,
    getStoreCardStatus: RPT.func.isRequired,
    processStoreCardTaskId: RPT.func.isRequired,
    removeIntervalById: RPT.func.isRequired,
  }

  pollTasks = () => {
    const { getStoreCardStatus, processStoreCardTaskId, fetchClientCardsActive, removeIntervalById, router } = this.props;

    getStoreCardStatus().then((result) => {
      if (result.payload !== 'IN_PROGRESS') {
        removeIntervalById('POLL_TASK');
        processStoreCardTaskId(routesList.settings, routesList.settingsCardAdd, result.payload);
        fetchClientCardsActive();
        if (result.payload === 'COMPLETED') {
          router.push(routesList.dashboard);
        }
      }

      return null;
    });
  }

  componentDidMount() {
    this.props.addInterval('POLL_TASK', 2000, this.pollTasks, true);
  }

  render() {
    const style = this.getStyle();

    return (
      <Loader
        variant="sections"
        style={style.loader}
      />
    );
  }

  static style = ({ colors }) => ({
    loader: {
      section: {
        spinning: {
          borderTop: `25px solid ${colors.secondary}`,
          borderRight: `25px solid ${colors.secondary}`,
          borderBottom: `25px solid ${colors.secondary}`,
        },
      },
    },
  })
}
