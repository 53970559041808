import { MultiField } from '4finance-components';
import { isRequired, minLength } from '4finance-validation';
import translatedField from '../../../lib/translatedField';

export const getVerificationCode = (verificationCode) => `${verificationCode}`.replace(/-/g, '');

const isVerification = (value) => {
  const verificationCode = getVerificationCode(value);

  if (verificationCode && !`${verificationCode}`.match(/^(-)?\d+((,|\.)\d+)?$/)) return 'must_be_number';

  return null;
};

export default translatedField('verification', {
  delimiter: '-',
  showDelimiter: false,
  separated: true,
  fields: [
    { length: 1, type: 'tel' },
    { length: 1, type: 'tel' },
    { length: 1, type: 'tel' },
    { length: 1, type: 'tel' },
  ],
  inheritedStyle: {
    padding: 0,
    container: {
      textAlign: 'center',
      margin: 0,
    },
    input: {
      background: 'white',
      borderBottomWidth: '2px',
      fontSize: '20px',
      height: '50px',
    },
  },
}, [isRequired(), minLength(7), isVerification])(MultiField);
