export default {
  base: {
    borderLeft: 0,
    paddingLeft: 0,
  },
  label: {
    fontSize: '15px',
    lineHeight: '32px',
    marginRight: 0,
  },
  bottomMargin: {
    marginBottom: 0,
  },
};
