import React, { PureComponent as Component } from 'react';
import Radium, { Style } from 'radium';

export default function withErrorBackground(WrappedComponent) {
  class WithErrorStyle extends Component {
    render() {
      return (
        <div className="with-error-background">
          <Style
            scopeSelector=".with-error-background"
            rules={{
              '.errorMessage:first-of-type': {
                background: 'rgb(244,231,227) !important',
              },
              '.errorMessage div input, .errorMessage select': {
                background: 'rgb(244,231,227) !important',
              },
            }}
          />
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }

  return Radium(WithErrorStyle);
}
