import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Map } from 'immutable';
import { StyledComponent } from '4finance-components';
import { connectSelectors } from '../../connect';
import { RPTcalculatorOfferSelector } from '../../../common/calculator/selectors';

const OFFER_FIELDS = [
  'amount',
  'term',
  'due_date',
];

@connectSelectors({
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@translate
@Radium
export default class CalculatorResponsiveOffer extends StyledComponent {
  static styleRoot = 'CalculatorResponsiveOffer'

  static defaultProps = {
    offer: new Map(),
  }

  static propTypes = {
    formatDate: RPT.func.isRequired,
    msg: RPT.func,
    preciseNumberToCurrency: RPT.func.isRequired,
    calculatorOfferSelector: RPTcalculatorOfferSelector,
  }

  constructor(props, state) {
    super(props, state);

    const { msg, preciseNumberToCurrency, formatDate } = props;
    const fieldValues = {
      due_date: (offer) => ({ bold: false, content: formatDate(offer.get('agreementEndDate')) }),
      amount: (offer) => ({ bold: false, content: preciseNumberToCurrency(offer.get('totalPrincipal')) }),
      term: (offer) => ({ bold: false, content: msg('date.months', { count: offer.get('term') }) }),
    };

    this.getFieldValue = (fieldName, offer) => fieldValues[fieldName](offer) || { bold: false, content: '' };
  }

  getOffer() {
    const { offer, offerFetched } = this.props.calculatorOfferSelector;

    if (offerFetched) {
      this.previous = offer;
    }

    return this.previous || new Map();
  }

  render() {
    const { msg } = this.props;
    const offer = this.getOffer();
    const style = this.getStyle();

    const ResponsiveOfferRow = Radium(({ title, children, last }) => (
      <div
        style={[
          style.cell.base,
          last && style.cell.last,
        ]}
      >
        <div style={style.cell.title}>{title}</div>
        <div style={style.cell.value}>{children}</div>
      </div>
    ));

    return (
      <div style={style.wrapper}>
        {OFFER_FIELDS.map((fieldName, key) => (
          <ResponsiveOfferRow
            title={msg(`offer.fields.${fieldName}`)}
            last={OFFER_FIELDS.length - 1 === key}
          >
            {this.getFieldValue(fieldName, offer).content}
          </ResponsiveOfferRow>
        ))}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      minHeight: '35px',
      color: colors.darkFont,
      display: 'flex',
      alignItems: 'start',
    },

    cell: {
      base: {
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        paddingRight: '10px',
        marginRight: '10px',
        fontSize: '14px',
        fontWeight: 500,
        borderRight: `1px solid ${colors.gray}`,
        [mediaQueries.breakpointSmall]: {
          fontSize: '12px',
        },
      },

      last: {
        paddingRight: 0,
        borderRight: 'none',
      },

      title: {
        marginBottom: '3px',
        opacity: 0.7,
      },

      value: {
        fontSize: '16px',
        [mediaQueries.breakpointSmall]: {
          fontSize: '14px',
        },
      },
    },
  })
}
