import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions } from '../../connect';
import { TextField } from '4finance-components';

@connectActions({
  setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
})
@translate
export default class AmountField extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
    label: RPT.string,
    setFormFieldProperty: RPT.func.isRequired,
    labelInside: RPT.bool,
  }

  static default = {
    labelInside: false,
  }

  static contextTypes = {
    onionFormName: RPT.string.isRequired,
    onionLiveValidate: RPT.func.isRequired,
  }


  onChange = ({ name, value }) => {
    const { setFormFieldProperty } = this.props;
    const { onionFormName, onionLiveValidate } = this.context;

    setFormFieldProperty(onionFormName, name, 'value', value.toString().replace(/[^0-9]/g, ''));
    onionLiveValidate();
  }

  render() {
    const { msg, label, labelInside, ...props } = this.props;

    return (
      <div>
        {!labelInside && <div
          style={{
            lineHeight: '20px',
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '8px',
          }}
        >
          {label}
        </div>}
        <TextField
          {...props}
          width="small"
          unit={msg('number.currency.format.unit')}
          label={labelInside ? label : false}
          topMargin={false}
          style={!labelInside ? style : null}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

const style = ({ mediaQueries }) => ({
  container: {
    bottomMargin: {
      [mediaQueries.breakpointSmall]: {
        width: 'calc(100% - 20px)',
      },
    },
  },
  input: {
    padding: '18px 40px 15px 20px',
    width: '90%',
  },
});
