export default {
  wrapper: {
    size: {
      default: {
        minWidth: '320px',
      },
      medium: {
        minWidth: '320px',
      },
    },
  },
};
