import BankAccountNumberField from './BankAccountNumber.react';
import BankRadioInputGroup from '../../components/BankRadioInputGroup.react';
import BANK_OPTIONS from './data/bankOptions';
import customIbanEs from '../../../common/validations/ibanEs';
import translatedField from '../../lib/translatedField';
import { Button } from '4finance-components';
import { connectSubmit } from '4finance-onion-form';
import { isRequired, ibanEs } from '4finance-validation';

export const BankAccountNumber = translatedField('bankAccountNumber', {
  fields: [
    { length: 4, placeholder: 'ES00' },
    { length: 4, type: 'tel', placeholder: '0000' },
    { length: 4, type: 'tel', placeholder: '0000' },
    { length: 4, type: 'tel', placeholder: '0000' },
    { length: 4, type: 'tel', placeholder: '0000' },
    { length: 4, type: 'tel', placeholder: '0000' },
  ],
  delimiter: '-',
  showDelimiter: false,
}, [
  isRequired(),
  ibanEs(),
  customIbanEs(),
])(BankAccountNumberField);

export const Bank = translatedField('bank', {
  options: BANK_OPTIONS,
}, [
  isRequired(),
])(BankRadioInputGroup);

export const Submit = connectSubmit(Button);
