import getExtension from '../../lib/getExtension';
import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import LoanManagmentDesktop from './LoanManagmentDesktop.react';
import LoanManagmentMobile from './LoanManagmentMobile.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPrefetch, connectSelectors, connectState } from '../../connect';

@connectState({
  creditLimit: ['api', 'fetch', 'client', 'query', 'data', 'creditLimit'],
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  extensions: ['api', 'fetch', 'client', 'loans', 'extensions', 'root', 'latest', 'data'],
  client: ['api', 'fetch', 'client', 'query', 'data'],
  nextPayment: ['loans', 'nextPayment'],
  activeCard: ['client', 'activeCard'],
})
@connectSelectors({
  additionalAmountAvailable: ['client', 'additionalAmountAvailable'],
  isFintonicOrPrestaloProduct: ['client', 'isFintonicOrPrestaloProduct'],
  cardExpired: ['client', 'cardExpired'],
  autoRepayment: ['client', 'getAutoRepaymentStatus'],
})
@connectPrefetch([
  ['client', 'fetchClientLatestLoanExtensions'],
])
@translate
@listenWindowResize
export default class LoanManagment extends Component {
  static styleRoot = 'LoanManagment'

  static propTypes = {
    msg: RPT.func,
    creditLimit: RPT.number,
    additionalAmountAvailable: RPT.bool,
    paymentAvailable: RPT.bool,
    nextPayment: RPT.object,
    extensions: RPT.object,
    loan: RPT.object,
    device: RPT.instanceOf(Device).isRequired,
  }

  static defaultProps = {
    creditLimit: 300,
    loan: new Map(),
    client: new Map(),
    extension: new Map(),
  }

  render() {
    const { device } = this.props;
    const isScreenWidthUnderTablet = device.atMost('desktop');
    const extension = getExtension(this.props.extensions);

    return isScreenWidthUnderTablet
      ? <LoanManagmentMobile {...this.props} extension={extension} />
      : <LoanManagmentDesktop {...this.props} extension={extension} />;
  }
}
