import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { SUBMIT_CHANGE_TEMPORARY_PASSWORD } from '../../common/passwordRecovery/actions';
import { autobind } from 'core-decorators';
import { Button, StyledComponent, TextField } from '4finance-components';
import { connectPending, connectActions } from '../connect';
import { Form, connectField, connectSubmit } from '4finance-onion-form';
import { isRequired, password as passwordValidation } from '4finance-validation';

const validations = {
  password: [isRequired(), passwordValidation()],
};

const Password = translate(connectField('password', { type: 'password' })(TextField));

const SubmitButton = connectSubmit(Button);

@connectPending({
  isPasswordResetPending: SUBMIT_CHANGE_TEMPORARY_PASSWORD,
})
@connectActions({
  reset: ['passwordRecovery', 'submitChangeTemporaryPassword'],
})
@translate
export default class PasswordReset extends StyledComponent {
  static styleRoot = 'PasswordReset'

  static propTypes = {
    isPasswordResetPending: RPT.bool,
    msg: RPT.func,
    reset: RPT.func,
  }

  @autobind
  onSubmit({ values: { password } = {} }) {
    const { reset } = this.props;

    reset(password);
  }

  render() {
    const { msg, isPasswordResetPending } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <Form onSubmit={this.onSubmit} name="password_reset" validations={validations}>
          <Password width="full" topMargin />

          <SubmitButton
            kind="primary"
            disabled={isPasswordResetPending}
            loading={isPasswordResetPending}
            type="submit"
            fullWidth
            inheritedStyle={style.button}
          >
            {msg('sign_in.password_reset.button')}
          </SubmitButton>
        </Form>
      </div>
    );
  }

  static style = ({ colors }) => ({
    button: {
      marginTop: 0,
      marginRight: '18px',
      verticalAlign: 'middle',
      height: '60px',
      textTransform: 'uppercase',
      fontWeight: '400',
      fontSize: '18px',
    },

    link: {
      display: 'block',
      textAlign: 'center',
      color: colors.primary,
      margin: '25px 0',
      fontSize: '14px',
    },

    serverError: {
      color: colors.error,
      marginBottom: '15px',
    },
  })
}
