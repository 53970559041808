import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import PageContainer from '../components/PageContainer.react';
import { Heading, StyledComponent } from '4finance-components';


@translate
@Radium
export default class DashboardRejected extends StyledComponent {
  static styleRoot = 'DashboardRejected';

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
  }

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <PageContainer width="widest" mobileFullScreen>
        <Jumbotron
          title={cnt('dashboard.rejected.title')}
          textAlign="left"
          kind="big"
          inheritedStyle={style.jumbotron}
        >
          <p style={style.paragraph}>
            {msg('dashboard.rejected.description')}
          </p>

          <p style={style.paragraph}>
            {msg('dashboard.rejected.futureOffer')}
          </p>

          <Heading kind="h3" inheritedStyle={style.heading}>
            {msg('approval.rejected.reason.title')}
          </Heading>

        </Jumbotron>
      </PageContainer>
    );
  }

  static style = ({ mediaQueries }) => ({
    jumbotron: {
      paddingBottom: '50px',
      marginTop: '50px',
      marginLeft: '10%',
      marginRight: '10%',
      paddingLeft: '10%',
      paddingRight: '10%',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
      },

      title: {
        fontWeight: 'bold',
      },
    },

    list: {
      list: {
        fontSize: '16px',
      },

      item: {
        marginTop: 0,
      },
    },

    paragraph: {
      lineHeight: '26px',
      fontSize: '20px',
      marginTop: '20px',
    },

    heading: {
      fontSize: '18px',
      marginTop: '30px',
      marginBottom: '20px',
    },
  })
}
