import config from '4finance-configuration';
import GetLoan from '../../home/GetLoan.react';
import MainContacts from '../components/contact/MainContacts.react';
import OtherContacts from '../components/contact/OtherContacts.react';
import PageContainer from '../../components/PageContainer.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StaticPage from '../../layouts/StaticPage.react';
import { StyledComponent } from '4finance-components';

@Radium
export default class Contact extends StyledComponent {
  static styleRoot = 'Contact'

  static propTypes = {
    page: RPT.object,
    fields: RPT.object,
  }

  render() {
    const { textBlock, page: { fields: { url, title, email, phone, ...otherContactsData } } } = this.props;
    const style = this.getStyle();

    return (
      <StaticPage location={{ pathname: url }} hasPaddingBottom={false} inheritedStyle={style.staticPage}>
        <PageContainer width="wide" inheritedStyle={style.container}>
          <MainContacts
            title={title}
            data={[
              { kind: 'chat', value: !config.zopimAPIKey },
              { kind: 'email', value: email },
              { kind: 'phone', value: phone },
            ]}
          />
        </PageContainer>

        <PageContainer width="wide" inheritedStyle={[style.container, style.container.otherContacts]}>
          <OtherContacts
            data={{ ...otherContactsData, openingHours: textBlock }}
          />
        </PageContainer>

        <GetLoan />
      </StaticPage>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    staticPage: {
      backgroundColor: colors.white,
    },

    container: {
      paddingTop: 0,
      margin: '10px 0 50px 0',

      [mediaQueries.breakpointLaptopSmall]: {
        paddingTop: 0,
      },

      [mediaQueries.breakpointTablet]: {
        margin: '0 0 40px 0',
      },

      otherContacts: {
        backgroundColor: colors.background,
        margin: '20px 0 0 0',
        paddingBottom: '30px',

        [mediaQueries.breakpointTablet]: {
          paddingBottom: '15px',
        },
      },
    },
  })
}
