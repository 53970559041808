import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import config from '4finance-configuration';
import { connectActions, connectState } from '../connect';
import { Modal, StyledComponent, Heading } from '4finance-components';
import routesList from '../routesList';
import Icon from '../components/Icon.react';
import Link from '../components/Link.react';

@connectState({
  dismissClosingPopup: ['settings', 'closingZaplo', 'dismissClosingPopup'],
})
@connectActions({
  dismissZaploClosingPopup: ['settings', 'dismissZaploClosingPopup'],
})
@translate
@Radium
export default class zaploClosingPopup extends StyledComponent {
  static styleRoot = 'ZaploClosingPopup';

  static propTypes = {
    dismissClosingPopup: RPT.bool.isRequired,
    dismissZaploClosingPopup: RPT.func.isRequired,
    msg: RPT.func,
    cnt: RPT.func,
  };

  renderContent() {
    const { cnt, msg, dismissZaploClosingPopup } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.leftContainer}>
          <Heading inheritedStyle={style.title} bold kind="h2">
            {msg('zaplo_close.title')}
          </Heading>
          <p style={style.pharagraf}>{msg('zaplo_close.subtitle')} {msg('zaplo_close.subtitle2')}</p>
          <p style={style.pharagraf}>
            {msg('zaplo_close.subtitle4')}
          </p>
          <Link to={routesList.signin} onClick={dismissZaploClosingPopup} bold inheritedStyle={style.link}>
            <div style={style.userIcon}><Icon kind="user-area" /></div>
            {msg('zaplo_close.link')}
          </Link>
        </div>
        <div style={style.rightContainer}>
          <div style={style.triangle}></div>
          <div style={style.logoWrapper}>
            <a href={config.vivusRedirectUrl} target="_blank">
              <Icon kind="vivus-sign" style={style.logoVivus} />
            </a>
          </div>
          <Heading kind="h2" inheritedStyle={[style.title, style.rightTitle]}>
            {msg('zaplo_close.title2')}
          </Heading>
          <p style={[style.pharagraf, style.rightPharagraf]}>
            {cnt('zaplo_close.subtitle3')}
          </p>
          <a href={config.vivusRedirectUrl} style={style.button} onClick={dismissZaploClosingPopup}>
            {msg('zaplo_close.button')}
          </a>
        </div>
        <div style={style.close} onClick={dismissZaploClosingPopup}>x</div>
      </div>);
  }

  render() {
    const { dismissClosingPopup, dismissZaploClosingPopup } = this.props;
    const style = this.getStyle();

    return (
      <Modal
        isModalOpen={!dismissClosingPopup}
        blurSelector="#app"
        style={style.modal}
        onClose={dismissZaploClosingPopup}
      >
        {this.renderContent()}
      </Modal>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    modal: {
      innerWrapper: {
        padding: 0,
        [mediaQueries.breakpointTablet]: {
          width: '90vh',
          minWidth: '320px',
        },
      },
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
      margin: '0px',
      maxHeight: '90vh',
      width: '740px',
      height: '285px',
      overflow: 'hidden',
      textAlign: 'center',
      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
        height: '525px',
        width: '320px',
        overflowY: 'scroll',
      },
    },

    leftContainer: {
      backgroundColor: colors.white,
      color: colors.darkFont,
      height: '45%',
      padding: '0 25px',
      [mediaQueries.breakpointStartTablet]: {
        width: '50%',
        height: '100%',
        padding: '0 50px',
      },
    },

    rightContainer: {
      position: 'relative',
      backgroundColor: '#a7cc46',
      color: colors.white,
      height: '55%',
      padding: '0 25px',
      [mediaQueries.breakpointStartTablet]: {
        width: '50%',
        height: '100%',
        padding: '0 40px',
      },
    },

    triangle: {
      position: 'absolute',
      top: 0,
      left: -1,
      width: 0,
      height: 0,
      borderTop: '142px solid transparent',
      borderBottom: '142px solid transparent',
      borderLeft: '30px solid #fff',
      [mediaQueries.breakpointTablet]: {
        borderLeft: '161px solid transparent',
        borderRight: '161px solid transparent',
        borderTop: '20px solid #fff',
        top: '-1px',
      },
    },

    title: {
      fontSize: '28px',
      marginTop: '40px',
      [mediaQueries.breakpointTablet]: {
        marginTop: '30px',
        fontSize: '28px',
      },
    },

    logoVivus: {
      width: '145px',
    },

    logoWrapper: {
      marginTop: '35px',
      [mediaQueries.breakpointTablet]: {
        marginTop: '50px',
      },
    },

    rightTitle: {
      color: colors.white,
      marginTop: '15px',
      [mediaQueries.breakpointTablet]: {
        marginTop: '15px',
      },
    },

    pharagraf: {
      fontSize: '13px',
      lineHeight: '16px',
      marginTop: '15px',
    },

    rightPharagraf: {
      marginTop: 0,
      padding: '0 16px',
      [mediaQueries.breakpointTablet]: {
        padding: '0 10px',
      },
    },

    link: {
      fontSize: '14px',
      lineHeight: '17px',
      marginTop: '19px',
    },

    userIcon: {
      margin: '0 5px',
      display: 'inline-block',
      float: 'left',
    },

    button: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '15px',
      backgroundColor: '#116c3a',
      width: '280px',
      borderRadius: '4px',
      textDecoration: 'none',
      color: colors.white,
      padding: '15px 20px 12px',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#014720',
      },
    },

    close: {
      color: '#575757',
      height: '14px',
      width: '14px',
      fontSize: '25px',
      lineHeight: '11px',
      position: 'fixed',
      right: '15px',
      top: '15px',
      cursor: 'pointer',
      zIndex: 2,
    },

  });
}
