import config from '4finance-configuration';
import delay from '../lib/delay';
import routesList from '../../browser/routesList';
import * as calculator from '../calculator/actions';
import { actions as api } from '../api';
import { CARD_FRONT, CARD_BACK } from '../../browser/components/upload/consts';
import { nextApplicationFunnelPath, updateApplicationFunnelPath } from '../application/actions';
import { push as updatePath } from 'react-router-redux';

export const STATUS_CHECK_COUNTER_LIMIT = 50;
export const STATUS_CHECK_DELAY = 5000;

export const FETCH_CLIENT_APPLICATION_WITH_OFFER = 'FETCH_CLIENT_APPLICATION_WITH_OFFER';
export const INSTANTOR_CONSENT = 'INSTANTOR_CONSENT';
export const TOGGLE_TEST_DOUBLE = 'TOGGLE_TEST_DOUBLE';
export const CHECK_FOR_TEST_DOUBLE = 'CHECK_FOR_TEST_DOUBLE';
export const INSTANTOR_ERROR = 'INSTANTOR_ERROR';
export const INSTANTOR_SUCCESS = 'INSTANTOR_SUCCESS';
export const STATUS_CHECK = 'STATUS_CHECK';
export const RISK_RULES = 'RISK_RULES';
export const RISK_RULES_SUCCESS = 'RISK_RULES_SUCCESS';
export const CHANGE_METHOD = 'CHANGE_METHOD';

const APPROVED = 'APPROVED';
const REJECTED = 'REJECTED';
const MANUAL = 'MANUAL';

const CHECK_STATUS_ROUTES = {
  documentUpload: routesList.approvalDocumentUploadWay,
  preapproved: routesList.approvalPreApproved,
  denied: routesList.approvalRejected,
  error: routesList.applicationInstantorError,
  proposal: routesList.approvalProposal,
};

export function changeMethod(method) {
  return {
    type: CHANGE_METHOD,
    payload: method,
  };
}

export function getRiskRules() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const { error, payload } = await dispatch(api.fetchClientApplicationRiskRuleResults());

      if (error) {
        throw Error('Cannot get risk rules result');
      }

      const upload = [];

      if (payload.includes('idDocumentUpload')) {
        upload.push(CARD_FRONT);
        upload.push(CARD_BACK);
      }

      return {
        upload,
      };
    };

    return {
      type: RISK_RULES,
      payload: getPromise(),
    };
  };
}

export function submitDocumentUpload(redirectUrl) {
  return ({ dispatch }) => {
    dispatch(api.fetchClientApplicationRiskRuleResultsReset(true));
    dispatch(updateApplicationFunnelPath(redirectUrl || routesList.approvalPreApproved));

    return {
      type: 'SUBMIT_DOCUMENT_UPLOAD',
    };
  };
}

export function checkStatus(isInstantor) {
  return ({ getState, dispatch }) => {
    const isApplicationResolved = async () => {
      const { resolutionDetail = '', resolution } = await getApplicationResolution();

      const isSolvencyPresent = resolutionDetail.match(/^SOLVENCY/i);
      const isManual = resolution === MANUAL;
      const isRejected = resolution === REJECTED;
      const isApproved = resolution === APPROVED;

      await dispatch(getRiskRules());

      if (isManual) {
        dispatch(updateApplicationFunnelPath(CHECK_STATUS_ROUTES.preapproved));
      } else if (isRejected) {
        if (isSolvencyPresent) {
          const { error } = await dispatch(api.fetchClientApplicationProposal);

          if (!error) {
            dispatch(updateApplicationFunnelPath(CHECK_STATUS_ROUTES.proposal));

            return {};
          }
        }

        dispatch(updateApplicationFunnelPath(CHECK_STATUS_ROUTES.denied));
      } else if (isApproved) {
        dispatch(updatePath(routesList.approvalCompleted));
      } else {
        dispatch(updateApplicationFunnelPath(CHECK_STATUS_ROUTES.error));
      }

      return {};
    };

    const getApplicationResolution = async () => {
      const { error, payload, payload: { resolution } } = await dispatch(api.fetchClientApplication());

      if (error) {
        throw Error('Check Status - application checked but not able to get client application', payload);
      }

      if (!resolution) {
        await delay(STATUS_CHECK_DELAY); // eslint-disable-line no-unused-expressions

        return await getApplicationResolution();
      }

      return payload;
    };

    const performStatusCheck = async (counter = 1) => {
      await delay(STATUS_CHECK_DELAY); // eslint-disable-line no-unused-expressions

      if (counter === STATUS_CHECK_COUNTER_LIMIT) {
        dispatch(updateApplicationFunnelPath(routesList.approvalRejected)); // TODO: redirect to better URL maybe?

        return {};
      }

      const currentRoute = getState().routing.locationBeforeTransitions.pathname;

      if (currentRoute.indexOf(routesList.applicationIdentificationProcessing) === -1) {
        return {};
      }

      const { error, payload } = await dispatch(api.fetchClientApplicationCheckStatus());

      if (error) {
        throw Error('Check Status - application failed', payload);
      }

      const { completed } = payload;

      if (completed) {
        await isApplicationResolved(); // eslint-disable-line no-unused-expressions
      }

      await performStatusCheck(counter + 1); // eslint-disable-line no-unused-expressions

      return {};
    };

    const statusCheck = async () => {
      if (!isInstantor) {
        await dispatch(api.patchClientApplicationCheck()); // eslint-disable-line no-unused-expressions
      }

      return await performStatusCheck();
    };

    return {
      type: STATUS_CHECK,
      payload: statusCheck(),
    };
  };
}

export function sendInstantorConsent() {
  return ({ dispatch, httpPost }) => {
    const promise = async () => {
      const result = await httpPost('/client/consent/instantor?consented=true');

      dispatch(updateApplicationFunnelPath(routesList.applicationIdentificationInstantor));

      return result;
    };

    return {
      type: INSTANTOR_CONSENT,
      payload: promise(),
    };
  };
}

export function setInstantorError(error) {
  return ({ dispatch }) => {
    // too-many-retries invalid-login process-error
    dispatch(updateApplicationFunnelPath(`${routesList.applicationInstantorError}/${error}`));

    return {
      type: INSTANTOR_ERROR,
    };
  };
}

export function setInstantorSuccess() {
  return ({ dispatch }) => {
    dispatch(updateApplicationFunnelPath(routesList.applicationIdentificationProcessingInstantor));

    return {
      type: INSTANTOR_SUCCESS,
    };
  };
}

export function toggleTestDouble() {
  return ({ dispatch, getState }) => {
    if (process.env.IS_BROWSER) {
      const { isTestDouble } = getState().identification;
      const nextPath = dispatch(nextApplicationFunnelPath(routesList.applicationIdentificationInstantorBank)).payload;

      window.location.replace(`${nextPath}${isTestDouble ? '' : '/?testdouble=1'}`);
    }

    return {
      type: TOGGLE_TEST_DOUBLE,
    };
  };
}

export function checkForTestDouble() {
  return ({ dispatch, getState }) => {
    const getPayload = () => {
      if (process.env.IS_BROWSER && config.faker.enabled) {
        const { testdouble } = getState().routing.locationBeforeTransitions.query || {};

        if (testdouble) {
          dispatch(updateApplicationFunnelPath(routesList.applicationIdentificationInstantorConsent));

          return true;
        }
      }

      return false;
    };

    return {
      type: CHECK_FOR_TEST_DOUBLE,
      payload: getPayload(),
    };
  };
}


export function fetchClientApplicationWithOffer() {
  return ({ dispatch }) => {
    async function getPromise() {
      const { payload: { amount, term } } = await dispatch(api.fetchClientApplication());

      await dispatch(calculator.onAmountTermChange(amount, term)); // eslint-disable-line no-unused-expressions
    }

    return {
      type: FETCH_CLIENT_APPLICATION_WITH_OFFER,
      payload: getPromise(),
    };
  };
}
