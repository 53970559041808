import PageHeader from '../../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent, Container, Row, Column } from '4finance-components';

@Radium
export default class Title extends StyledComponent {
  static styleRoot = 'Title';

  static propTypes = {
    text: RPT.string.isRequired,
    subtitle: RPT.string,
    bold: RPT.bool,
    decorated: RPT.bool,
    wrapped: RPT.bool,
    textAlign: RPT.oneOf(['left', 'center', 'right']),
  };

  static defaultProps = {
    wrapped: true,
    textAlign: 'left',
  }

  render() {
    const { bold, text, subtitle, decorated, wrapped, textAlign } = this.props;
    const style = this.getStyle();
    const styleDeepCopy = { ...style };

    const WrapperComponent = ({ children }) => (wrapped
      && <Container>
        <Row>
          <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
            {children}
          </Column>
        </Row>
      </Container>)
      || <div>{children}</div>;

    if (bold) {
      styleDeepCopy.heading.fontWeight = 700;
    }

    return (
      <WrapperComponent>
        <PageHeader
          decorated={decorated}
          decorationStyle={style.decoration}
          style={styleDeepCopy}
          subtitle={subtitle}
          title={text}
          textAlign={textAlign}
        />
      </WrapperComponent>
    );
  }

  static style = ({ mediaQueries }) => ({
    heading: {
      fontSize: '60px',
      fontWeight: 200,
      lineHeight: '1.1',
      marginBottom: '40px',
      maxWidth: '80%',
      textOverflow: 'ellipsis',
      marginTop: '40px',

      [mediaQueries.breakpointTablet]: {
        marginBottom: '30px',
        fontSize: '45px',
        maxWidth: '90%',
        width: '100%',
        marginTop: '25px',
      },

      [mediaQueries.breakpointSmall]: {
        fontSize: '35px',
        width: '100%',
      },

      bold: {
        fontWeight: 700,
      },
    },

    decoration: {
      height: '4px',
      left: '-26px',
      top: '15px',

      [mediaQueries.breakpointTablet]: {
        left: 0,
      },
    },

    subtitle: {
      fontSize: '16px',
      fontWeight: 300,
      margin: 0,
      padding: 0,
    },
  })
}
