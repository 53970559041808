export const PERSISTENCE_SET = 'PERSISTENCE_SET';
export const PERSISTENCE_REMOVE = 'PERSISTENCE_REMOVE';

export function set(key, value, options) {
  return ({ persistenceStore }) => {
    persistenceStore.set(key, value, options);

    return {
      type: PERSISTENCE_SET,
      data: { key, value },
    };
  };
}

export function remove(key) {
  return ({ persistenceStore }) => {
    persistenceStore.remove(key);

    return {
      type: PERSISTENCE_REMOVE,
      data: { key },
    };
  };
}

export function revive(key) {
  return ({ persistenceStore }) => {
    const value = persistenceStore.get(key);

    return {
      type: PERSISTENCE_SET,
      data: { key, value },
    };
  };
}
