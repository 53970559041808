import Notification from '../../components/Notification.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../../connect';

@connectState({
  clientBlockedStatus: ['api', 'fetch', 'client', 'blockedStatus', 'query', 'data'],
})
@translate
export default class LoanInProgressNotification extends Component {
  static propTypes = {
    msg: RPT.func,
    clientBlockedStatus: RPT.object,
  }

  render() {
    const { msg, clientBlockedStatus, formatDate } = this.props;

    if (!clientBlockedStatus) {
      return null;
    }

    const body = (
      <span>
        {msg('clientBlockedFromApplying.content_2')}
        {formatDate(clientBlockedStatus.get('blockedUntil'))}
      </span>
    );

    return (
      <Notification
        body={body}
        type="warning"
        noClose
        visible={clientBlockedStatus.get('blocked')}
      />
    );
  }
}
