export default {
  Bankia: 'Bankia',
  Bankinter: 'Bankinter',
  CAJAESPANA: 'EspañaDuero',
  IBERCAJA: 'Ibercaja',
  INGDIRECT: 'ING',
  KUTXA: 'Kutxabank',
  POPULAR: 'Popular',
  Sabadell: 'Sabadell',
  Santander: 'Santander',
  Unicaja: 'Unicaja',
  Worldpay: 'Worldpay',
};
