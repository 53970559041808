import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent as Component } from '4finance-components';

@Radium
export default class JumbotronWrapper extends Component {
  static styleRoot = 'JumbotronWrapper';

  static propTypes = {
    children: RPT.node.isRequired,
    textAlign: RPT.oneOf(['center', 'right', 'left']),
    hasPadding: RPT.bool,
    hasShadow: RPT.bool,
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
    isCentered: RPT.bool,
  }

  static defaultProps = {
    textAlign: 'center',
    hasPadding: false,
    hasShadow: false,
    inheritedStyle: {},
    isCentered: false,
  }

  render() {
    const {
      children,
      hasShadow,
      hasPadding,
      inheritedStyle,
      isCentered,
      textAlign,
    } = this.props;
    const style = this.getStyle();

    return (
      <div
        style={[
          style.wrapper,
          style.wrapper[textAlign],
          isCentered && style.wrapper.isCentered,
          hasShadow && style.wrapper.hasShadow,
          hasPadding && style.wrapper.hasPadding,
          inheritedStyle,
        ]}
      >
        {children}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { hasArrow }) => ({

    wrapper: {
      backgroundColor: colors.white,
      borderRadius: '5px',
      overflow: 'hidden',
      position: 'relative',

      right: {
        textAlign: 'right',
      },

      left: {
        textAlign: 'left',
      },

      center: {
        textAlign: 'center',
      },

      [mediaQueries.breakpointSmall]: {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0,
      },

      hasPadding: {
        paddingBottom: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: hasArrow ? '46px' : '24px',

        [mediaQueries.breakpointSmall]: {
          paddingBottom: '15px',
          paddingLeft: '15px',
          paddingRight: '15px',
          paddingTop: hasArrow ? '46px' : '24px',
        },
      },

      hasShadow: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.04)',
      },
      isCentered: {
        margin: '0 auto',
      },

      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
        overflow: 'visible',
      },
    },
  });
}
