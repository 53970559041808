export const CARD_FRONT = 'frontSideDni';
export const CARD_BACK = 'backSideDni';
export const BANK_RECEIPT = 'bankReceipt';
export const EXTRA = 'otherDocument';

export function getImageForType(fieldName) {
  if (fieldName === EXTRA) {
    return BANK_RECEIPT;
  }

  return fieldName;
}
