import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StaticPageLayout from '../../layouts/StaticPage.react';
import Title from '../components/Title.react';
import { StyledComponent, Column, Row, Container } from '4finance-components';

@Radium
export default class StaticPage extends StyledComponent {
  static styleRoot = 'StaticPage'

  static propTypes = {
    page: RPT.object.isRequired,
  }

  render() {
    const { page: { fields }, textBlock } = this.props;
    const { url, pageHeading } = fields;
    const style = this.getStyle();

    return (
      <StaticPageLayout location={{ pathname: url }} >
        <Style scopeSelector=".static-page" rules={{ ...style.staticStyles }} />

        {pageHeading &&
          <Title decorated text={pageHeading} />
        }

        <Container>
          <Row>
            <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
              <article className="static-page" dangerouslySetInnerHTML={{ __html: textBlock }} />
            </Column>
          </Row>
        </Container>
      </StaticPageLayout>
    );
  }

  static style = ({ colors }) => ({

    staticStyles: {
      marginTop: '20px',

      mediaQueries: {
        '(max-width: 768px)': {
          ol: {
            paddingLeft: '0 !important',
          },
        },
      },

      'p, ol, li': {
        color: colors.grayDarker,
        fontSize: '16px',
      },

      a: {
        color: colors.primary,
      },

      'a:hover': {
        color: colors.primaryDarker,
      },

      '>ol>li': {
        fontSize: '24px',
        fontWeight: 'bold',
      },
      '>div>li': {
        fontSize: '24px',
        fontWeight: 'bold',
      },

      ul: {
        listStyleType: 'disc',
        padding: '0 0 10px 35px',
      },
      'ul li': {
        fontWeight: 300,
      },

      'ol>li': {
        content: 'counters(item,".") ". "',
        counterIncrement: 'item',
      },

      ol: {
        paddingLeft: '50px',
      },
      'ol li': {
        padding: '3px 0',
      },
      'ol li table': {
        margin: '10px 0',
        borderCollapse: 'collapse',
        tableLayout: 'auto',
        backgroundColor: colors.white,
      },
      'ol li table tr td': {
        border: `1px solid ${colors.grayLighter}`,
        padding: '7px',
        background: colors.white,
      },
      'ol li table tr th': {
        border: `1px solid ${colors.grayLighter}`,
        padding: '7px',
        background: colors.white,
        textAlign: 'center',
      },
      'ol li p': {
        fontWeight: 300,
      },
      'ol li ol': {
        fontWeight: 300,
        padding: '0 0 10px 0',
        listStyle: 'none',
        counterReset: 'item',
      },
      'ol li ol > li:before': {
        content: 'counters(item,".") ". "',
        fontWeight: 'bold',
      },
    },
  })
}
