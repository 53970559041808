import PageContainer from '../components/PageContainer.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { connectState } from '../connect';

@connectState({
  emailApiError: ['onionForm', 'fields', 'registration', 'email', 'apiError'],
})
export default class PageWithCalculator extends Component {
  static propTypes = {
    emailApiError: RPT.string,
    children: RPT.node.isRequired,
  }

  render() {
    const { emailApiError, children } = this.props;

    const notification = emailApiError && {
      heading: 'messages.email_taken.title',
      body: 'messages.email_taken.text',
      type: 'error',
      icon: 'person',
    };

    return (
      <PageContainer
        notification={notification}
        isRegistration
        hasCalculator
      >
        {children}
      </PageContainer>
    );
  }
}
