import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, StyledComponent } from '4finance-components';

@translate
@Radium
export default class ApprovalCompleted extends StyledComponent {
  static styleRoot = 'ApprovalCompleted';

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
  }

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <Jumbotron
        icon="resolution-like"
        title={cnt('payments.autoRepayment.description.activated')}
        textAlign="center"
        kind="big"
        inheritedStyle={style.jumbotron}
      >
        <ButtonLink
          to={routesList.dashboard}
        >
          {msg('approval.completed.button')}
        </ButtonLink>
      </Jumbotron>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    jumbotron: {
      paddingBottom: '50px',
      paddingLeft: '15%',
      paddingRight: '15%',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },

      title: {
        fontWeight: 'bold',
      },
    },

    paragraph: {
      fontSize: '16px',
      lineHeight: '25px',
      marginTop: '35px',
      marginBottom: '25px',
      color: colors.grayFont,
    },
  })
}
