import Links from './footer/Links.react';
import Logo from '../components/Logo.react';
import ZaploClosingPopup from './ZaploClosingPopup.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, Footer, StyledComponent } from '4finance-components';

@translate
@Radium
export default class AppFooter extends StyledComponent {
  static styleRoot = 'AppFooter'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
  }

  render() {
    const { msg, cnt } = this.props;
    const style = this.getStyle();

    return (
      <Footer style={style.footer}>
        <Container inheritedStyle={style.container}>
          <div style={style.topPanel}>
            <Logo inverseColors inheritedStyle={style.logo} />
            <ul style={style.contacts.list}>
              <li style={style.contacts.item}>
                <a href={`mailto:${msg('footer.contacts.email')}`} style={style.contacts.email}>
                  {msg('footer.contacts.email')}
                </a>
              </li>
              <li style={style.contacts.item}>
                {msg('footer.contacts.phone')}
              </li>
              <li style={style.contacts.item}>
                {cnt('footer.contacts.opening_hours')}
              </li>
            </ul>
          </div>
          <Links />
        </Container>
        <div style={style.agreement}>
          <Container>
            {cnt('footer.agreement')}
          </Container>
        </div>
        <ZaploClosingPopup />
      </Footer>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    footer: {
      base: {
        backgroundColor: colors.darkerBlue,
        marginTop: 0,
        paddingTop: '20px',
        paddingBottom: 0,
        fontSize: '14px',
      },
    },

    container: {
      paddingBottom: '20px',
      [mediaQueries.breakpointTablet]: {
        paddingLeft: '25px',
        paddingRight: '25px',
      },
    },

    topPanel: {
      padding: '20px 0',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${colors.gray}`,
    },

    logo: {
      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    contacts: {
      list: {
        listStyle: 'none',
        color: colors.grayLighter,
        textAlign: 'right',

        [mediaQueries.breakpointTablet]: {
          textAlign: 'left',
          fontSize: '17px',
        },
      },
      item: {
        marginBottom: '5px',
      },
      email: {
        color: colors.white,
        marginBottom: '10px',
        fontSize: '19px',
        textDecoration: 'none',
        fontWeight: 'bold',

        ':hover': {
          textDecoration: 'underline',
        },

        [mediaQueries.breakpointTablet]: {
          fontSize: '22px',
        },
      },
    },

    agreement: {
      backgroundColor: colors.white,
      color: colors.gray,
      padding: '30px 0 40px',
      lineHeight: '20px',
      fontSize: '13px',

      [mediaQueries.breakpointTablet]: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
  })
}
