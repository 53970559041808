import { combineReducers } from 'redux';

import changeEmail from './changeEmail.reducer';
import changePassword from './changePassword.reducer';
import changePhone from './changePhone.reducer';
import changeConsentOptions from './changeConsentOptions.reducer';
import closingZaplo from './closingZaplo.reducer';

export default combineReducers({
  changeEmail,
  changePassword,
  changePhone,
  changeConsentOptions,
  closingZaplo,
});
