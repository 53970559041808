import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, StyledComponent, Button, Heading } from '4finance-components';
import { connectActions } from '../connect';

@connectActions({
  showZaploClosingPopup: ['settings', 'showZaploClosingPopup'],
})
@translate
@Radium
export default class GetLoan extends StyledComponent {
  static styleRoot = 'GetLoan'

  static propTypes = {
    showZaploClosingPopup: RPT.func.isRequired,
    msg: RPT.func,
  }

  render() {
    const { showZaploClosingPopup, msg } = this.props;
    const styles = this.getStyle();

    return (
      <div style={styles.wrapper}>
        <Container>
          <Heading inheritedStyle={styles.heading} kind="h2" noMargin>
            {msg('home.get_loan.title')}
          </Heading>
          <p style={styles.content}>
            {msg('home.get_loan.content')}
          </p>
          <Button
            wrapperStyle={styles.button.wrapper}
            inheritedStyle={styles.button}
            kind="primary"
            onClick={showZaploClosingPopup}
          >
            {msg('home.get_loan.apply_button')}
          </Button>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundColor: colors.lighterBackground,
      textAlign: 'left',
      backgroundRepeat: 'no-repeat',
      paddingTop: '30px',
      paddingLeft: 0,
      paddingBottom: '70px',
      backgroundImage: 'url(/assets/img/home-pic.jpg)',
      backgroundSize: 'auto 100%',
      backgroundPosition: 'center',

      [mediaQueries.breakpointLaptopSmall]: {
        backgroundPosition: '55% 0',
      },

      [mediaQueries.breakpointTablet]: {
        paddingTop: '10px',
        paddingBottom: '40px',
        paddingLeft: 0,
        textAlign: 'center',
        backgroundColor: colors.secondary,
        backgroundImage: 'none',
      },
    },

    heading: {
      width: '515px',
      lineHeight: '45px',
      fontSize: '40px',
      color: colors.darkFont,
      paddingTop: '30px',

      [mediaQueries.breakpointLaptopSmall]: {
        lineHeight: '40px',
        fontSize: '35px',
        width: '450px',
      },

      [mediaQueries.breakpointTablet]: {
        width: 'auto',
      },
    },

    content: {
      width: '330px',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 600,
      color: colors.darkFont,
      marginTop: '10px',

      [mediaQueries.breakpointTablet]: {
        fontSize: '25px',
        width: 'auto',
        fontWeight: 'normal',
        marginTop: '20px',
      },
    },

    button: {
      [mediaQueries.breakpointTablet]: {
        width: '100%',
      },
      wrapper: {
        marginTop: '20px',
        [mediaQueries.breakpointTablet]: {
          display: 'block',
        },
      },
    },
  })
}
