import getExtension from '../../browser/lib/getExtension';
import routesList from '../../browser/routesList';
import { fromJS } from 'immutable';
import { isFintonicOrPrestaloProduct } from '../client/selectors';

export default async function requireExtension({ getApiResponse, replace, getState, dispatch }) {
  await getApiResponse(['fetch', 'client']); // eslint-disable-line no-unused-expressions
  const extensions = await getApiResponse(['fetch', 'client', 'loans', 'extensions', 'byLoanid'], { dynamicKeys: ['latest'], forceFetch: true });
  const extension = getExtension(fromJS(extensions));
  const isFintonic = isFintonicOrPrestaloProduct(getState());

  if (!isFintonic && extension) {
    return true;
  }

  dispatch({ type: 'GUARD_REQUIRE_EXTENSION_FAILED' });

  replace(routesList.dashboard);

  return false;
}
