import Icon from '../../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { FileUpload } from '4finance-redux-file-upload';
import { getImageForType } from '../consts';
import { ButtonLink, StyledComponent } from '4finance-components';

@translate
@Radium
export default class DocumentModal extends StyledComponent {
  static styleRoot = 'DocumentModal';

  static propTypes = {
    msg: RPT.func.isRequired,
    fileUploadProps: RPT.object,
    onClose: RPT.func.isRequired,
    kind: RPT.string.isRequired,
  };

  render() {
    const { kind, fileUploadProps, onClose, msg } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <div style={style.example}>
          <img style={style.image} src={`/assets/img/${getImageForType(kind)}-good.jpg`} alt={kind} />
          <Icon
            kind="example-upload"
            style={style.icon}
            size={52}
          />
        </div>

        <div style={style.text}>
          {msg(`identification.documentUpload.modal.${kind}`)}
        </div>

        <FileUpload {...fileUploadProps}>
          <ButtonLink
            inheritedStyle={style.button}
          >
            {msg('common.continue')}
          </ButtonLink>
        </FileUpload>
        <FileUpload {...fileUploadProps} />
        <a
          onClick={onClose}
          style={style.link}
        >
          {msg('common.cancel')}
        </a>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    button: {
      display: 'block',
      position: 'relative',
      margin: '0 auto',

      [mediaQueries.breakpointStartTablet]: {
        minWidth: '200px',
        width: '90%',
      },

      [mediaQueries.breakpointTablet]: {
        minWidth: '100px',
        width: '90%',
      },
    },

    link: {
      display: 'block',
      textAlign: 'center',
      textDecoration: 'underline',
      fontSize: '18px',
      marginTop: '25px',
      cursor: 'pointer',
    },

    instructions: {
      width: '100%',
      display: 'flex',
    },

    image: {
      width: '100%',
      height: '100%',
    },

    text: {
      width: '100%',
      padding: '20px',
      marginTop: '20px',
    },

    icon: {
      position: 'absolute',
      bottom: '-20px',
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
      width: '52px',
      height: '52px',
      overflow: 'visible',
    },

    example: {
      width: '100%',
      position: 'relative',
    },
  });
}
