import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions } from '../connect';
import { Loader, StyledComponent } from '4finance-components';

@connectActions({
  checkStatus: ['identification', 'checkStatus'],
})
@translate
@Radium
export default class Processing extends StyledComponent {
  static styleRoot = 'Processing'

  static propTypes = {
    cnt: RPT.func,
    checkStatus: RPT.func.isRequired,
  }

  componentDidMount() {
    const { checkStatus, params: { instantor } } = this.props;

    checkStatus(!!instantor);
  }

  render() {
    const { cnt } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Loader
          variant="sections"
          style={style.loader}
        />
        {cnt('identification.processing')}
      </div>
    );
  }

  static style = ({ colors }) => ({
    wrapper: {
      marginBottom: '120px',
      textAlign: 'center',
      lineHeight: '25px',
    },

    loader: {
      section: {
        spinning: {
          borderTop: `25px solid ${colors.secondary}`,
          borderRight: `25px solid ${colors.secondary}`,
          borderBottom: `25px solid ${colors.secondary}`,
        },
      },
    },
  });
}
