import JumbotronHeader from './JumbotronHeader.react';
import JumbotronWrapper from './JumbotronWrapper.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent as Component } from '4finance-components';

@Radium
export default class Jumbotron extends Component {
  static styleRoot = 'Jumbotron';

  static propTypes = {
    children: RPT.node.isRequired,
    content: RPT.string,
    hasPadding: RPT.bool,
    hasWrapper: RPT.bool,
    hasWrapperArrow: RPT.bool,
    icon: RPT.string,
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
    isHeaderWrapped: RPT.bool,
    kind: RPT.oneOf(['small', 'big']),
    textAlign: RPT.oneOf(['center', 'left', 'right']),
    title: RPT.oneOfType([RPT.string, RPT.object]),
  }

  static defaultProps = {
    content: null,
    hasWrapper: false,
    hasWrapperArrow: false,
    icon: null,
    inheritedStyle: {},
    isHeaderWrapped: false,
    textAlign: 'left',
    title: null,
    titleKind: 'small',
    hasPadding: true,
  }

  renderHeader() {
    const {
      content,
      icon,
      title,
      kind,
      inheritedStyle,
    } = this.props;

    return (
      <JumbotronHeader
        icon={icon}
        title={title}
        content={content}
        titleKind={kind}
        contentKind={kind}
        inheritedStyle={inheritedStyle}
      />
    );
  }

  render() {
    const {
      hasPadding,
      children,
      hasWrapper,
      hasWrapperArrow,
      inheritedStyle,
      isHeaderWrapped,
      textAlign,
    } = this.props;
    const style = this.getStyle();

    return (
      <JumbotronWrapper
        hasPadding={hasPadding}
        hasShadow
        isCentered
        textAlign={textAlign}
        inheritedStyle={inheritedStyle}
      >
        {!isHeaderWrapped && this.renderHeader()}
        {hasWrapper || isHeaderWrapped ?
          <JumbotronWrapper
            hasArrow={hasWrapperArrow}
            isCentered
            inheritedStyle={[
              style.innerWrapper,
              inheritedStyle.innerWrapper && inheritedStyle.innerWrapper,
            ]}
          >
            {isHeaderWrapped && this.renderHeader()}
            {children}
          </JumbotronWrapper> : children}
      </JumbotronWrapper>
    );
  }

  static style = ({ colors }) => ({

    innerWrapper: {
      backgroundColor: colors.lighterBackground,
      borderRadius: '5px',

      arrowDecorator: {
        borderTopColor: colors.white,
      },
    },
  });
}
