import ActiveLoan from './overview/ActiveLoan.react';
import ClientBlockedFromApplying from './components/ClientBlockedFromApplying.react';
import NoActiveLoan from './overview/NoActiveLoan.react';
import ProposalOffer from '../approval/ProposalOffer.react';
import Processing from '../processing/Processing.react';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import Rejected from './Rejected.react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import UnfinishedApplication from './components/UnfinishedApplication.react';
import { connectPending, connectPrefetch, connectSelectors, connectState } from '../connect';
import { FETCH_CLIENT_INFO } from '../../common/client/actions';
import { Loader } from '4finance-components';

@connectState({
  application: ['api', 'fetch', 'client', 'application', 'query'],
  client: ['api', 'fetch', 'client', 'query'],
  clientBlockedStatus: ['api', 'fetch', 'client', 'blockedStatus', 'query', 'data'],
  hasLatestLoan: ['loans', 'hasLatestLoan'],
  latestLoanFetched: ['loans', 'latestLoanFetched'],
})
@connectSelectors({
  applicationPending: ['client', 'applicationPending'],
  hasProposal: ['client', 'hasProposal'],
  isClientBlacklisted: ['client', 'isClientBlacklisted'],
  unfinishedApplication: ['client', 'unfinishedApplication'],
})
@connectPrefetch([
  ['api', 'fetchClientBlockedstatus'],
  ['api', 'fetchClientPaymentPreferences'],
  ['api', 'fetchApplication'],
  ['api', 'fetchClientApplicationProposal'],
])
@connectPending({
  pending: FETCH_CLIENT_INFO,
})
@translate
@Radium
export default class Overview extends Component {
  static styleRoot = 'DashboardOverview';

  static propTypes = {
    application: RPT.object,
    applicationPending: RPT.bool,
    hasProposal: RPT.bool,
    client: RPT.object,
    clientBlockedStatus: RPT.object,
    hasLatestLoan: RPT.bool,
    isClientBlacklisted: RPT.bool.isRequired,
    latestLoanFetched: RPT.bool,
    msg: RPT.func.isRequired,
    pending: RPT.bool.isRequired,
    unfinishedApplication: RPT.bool,
  }

  static defaultProps = {
    application: new Map(),
    client: new Map(),
    latestLoanFetched: false,
  }

  render() {
    const { msg, applicationPending, isClientBlacklisted, pending, client, application, hasLatestLoan, latestLoanFetched, unfinishedApplication, clientBlockedStatus, hasProposal } = this.props;

    if (!client.get('fetched') || !application.get('fetched') || !latestLoanFetched || pending) {
      return <Loader />;
    }

    if (clientBlockedStatus && clientBlockedStatus.get('blocked') && !hasLatestLoan) {
      return <ClientBlockedFromApplying date={clientBlockedStatus.get('blockedUntil')} />;
    }

    if (isClientBlacklisted) {
      return <Rejected />;
    }

    if (unfinishedApplication) {
      return (<UnfinishedApplication />);
    }

    if (applicationPending) {
      return (<Processing
        title={msg('approval.reviewing.title')}
        contact={msg('approval.reviewing.contact')}
      />);
    }

    if (hasProposal) {
      return <ProposalOffer />;
    }

    if (hasLatestLoan) {
      return <ActiveLoan />;
    }

    return <NoActiveLoan />;
  }
}
