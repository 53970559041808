import Card from './components/Card.react';
import PageContainer from '../components/PageContainer.react';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';
import { connectActions, connectState, connectFunnels } from '../connect';

export const METHODS_URLS = {
  upload: routesList.applicationIdentificationDocumentUpload,
  instantor: routesList.applicationIdentificationInstantorBank,
  whatsapp: routesList.applicationIdentificationEmailOrWhatsapp,
};

@connectState({
  methods: ['identification', 'methods'],
})
@connectActions({
  changeMethod: ['identification', 'changeMethod'],
})
@connectFunnels
@translate
@Radium
export default class Method extends StyledComponent {
  static styleRoot = 'Method'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    methods: RPT.arrayOf(RPT.oneOf(['upload', 'instantor', 'whatsapp'])),
    recommended: RPT.oneOf(['upload', 'instantor', 'whatsapp']),
    changeMethod: RPT.func.isRequired,
    funnelPaths: RPT.object.isRequired,
  }

  static defaultProps = {
    methods: ['upload', 'instantor', 'whatsapp'],
    recommended: 'instantor',
  }

  render() {
    const { funnelPaths, changeMethod, methods, cnt, msg, recommended } = this.props;
    const style = this.getStyle();

    return (
      <PageContainer width="wide" style={style.container}>
        <PageHeader
          title={cnt('identification.title')}
          subtitle={cnt('identification.subtitle')}
          textAlign="center"
        />

        <div style={style.cards}>
          {methods.map(key => <Card
            button={msg(`identification.cards.${key}.button`)}
            subtitle={msg(`identification.cards.${key}.subtitle`)}
            title={msg(`identification.cards.${key}.title`)}
            estimate={msg(`identification.cards.${key}.estimate`)}
            icon={`desktop-verification-${key}`}
            link={funnelPaths[METHODS_URLS[key]]}
            recommended={key === recommended}
            key={key}
            onClick={() => changeMethod(key)}
          />)}
        </div>
      </PageContainer>
    );
  }

  static style = ({ mediaQueries }) => ({
    container: {
      wrapper: {
        [mediaQueries.breakpointTablet]: {
          marginBottom: 0,
        },
      },
    },
    cards: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
        width: 'auto',
        marginLeft: '-20px',
        marginRight: '-20px',
      },
    },

    button: {
      display: 'block',
      position: 'relative',
      margin: '0 auto',
      [mediaQueries.breakpointSmall]: {
        width: '90%',
      },
      [mediaQueries.breakpointTablet]: {
        minWidth: '300px',
      },
    },

    link: {
      display: 'block',
      textAlign: 'center',
      textDecoration: 'underline',
      fontSize: '18px',
      marginTop: '25px',
      cursor: 'pointer',
    },

    randomFill: {
      container: {
        display: 'block',
        textAlign: 'center',
        marginBottom: '0',
        [mediaQueries.breakpointSmall]: {
          padding: '5%',
        },
      },
    },
  });
}
