import React from 'react';
import RPT from 'prop-types';
import PageHeader from '../../components/PageHeader.react';
import { Wrapper as JumbotronWrapper } from '../../components/Jumbotron';
import { StyledComponent } from '4finance-components';

export default class PaymentWrapper extends StyledComponent {
  static styleRoot = 'PaymentWrapper';

  static propTypes = {
    title: RPT.string,
    children: RPT.node,
    style: RPT.object,
  }

  render() {
    const { title, children } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <PageHeader title={title} textAlign="left" inheritedStyle={style.header} />
        <JumbotronWrapper hasPadding hasShadow textAlign="left" inheritedStyle={style.wrapper}>
          {children}
        </JumbotronWrapper>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    header: {
      [mediaQueries.breakpointTablet]: {
        minHeight: '70px',
        marginTop: '30px',
        paddingLeft: '15px',
        paddingRight: '120px',
      },
    },
    wrapper: {
      [mediaQueries.breakpointTablet]: {
        marginTop: '30px',
      },
    },
  })
}
