import Icon from '../../components/Icon.react';
import Link from '../../components/Link.react';
import LoanDetails from '../components/LoanDetails.react';
import LoanManagment from '../components/LoanManagment.react';
import PageContainer from '../../components/PageContainer.react';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, StyledComponent } from '4finance-components';
import { connectState } from '../../connect';

@connectState({
  isPaymentProcessing: ['loans', 'isPaymentProcessing'],
  nextPayment: ['loans', 'nextPayment'],
})
@translate
export default class ActiveLoan extends StyledComponent {
  static styleRoot = 'ActiveLoan'

  static propTypes = {
    msg: RPT.func,
    isPaymentProcessing: RPT.bool,
    nextPayment: RPT.object,
  }

  render() {
    const { msg, isPaymentProcessing, nextPayment } = this.props;
    const style = this.getStyle();

    return (
      <PageContainer width="medium" style={style.container} mobileFullScreen>
        <Heading kind="h1" inheritedStyle={style.heading}>
          {!!nextPayment.penalty ?
            msg('loan.active.headingWithPenalty') :
            msg('loan.active.heading')
          }
        </Heading>

        <LoanManagment paymentAvailable={!isPaymentProcessing} />
        <LoanDetails />

        {!isPaymentProcessing &&
          <div style={style.panel}>
            <div style={style.description}>
              <Icon kind="full_refund" style={style.icon} size={45} />
              <div>
                <strong>{msg('loan.actions.pay_loan')}</strong>
              </div>
            </div>
            <Link to={routesList.paymentFull} inheritedStyle={style.link}>
              {msg('loan.pay_loan.link')}
            </Link>
          </div>
        }
      </PageContainer>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    container: {
      wrapper: {
        [mediaQueries.breakpointTablet]: {
          marginTop: '20px',
          paddingBottom: '30px',
        },
      },
    },

    heading: {
      fontSize: '18px',
      textTransform: 'uppercase',
      lineHeight: '23px',
      marginTop: 0,

      [mediaQueries.breakpointLaptopSmall]: {
        fontSize: '14px',
        textAlign: 'center',
        textTransform: 'none',
        fontWeight: 200,
      },
    },

    icon: {
      fill: colors.secondary,
      marginRight: '20px',
    },

    panel: {
      marginTop: '40px',
      background: colors.white,
      display: 'flex',
      padding: '20px 24px',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.07)',

      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    description: {
      display: 'flex',
      fontSize: '16px',
      lineHeight: '23px',
      alignItems: 'center',
    },

    link: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '16px',
    },
  })
}
