import Proposal from './Proposal.react';
import React from 'react';
import { StyledComponent } from '4finance-components';

export default class ProposalOffer extends StyledComponent {
  static styleRoot = 'ProposalOffer';

  render() {
    const style = this.getStyle();

    return (
      <div style={style.panel}>
        <Proposal />
      </div>
    );
  }
  static style = {
    panel: {
      borderRadius: '5px',
      overflow: 'hidden',
      margin: '80px auto 150px',
      maxWidth: '850px',
      paddingTop: '5px',
    },
  };
}
