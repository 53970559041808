import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, StyledComponent } from '4finance-components';

@translate
@Radium
export default class InstantorConsent extends StyledComponent {
  static styleRoot = 'InstantorConsent'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
  }

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Jumbotron
          icon="resolution-like"
          title={cnt('payments.autoRepayment.description.cardExpired')}
          textAlign="center"
          kind="big"
          inheritedStyle={style.jumbotron}
        >
          <ButtonLink
            to={routesList.settingsCardAdd}
          >
            {msg('overview.card.cardInfo.cardSettings')}
          </ButtonLink>
        </Jumbotron>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    jumbotron: {
      paddingBottom: '50px',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },

      title: {
        fontWeight: 'bold',
      },
      icon: {
        transform: 'rotateX(180deg)',
      },
    },

    heading: {
      fontSize: '18px',
      marginBottom: '15px',
    },

    list: {
      list: {
        marginBottom: '30px',
        fontSize: '14px',
      },

      item: {
        marginTop: 0,
      },
    },


    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '300px',
      marginTop: 0,
      marginBottom: '30px',
    },

    paragraph: {
      fontSize: '14px',
      lineHeight: '20px',
    },

    innerWrapper: {
      textAlign: 'left',
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  })
}
