import { Record } from 'immutable';

// configure keys you would like to store and revive from persistence store
const Persistence = Record({
  acceptedCookiePolicy: false,
  authorizationToken: null,
  dismissAcceptNews: false,
  fromTemporaryPassword: false,
  onlinePaymentCreatedOn: null,
  storeCardTaskId: null,
});

export default Persistence;
