import * as Modal from '../components/Modal';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectPending, connectState } from '../../connect';
import { NewPasswordField, PasswordField, UserNameField } from './fields';
import { PROCESS_SUBMIT_PASSWORD_CHANGE } from '../../../common/settings/actions';

const FORM_NAME = 'changePassword';

@connectPending({
  pendingSubmit: PROCESS_SUBMIT_PASSWORD_CHANGE,
})
@connectState({
  isPasswordProcessed: ['settings', FORM_NAME, 'processed'],
  isPasswordIncorect: ['onionForm', 'fields', FORM_NAME, 'oldPassword', 'apiError'],
})
@connectActions({
  clearForm: ['onionForm', 'clearForm'],
  settingsAction: ['settings'],
})
@translate
export default class ChangePassword extends Component {
  static propTypes = {
    clearForm: RPT.func.isRequired,
    isPasswordProcessed: RPT.bool.isRequired,
    msg: RPT.func.isRequired,
    onClose: RPT.func.isRequired,
    pendingSubmit: RPT.bool.isRequired,
    settingsAction: RPT.object.isRequired,
    isPasswordIncorect: RPT.bool,
  }

  componentDidUpdate() {
    const { settingsAction: { setPasswordChangeDone }, onClose, clearForm, isPasswordProcessed } = this.props;

    if (isPasswordProcessed) {
      setPasswordChangeDone();
      clearForm(FORM_NAME);
      onClose();
    }
  }

  handleSubmit = ({ values: { password, newPassword } }) => {
    const { settingsAction: { submitPasswordChange } } = this.props;

    submitPasswordChange({
      newPassword,
      oldPassword: password,
      repeatedPassword: newPassword,
    });
  }

  render() {
    const { msg, onClose, pendingSubmit, isPasswordIncorect } = this.props;
    const showErroMessage = isPasswordIncorect === 'incorrect_password';

    return (
      <Modal.Wrapper
        heading={msg(`modals.${FORM_NAME}.heading`)}
        formName={FORM_NAME}
        formSubmit={this.handleSubmit}
        onClose={onClose}
      >
        {showErroMessage && <Modal.Message kind="warning">{msg(`modals.${FORM_NAME}.warningMessage`)}</Modal.Message>}
        <UserNameField />
        <PasswordField />
        <NewPasswordField />
        <Modal.Submit disabled={pendingSubmit} loading={pendingSubmit} >
          {msg(`modals.${FORM_NAME}.submitButton`)}
        </Modal.Submit>
      </Modal.Wrapper>
    );
  }
}
