import * as actions from './actions';
import { Record } from 'immutable';

const InitialState = Record({
  amount: -1,
  amountLimits: {},
  applyPending: true,
  isCalculatorOpened: false,
  pending: true,
  term: -1,
  termLimits: {},
});
const initialState = new InitialState;

export default function calculatorReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.CALCULATOR_VALUE_CHANGE:
    case actions.CALCULATOR_SET_DEFAULT_VALUES:
      return state
        .set('amount', action.amount)
        .set('term', action.term);

    case actions.CALCULATOR_RESET_DEFAULTS:
      return state
        .set('amount', -1)
        .set('term', -1);

    case actions.CALCULATOR_VALUE_OVERFLOW:
      return state.set(action.field, action.value);

    case actions.CALCULATOR_SET_LIMIT:
      return state.setIn(['termLimits', action.field], action.value);

    case actions.CALCULATOR_TOGGLE:
      return state.set('isCalculatorOpened', !state.isCalculatorOpened);

    case actions.CALCULATOR_SET_AMOUNT_LIMIT:
      return state.setIn(['amountLimits', action.field], action.value);
  }

  return state;
}
