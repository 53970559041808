import Helmet from 'react-helmet';
import Link from '../components/Link.react';
import React, { PureComponent as Component } from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import StaticPageLayout from '../layouts/StaticPage.react';
import Title from './components/Title.react';
import translate from '4finance-translate';
import { Container, Column, Row } from '4finance-components';
import { connectState } from '../connect';

@connectState({
  isLoggedIn: ['authentication', 'isLoggedIn'],
})
@translate
export default class NotFound extends Component {
  static propTypes = {
    isLoggedIn: RPT.bool,
    msg: RPT.func,
    params: RPT.object.isRequired,
  }

  render() {
    const { msg, isLoggedIn, params: { splat } } = this.props;

    return (
      <StaticPageLayout location={{ pathname: `/${splat}` }}>

        <Title text={msg('notFound.description')} />
        <Container>
          <Row>
            <Column large={11} medium={12} small={12} phone={12} lgOffset={1}>
              <Helmet title={msg('notFound.title')} />

              <Link to={isLoggedIn ? routesList.dashboard : routesList.base}>
                {msg('notFound.continue')}
              </Link>
            </Column>
          </Row>
        </Container>
      </StaticPageLayout>
    );
  }
}
