import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../components/RandomFill.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { connectActions, connectPending, connectPrefetch } from '../connect';
import { Form } from '4finance-onion-form';
import { SUBMIT_OTHER_DETAILS } from '../../common/registration/actions';

import {
  LoanPurpose,
  EmploymentStatus,
  TotalIncome,
  LoansExpenses,
  Submit,
} from './fields';

const FORM_NAME = 'otherDetails';

@connectPrefetch([
  ['api', 'fetchClientApplication'],
  ['calculator', 'fetchCalculatorWithOffer'],
  ['registration', 'prefetchOtherDetailsFormData'],
])
@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  submitOtherDetails: ['registration', 'submitOtherDetails'],
})
@connectPending({
  pending: SUBMIT_OTHER_DETAILS,
})
@translate
@Radium
export default class OtherDetails extends Component {
  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    pending: RPT.bool,
    submitOtherDetails: RPT.func.isRequired,
    randomFill: RPT.func.isRequired,
  }

  @autobind
  async onSubmit({ values }) {
    const { submitOtherDetails } = this.props;

    await submitOtherDetails(values); // eslint-disable-line no-unused-expressions
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  render() {
    const { cnt, msg, pending } = this.props;

    return (
      <div>
        <PageHeader
          title={msg('registration.otherDetails.title')}
          subtitle={cnt('registration.otherDetails.subtitle')}
          textAlign="left"
          size="small"
        />

        <Form
          ref={this.addFormRef}
          name={FORM_NAME}
          onSubmit={this.onSubmit}
        >
          <LoanPurpose inline />
          <EmploymentStatus inline />
          <TotalIncome inline />
          <LoansExpenses inline />
          <Submit
            disabled={pending}
            loading={pending}
            loadingText={msg('common.loading')}
          >
            {msg('common.submit')}
          </Submit>

          <RandomFill
            pending={pending}
            submit={this.submitRandomFill}
            randomFill={this.randomFill}
          />

        </Form>
      </div>
    );
  }
}
