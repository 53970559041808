import config from '4finance-configuration';
import React from 'react';
import Radium, { Style } from 'radium';
import Recaptcha from 'react-recaptcha';
import RPT from 'prop-types';
import Script from 'react-load-script';
import translate from '4finance-translate';

import { SUBMIT_PASSWORD_RECOVERY_FORM } from '../../common/passwordRecovery/actions';
import { autobind } from 'core-decorators';
import { Button, Loader, StyledComponent, Heading, TextField } from '4finance-components';
import { connectPending, connectState, connectActions } from '../connect';
import { Form, connectField, connectSubmit } from '4finance-onion-form';
import { isRequired, email } from '4finance-validation';

const validations = {
  username: [isRequired(), email()],
};
const Username = translate(connectField('username', { type: 'text' })(TextField));
const SubmitButton = connectSubmit(Button);

@connectPending({
  isPasswordRecoveryPending: SUBMIT_PASSWORD_RECOVERY_FORM,
})
@connectState({
  error: ['passwordRecovery', 'error'],
  recaptchaSuccess: ['passwordRecovery', 'recaptchaSuccess'],
})
@connectActions({
  recover: ['passwordRecovery', 'submitPasswordRecoveryForm'],
  resetRecaptcha: ['passwordRecovery', 'resetRecaptcha'],
  resetPasswordRecoveryError: ['passwordRecovery', 'resetPasswordRecoveryError'],
})
@translate
@Radium
export default class PasswordRecovery extends StyledComponent {
  static styleRoot = 'PasswordRecovery'

  state = {
    captchaLoaded: false,
    captchaPassed: false,
    scriptLoaded: false,
    captchaResponse: '',
  }

  static propTypes = {
    isPasswordRecoveryPending: RPT.bool,
    msg: RPT.func,
    recover: RPT.func,
    resetRecaptcha: RPT.func,
    resetPasswordRecoveryError: RPT.func,
    recaptchaSuccess: RPT.bool,
  }

  @autobind
  onSubmit({ values: { username } = {} }) {
    const { recover, recaptchaSuccess } = this.props;
    const { captchaResponse } = this.state;

    recover({ username, captchaResponse, recaptchaSuccess });
  }

  componentDidUpdate() {
    const { error } = this.props;

    if (error === 'invalid_recaptcha') {
      this.resetRecaptcha();
    }
  }

  @autobind
  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  @autobind
  captchaPassed(response) {
    const { resetPasswordRecoveryError } = this.props;

    this.setState({ captchaPassed: true });
    this.setState({ captchaResponse: response });
    resetPasswordRecoveryError();
  }

  @autobind
  captchaLoaded() {
    this.setState({ captchaLoaded: true });
  }

  @autobind
  resetRecaptcha() {
    const { resetRecaptcha, resetPasswordRecoveryError } = this.props;

    this.recaptcha.reset();
    this.setState({ captchaPassed: false });
    this.setState({ captchaResponse: null });
    resetRecaptcha();
    resetPasswordRecoveryError();
  }

  render() {
    const { error, msg, isPasswordRecoveryPending } = this.props;
    const { captchaLoaded, captchaPassed, scriptLoaded } = this.state;
    const disabled = !captchaLoaded || !captchaPassed || !scriptLoaded || isPasswordRecoveryPending;
    const style = this.getStyle();

    return (
      <div>
        <Heading kind="h1" thin inheritedStyle={style.heading}>
          {msg('sign_in.password_recovery.title')}
        </Heading>

        <Form onSubmit={this.onSubmit} name="password_recovery" validations={validations}>
          <Username width="full" bottomMargin={false} />

          {error &&
            <div className="error" style={style.serverError}>{msg(`sign_in.error.${error}`)}</div>
          }

          <SubmitButton
            kind="primary"
            disabled={disabled}
            loading={isPasswordRecoveryPending}
            type="submit"
            fullWidth
            inheritedStyle={style.button}
          >
            {msg('sign_in.password_recovery.button')}
          </SubmitButton>

          <div style={style.recaptchaWrap}>
            <Script
              url="https://www.google.com/recaptcha/api.js"
              onLoad={this.handleScriptLoad}
            />

            <Style scopeSelector="#g-recaptcha" rules={style.captcha} />

            {scriptLoaded &&
              <Recaptcha
                onloadCallback={this.captchaLoaded}
                render="explicit"
                verifyCallback={this.captchaPassed}
                hl="es"
                sitekey={config.recaptchaSiteKey}
                expiredCallback={this.resetRecaptcha}
                ref={(element) => (this.recaptcha = element)}
              />
            }

            {!captchaLoaded &&
              <Loader inline inheritedStyle={style.loader} />
            }
          </div>

        </Form>
      </div>
    );
  }

  static style = ({ colors }) => ({
    button: {
      marginTop: '20px',
      marginRight: '18px',
      verticalAlign: 'middle',
      height: '60px',
      textTransform: 'uppercase',
      fontWeight: '400',
      fontSize: '18px',
    },

    heading: {
      marginTop: 0,
      marginBottom: '25px',
      fontSize: '26px',
      lineHeight: '28px',
      textAlign: 'center',
      fontWeight: 'bold',
    },

    link: {
      display: 'block',
      textAlign: 'center',
      color: colors.primary,
      margin: '25px 0',
      fontSize: '14px',
    },

    serverError: {
      color: colors.error,
      marginTop: '15px',
    },

    recaptchaWrap: {
      marginTop: '25px',
    },

    captcha: {
      paddingLeft: '8px',

      mediaQueries: {
        '(max-width: 400px)': {
          transform: 'scale(0.9) translate(-23px, 0)',
        },
      },
    },
  })
}
