import PageContainer from '../components/PageContainer.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import Title from '../contentful/components/Title.react';
import translate from '4finance-translate';

@translate
export default class StaticPages extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
  }

  render() {
    const { msg } = this.props;

    return (
      <div>
        <Title decorated text={msg('generalTerms.title')} />
        <PageContainer width="wide">
          <article className="static-page">
            <iframe src="/webapi/general-terms" style={{ width: '100%', height: '1000px' }} />
          </article>
        </PageContainer>
      </div >
    );
  }
}
