import Icon from '../Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent as Component, Heading } from '4finance-components';

@Radium
export default class JumbotronHeader extends Component {
  static styleRoot = 'JumbotronHeader';

  static propTypes = {
    content: RPT.string,
    contentKind: RPT.oneOf(['small', 'big']),
    icon: RPT.string,
    title: RPT.oneOfType([RPT.string, RPT.object]),
    titleKind: RPT.oneOf(['small', 'big']),
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.array]),
  }

  static defaultProps = {
    content: null,
    contentKind: 'small',
    icon: null,
    title: null,
    titleKind: 'small',
    inheritedStyle: {},
  }

  render() {
    const {
      content,
      contentKind,
      icon,
      inheritedStyle,
      title,
      titleKind,
    } = this.props;
    const headingKind = {
      small: 'h3',
      big: 'h2',
    };
    const style = this.getStyle();

    return (
      <div>
        {icon &&
          <Icon
            kind={icon}
            style={[
              style.icon,
              inheritedStyle.icon,
            ]}
          />}
        {title &&
          <Heading
            kind={headingKind[titleKind]}
            inheritedStyle={[
              style.title,
              style.title[titleKind],
              inheritedStyle.title,
            ]}
          >
            {title}
          </Heading>}
        {content &&
          <div
            style={[
              style.content,
              style.content[contentKind],
            ]}
          >
            {content}
          </div>}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({

    icon: {
      marginBottom: '10px',
      marginTop: '10px',
      width: '70px',
      height: '70px',

      [mediaQueries.breakpointSmall]: {
        width: '40px',
        height: '40px',
        marginTop: '20px',
      },
    },

    title: {
      marginBottom: '20px',
      marginLeft: 0,
      marginRight: 0,
      marginTop: '18px',
      padding: 0,

      big: {
        fontSize: '35px',
        fontWeight: 300,
        lineHeight: '40px',
        marginBottom: '30px',

        [mediaQueries.breakpointSmall]: {
          fontSize: '26px',
          lineHeight: '30px',
          marginTop: 0,
        },
      },
    },

    content: {
      color: colors.textSecondary,
      fontSize: '14px',
      lineHeight: '18px',
      marginBottom: '20px',
      marginTop: '18px',
      big: {
        fontSize: '16px',
        lineHeight: '25px',
      },
    },
  });
}
