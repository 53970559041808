import React, { PureComponent as Component } from 'react';
import { TextField } from '4finance-components';

export default class SmallAddressField extends Component {
  render() {
    const { ...props } = this.props;

    return (
      <TextField
        {...props}
        adjustToContainer
        hasIndicator
        topMargin={false}
      />
    );
  }
}
