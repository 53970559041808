import config from '4finance-configuration';
import { createContentful } from '4finance-contentful'; // eslint-disable-line import/named

const CONTENTFUL_DICTIONARY = {
  'como-solicitar-prestamos': 'featuresPage',
  'politica-cookies': 'policyPage',
  'prestamos-rapidos-online': 'aboutUs',
  archivo: 'archive',
  contacto: 'contact',
};

const customEntities = Object.keys(config.contentful.entities);
const isPrivacePolicyPage = path => ~path.indexOf('politica-privacidad');
const isMarketingPolicyPage = path => ~path.indexOf('informacion') || ~path.indexOf('comunicaciones-a-terceros');

export function getEntityName(splat) {
  const path = splat.split('/')[0];
  const customEntityName = CONTENTFUL_DICTIONARY[path];

  if (isPrivacePolicyPage(path)) return 'policyPage';
  if (isMarketingPolicyPage(splat)) return 'marketingAndCommunication';

  return ~customEntities.indexOf(customEntityName) ? customEntityName : 'page';
}

export default createContentful(config.contentful);
