export default ({ mediaQueries }) => ({
  base: {
    borderRadius: '3px',
    boxShadow: 'inset 0 -1px 1px 0 rgba(0,0,0,0.11), 0 2px 2px 0 rgba(0,0,0,0.12)',
    fontSize: '18px',
    fontWeight: 400,
    height: '60px',
    textTransform: 'uppercase',

    [mediaQueries.breakpointLarge]: {
      minWidth: '300px',
    },
  },
  disabled: {
    primary: {
      backgroundColor: '#CED0D1',
    },
  },
});
