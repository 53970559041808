import Notification from '../../components/Notification.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../../connect';

@connectState({
  isPaymentProcessing: ['loans', 'isPaymentProcessing'],
})
@translate
export default class LoanInProgressNotification extends Component {
  static propTypes = {
    msg: RPT.func,
    isPaymentProcessing: RPT.bool,
  }

  render() {
    const { msg, isPaymentProcessing } = this.props;

    return (
      <Notification
        body={msg('messages.loan_in_progress.text')}
        type="waiting"
        noClose
        visible={isPaymentProcessing}
      />
    );
  }
}
