import { StyledComponent } from '4finance-components';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import Radium from 'radium';

@translate
@Radium
export default class ModalResendButton extends StyledComponent {
  static styleRoot = 'ModalResendButton'
  static propTypes = {
    onResendClick: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    disabled: RPT.bool.isRequired,
    timeRemaining: RPT.number,
  }

  render() {
    const { onResendClick, disabled, msg, timeRemaining } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <span style={[style.link, disabled && style.linkDisabled]} onClick={!disabled && onResendClick}>
          {msg('modals.partials.resendCodeButton')}{timeRemaining > 0 && ` - ${timeRemaining}`}
        </span>
      </div>
    );
  }

  static style = ({ font, colors }) => ({
    wrapper: {
      textAlign: 'center',
      marginTop: '20px',
    },

    link: {
      color: colors.primary,
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: font.bold,
      lineHeight: '22px',
    },

    linkDisabled: {
      color: colors.monotone2,
      cursor: 'default',
    },
  });
}
