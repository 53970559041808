import color from 'color';

export default ({ colors }) => ({
  globalWrapper: {
    background: colors.secondary,
    borderBottom: `4px solid ${colors.secondaryDarker}`,
  },

  heading: {
    color: colors.monotone5,
    textTransform: 'uppercase',
  },

  item: {
    color: colors.secondaryDarker,
    active: {
      color: colors.white,
    },
  },

  measurements: {
    background: `url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 17' fill='${color(colors.secondaryDarker).rgb()}'><path d='M29 0h1v17h-1zM14 12h1v5h-1zm30 0h1v5h-1zm15-4h1v9h-1z'/></svg>") center`,
  },

  gradient: {
    left: {
      background: `linear-gradient(270deg, ${color(colors.secondary).fade(1)} 0%, ${colors.secondary} 100%)`,
    },
    right: {
      background: `linear-gradient(-270deg, ${color(colors.secondary).fade(1)} 0%, ${colors.secondary} 100%)`,
    },
  },
});
