import routesList from '../../browser/routesList';
import { actions as api } from '../api';

export default async function requireAuth({ replace, getState, dispatch }) {
  const { authentication: { isLoggedIn } } = getState();

  if (isLoggedIn) {
    const { error } = await dispatch(api.fetchClient());

    if (!error) {
      return true;
    }
  }

  dispatch({ type: 'GUARD_REQUIRE_AUTH_FAILED' });

  if (process.env.IS_BROWSER && isLoggedIn) {
    window.location = routesList.signoutUnauthorized;
  } else if (isLoggedIn) {
    replace(routesList.signoutUnauthorized);
  } else if (process.env.IS_BROWSER) {
    window.location = routesList.signinUnauthorized;
  } else {
    replace(routesList.signinUnauthorized);
  }

  return false;
}
