import MainContactItem from './MainContactItem.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import Title from '../Title.react';
import { StyledComponent } from '4finance-components';

@Radium
export default class MainContacts extends StyledComponent {
  static styleRoot = 'Contact'

  static propTypes = {
    data: RPT.arrayOf(RPT.shape({
      kind: RPT.string.isRequired,
      value: RPT.string.isRequired,
    })),
    title: RPT.string.isRequired,
  }

  static defaultProps = {
    data: [],
  }

  render() {
    const { data, title } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <Title
          decorated
          text={title}
          style={style.title}
          wrapped={false}
        />
        <div style={style.wrapper}>
          {data.map(contact => <MainContactItem key={contact.kind} {...contact} />)}
        </div>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      display: 'flex',
      marginLeft: '-30px',
      justifyContent: 'space-between',

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
      },
    },

    title: {
      heading: {
        fontWeight: 200,
      },
    },
  })
}
