import actions from '../common/actions';
import createConnectWrappers from '4finance-connect';
import selectors from '../common/selectors';

const {
  connect: connectDecorator,
  connectState: connectStateDecorator,
  connectActions: connectActionsDecorator,
  connectPrefetch: connectPrefetchDecorator,
  connectPending: connectPendingDecorator,
  connectSelectors: connectSelectorsDecorator,
} = createConnectWrappers({ actions, selectors });

export const connectState = connectStateDecorator;
export const connectActions = connectActionsDecorator;
export const connectPrefetch = connectPrefetchDecorator;
export const connectPending = connectPendingDecorator;
export const connectSelectors = connectSelectorsDecorator;
export { default as connectContext } from './lib/connectContext';
export { default as connectFunnels } from './lib/connectFunnels';

export default connectDecorator;
