import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent as Component } from '4finance-components';
import routesList from '../routesList';
import Jumbotron from '../components/Jumbotron';
import Mymoid from '../components/Mymoid.react';
import PageHeader from '../components/PageHeader.react';


@translate
@Radium
export default class CardAdd extends Component {
  static styleRoot = 'CardAdd';

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
  }

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <PageHeader
          title={msg('applicationCardAdd.title')}
          subtitle={cnt('applicationCardAdd.content')}
          padded
        />
        <Jumbotron hasWrapper inheritedStyle={style.container}>
          <Mymoid root={routesList.application} />
        </Jumbotron>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      padding: '0 40px',

      [mediaQueries.breakpointTablet]: {
        padding: '20px 0',
      },
    },

    container: {
      marginBottom: '20px',
      width: '620px',

      [mediaQueries.breakpointTablet]: {
        width: 'auto',
        marginBottom: 0,
        padding: '15px',
      },
    },
  });
}
