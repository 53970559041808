import Jumbotron, { GrayBox } from '../components/Jumbotron';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../components/RandomFill.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectPending } from '../connect';
import { Form } from '4finance-onion-form';
import { StyledComponent } from '4finance-components';
import { SUBMIT_BANK_ACCOUNT } from '../../common/application/actions';

import {
  BankAccountNumber,
  Submit,
} from './fields';

const FORM_NAME = 'bankAccount';

@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  submitBankAccount: ['application', 'submitBankAccount'],
})
@connectPending({
  pending: SUBMIT_BANK_ACCOUNT,
})
@translate
@Radium
export default class BankAccount extends StyledComponent {
  static styleRoot = 'BankAccount'

  static propTypes = {
    cnt: RPT.func,
    submitBankAccount: RPT.func.isRequired,
    msg: RPT.func,
    pending: RPT.bool,
    randomFill: RPT.func.isRequired,
  }

  onSubmit = ({ values }) => {
    const { submitBankAccount } = this.props;

    submitBankAccount(values); // eslint-disable-line no-unused-expressions
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  render() {
    const { cnt, msg, pending } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <PageHeader
          title={msg('registration.bankAccount.title')}
          subtitle={cnt('registration.bankAccount.description')}
          style={style.pageHeader}
        />

        <Jumbotron
          title={msg('registration.bankAccount.subtitle')}
          textAlign="center"
          inheritedStyle={style.jumbotron}
        >
          <div style={style.hint}>
            {cnt('registration.bankAccount.hint')}
          </div>

          <GrayBox
            hasArrow
            hasPadding
            textAlign="center"
            style={style.grayBox}
          >
            <Form
              ref={this.addFormRef}
              name={FORM_NAME}
              onSubmit={this.onSubmit}
            >
              <BankAccountNumber />

              <div style={style.hint.mobile}>
                {cnt('registration.bankAccount.hint')}
              </div>

              <Submit
                disabled={pending}
                loading={pending}
                loadingText={msg('common.loading')}
                inheritedStyle={style.button}
              >
                {msg('common.continue')}
              </Submit>
            </Form>
          </GrayBox>
        </Jumbotron>
        <RandomFill
          pending={pending}
          submit={this.submitRandomFill}
          randomFill={this.randomFill}
          style={randomFillStyle}
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      padding: '0 60px',
      [mediaQueries.breakpointTablet]: {
        padding: '0 15px',
        marginTop: '20px',
        marginBottom: '40px',
      },
    },

    pageHeader: {
      subtitle: {
        paddingLeft: '15%',
        paddingRight: '15%',

        [mediaQueries.breakpointTablet]: {
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: '5px',
          marginTop: '20px',
        },
      },
    },

    grayBox: {
      wrapper: {
        backgroundColor: colors.lighterBackground,

        [mediaQueries.breakpointTablet]: {
          borderRadius: '5px',
        },

        [mediaQueries.breakpointSmall]: {
          paddingBottom: '24px',
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: 'inherit',
          textAlign: 'left',
        },
      },

      arrowDecorator: {
        [mediaQueries.breakpointSmall]: {
          display: 'none',
        },
      },
    },

    hint: {
      marginTop: '30px',
      marginBottom: '20px',
      fontSize: '14px',
      width: '80%',
      marginLeft: '10%',

      [mediaQueries.breakpointSmall]: {
        display: 'none',
      },

      mobile: {
        fontSize: '12px',
        lineHeight: '14px',
        marginBottom: '25px',

        [mediaQueries.breakpointStartSmall]: {
          display: 'none',
        },
      },
    },

    jumbotron: {
      [mediaQueries.breakpointSmall]: {
        backgroundColor: 'inherit',
        textAlign: 'left',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      },

      title: {
        [mediaQueries.breakpointSmall]: {
          display: 'none',
        },
      },
    },

    mobileHeading: {
      fontSize: '26px',
      lineHeight: '28px',
    },

    description: {
      fontSize: '14px',
      lineHeight: '18px',
      marginBottom: '25px',
      marginTop: '20px',

      [mediaQueries.breakpointStartSmall]: {
        marginLeft: '45px',
        marginRight: '45px',
      },
    },

    troubleshooting: {
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '27px',

      [mediaQueries.breakpointSmall]: {
        marginTop: '11px',
      },
    },

    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      marginBottom: 0,
      order: 3,

      [mediaQueries.breakpointTablet]: {
        minWidth: '200px',
      },
    },

    link: {
      display: 'block',
      textDecoration: 'underline',
      fontSize: '15px',
      marginTop: '25px',
      cursor: 'pointer',
      color: colors.link,
    },

    countdown: {
      marginTop: '25px',
    },
  });
}

const randomFillStyle = ({ mediaQueries }) => ({
  container: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '0',

    [mediaQueries.breakpointSmall]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
});
