export default ({ colors }) => ({
  select: {
    height: '48px',
    borderRadius: 0,
  },
  selects: {
    justifyContent: 'space-between',
  },
  selectWrapper: {
    borderBottom: `2px solid ${colors.grayLighter}`,

    day: {
      flexBasis: '29%',
    },
    month: {
      flexBasis: '29%',
    },
    year: {
      flexBasis: '39%',
    },
  },
  icon: {
    top: '16px',
    right: '5px',
  },
});
