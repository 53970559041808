import config from '4finance-configuration';
import Icon from '../components/Icon.react';
import Link from '../components/Link.react';
import Logo from '../components/Logo.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectState } from '../connect';
import { Container, Menu, MenuButton, MenuItem, StyledComponent } from '4finance-components';

export const STAGE_COLA_LINK = 'http://app.es.stage-pipeline.dev.4finance.net:8319/#client/{id}';

@connectState({
  clientId: ['api', 'fetch', 'client', 'query', 'data', 'id'],
  isLoggedIn: ['authentication', 'isLoggedIn'],
  isMobileMenuOpen: ['ui', 'isMobileMenuOpen'],
})
@connectActions({
  toggleSideMenu: ['ui', 'toggleSideMenu'],
  goBack: ['routing', 'goBack'],
})
@translate
@Radium
export default class Header extends StyledComponent {
  static styleRoot = 'Header'
  static displayName = 'PageHeader'

  static propTypes = {
    inverseColors: RPT.bool,
    isMobileMenuOpen: RPT.bool,
    isRegistration: RPT.bool,
    msg: RPT.func.isRequired,
    pathname: RPT.string,
    toggleSideMenu: RPT.func.isRequired,
    isAbsolute: RPT.bool,
    showBackButton: RPT.bool,
    clientId: RPT.number,
  }

  static defaultProps = {
    isAbsolute: false,
  }

  renderBackButton() {
    const { showBackButton, isRegistration, isLoggedIn, goBack } = this.props;
    const style = this.getStyle();

    if (!showBackButton || !isLoggedIn || isRegistration) {
      return null;
    }

    return (
      <Icon
        kind="arrow-back"
        size={20}
        color={style.backButton.color}
        style={style.backButton}
        onClick={() => (goBack && goBack())}
      />
    );
  }

  renderMenuItem(key, to, { activeExactly = true, external, target, type = 'link' } = {}) {
    const { msg, pathname, inverseColors, isAbsolute } = this.props;

    const style = this.getStyle();
    const Element = type === 'button' ? MenuButton : MenuItem;
    const itemStyle = {
      ...style[type],
      ...inverseColors && style[type].inverse,
      ...isAbsolute && style[type].isAbsolute,
    };

    return (
      <Element
        activeExactly={activeExactly}
        external={external}
        key={key}
        label={msg(`menu.${key}`)}
        pathname={pathname}
        style={itemStyle}
        target={target}
        to={to}
      />
    );
  }

  renderMenuItems() {
    const { clientId, isLoggedIn, isRegistration } = this.props;

    const userMenu = (
      <Menu>
        {this.renderMenuItem('dashboard', routesList.dashboard)}
        {this.renderMenuItem('settings', routesList.settings)}
        {config.faker.enabled && this.renderMenuItem('cola', STAGE_COLA_LINK.replace('{id}', clientId), { external: true, target: '_blank' })}
        {this.renderMenuItem('sign_out', routesList.signout, { type: 'button', external: true })}
      </Menu>
    );

    const publicMenu = (
      <Menu>
        {this.renderMenuItem('features', routesList.contentFeatures, { activeExactly: false })}
        {this.renderMenuItem('blog', routesList.blog, { external: true })}
        {this.renderMenuItem('contact', routesList.contentContact, { activeExactly: false })}
        {this.renderMenuItem('faq', routesList.contentFaq)}
        {this.renderMenuItem('sign_in', routesList.signin, { type: 'button' })}
      </Menu>
    );

    const registrationMenu = (
      <Menu>
        {!isLoggedIn && this.renderMenuItem('sign_in', routesList.signin, { type: 'button' })}
      </Menu>
    );

    if (isRegistration) {
      return registrationMenu;
    }

    return (isLoggedIn ? userMenu : publicMenu);
  }

  render() {
    const { inverseColors, isLoggedIn, isMobileMenuOpen, msg, toggleSideMenu, isRegistration, isAbsolute } = this.props;
    const style = this.getStyle();

    const menuToggle = (isRegistration && isLoggedIn)
      ? null
      : (<span
        id="menuToggle"
        onClick={toggleSideMenu}
        style={[
          style.mobileMenuButton,
          style.mobileMenuButton.menu,
          isMobileMenuOpen && style.mobileMenuButton.close,
          inverseColors && style.mobileMenuButton.inverse,
        ]}
      >
        {isMobileMenuOpen ?
          <Icon kind="menu-x" size={15} style={style.closeButton} />
          :
          msg('header.menu')
        }
      </span>
      );

    const isMenuHidden = (isMobileMenuOpen || isLoggedIn || isRegistration);

    const mobileLogin = (
      <div style={style.mobileOnly}>
        <Link
          to={routesList.signin}
          inheritedStyle={[
            style.mobileLoginButton,
            isMenuHidden && style.mobileLoginButton.hidden,
            isMenuHidden && !menuToggle && style.mobileLoginButton.absolute]}
        >
          {msg('header.login')}
        </Link>
      </div>
    );

    return (
      <header
        style={[
          style.header.base,
          inverseColors && style.header.inverse,
          isAbsolute && style.header.isAbsolute,
        ]}
      >
        <Container inheritedStyle={style.container}>
          <div style={style.flexContainer}>

            {mobileLogin}

            {this.renderBackButton()}

            <Link inheritedStyle={style.logo} to={isLoggedIn ? routesList.dashboard : routesList.homepage}>
              <Logo inverseColors={isAbsolute} inheritedStyle={isAbsolute ? style.logoAbsolute : {}} />
            </Link>

            <div style={style.menu}>
              {this.renderMenuItems()}
            </div>

            <div style={style.mobileOnly}>
              {menuToggle}
            </div>
          </div>
        </Container>
      </header>
    );
  }

  static style = ({ colors, mediaQueries }, { kind }) => ({
    header: {
      base: {
        alignSelf: 'auto',
        flex: '0 0 auto',
        order: 0,
        width: '100%',
        height: '80px',
        zIndex: 100,
        boxShadow: '0 2px 5px rgba(0, 1, 1, .14)',
        backgroundColor: colors.white,

        [mediaQueries.breakpointLaptopSmall]: {
          position: 'fixed',
          top: 0,
          backgroundColor: colors.white,
          height: 'auto',
          boxShadow: '0 2px 5px rgba(0, 1, 1, .14)',
        },
      },

      inverse: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },

      isAbsolute: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        position: 'absolute',
      },
    },

    logoAbsolute: {
      [mediaQueries.breakpointLaptopSmall]: {
        fill: colors.black,
      },
    },

    backButton: {
      color: colors.primary,
      position: 'absolute',
      top: '15px',
      left: '20px',
      display: 'none',
      zIndex: '5',
      cursor: 'pointer',

      [mediaQueries.breakpointTablet]: {
        display: 'block',
      },
    },

    mobileOnly: {
      display: 'none',

      [mediaQueries.breakpointLaptopSmall]: {
        display: 'block',
      },
    },

    container: {
      [mediaQueries.breakpointLaptopSmall]: {
        padding: 0,
        width: '100%',
        height: '60px',
      },

      [mediaQueries.breakpointTablet]: {
        height: '50px',
      },
    },

    logo: {
      lineHeight: '80px',

      [mediaQueries.breakpointLaptopSmall]: {
        lineHeight: '60px',
      },

      [mediaQueries.breakpointTablet]: {
        lineHeight: '50px',
      },
    },

    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      [mediaQueries.breakpointTablet]: {
        justifyContent: kind === 'registration' ? 'center' : 'space-between',
      },
    },

    link: {
      link: {
        fontWeight: '400',
        fontSize: '16px',
        marginLeft: '50px',
        color: colors.darkFont,

        ':hover': {
          borderBottom: `3px solid ${colors.primary}`,
          color: 'inherit',
        },
      },

      isAbsolute: {
        link: {
          color: colors.white,
          marginLeft: '50px',

          ':hover': {
            borderBottom: `3px solid ${colors.primary}`,
            color: colors.primary,
          },
        },
        activeLink: {
          borderBottom: `3px solid ${colors.primary}`,
          color: colors.primary,
          fontWeight: 'normal',
          ':hover': {
            color: colors.primary,
          },
        },
      },

      activeLink: {
        borderBottom: `3px solid ${colors.primary}`,
        top: 0,
        fontWeight: 'bold',
      },

      inverse: {
        link: {
          lineHeight: '20px',
          height: '25px',
          fontWeight: '600',
          fontSize: '16px',
          marginLeft: '50px',
          color: colors.darkFont,

          ':hover': {
            borderBottom: `2px solid ${colors.primary}`,
            color: colors.primary,
          },
        },
      },
    },

    button: {
      linkWrapper: {
        paddingTop: '15px',
        active: {
          borderBottom: 'none',
        },
      },

      default: {
        linkWrapper: {
          paddingTop: '15px',
        },
      },

      isAbsolute: {
        link: {
          base: {
            color: colors.primary,
            borderColor: colors.primary,
            ':hover': {
              color: colors.white,
              borderColor: colors.white,
            },
          },
        },
        active: {
          borderColor: 'red',
        },
      },

      link: {
        base: {
          color: colors.darkFont,
          borderColor: colors.black,
          backgroundColor: colors.white,
          padding: '7px 20px',
          transition: 'background-color .3s, color .3s',
          ':hover': {
            backgroundColor: 'transparent',
            borderColor: colors.primary,
            color: colors.primary,
          },
        },
      },

      inverse: {
        linkWrapper: {
          paddingTop: '15px',
        },

        link: {
          base: {
            color: colors.white,
            borderColor: colors.primary,
            backgroundColor: colors.primary,
            padding: '7px 20px',
            transition: 'background-color .3s, color .3s',
            ':hover': {
              backgroundColor: 'transparent',
              borderColor: colors.primary,
              color: colors.primary,
            },
          },
        },
        active: {
          borderColor: colors.primary,
        },
      },
    },

    menu: {
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
    },

    mobileLoginButton: {
      height: '60px',
      width: '100px',
      display: 'none',
      marginTop: 0,
      padding: '0 5px',
      fontSize: '16px',
      fontWeight: 'normal',
      backgroundColor: colors.primary,
      color: colors.white,
      textAlign: 'center',
      textDecoration: 'none',

      [mediaQueries.breakpointLaptopSmall]: {
        display: 'table-cell',
        verticalAlign: 'middle',
        minWidth: 0,
      },

      [mediaQueries.breakpointTablet]: {
        width: '70px',
        height: '50px',
        fontSize: '14px',
      },

      hidden: {
        visibility: 'hidden',
      },

      absolute: {
        position: 'absolute',
      },
    },

    mobileMenuButton: {
      height: '60px',
      width: '80px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '60px',
      textAlign: 'center',
      position: 'relative',
      display: 'inline-block',
      textDecoration: 'none',
      borderLeft: `1px solid ${colors.monotone1}`,
      padding: '0 20px',
      marginRight: '-25px',
      color: colors.primary,

      [mediaQueries.breakpointTablet]: {
        padding: '0 10px',
        marginRight: 0,
        height: '50px',
        lineHeight: '50px',
      },
      menu: {
        marginRight: 0,
      },
      inverse: {
        borderLeft: 'none',
      },
    },
    closeButton: {
      verticalAlign: 'middle',
    },
  })
}
