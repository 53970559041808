import React from 'react';
import { Loader as DefaultLoader, StyledComponent } from '4finance-components';

export default class Loader extends StyledComponent {
  static styleRoot = 'Loader';

  render() {
    const style = this.getStyle();

    return <DefaultLoader variant="sections" style={style.loader} />;
  }

  static style = ({ colors }) => ({
    loader: {
      section: {
        spinning: {
          borderTop: `25px solid ${colors.secondary}`,
          borderRight: `25px solid ${colors.secondary}`,
          borderBottom: `25px solid ${colors.secondary}`,
        },
      },
    },
  })
}
