import AdditionalCalculator from './additionalAmount/AdditionalCalculator.react';
import AdditionalCalculatorOffer from './additionalAmount/AdditionalCalculatorOffer.react';
import AdditionalHeading from './components/AdditionalHeading.react';
import Jumbotron from '../components/Jumbotron';
import Loader from '../components/Loader.react';
import PageHeader from '../components/PageHeader.react';
import PageContainer from '../components/PageContainer.react';
import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column } from '4finance-components';
import { connectState } from '../connect';

const JumbotronColl = (props) => (
  <Column large={6} medium={6} small={6} tablet={12} phone={12} inheritedStyle={{ paddingLeft: 0, paddingRight: 0 }} >
    {props.children}
  </Column>
);

@connectState({
  amount: ['calculator', 'amount'], // Check if amount has value for loading state
})
@translate
export default class AdditionalAmount extends Component {
  static propTypes = {
    amount: RPT.number,
  }

  static defaultProps = {
    amount: 0,
  }
  render() {
    const { amount: isLoading, msg } = this.props;

    if (!isLoading) return <Loader />;

    return (
      <PageContainer mobileFullScreen>
        <PageHeader
          hideTitleOnTablet
          title={msg('additionalAmount.title')}
        />
        <AdditionalHeading />

        <Jumbotron hasPadding={false}>
          <JumbotronColl>
            <AdditionalCalculator />
          </JumbotronColl>
          <JumbotronColl>
            <AdditionalCalculatorOffer />
          </JumbotronColl>
        </Jumbotron>

      </PageContainer>
    );
  }
}
