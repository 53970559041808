import DocumentUpload from './DocumentUpload.react';

export const PHOTO_KINDS_UPLOAD_MAP = {
  CARD_FRONT: {
    id: 'cardFront',
    uploadId: 'frontSideDni',
  },
  CARD_BACK: {
    id: 'cardBack',
    uploadId: 'backSideDni',
  },
  BANK_RECEIPT: {
    id: 'bankStatement',
    uploadId: 'bankReceipt',
  },
};

export const PHOTO_KIND_IDS = Object.keys(PHOTO_KINDS_UPLOAD_MAP).map(kind => kind.id);
export const [CARD_FRONT, CARD_BACK, BANK_RECEIPT] = Object.keys(PHOTO_KINDS_UPLOAD_MAP);
export const PHOTO_KINDS_UPLOAD_IDS = Object.keys(PHOTO_KINDS_UPLOAD_MAP).map(kind => kind.uploadId);

export default DocumentUpload;
