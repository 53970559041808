import Footer from '../app/Footer.react';
import Header from '../app/Header.react';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { connectState } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  isLoggedIn: ['authentication', 'isLoggedIn'],
})
@Radium
export default class StaticPageLayout extends StyledComponent {
  static styleRoot = 'StaticPageLayout'

  static propTypes = {
    children: RPT.any,
    isLoggedIn: RPT.bool.isRequired,
    location: RPT.object,
    hasPaddingBottom: RPT.bool,
    hasMenuAbsolute: RPT.bool,
  }

  static defaultProps = {
    hasPaddingBottom: true,
    hasMenuAbsolute: false,
  }

  render() {
    const { children, isLoggedIn, inheritedStyle, location: { pathname } = {}, hasMenuAbsolute } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.pageWrapper, inheritedStyle]}>
        <Style scopeSelector=".static-page" rules={style.staticStyles} />

        <Header isLoggedIn={isLoggedIn} pathname={pathname} isAbsolute={hasMenuAbsolute} />
        <section style={style.contentWrapper} className="static-page">
          {children}
        </section>
        <Footer />
      </div>
    );
  }

  static style = ({ colors }, { hasPaddingBottom }) => ({
    pageWrapper: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      backgroundColor: colors.background,
      lineHeight: 1.414,
    },

    contentWrapper: {
      minHeight: '220px',
      flex: '1 0 auto',
      paddingBottom: hasPaddingBottom ? '80px' : 0,
    },

    staticStyles: {
      fontSize: '16px',

      h1: {
        fontSize: '45px',
        margin: '25px 0',
      },

      h2: {
        lineHeight: '1.15',
      },

      h3: {
        lineHeight: '1.2',
      },

      'ul, ol': {
        paddingLeft: '30px',
      },

      li: {
        marginBottom: '10px',
      },

      table: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        borderBottom: `1px solid ${colors.gray}`,
        marginBottom: '40px',
      },

      td: {
        padding: '10px',
        borderTop: `1px solid ${colors.gray}`,
      },

      hr: {
        borderTop: `1px solid ${colors.grayLight}`,
      },

      a: {
        fontWeight: 500,
      },

      mediaQueries: {
        '(max-width: 480px)': {
          td: {
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
          },
        },
      },
    },
  })
}
