import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import withInterval from '../lib/withInterval';

@translate
@withInterval()
@Radium
export default class Countdown extends Component {
  static propTypes = {
    addInterval: RPT.func.isRequired,
    callback: RPT.func,
    inheritedStyle: RPT.object,
    label: RPT.string,
    msg: RPT.func.isRequired,
    removeIntervalById: RPT.func.isRequired,
    seconds: RPT.number.isRequired,
  };

  static state = {
    secondsLeft: 0,
  }

  componentDidMount() {
    const { seconds, addInterval } = this.props;

    addInterval('COUNTDOWN', 1000, this.countdown, true);

    this.onUpdateState(seconds);
  }

  onUpdateState(propsSeconds) {
    this.setState({ secondsLeft: propsSeconds });
  }

  countdown = () => {
    const { callback, removeIntervalById, seconds } = this.props;

    this.setState({ secondsLeft: this.state.secondsLeft - 1 }, () => {
      if (this.state.secondsLeft <= 0 && seconds > 0) {
        removeIntervalById('COUNTDOWN');
        callback();
      }
    });
  }

  render() {
    const { msg, inheritedStyle, label } = this.props;

    return (
      <div style={inheritedStyle}>
        {msg(label, { seconds: this.state.secondsLeft })}
      </div>
    );
  }
}
