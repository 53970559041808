import * as Modal from '../components/Modal';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import withInterval from '../../lib/withInterval';
import { autobind } from 'core-decorators';
import { connectActions, connectPending, connectState } from '../../connect';
import { EmailField } from './fields';
import { PROCESS_SUBMIT_EMAIL_CHANGE } from '../../../common/settings/actions';

export const FORM_NAME = 'changeEmail';

@connectPending({
  pendingSubmit: PROCESS_SUBMIT_EMAIL_CHANGE,
})
@connectState({
  rateCountdown: ['settings', FORM_NAME, 'rateCountdown'],
  newEmail: ['onionForm', 'fields', FORM_NAME, 'email', 'value'],
  originalEmail: ['api', 'fetch', 'client', 'email', 'query', 'data', 'email'],
})
@connectActions({
  setFieldValue: ['onionForm', 'setFieldValue'],
  submitEmailChange: ['settings', 'submitEmailChange'],
})
@translate
@withInterval()
export default class ChangeEmail extends Component {
  static propTypes = {
    addInterval: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    newEmail: RPT.string,
    onClose: RPT.func.isRequired,
    originalEmail: RPT.string.isRequired,
    pendingSubmit: RPT.bool.isRequired,
    rateCountdown: RPT.instanceOf(Date).isRequired,
    removeIntervalById: RPT.func.isRequired,
    setFieldValue: RPT.func.isRequired,
    submitEmailChange: RPT.func.isRequired,
  }

  state = {
    countdown: null,
  }

  componentDidMount() {
    const { originalEmail, setFieldValue, addInterval, rateCountdown } = this.props;

    if (rateCountdown) {
      addInterval('RATECOUNTDOWN', 1000, this.tickCountdown, true);
    }

    setFieldValue(FORM_NAME, 'email', originalEmail);
  }

  @autobind
  async handleSubmit({ values: { email } }) {
    const { submitEmailChange, originalEmail } = this.props;

    if (email !== originalEmail) {
      await submitEmailChange(email);
      this.input.focus();
    }
  }

  tickCountdown = () => {
    this.setState(
      { countdown: this.getCountdown(this.props.rateCountdown) },
      () => {
        if (this.state.countdown <= 0) {
          this.props.removeIntervalById('RATECOUNTDOWN');
        }
      }
    );
  }

  getCountdown(countdown) {
    return Math.floor(((countdown - new Date()) % 6e4) / 1000);
  }

  render() {
    const { msg, pendingSubmit, newEmail, originalEmail, onClose } = this.props;
    const { countdown } = this.state;
    const isDisabled = pendingSubmit || newEmail === originalEmail || countdown > 0;

    return (
      <Modal.Wrapper
        formName={FORM_NAME}
        formSubmit={this.handleSubmit}
        heading={msg(`modals.${FORM_NAME}.heading`)}
        onClose={onClose}
      >
        <EmailField inputRef={(el) => (this.input = el)} />
        <Modal.Submit disabled={isDisabled} loading={pendingSubmit}>
          {msg(`modals.${FORM_NAME}.submitButton`)}{countdown > 0 && ` - ${countdown}`}
        </Modal.Submit>
        <Modal.Message>
          {msg(`modals.${FORM_NAME}.message`)}
        </Modal.Message>
      </Modal.Wrapper>
    );
  }
}
