import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

@Radium
export default class ModalMessage extends StyledComponent {
  static styleRoot = 'ModalMessage'

  static propTypes = {
    children: RPT.node.isRequired,
    kind: RPT.oneOf(['default', 'warning']),
  }

  static defaultProps = {
    kind: 'default',
  }

  render() {
    const { children, kind } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.message, style.message[kind]]}>
        {children}
      </div>
    );
  }

  static style = ({ colors }) => ({
    message: {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: '15px',
      padding: '0 15px',
      textAlign: 'center',

      default: {
        color: colors.grayFont,
      },

      warning: {
        color: colors.errorMessage,
      },
    },
  });
}
