import Calculator from '../components/Calculator/Calculator.react';
import CalculatorEditable from '../components/Calculator/CalculatorEditable.react';
import CalculatorEditableResponsive from '../components/Calculator/CalculatorEditableResponsive.react';
import FormMessage from './FormMessage.react';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { Column, Container, StyledComponent as Component, Row } from '4finance-components';
import { connectActions } from '../connect';

const WIDTH_OPTIONS = {
  widest: {
    lg: 11,
    medium: 12,
    small: 12,
    lgOffset: 1,
  },
  wide: {
    large: 10,
    medium: 12,
    small: 12,
    lgOffset: 1,
  },
  medium: {
    large: 9,
    medium: 10,
    small: 12,
    lgOffset: 1,
    mdOffset: 1,
  },
  narrow: {
    large: 8,
    medium: 10,
    small: 12,
    lgOffset: 2,
    mdOffset: 1,
  },
  narrowest: {
    large: 6,
    lgOffset: 3,
    medium: 8,
    mdOffset: 2,
    small: 10,
    smOffset: 1,
  },
};

@connectActions({
  showZaploClosingPopup: ['settings', 'showZaploClosingPopup'],
})
@listenWindowResize
@Radium
export default class PageContainer extends Component {
  static styleRoot = 'PageContainer';

  static propTypes = {
    children: RPT.node.isRequired,
    device: RPT.instanceOf(Device).isRequired,
    hasCalculator: RPT.bool,
    isRegistration: RPT.bool,
    notification: RPT.object,
    style: RPT.oneOfType([RPT.object, RPT.array]),
    showZaploClosingPopup: RPT.func.isRequired,
    width: RPT.oneOf(['wide', 'narrow', 'narrowest', 'medium', 'widest']),
    mobileFullScreen: RPT.bool,
    inheritedStyle: RPT.object,
  }

  static defaultProps = {
    hasCalculator: false,
    isRegistration: false,
    mobileFullScreen: false,
    notification: null,
    width: 'medium',
    inheritedStyle: {},
  }

  renderWithCalculator() {
    const { isRegistration, children, device, mobileFullScreen, showZaploClosingPopup } = this.props;
    const isScreenWidthUnderDesktop = device.atMost('desktop');
    const style = this.getStyle();

    return (
      <div>
        <Column large={7} medium={7} small={12} phone={12} lgOffset={1} style={[mobileFullScreen && style.column]}>
          {children}
        </Column>
        {(!isRegistration || !isScreenWidthUnderDesktop) &&
          <Column large={4} medium={5} small={12} phone={12}>
            {isRegistration
              ? <CalculatorEditable />
              : <Calculator
                onApply={showZaploClosingPopup}
                style={style.calculator}
              />
            }
          </Column>
        }
      </div>
    );
  }

  renderWithoutCalculator() {
    const { inheritedStyle, children, width, mobileFullScreen } = this.props;
    const style = this.getStyle();

    return (<Column {...WIDTH_OPTIONS[width]} phone={12} style={[mobileFullScreen && style.column, inheritedStyle.column]}>
      {children}
    </Column>);
  }

  render() {
    const { inheritedStyle, device, notification, isRegistration, hasCalculator, mobileFullScreen } = this.props;
    const style = this.getStyle();
    const isScreenWidthUnderDesktop = device.atMost('desktop');

    return (
      <div style={[style.wrapper, mobileFullScreen && style.mobileFullScreen, inheritedStyle]}>
        {isRegistration && hasCalculator && isScreenWidthUnderDesktop && <CalculatorEditableResponsive />}

        {notification && <FormMessage {...notification} />}
        <Container style={[mobileFullScreen && { paddingLeft: 0, paddingRight: 0 }, inheritedStyle.container]}>
          <Row style={[mobileFullScreen && { marginLeft: 0, marginRight: 0 }, inheritedStyle.row]}>
            {hasCalculator
              ? this.renderWithCalculator()
              : this.renderWithoutCalculator()
            }
          </Row>
        </Container>
      </div>
    );
  }

  static style = ({ mediaQueries }, { hasCalculator }) => ({
    column: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0, marginRight: 0,
    },

    wrapper: {
      marginBottom: '120px',
      paddingTop: '50px',

      [mediaQueries.breakpointLaptopSmall]: {
        paddingTop: hasCalculator ? 0 : '40px',
      },

      [mediaQueries.breakpointTablet]: {
        marginBottom: '80px',
      },

      [mediaQueries.breakpointSmall]: {
        paddingTop: hasCalculator ? 0 : '30px',
        marginBottom: '20px',
      },
    },

    mobileFullScreen: {
      [mediaQueries.breakpointTablet]: {
        paddingTop: 0,
        marginBottom: 0,
      },
    },

    calculator: {
      outerWrapper: {
        marginTop: '45px',
      },
    },
  });
}
