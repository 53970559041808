import React from 'react';
import RPT from 'prop-types';

export default (contextTypes) => (WrappedComponent) => {
  class ConnectContext extends React.Component {
    render() {
      const context = Object.keys(contextTypes).reduce((acc, cur) =>
        ({ ...acc, [cur]: this.context[contextTypes[cur]] })
        , {});

      return <WrappedComponent {...this.props} {...context} />;
    }
  }

  ConnectContext.contextTypes = Object.keys(contextTypes).reduce((acc, cur) =>
    ({ ...acc, [contextTypes[cur]]: RPT.any.isRequired })
    , {});

  return ConnectContext;
};
