import BankRadioInputGroup from '../../../components/BankRadioInputGroup.react';
import translate from '4finance-translate';
import { Button } from '4finance-components';
import { connectField, connectSubmit } from '4finance-onion-form';
import { isRequired } from '4finance-validation';

function translatedField(name, defaultProps, validations) {
  return FieldComponent => translate(connectField(name, defaultProps, validations)(FieldComponent));
}

export const Bank = translatedField('bank', {}, [
  isRequired(),
])(BankRadioInputGroup);

export const Submit = connectSubmit(Button);
