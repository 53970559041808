import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import ActionPanel from '../components/ActionPanel.react';
import translate from '4finance-translate';
import { RadioSelect, StyledComponent } from '4finance-components';

export const OTHER_OPTION = 'other';

@translate
@Radium
export default class BankRadioInputGroup extends StyledComponent {
  static styleRoot = 'BankRadioInputGroup';

  static propTypes = {
    msg: RPT.func.isRequired,
    onSubmit: RPT.func.isRequired,
    options: RPT.array.isRequired,
    isPhone: RPT.bool,
    fromInstantor: RPT.bool,
  };

  getStyledLabel = (label) => {
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <span style={style.label}>{label}</span>
      </div>
    );
  }

  getOptions = () => {
    const { msg, fromInstantor, options, isPhone } = this.props;
    const instantorOtherOption = {
      name: msg(`registration.chooseBank.options.${OTHER_OPTION}`),
      identification: OTHER_OPTION,
    };

    return [
      ...options,
      isPhone && (fromInstantor && instantorOtherOption),
    ].filter(value => value).map(option => ({
      value: fromInstantor // eslint-disable-line no-nested-ternary
        ? option.identification
        : (option === OTHER_OPTION ? OTHER_OPTION : option),
      label: this.getStyledLabel(
        fromInstantor ? option.name : msg(`registration.chooseBank.options.${option}`)
      ),
      style: radioInputStyle,
    }));
  };

  renderActionButtons(options) {
    const { onSubmit } = this.props;

    return options.map((option) => (<ActionPanel
      title={option.label}
      hasBorder
      onClick={() => { onSubmit({ values: { bank: option.value } }); }}
    />));
  }

  render() {
    const { isPhone } = this.props;
    const style = this.getStyle();
    const options = this.getOptions();

    return (
      <div id="bankRadioInput">
        <Style scopeSelector="#bankRadioInput" rules={style.static} />
        {isPhone ? this.renderActionButtons(options) :
          <RadioSelect
            {...this.props}
            topMargin={false}
            bottomMargin={false}
            hasIndicator={false}
            errorWithLabel={false}
            options={options}
            style={radioSelectStyle}
          />
        }
      </div>
    );
  }


  static style = ({ mediaQueries }) => ({
    static: {
      '.errorMessage': {
        textAlign: 'center !important',
      },
    },

    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    label: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
      marginLeft: '15px',
    },

    image: {
      width: '32px',
      height: '32px',
      [mediaQueries.breakpointSmall]: {
        width: '70px',
        height: '70px',
      },
    },
  })
}

const radioInputStyle = ({ colors, mediaQueries }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% / 3)',
    marginTop: '20px',
    [mediaQueries.breakpointLaptopSmall]: {
      display: 'flex',
      margin: '20px 0 0 0',
    },
    [mediaQueries.breakpointTablet]: {
      width: '50%',
    },
    [mediaQueries.breakpointSmall]: {
      width: '100%',
      borderBottom: `1px solid ${colors.grayLighter}`,
      padding: 0,
      marginTop: 0,
      minHeight: '70px',
    },
  },
  base: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    height: '18px',
    width: '18px',
    position: 'relative',
    flexShrink: 0,

    [mediaQueries.breakpointSmall]: {
      position: 'absolute',
      left: '90%',
      top: '26px',
    },
  },
  baseChecked: {
    borderColor: colors.primary,
  },
  check: {
    top: '4px',
    left: '4px',
    height: '8px',
    width: '8px',
    backgroundColor: colors.primary,
  },
  optionText: {
    [mediaQueries.breakpointSmall]: {
      padding: '0px 25px',
    },
  },
});

const radioSelectStyle = ({ mediaQueries }) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingLeft: '7%',
    paddingBottom: '50px',
    borderBottom: 0,
    [mediaQueries.breakpointLaptopSmall]: {
      paddingLeft: '5%',
    },
    [mediaQueries.breakpointSmall]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },

    full: {
      backgroundColor: 'transparent',
      borderBottom: 0,
    },

    error: {
      borderBottom: 0,
    },

    focused: {
      borderBottom: 0,
    },

    padded: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 0,
      paddingRight: 0,
    },
  },
});
