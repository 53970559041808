import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { TextField } from '4finance-components';
import { connectActions } from '../../connect';

@connectActions({
  setFormFieldProperty: ['onionForm', 'setFormFieldProperty'],
})
@translate
export default class PhoneNumber extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
    options: RPT.array.isRequired,
  };

  static propTypes = {
    setFormFieldProperty: RPT.func.isRequired,
  }

  static contextTypes = {
    onionFormName: RPT.string.isRequired,
    onionLiveValidate: RPT.func.isRequired,
  }

  onChange = ({ name, value }) => {
    const { setFormFieldProperty } = this.props;
    const { onionFormName, onionLiveValidate } = this.context;

    setFormFieldProperty(onionFormName, name, 'value', value.replace(/[^+0-9]/g, ''));
    onionLiveValidate();
  }

  render() {
    return (
      <TextField
        {...this.props}
        onChange={this.onChange}
      />
    );
  }
}
