import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Select } from '4finance-components';

@translate
export default class TranslatedSelect extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
    options: RPT.array.isRequired,
  };

  render() {
    const { msg, options, ...props } = this.props;

    return (
      <Select
        {...props}
        options={options.map(({ value, text }) => ({ value, text: msg(text) }))}
      />
    );
  }
}
