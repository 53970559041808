import checkForApiError from '../lib/checkForApiError';
import getAffiliateInfo from '../lib/getAffiliateInfo';
import routesList, { APPLICATION_PREFIXES, getNewApplicationUrl } from '../../browser/routesList';
import { actions as api } from '../api';
import { push as updatePath, replace as replacePath } from 'react-router-redux';

import { serializeBankAccountNumber } from './serializer';

export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const TAKE_ADDITIONAL_AMOUNT = 'TAKE_ADDITIONAL_AMOUNT';
export const APPROVE_LOAN_APPLICATION = 'APPROVE_LOAN_APPLICATION';
export const CONFIRM_LOAN_APPLICATION = 'CONFIRM_LOAN_APPLICATION';
export const HANDLE_LOAN_REJECTION = 'HANDLE_LOAN_REJECTION';
export const CONFIRM_LOAN = 'CONFIRM_LOAN';
export const FETCH_OFFER_FROM_APPLICATION = 'FETCH_OFFER_FROM_APPLICATION';
export const SUBMIT_BANK = 'SUBMIT_BANK';
export const CHECK_WEBCODE = 'CHECK_WEBCODE';
export const GENERATE_WEBCODE = 'GENERATE_WEBCODE';
export const SUBMIT_BANK_ACCOUNT = 'SUBMIT_BANK_ACCOUNT';

export function createApplication(redirectUrl) {
  return ({ dispatch, getState }) => {
    async function getPromise() {
      const { calculator, device } = getState();

      const application = {
        amount: calculator.get('amount'),
        term: calculator.get('term'),
        autoRepay: true,
        source: device.get('type').toUpperCase(),
        ...getAffiliateInfo(),
      };

      const { error, payload: { errors } } = await dispatch(api.sendClientApplication(application));

      if (error) {
        if (errors.find(err => err.message === 'Application is rejected')) {
          dispatch(updateApplicationFunnelPath(routesList.loanRejected));
        }

        throw Error('Create application failed');
      }

      if (redirectUrl) {
        dispatch(updatePath(redirectUrl));
      }
    }

    return {
      type: CREATE_APPLICATION,
      payload: getPromise(),
    };
  };
}

export function takeAdditionalAmount() {
  return ({ dispatch }) => {
    async function getPromise() {
      await dispatch(createApplication()); // eslint-disable-line no-unused-expressions
      dispatch(updatePath(routesList.identificationApproveApplication));
    }

    return {
      type: TAKE_ADDITIONAL_AMOUNT,
      payload: getPromise(),
    };
  };
}

export function handleLoanRejection() {
  const cprMismatchMessage = /CPR mismatch$|PID_CPR_NOT_MATCH/g;

  return ({ dispatch }) => {
    async function getPromise() {
      const { payload: { resolutionDetail, resolutionMessage } } = await dispatch(api.fetchClientApplication());

      if (resolutionMessage && cprMismatchMessage.test(resolutionMessage)) {
        return dispatch(updateApplicationFunnelPath(routesList.identificationCprMismatch));
      }

      if (resolutionDetail === 'NEW_OFFER') {
        return dispatch(updateApplicationFunnelPath(routesList.loanApproveOffer));
      }

      return dispatch(updateApplicationFunnelPath(routesList.loanRejected));
    }

    return {
      type: HANDLE_LOAN_REJECTION,
      payload: getPromise(),
    };
  };
}

export function confirmLoan({ webCode }, redirectUrl) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const nextPath = redirectUrl || routesList.applicationIdentification;

      checkForApiError({
        response: await dispatch(api.patchClientApplication({ webCode: webCode.replace(/-/g, ''), acceptAgreement: true })),
        formName: 'confirmLoan',
        dispatch,
        mapping: {
          application: 'webCode',
        },
        callback: ({ errors = [] }) => errors.forEach(({ messageTemplate: error }) => {
          if (error === 'already_confirmed') {
            dispatch(updateApplicationFunnelPath(nextPath));
          }
        }),
      });

      dispatch(updateApplicationFunnelPath(nextPath));
    };

    return {
      type: CONFIRM_LOAN,
      payload: getPromise(),
    };
  };
}

export function checkWebcode() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const { payload: { canBeResendAfter } } = await dispatch(api.fetchClientApplicationWebcode());

      return { canBeResendAfter: canBeResendAfter || 60 };
    };

    return {
      type: CHECK_WEBCODE,
      payload: getPromise(),
    };
  };
}

export function generateWebcode() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      const { payload: { canBeResendAfter } } = await dispatch(api.fetchClientApplicationWebcode());

      if (canBeResendAfter === 0) {
        const response = await dispatch(api.sendClientApplicationWebcode());

        checkForApiError({
          response,
          formName: 'confirmLoan',
          dispatch,
        });

        return { canBeResendAfter: 60 };
      }

      return { canBeResendAfter };
    };

    return {
      type: GENERATE_WEBCODE,
      payload: getPromise(),
    };
  };
}

export function submitBankAccount({ bankAccountNumber }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      checkForApiError({
        response: await dispatch(api.patchClientBankAccountNumber(serializeBankAccountNumber(bankAccountNumber))),
        formName: 'bankAccount',
        dispatch,
      });

      dispatch(updateApplicationFunnelPath(routesList.applicationCardAdd));
    };

    return {
      type: SUBMIT_BANK_ACCOUNT,
      payload: getPromise(),
    };
  };
}

export function submitBank(bankName) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      if (bankName) {
        await dispatch(api.patchClientBank({ bankName })); // eslint-disable-line no-unused-expressions
      }

      dispatch(generateWebcode());
      dispatch(updateApplicationFunnelPath(routesList.applicationConfirm));
    };

    return {
      type: SUBMIT_BANK,
      payload: getPromise(),
    };
  };
}

export function prefetchConfirmFormData() {
  return ({ dispatch, getApiResponse }) => {
    const getPromise = async () => {
      const { confirmed } = await getApiResponse(['fetch', 'client', 'application']);

      if (confirmed) {
        dispatch(updateApplicationFunnelPath(routesList.applicationIdentification));
      }
    };

    return {
      type: 'PREFETCH_CONFIRM_FORM_DATA',
      payload: getPromise(),
    };
  };
}

export function nextApplicationFunnelPath(nextPath) {
  return ({ getState }) => {
    const currentPath = (getState().routing.locationBeforeTransitions || {}).pathname;
    const getPath = () => {
      if ((currentPath || '').startsWith(APPLICATION_PREFIXES.NEW)) {
        return getNewApplicationUrl(nextPath);
      }

      return nextPath;
    };
    const path = getPath();

    return {
      type: 'APPLICATION_FUNNEL_PATH',
      payload: path,
    };
  };
}

export function replaceApplicationFunnelPath(nextPath) {
  return ({ dispatch }) => {
    const path = dispatch(nextApplicationFunnelPath(nextPath)).payload;

    dispatch(replacePath({ path, state: { status: 302 } }));

    return {
      type: 'REPLACE_APPLICATION_FUNNEL_PATH',
    };
  };
}

export function updateApplicationFunnelPath(nextPath) {
  return ({ dispatch }) => {
    const path = dispatch(nextApplicationFunnelPath(nextPath)).payload;

    dispatch(updatePath(path));

    return {
      type: 'PUSH_APPLICATION_FUNNEL_PATH',
    };
  };
}
