import PageContainer from '../components/PageContainer.react';
import PageHeader from '../components/PageHeader.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import SmsCode from '../components/confirm';
import translate from '4finance-translate';
import { connectActions } from '../connect';

@connectActions({
  confirmLoan: ['application', 'confirmLoan'],
})
@translate
export default class ConfirmLoan extends Component {
  static styleRoot = 'ConfirmLoan'

  static propTypes = {
    confirmLoan: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    cnt: RPT.func.isRequired,
  }

  onSubmit = ({ values }) => {
    const { confirmLoan } = this.props;

    confirmLoan(values); // eslint-disable-line no-unused-expressions
  }

  render() {
    const { cnt, msg } = this.props;

    return (
      <PageContainer width="narrowest">
        <PageHeader
          title={msg('registration.confirmLoan.title')}
          subtitle={cnt('registration.confirmLoan.description')}
        />

        <SmsCode
          title={msg('calculator.title')}
          subtitle={msg('registration.confirmLoan.subtitle')}
          showOffer
          onSubmit={this.onSubmit}
        />
      </PageContainer>
    );
  }
}
