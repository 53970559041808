import PageContainer from '../../components/PageContainer.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import PageHeader from '../../components/PageHeader.react';
import { StyledComponent } from '4finance-components';
import JumbotronWrapper from '../../components/Jumbotron/JumbotronWrapper.react';

@translate
@Radium
export default class ClientBlockedFromApplying extends StyledComponent {
  static styleRoot = 'ClientBlockedFromApplying';

  static propTypes = {
    date: RPT.string,
  }

  render() {
    const { msg, date, formatDate } = this.props;
    const style = this.getStyle();

    return (
      <PageContainer mobileFullScreen>
        <PageHeader textAlign="left" decorated title={msg('clientBlockedFromApplying.title')} />
        <JumbotronWrapper textAlign="left" hasPadding hasShadow inheritedStyle={style.wrapper}>
          <div style={style.heading}>{msg('clientBlockedFromApplying.heading')}</div>
          <div style={style.subHeading}>{msg('clientBlockedFromApplying.content_1')}</div>
          <div style={style.subHeading}>{msg('clientBlockedFromApplying.content_2')} <strong style={style.date}>{formatDate(date)}</strong></div>
        </JumbotronWrapper>
      </PageContainer>
    );
  }

  static style = {
    wrapper: {
      maxWidth: '650px',
      padding: '40px 50px',
    },

    heading: {
      fontSize: '34px',
      lineHeight: '40px',
      fontWeight: '700',
      margin: '10px 0 25px',
    },

    listHeading: {
      fontSize: '16px',
      padding: '0 0 10px',
    },

    list: {
      fontSize: '16px',
      padding: '0',
      lineHeight: '24px',
      fontWeight: '100',
    },

    dash: {
      paddingRight: '8px',
    },

    date: {
      fontSize: '21px',
      fontWeight: '500',
    },

    subHeading: {
      fontSize: '20px',
      fontWeight: '200',
      paddingBottom: '20px',
    },
  };
}
