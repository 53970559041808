import * as actions from './actions';
import { Record, fromJS } from 'immutable';

const InitialState = Record({
  verification: false,
  countdown: null,
  rateCountdown: null,
  processed: false,
});

const initialState = new InitialState;

export default (state = initialState, action) => {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case `${actions.PROCESS_SUBMIT_EMAIL_CHANGE}_SUCCESS`: {
      return state.mergeDeep(fromJS({
        verification: true,
        processed: false,
      }));
    }

    case `${actions.PROCESS_SUBMIT_EMAIL_VERIFICATION}_SUCCESS`: {
      return state.mergeDeep(fromJS({
        verification: false,
        processed: true,
      }));
    }

    case actions.SET_EMAIL_CHANGE_COUNTDOWN: {
      return state.setIn(['countdown'], action.payload);
    }

    case actions.SET_EMAIL_RATE_COUNTDOWN: {
      return state.setIn(['rateCountdown'], action.payload);
    }

    case actions.PROCESS_SET_EMAIL_CHANGE_DONE: {
      return state.setIn(['processed'], false);
    }
  }

  return state;
};
