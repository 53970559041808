import StaticPageLayout from '../layouts/Common.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';

export default class StaticPage extends Component {
  static propTypes = {
    children: RPT.node.isRequired,
  }

  render() {
    const { children } = this.props;

    return (
      <StaticPageLayout>
        {children}
      </StaticPageLayout>
    );
  }
}
