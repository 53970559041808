import PaymentHeader from './components/PaymentHeader.react';
import PaymentCard from './components/PaymentCard.react';
import PaymentWrapper from './components/PaymentWrapper.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  loanTerm: ['api', 'fetch', 'client', 'loans', 'latest', 'data', 'loanTerm', 'value'],
  nextPayment: ['loans', 'nextPayment'],
})
@translate
export default class AutoRepayment extends StyledComponent {
  static styleRoot = 'AutoRepayment';

  static propTypes = {
    location: RPT.object.isRequired,
    nextPayment: RPT.object.isRequired,
    formatDate: RPT.func,
  }

  static defaultProps = {
    loan: new Map(),
  }

  renderHeader() {
    const { msg, loanTerm, preciseNumberToCurrency, nextPayment, formatDate } = this.props;
    const style = this.getStyle();

    return (
      <ul style={style.list}>
        <li style={style.row}>
          <span style={style.value}>{msg('payments.monthly.date')}</span>
          <strong>{formatDate(nextPayment.date)}</strong>
        </li>
        <li style={style.row}>
          <span style={style.value}>{msg('payments.monthly.type')}</span>
          <strong>
            {preciseNumberToCurrency(nextPayment.amount)}
          </strong>
        </li>
        <li style={style.row}>
          <span style={style.value}>{msg('payments.monthly.quotas')}</span>
          <strong>{loanTerm}x</strong>
        </li>
      </ul>
    );
  }

  render() {
    const { msg, cnt, formatDate, nextPayment, loan } = this.props;
    const autoRepayStatus = loan.get('autoRepay');
    const amountToPay = nextPayment.amount;

    return (
      <PaymentWrapper
        type="monthly"
        title={msg(autoRepayStatus ? 'loan.automaticPayment.enabled.title' : 'payments.autoRepayment.title')}
      >
        <PaymentHeader
          amount={amountToPay}
          paymentDescription={msg('payments.monthly.amountInfo')}
          type="monthly"
        >
          {this.renderHeader()}
        </PaymentHeader>
        <PaymentCard type="autoRepayment" amount={amountToPay} description={cnt(autoRepayStatus ? 'settingsCardAdd.content' : 'payments.autoRepayment.description.disabled', { date: formatDate(nextPayment.date) })} />
      </PaymentWrapper>
    );
  }

  static style = ({ mediaQueries }) => ({
    list: {
      width: '100%',
    },

    row: {
      listStyle: 'none',
      marginBottom: '5px',
    },

    value: {
      display: 'inline-block',
      width: '200px',

      [mediaQueries.breakpointTablet]: {
        width: '55%',
      },
    },
  });
}
