import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState, connectSelectors } from '../../connect';
import { RPTcalculatorOfferSelector } from '../../../common/calculator/selectors';
import { StyledComponent } from '4finance-components';

@connectState({
  term: ['calculator', 'term'],
  amount: ['calculator', 'amount'],
})
@connectSelectors({
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@translate
export default class CalculatorLoanInfoLink extends StyledComponent {
  static styleRoot = 'CalculatorLoanInfoLink';

  static propTypes = {
    useOfferValues: RPT.bool,
    calculatorOfferSelector: RPTcalculatorOfferSelector,
  }

  handleOpen = (url) => (event) => {
    event.preventDefault();

    const POPUP_WIDTH = 800;
    const POPUP_HEIGHT = 600;

    if (process.env.IS_BROWSER && window) {
      const popupLocation = {
        left: (window.screen.availLeft + (window.screen.availWidth / 2)) - (POPUP_WIDTH / 2),
        top: (window.screen.availTop + (window.screen.availHeight / 2)) - (POPUP_HEIGHT / 2),
      };

      window.open(
        url,
        '',
        `width=${POPUP_WIDTH},height=${POPUP_HEIGHT},left=${popupLocation.left}, top=${popupLocation.top}`
      );
    }
  }

  render() {
    const { msg, amount, term, useOfferValues, calculatorOfferSelector: { offerFetched, offer = {} } } = this.props;
    const style = this.getStyle();
    const linkAmount = useOfferValues && offerFetched ? offer.get('totalPrincipal') : amount;
    const linkTerm = useOfferValues && offerFetched ? offer.get('term') : term;
    const link = `/webapi/first-loan-legal-terms?amount=${linkAmount}&term=${linkTerm}`;

    return (
      <div style={style.wrapper}>
        <a
          href={link}
          onClick={this.handleOpen(link)}
          target="_blank"
          style={style.link}
          rel="noopener"
        >
          {msg('calculator.informationLoanForm')}
        </a>
      </div>
    );
  }

  static style = {
    wrapper: {
      padding: '16px 0 0 0',
      textAlign: 'center',
    },

    link: {
      fontSize: '14px',
    },
  }
}
