import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

@Radium
export default class SmallButton extends StyledComponent {
  static styleRoot = 'SmallButton'

  static defaultProps = {
    bold: false,
    kind: 'primary',
    to: '',
  }

  static propTypes = {
    bold: RPT.bool,
    text: RPT.string.isRequired,
    inheritedStyle: RPT.oneOfType([
      RPT.object,
      RPT.array,
    ]),
    kind: RPT.oneOf(['primary', 'secondary', 'white']),
    to: RPT.string,
    onClick: RPT.func.isRequired,
  }

  render() {
    const { bold, inheritedStyle, kind, centered, text, onClick } = this.props;
    const styles = this.getStyle();

    return (
      <button
        onClick={onClick}
        style={[
          styles.base,
          styles.kind[kind],
          bold && styles.bold,
          centered && styles.centered,
          inheritedStyle,
        ]}
      >
        {text}
      </button>
    );
  }

  static style = ({ colors }) => ({
    base: {
      display: 'inline-block',
      cursor: 'pointer',
      borderStyle: 'solid',
      borderWight: '1px',
      borderRadius: '3px',
      backgroundColor: 'transparent',
      padding: '9px 13px 7px',
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: '12px',
      fontWeight: 100,
      letterSpacing: '0.8px',
      transition: 'background-color 0.3s',
    },

    kind: {
      primary: {
        color: colors.primary,
        borderColor: colors.primary,

        ':hover': {
          color: colors.white,
          backgroundColor: colors.primary,
        },
      },

      secondary: {
        color: colors.primaryDarker,
        borderColor: colors.primaryDarker,

        ':hover': {
          color: colors.white,
          backgroundColor: colors.primaryDarker,
        },
      },

      white: {
        color: colors.white,
        borderColor: colors.white,

        ':hover': {
          color: colors.transparent,
          backgroundColor: colors.white,
        },
      },
    },

    bold: {
      fontWeight: 500,
      borderWight: '2px',
    },
  })
}
