import * as actions from './actions';
import { Map, Record } from 'immutable';
import { actions as fileUploadActions } from '4finance-redux-file-upload';

const InitialState = Record({
  currentFiles: Map(),
});

const initialState = new InitialState;

export default function documentUploadReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case fileUploadActions.FILE_UPLOAD_ADD_UPLOADING_DOCUMENTS_SUCCESS:
    case fileUploadActions.FILE_UPLOAD_ADD_UPLOADING_IMAGES_SUCCESS: {
      const { identificator } = action.meta;
      const [firstImage] = action.payload;

      return state.setIn(['currentFiles', identificator], firstImage);
    }

    case fileUploadActions.FILE_UPLOAD_COMPLETE: {
      const { identificator } = action.payload;

      return state.updateIn(['currentFiles', identificator], image => ({ ...image, uploaded: true }));
    }

    case actions.CLEAR_ALL_FILES: {
      return state.setIn(['currentFiles'], new Map());
    }
  }

  return state;
}
