import routesList from '../../../browser/routesList';
import { replace } from 'react-router-redux';
import { UnauthorizedError, ForbiddenError } from '4finance-http';

export default function webApiErrorsHandler(dispatch, getState) {
  return (error) => {
    const { authentication: { isLoggedIn } } = getState();

    if (isLoggedIn && (error instanceof UnauthorizedError || error instanceof ForbiddenError)) {
      if (process.env.IS_BROWSER) {
        window.location = routesList.signout;
      } else {
        replace(routesList.signinUnauthorized);
      }
    }
  };
}
