import { StyledComponent, Heading } from '4finance-components';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import FaqLinkItems from '../../components/FaqLinkItems.react';

@translate
export default class ResponsiveMenuFaqItems extends StyledComponent {
  static styleRoot = 'ResponsiveMenuFaqItems';

  static propTypes = {
    faqs: RPT.object,
    msg: RPT.func.isRequired,
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <li style={style.wrapper}>
        <Heading kind="h4" noMargin inheritedStyle={style.heading}>{msg('responsiveFaq.heading')}</Heading>
        <ul style={style.list}>
          <FaqLinkItems limit={5} inheritedStyles={style.faqLinkItems} />
        </ul>
      </li>
    );
  }

  static style = ({ colors }) => ({

    wrapper: {
      borderTop: `2px ${colors.lighterGray} solid`,
      margin: '20px 25px 0 0',
      padding: '20px 0',
    },

    heading: {
      color: colors.lightestFont,
      textTransform: 'uppercase',
      marginBottom: '10px',
    },

    list: {
      listStyle: 'none',
    },

    faqLinkItems: {
      item: {
        padding: '10px 0',
        fontSize: '18px',
      },
    },
  })
}
