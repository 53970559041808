import Link from '../components/Link.react';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { AUTHENTICATION_LOGIN } from '../../common/authentication/actions';
import { autobind } from 'core-decorators';
import { Button, StyledComponent, TextField, Heading } from '4finance-components';
import { connectPending, connectState, connectActions } from '../connect';
import { Form, connectField, connectSubmit } from '4finance-onion-form';
import { isRequired, email, password as passwordValidation } from '4finance-validation';

const validations = {
  username: [isRequired(), email()],
  password: [isRequired(), passwordValidation()],
};
const Username = translate(connectField('username', { type: 'text' })(TextField));
const Password = translate(connectField('password', { type: 'password' })(TextField));
const SubmitButton = connectSubmit(Button);

@connectPending({
  isAuthenticationPending: AUTHENTICATION_LOGIN,
})
@connectState({
  error: ['authentication', 'error'],
})
@connectActions({
  login: ['authentication', 'login'],
})
@translate
export default class LoginForm extends StyledComponent {
  static styleRoot = 'LoginForm'

  static propTypes = {
    bordered: RPT.bool,
    isAuthenticationPending: RPT.bool,
    login: RPT.func,
    msg: RPT.func,
  }

  @autobind
  onSubmit({ values: { username, password } = {} }) {
    const { login } = this.props;

    login({ username, password });
  }

  render() {
    const { error, msg, isAuthenticationPending } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <Heading kind="h1" thin inheritedStyle={style.heading}>
          {msg('sign_in.title')}
        </Heading>

        <Form onSubmit={this.onSubmit} name="login" validations={validations}>
          <Username width="full" bottomMargin={false} />
          <Password width="full" topMargin={false} />

          {error &&
            <div className="error" style={style.serverError}>{msg(`sign_in.error.${error}`)}</div>
          }

          <SubmitButton
            kind="primary"
            disabled={isAuthenticationPending}
            loading={isAuthenticationPending}
            type="submit"
            fullWidth
            inheritedStyle={style.button}
          >
            {msg('sign_in.button')}
          </SubmitButton>

          <Link to={routesList.passwordRecovery} bold inheritedStyle={style.link}>
            {msg('sign_in.password_recovery.link')}
          </Link>
        </Form>
      </div>
    );
  }

  static style = ({ colors }) => ({
    button: {
      marginTop: 0,
      marginRight: '18px',
      verticalAlign: 'middle',
      height: '60px',
      textTransform: 'uppercase',
      fontWeight: '400',
      fontSize: '18px',
    },

    heading: {
      marginTop: 0,
      marginBottom: '25px',
      fontSize: '26px',
      lineHeight: '28px',
      textAlign: 'center',
      fontWeight: 'bold',
    },

    link: {
      display: 'block',
      textAlign: 'center',
      color: colors.primary,
      margin: '25px 0',
      fontSize: '14px',
    },

    serverError: {
      color: colors.error,
      marginBottom: '15px',
    },
  })
}
