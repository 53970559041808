import Icon from './Icon.react';
import Link from './Link.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import SmallButton from './SmallButton.react';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class Notification extends StyledComponent {
  static styleRoot = 'Notification'

  static propTypes = {
    action: RPT.func,
    actionText: RPT.string,
    body: RPT.any,
    heading: RPT.string,
    ignoreButton: RPT.bool,
    visible: RPT.bool,
    noClose: RPT.bool,
    msg: RPT.func,
    onClose: RPT.func,
    linkTitle: RPT.any,
    link: RPT.string,
    linkOnClick: RPT.func,
    type: RPT.oneOf(['error', 'warning', 'success', 'waiting', 'info', 'default']),
  }

  static defaultProps = {
    type: 'default',
    visible: false,
  }

  showNotification = () => {
    const wrapper = this.wrapper;

    if (wrapper && this.props.visible) {
      setTimeout(() => {
        wrapper.style.maxHeight = '180px';
      }, 50);
    }
  }

  componentDidMount() {
    this.showNotification();
  }

  componentDidUpdate() {
    this.showNotification();
  }

  getIcon() {
    const { type } = this.props;

    switch (type) {
      case 'success':
        return 'check';
      case 'waiting':
        return 'loan-time';
    }

    return 'warning-triangle';
  }

  renderBody() {
    const { body, type } = this.props;
    const style = this.getStyle();

    return (
      <p style={style.body}>
        {!['default', 'info'].includes(type) &&
          <Icon style={style.icon} kind={this.getIcon()} size={18} />
        }
        {body}
      </p>
    );
  }

  renderCloseButton() {
    const { onClose, noClose, ignoreButton } = this.props;
    const style = this.getStyle();


    if (!onClose || noClose || ignoreButton) {
      return null;
    }

    return (
      <button style={style.button} onClick={onClose}>&times;</button>
    );
  }

  renderActionButtons() {
    const { onClose, action, actionText, msg, linkOnClick, link, linkTitle } = this.props;
    const style = this.getStyle();

    if (!action) {
      return null;
    }

    return (
      <div style={style.buttons}>
        <SmallButton text={actionText} onClick={action} kind="white" />

        {link ?
          <Link inheritedStyle={style.link} kind="white" to={link} onClick={linkOnClick}>
            {linkTitle}
          </Link> :
          <span style={style.dismiss} onClick={onClose}>
            {msg('messages.dismiss_action')}
          </span>
        }
      </div>
    );
  }

  render() {
    const { body, type, visible } = this.props;
    const style = this.getStyle();

    if (!visible) {
      return null;
    }

    return (
      <div style={[style.wrapper.common, type && style.wrapper[type]]} ref={(el) => { this.wrapper = el; }}>

        <div style={style.content}>
          {!!body && this.renderBody()}

          {this.renderActionButtons()}
        </div>

        {this.renderCloseButton()}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      common: {
        width: '100%',
        maxHeight: 0,
        color: colors.white,
        backgroundColor: colors.monotone3,
        position: 'relative',
        fontSize: '14px',
        fontWeight: 100,
        transition: 'max-height 0.3s',
        overflow: 'hidden',
      },

      error: {
        backgroundColor: colors.alternativeErrorMessage,
      },

      warning: {
        backgroundColor: colors.warningMessage,
      },

      success: {
        backgroundColor: colors.successMessage,
      },
      info: {
        fontSize: '12px',
        backgroundColor: colors.darkerBlue,
      },
    },

    content: {
      width: '100%',
      textAlign: 'center',
      verticalAlign: 'middle',
      padding: '15px 25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
      },

    },

    body: {
      margin: '0 30px 0 0',
      lineHeight: '20px',
      display: 'inline-block',
      maxWidth: '700px',

      [mediaQueries.breakpointTablet]: {
        display: 'block',
        textAlign: 'left',
        margin: '0 20px 0 0',
      },

      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
        lineHeight: 1.4,
      },
    },

    buttons: {
      marginTop: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [mediaQueries.breakpointTablet]: {
        fontSize: '12px',
        marginTop: '10px',
      },
    },

    icon: {
      fill: colors.white,
      fontSize: '42px',
      marginRight: '15px',
      marginTop: '-4px',
      verticalAlign: 'middle',
    },

    button: {
      color: colors.white,
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent',
      position: 'absolute',
      right: '10px',
      top: '0',
      outline: 'none',
      fontSize: '34px',
      width: '40px',
      height: '100%',
      padding: '0',
    },

    dismiss: {
      textDecoration: 'underline',
      marginLeft: '30px',
      cursor: 'pointer',
    },

    link: {
      marginLeft: '30px',
      fontWeight: 100,
      fontSize: '14px',
      lineHeight: 'inherit',

      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
      },
    },
  })
}
