import * as persistence from '../persistence/actions';
import routesList from '../../browser/routesList';
import setFormApiError from '../lib/setFormApiError';
import { actions as api } from '../api';
import { push as updatePath } from 'react-router-redux';

export const SUBMIT_PASSWORD_RECOVERY_FORM = 'SUBMIT_PASSWORD_RECOVERY_FORM';
export const SUBMIT_PASSWORD_RECOVERY_FORM_SUCCESS = 'SUBMIT_PASSWORD_RECOVERY_FORM_SUCCESS';
export const SUBMIT_CHANGE_TEMPORARY_PASSWORD = 'SUBMIT_CHANGE_TEMPORARY_PASSWORD';
export const DISMISS_PASSWORD_RECOVERY_NOTIFICATION = 'DISMISS_PASSWORD_RECOVERY_NOTIFICATION';
export const SUBMIT_PASSWORD_RECOVERY_FORM_ERROR = 'SUBMIT_PASSWORD_RECOVERY_FORM_ERROR';
export const SUBMIT_PASSWORD_RECOVERY_FORM_START = 'SUBMIT_PASSWORD_RECOVERY_FORM_START';
export const RESET_PASSWORD_RECOVERY_ERROR = 'RESET_PASSWORD_RECOVERY_ERROR';
export const RECAPTCHA_SUCCESS = 'RECAPTCHA_SUCCESS';
export const RESET_RECAPTCHA = 'RESET_RECAPTCHA';
export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';

export function submitPasswordRecoveryForm({ captchaResponse, username, recaptchaSuccess } = {}) {
  return ({ dispatch }) => {
    async function getPromise() {
      if (!recaptchaSuccess) {
        const { error: errorRecaptcha, payload: payloadRecaptcha } = await dispatch(verifyRecaptcha({ captchaResponse }));

        if (errorRecaptcha || !(payloadRecaptcha && payloadRecaptcha.success)) {
          dispatch(dismissPasswordRecoveryNotification());
          throw new Error('invalid_recaptcha');
        }
        dispatch(setRecaptchaSuccess());
      }

      const { payload, error } = await dispatch(api.sendClientTemporaryPassword(username));

      if (error) {
        dispatch(setFormApiError('password_recovery', payload));
        throw Error('Submit password recovery failed');
      }
      dispatch(updatePath(routesList.signin));
    }

    return {
      type: SUBMIT_PASSWORD_RECOVERY_FORM,
      payload: getPromise(),
    };
  };
}

export function submitChangeTemporaryPassword(newPassword) {
  return ({ dispatch }) => {
    async function getPromise() {
      const { error, payload } = await dispatch(api.patchClientPassword({
        newPassword,
        repeatedPassword: newPassword,
        recovery: true,
      }));

      if (error) {
        dispatch(setFormApiError('password_reset', payload));
        throw Error('Change temporary password failed');
      }

      dispatch(persistence.remove('fromTemporaryPassword'));
      dispatch(updatePath(routesList.dashboard));
    }

    return {
      type: SUBMIT_CHANGE_TEMPORARY_PASSWORD,
      payload: getPromise(),
    };
  };
}

export function dismissPasswordRecoveryNotification() {
  return {
    type: DISMISS_PASSWORD_RECOVERY_NOTIFICATION,
  };
}

export function setRecaptchaSuccess() {
  return {
    type: RECAPTCHA_SUCCESS,
  };
}

export function resetRecaptcha() {
  return {
    type: RESET_RECAPTCHA,
  };
}

export function resetPasswordRecoveryError() {
  return {
    type: RESET_PASSWORD_RECOVERY_ERROR,
  };
}

export function verifyRecaptcha(params) {
  return ({ httpPost }) => ({
    type: VERIFY_RECAPTCHA,
    payload: httpPost('/verify/recaptcha', params),
  });
}
