import CalculatorOffer from '../Calculator/CalculatorOffer.react';
import Countdown from '../Countdown.react';
import Jumbotron, { GrayBox } from '../Jumbotron';
import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import Radium from 'radium';
import RandomFill from '../RandomFill.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { Heading, StyledComponent } from '4finance-components';
import { CONFIRM_LOAN } from '../../../common/application/actions';
import { connectActions, connectPrefetch, connectPending, connectState } from '../../connect';
import { Form } from '4finance-onion-form';

import {
  PhoneCode,
  Submit,
} from './fields';

const FORM_NAME = 'confirmLoan';

@connectPrefetch([
  ['api', 'fetchClientApplication'],
  ['calculator', 'fetchCalculatorWithOffer'],
  ['application', 'checkWebcode'],
  ['application', 'prefetchConfirmFormData'],
])
@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  confirmLoan: ['application', 'confirmLoan'],
  generateWebcode: ['application', 'generateWebcode'],
  checkWebCode: ['application', 'checkWebcode'],
})
@connectState({
  timeToResendWebcode: ['application', 'timeToResendWebcode'],
  term: ['calculator', 'term'],
  amount: ['calculator', 'amount'],
})
@connectPending({
  pending: CONFIRM_LOAN,
})
@listenWindowResize
@translate
@Radium
export default class ConfirmSmsCode extends StyledComponent {
  static styleRoot = 'ConfirmSmsCode'

  state = {
    showResendCode: false,
  }

  static propTypes = {
    checkWebCode: RPT.func.isRequired,
    confirmLoan: RPT.func.isRequired,
    device: RPT.instanceOf(Device).isRequired,
    generateWebcode: RPT.func.isRequired,
    pending: RPT.bool,
    randomFill: RPT.func.isRequired,
    timeToResendWebcode: RPT.oneOfType([RPT.string, RPT.instanceOf(Date)]),
    title: RPT.string.isRequired,
    subtitle: RPT.string.isRequired,
    showOffer: RPT.bool,
    onSubmit: RPT.func.isRequired,
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  @autobind
  async resetCounter() {
    await this.props.checkWebCode();
    this.setState({
      showResendCode: true,
    });
  }

  @autobind
  async resendCode() {
    await this.props.generateWebcode();
    this.setState({
      showResendCode: false,
    });
  }

  render() {
    const { timeToResendWebcode, cnt, device, msg, onSubmit, pending, title, subtitle, showOffer, amount, term } = this.props;
    const style = this.getStyle();
    const isScreenWidthUnderPhone = device.atMost('phone');
    const secondsToResendWebcode = Math.ceil((new Date(timeToResendWebcode) - new Date()) / 1000);

    const resendWebcodeLink = (
      <span
        style={style.link}
        onClick={this.resendCode}
      >
        {msg('registration.confirmLoan.code.resend')}
      </span>
    );

    return (
      <div>
        <Jumbotron
          title={isScreenWidthUnderPhone
            ? title
            : subtitle
          }
          textAlign={isScreenWidthUnderPhone ? 'left' : 'center'}
          inheritedStyle={style.jumbotron}
        >
          <div>
            {showOffer &&
              <CalculatorOffer
                inheritedStyle={style.offer}
                rowStyle={offerRowStyle}
                offerFields={['amount', 'term', 'monthly_payment', 'next_installment_date']}
                hasInterestRateAndAnnualPercentageRate
                hasTotalRepayableAmount
              />
            }

            {isScreenWidthUnderPhone &&
              <Heading bold kind="h3" inheritedStyle={style.mobileHeading}>
                {subtitle}
              </Heading>
            }

            <div style={style.description}>
              {cnt('registration.confirmLoan.code.description', { link: `/webapi/client/agreements/preliminary?amount=${amount}&term=${term}` })}
            </div>
          </div>

          <GrayBox
            hasArrow
            hasPadding
            inheritedStyle={style.grayBox}
            textAlign={isScreenWidthUnderPhone ? 'left' : 'center'}
          >
            <Form
              ref={this.addFormRef}
              name={FORM_NAME}
              onSubmit={onSubmit}
            >
              <PhoneCode />

              {this.state.showResendCode || secondsToResendWebcode <= 0
                ? resendWebcodeLink
                : <Countdown
                  seconds={secondsToResendWebcode}
                  callback={this.resetCounter}
                  label="registration.confirmLoan.code.timeToResend"
                  inheritedStyle={style.countdown}
                />
              }

              <div style={style.troubleshooting}>{cnt('registration.confirmLoan.code.troubleshooting')}</div>

              <Submit
                disabled={pending}
                loading={pending}
                loadingText={msg('common.loading')}
                inheritedStyle={style.button}
              >
                {msg('common.submit')}
              </Submit>
            </Form>
          </GrayBox>
        </Jumbotron>

        <RandomFill
          pending={pending}
          submit={this.submitRandomFill}
          randomFill={this.randomFill}
          style={randomFillStyle}
        />
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    offer: {
      [mediaQueries.breakpointStartSmall]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
        marginBottom: '24px',
      },
    },

    grayBox: {
      backgroundColor: colors.background,
      [mediaQueries.breakpointSmall]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '20px',
        paddingTop: '36px',
      },
    },

    jumbotron: {
      [mediaQueries.breakpointSmall]: {
        marginLeft: '-15px',
        marginRight: '-15px',
        paddingBottom: 0,
        boxShadow: 'none',
      },

      title: {
        [mediaQueries.breakpointSmall]: {
          textTransform: 'uppercase',
          fontSize: '16px',
        },
      },
    },

    mobileHeading: {
      fontSize: '26px',
      lineHeight: '28px',
    },

    description: {
      fontSize: '14px',
      lineHeight: '18px',
      marginBottom: '25px',
      marginTop: '20px',

      [mediaQueries.breakpointStartSmall]: {
        marginLeft: '45px',
        marginRight: '45px',
      },
    },

    troubleshooting: {
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '27px',

      [mediaQueries.breakpointSmall]: {
        marginTop: '11px',
      },
    },

    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      marginBottom: 0,

      [mediaQueries.breakpointTablet]: {
        minWidth: '200px',
      },
    },

    link: {
      display: 'block',
      textDecoration: 'underline',
      fontSize: '15px',
      marginTop: '25px',
      cursor: 'pointer',
      color: colors.link,
    },

    countdown: {
      marginTop: '25px',
    },
  });
}

const randomFillStyle = ({ mediaQueries }) => ({
  container: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '30px',

    [mediaQueries.breakpointSmall]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
});

const offerRowStyle = ({ mediaQueries }) => ({
  row: {
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderBottom: 0,

    [mediaQueries.breakpointStartSmall]: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '25px',
      paddingRight: '25px',
    },
  },

  column: {
    padding: 0,
    marginBottom: '12px',
    fontSize: '15px',

    right: {
      marginBottom: '12px',
      padding: 0,

      [mediaQueries.breakpointStartSmall]: {
        textAlign: 'center',
        fontSize: '15px',
        fontWeight: 'bold',
      },
    },

    [mediaQueries.breakpointStartSmall]: {
      width: '100%',
      fontSize: '10px',
      lineHeight: '12px',
      paddingBottom: '5px',
      marginBottom: 0,
    },
  },

  text: {
    bold: {
      fontSize: 'inherit',
      lineHeight: 'inherit',

      [mediaQueries.breakpointStartSmall]: {
        fontWeight: 'inherit',
      },
    },
  },
});
