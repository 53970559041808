import banksMap from '../data/banks';
import Icon from '../../components/Icon.react';
import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, Select, StyledComponent } from '4finance-components';
import { connectState } from '../../connect';
import { GrayBox as JumbotronGrayBox } from '../../components/Jumbotron';

const BANK_INFO_ROWS = [
  'accountNumber',
  'receiver',
  'loanNumber',
  'amountToPay',
];

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  banks: ['api', 'fetch', 'client', 'banks', 'query', 'data', 'templates'],
})
@listenWindowResize
@translate
@Radium
export default class PaymentBank extends StyledComponent {
  static styleRoot = 'PaymentBank';

  static propTypes = {
    loan: RPT.object,
    device: RPT.instanceOf(Device).isRequired,
    banks: RPT.object,
    type: RPT.oneOf(['repayment', 'extension']),
  }

  static defaultProps = {
    loan: new Map(),
  }

  state = {
    activeBank: null,
    copiedElement: null,
  }

  constructor(props, state) {
    super(props, state);

    const { preciseNumberToCurrency, loan, amount } = props;
    const fieldValues = {
      accountNumber: (bank) => ({ content: bank.accountNumber }),
      receiver: (bank) => ({ content: bank.receiver }),
      loanNumber: () => ({ content: loan.get('loanNumber') }),
      amountToPay: () => ({ content: preciseNumberToCurrency(amount) }),
    };

    this.getFieldValue = (fieldName, bank) => fieldValues[fieldName](bank) || { content: '' };
    this.fields = {};
    this.banks = [];
  }

  updateBank = (bankName) => {
    const activeBank = this.banks.find((bank) => bankName === bank.bankName);

    this.setState({
      activeBank,
      copiedElement: null,
    });
  }

  copyText = (textField) => {
    this.setState({
      copiedElement: textField,
    });
    textField.select();
    document.execCommand('copy');
    textField.blur();

    setTimeout(() => {
      this.setState({
        copiedElement: null,
      });
    }, 1000);
  }

  renderBankInfoRow(fieldName, bank) {
    const { msg, device } = this.props;
    const { copiedElement } = this.state;
    const style = this.getStyle();
    const content = this.getFieldValue(fieldName, bank).content;
    const isCopied = copiedElement && (copiedElement === this.fields[fieldName]);
    const isScreenWidthUnderTablet = device.atMost('tablet');

    return (
      <tr style={style.row} key={fieldName}>
        <td style={style.name}>
          {msg(`payments.bankPayment.details.${fieldName}`)}
        </td>
        <td style={style.value} >
          <input
            ref={(el) => { this.fields[fieldName] = el; }}
            type="text"
            value={content}
            style={style.fakeInput}
          />
          {isScreenWidthUnderTablet &&
            <div style={[style.overlay, isCopied && style.isCopied]}>
              <Icon kind="check" size={10} style={style.icon} />
              {msg('payments.copied')}
            </div>
          }
        </td>
        <td style={style.copuLinkWrapper}>
          <span
            onClick={() => this.copyText(this.fields[fieldName])}
            style={style.copyLink}
            key={fieldName}
          >
            {isCopied && !isScreenWidthUnderTablet ?
              <span>
                <Icon kind="check" size={12} style={style.icon} />
                {msg('payments.copied')}
              </span>
              :
              msg('payments.copy')
            }
          </span>
        </td>
      </tr>
    );
  }

  render() {
    const { cnt, msg, loan, type } = this.props;

    this.banks = this.props.banks && this.props.banks.toJS();

    const banks = this.banks || [];
    const activeBank = this.state.activeBank || banks[0];

    if (!activeBank) {
      return null;
    }

    const contractNumber = loan.get('loanNumber');
    const style = this.getStyle();

    return (
      <div>
        <p style={style.description}>
          {cnt(`payments.bankPayment.description.${type}`, { contractNumber })}
        </p>

        <div style={style.select.wrapper}>
          <Heading kind="h3" inheritedStyle={style.heading}>
            {msg('payments.bankPayment.heading')}
          </Heading>
          <Select
            children={banks.map((bank) => <option key={bank.bankName} value={bank.bankName}>{banksMap[bank.bankName] || bank.bankName}</option>)}
            label={msg('payments.bankPayment.label')}
            value={activeBank.bankName}
            onChange={(bank) => this.updateBank(bank.value)}
            bordered
            style={style.select.input}
          />
        </div>

        <JumbotronGrayBox hasArrow hasPadding inheritedStyle={style.jumbotronMobile}>
          <table style={style.table}>
            <tbody style={style.tableBody}>
              {BANK_INFO_ROWS.map((fieldName) => (this.renderBankInfoRow(fieldName, activeBank)))}
            </tbody>
          </table>
        </JumbotronGrayBox>
      </div>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    description: {
      margin: '25px 0 35px',
      fontSize: '14px',
      lineHeight: '20px',
    },

    jumbotronMobile: {
      [mediaQueries.breakpointTablet]: {
        marginBottom: '-28px',
        backgroundColor: colors.background,
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },

    heading: {
      fontSize: '18px',
      marginBottom: '15px',
    },

    select: {
      wrapper: {
        textAlign: 'center',
      },
      input: {
        select: {
          paddingLeft: '5px',
        },
      },
    },

    table: {
      width: '100%',
      maxWidth: '500px',
      margin: '20px auto',
      fontWeight: '200',
      tableLayout: 'fixed',
      textAlign: 'right',
      borderCollapse: 'collapse',
      fontSize: '14px',

      [mediaQueries.breakpointTablet]: {
        display: 'block',
        textAlign: 'left',
      },
    },

    tableBody: {
      [mediaQueries.breakpointTablet]: {
        width: '100%',
        display: 'block',
      },
    },

    row: {
      height: '40px',
      verticalAlign: 'middle',
      borderBottom: `1px solid ${colors.monotone2}`,

      [mediaQueries.breakpointTablet]: {
        width: '100%',
        height: 'auto',
        display: 'block',
        borderBottom: 'none',
      },
    },

    name: {
      textAlign: 'left',
      width: '120px',
      borderRight: `1px solid ${colors.monotone2}`,

      [mediaQueries.breakpointTablet]: {
        display: 'block',
        borderRight: 'none',
        margin: '10px 0 5px',
        width: 'auto',
      },
    },

    overlay: {
      position: 'absolute',
      width: '100%',
      height: '52px',
      paddingLeft: '20px',
      lineHeight: '50px',
      backgroundColor: colors.successMessage,
      color: colors.white,
      border: '1px solid transparent',
      top: '1px',
      left: '1px',
      opacity: 0,
      transition: 'opacity 0.4s',
      borderRadius: '3px 0 0 3px',
    },

    isCopied: {
      transition: 'opacity 0.1s',
      opacity: 1,
    },

    copyLink: {
      cursor: 'pointer',
      color: colors.primary,
      textDecoration: 'underline',

      ':hover': {
        color: colors.primaryDarker,
      },

      [mediaQueries.breakpointTablet]: {
        color: colors.white,
        backgroundColor: colors.primary,
        marginBottom: 0,
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontSize: '12px',
        textAlign: 'center',
        transition: 'background-color 1s',
        padding: '18px 0',
        width: '100%',
        height: '56px',
        display: 'flex',
        justifyContent: 'center',

        ':hover': {
          backgroundColor: `${colors.white} !important`,
        },
      },
    },

    copuLinkWrapper: {
      [mediaQueries.breakpointTablet]: {
        display: 'inline-block',
        background: colors.primary,
        borderRadius: '0 3px 3px 0',
        width: '20%',
        overflow: 'hidden',
        height: '52px',
        borderTop: `1px solid ${colors.monotone2}`,
        borderRight: `1px solid ${colors.monotone2}`,
        borderBottom: `1px solid ${colors.monotone2}`,
      },
    },

    value: {
      width: '60%',
      position: 'relative',
      overflow: 'hidden',
      [mediaQueries.breakpointTablet]: {
        display: 'inline-block',
        width: '80%',
        borderRadius: '3px 0 0 3px',
        overflow: 'hidden',
      },
    },

    fakeInput: {
      border: 'none',
      fontWeight: 'bold',
      background: 'transparent',
      textAlign: 'right',
      width: '100%',
      pointerEvents: 'none',

      [mediaQueries.breakpointTablet]: {
        background: colors.white,
        padding: '17px 10px',
        borderTop: `1px solid ${colors.monotone2}`,
        borderBottom: `1px solid ${colors.monotone2}`,
        borderLeft: `1px solid ${colors.monotone2}`,
        borderRight: 'none',
        textAlign: 'left',
        borderRadius: '3px 0 0 3px',
      },
    },

    icon: {
      fill: colors.primary,
      marginRight: '5px',

      [mediaQueries.breakpointTablet]: {
        fill: colors.white,
      },
    },

    buttonIcon: {
      fill: colors.white,
      margin: '1px 8px',
    },
  });
}
