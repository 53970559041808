import AppStyles from '../lib/styles/Styles.zaplo.react';
import contentfulClient from '../../common/lib/contentful';
import extendedTheme from '../components/extendedZaploTheme.js';
import Radium, { StyleRoot } from 'radium';
import React, { PureComponent as Component } from 'react';
import ResponsiveMenu from './responsiveMenu/ResponsiveMenu.react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectPrefetch, connectState } from '../connect';
import { createActions as createContentfulActions, MetaTag } from '4finance-contentful';
import { ThemeProvider } from '4finance-components';

const { fetchContentfulEntries } = createContentfulActions(contentfulClient);

@connectState({
  metaTags: ['contentful', 'entries', 'metaTag', 'data'],
  userAgentString: ['device', 'userAgentString'],
  isMobileMenuOpen: ['ui', 'isMobileMenuOpen'],
})
@connectPrefetch([
  () => fetchContentfulEntries('metaTag'),
])
@translate
@Radium
export default class App extends Component {
  static contextTypes = {
    store: RPT.object.isRequired,
  }

  static defaultProps = {
    metaTags: new Map(),
  }

  static propTypes = {
    children: RPT.node,
    location: RPT.object.isRequired,
    metaTags: RPT.object,
    isMobileMenuOpen: RPT.bool,
    userAgentString: RPT.string.isRequired,
  }

  render() {
    const { children, location: { pathname }, metaTags, userAgentString, isMobileMenuOpen } = this.props;
    const pageClass = pathname
      .replace(/^.*?([a-z0-9-]+).*$/, '$1')
      .replace('/', 'home');

    return (
      <StyleRoot radiumConfig={{ userAgent: userAgentString }} >
        <ThemeProvider style={extendedTheme}>
          <AppStyles />
          <div id="outer-container" style={[isMobileMenuOpen && styles.openMenu]}>
            <ResponsiveMenu pathname={pathname} />
            <div className={`page ${pageClass}`} data-pathname={pathname} id="page-wrap" style={styles.page}>
              <MetaTag
                metaTags={metaTags.get(pathname)}
                pathname={pathname}
                link={[{ rel: 'canonical', href: `https://www.zaplo.es${pathname}` }]}
                titleTemplate="%s – Zaplo.es"
              />
              {children}
            </div>
          </div>
        </ThemeProvider>
      </StyleRoot>
    );
  }
}

const styles = {
  page: {
    minHeight: '100vh',
  },

  openMenu: {
    overflow: 'hidden',
    height: '100vh',
  },
};
