import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { Link as RouterLink } from 'react-router';
import { StyledComponent } from '4finance-components';

const RadiumLink = Radium(RouterLink);

@Radium
export default class Link extends StyledComponent {
  static styleRoot = 'Link'

  static defaultProps = {
    bold: false,
    kind: 'primary',
    to: '',
  }

  static propTypes = {
    bold: RPT.bool,
    centered: RPT.bool,
    children: RPT.node.isRequired,
    inheritedStyle: RPT.oneOfType([
      RPT.object,
      RPT.array,
    ]),
    kind: RPT.oneOf(['primary', 'secondary', 'white']),
    to: RPT.string,
  }

  render() {
    const { bold, inheritedStyle, kind, centered, ...rest } = this.props;
    const styles = this.getStyle();

    return (
      <RadiumLink
        {...rest}
        style={[
          styles.base,
          styles.kind[kind],
          bold && styles.bold,
          centered && styles.centered,
          inheritedStyle,
        ]}
      />
    );
  }

  static style = ({ colors }) => ({
    base: {
      display: 'inline-block',
      verticalAlign: 'middle',
      cursor: 'pointer',
      lineHeight: '12px',
      textDecoration: 'underline',
      ':hover': {
        textDecoration: 'none',
      },
    },

    centered: {
      display: 'block',
      textAlign: 'center',
    },

    kind: {
      primary: {
        color: colors.primary,

        ':hover': {
          color: colors.primaryDarker,
        },
      },

      secondary: {
        color: colors.primaryDarker,
      },

      white: {
        color: colors.white,
      },
    },

    bold: {
      fontSize: '16px',
      fontWeight: 500,
    },
  })
}
