import { Checkbox } from '4finance-components';
import translatedField from '../../lib/translatedField';

export const AcceptedLoanAgreementField = translatedField('acceptedLoanAgreement', {
  style: {
    base: {
      borderTop: '1px solid #D3D3D3',
      borderLeft: '1px solid #D3D3D3',
      borderRight: '1px solid #D3D3D3',
    },
    checked: {
      borderTop: '1px solid #00a9ce',
      borderLeft: '1px solid #00a9ce',
      borderRight: '1px solid #00a9ce',
    },
    checkboxLabel: {
      lineHeight: '20px',
    },
  },
})(Checkbox);
