import * as actions from './actions';
import { Record } from 'immutable';

export const InitialState = Record({
  notificationShown: false,
  error: null,
  recaptchaSuccess: false,
});

const initialState = new InitialState;

export default function passwordRecoveryReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.SUBMIT_PASSWORD_RECOVERY_FORM_SUCCESS: {
      return state.set('notificationShown', true);
    }

    case actions.DISMISS_PASSWORD_RECOVERY_NOTIFICATION: {
      return state.set('notificationShown', false);
    }

    case actions.SUBMIT_PASSWORD_RECOVERY_FORM_START: {
      return state.set('error', null);
    }

    case actions.SUBMIT_PASSWORD_RECOVERY_FORM_ERROR: {
      const { message } = action.payload;

      return state.set('error', message);
    }

    case actions.RESET_PASSWORD_RECOVERY_ERROR: {
      return state.set('error', null);
    }

    case actions.RECAPTCHA_SUCCESS: {
      return state.set('recaptchaSuccess', true);
    }

    case actions.RESET_RECAPTCHA: {
      return state.set('recaptchaSuccess', false);
    }
  }

  return state;
}
