import OtherContactItem from './OtherContactItem.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import Title from '../Title.react';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class OtherContacts extends StyledComponent {
  static styleRoot = 'otherContacts'

  static propTypes = {
    data: RPT.object.isRequired,
  };

  static defaultProps = {
    data: {},
  }

  renderLocation() {
    const { location, companyName } = this.props.data;

    return (<OtherContactItem title={companyName} kind="location">
      <div dangerouslySetInnerHTML={{ __html: location.replace(/\n/g, '<br />') }} />
    </OtherContactItem>);
  }

  renderOpeningHours() {
    const { openingHours } = this.props.data;

    return (<OtherContactItem kind="openingHours">
      <div dangerouslySetInnerHTML={{ __html: openingHours.replace(/\n([^<])/g, '<br />$1') }} />
    </OtherContactItem>);
  }

  renderOtherContacts() {
    const { complaintsNumber } = this.props.data;
    const style = this.getStyle();

    return (<div>
      <OtherContactItem kind="complaintsPhone">
        <a href={`tel:${complaintsNumber}`} style={style.strong[400]}>{complaintsNumber}</a>
      </OtherContactItem>
    </div>);
  }

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div>
        <Title
          text={msg('contentful.contactPage.otherContacts.title')}
          wrapped={false}
          style={style.title}
        />
        <div style={style.wrapper}>
          {this.renderLocation()}
          {this.renderOpeningHours()}
          {this.renderOtherContacts()}
        </div>
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [mediaQueries.breakpointTablet]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },

    strong: {
      400: {
        fontWeight: 400,
      },
      800: {
        fontWeight: 800,
      },
    },

    title: {
      heading: {
        [mediaQueries.breakpointTablet]: {
          maxWidth: '100%',
          textAlign: 'center',
        },
      },
    },
  })
}
