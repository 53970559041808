/* eslint-disable no-unused-expressions */

import * as application from '../application/actions';
import * as authentication from '../authentication/actions';
import * as calculator from '../calculator/actions';
import checkForApiError from '../lib/checkForApiError';
import getAffiliateInfo from '../lib/getAffiliateInfo';
import dayjs from 'dayjs';
import routesList from '../../browser/routesList';
import { actions as api } from '../api';
import { actions as form, extractPropertyFromState } from '4finance-onion-form';
import { push as updatePath } from 'react-router-redux';
import { serializeAboutYou, serializeRegistration } from './serializer';

import * as clientSelectors from '../client/selectors';

export const SUBMIT_ABOUT_YOU = 'SUBMIT_ABOUT_YOU';
export const SUBMIT_OTHER_DETAILS = 'SUBMIT_OTHER_DETAILS';
export const SUBMIT_REGISTRATION = 'SUBMIT_REGISTRATION';

export function submitRegistration(data) {
  return ({ getApiResponse, getState, dispatch, persistenceStore }) => {
    async function checkCreditLimit() {
      const { dateOfBirth } = data;
      const age = dayjs.utc().diff(dayjs.utc(dateOfBirth), 'years');
      const { payload: { maxAmount } } = await dispatch(api.fetchApplicationCreditLimit(age));
      const { calculator: { amount } } = getState();

      dispatch({ type: calculator.CALCULATOR_SET_AMOUNT_LIMIT, field: 'max', value: maxAmount });
      if (amount > maxAmount) {
        dispatch({ type: calculator.CALCULATOR_VALUE_OVERFLOW, field: 'amount', value: maxAmount });
        await calculator.fetchDebouncedOffer({ getApiResponse, getState });
      }
    }

    async function sendRegistrationAndSignIn() {
      const { email, password } = data;
      const gaCookie = persistenceStore.get('_ga');
      const analyticsValues = gaCookie && { analyticsCookie: { name: 'ga', value: gaCookie } };

      checkForApiError({
        response: await dispatch(api.sendRegistration({
          ...serializeRegistration(data),
          ...getAffiliateInfo(),
          ...analyticsValues,
        })),
        formName: 'registration',
        dispatch,
      });

      const { error: loginError } = await dispatch(authentication.login({
        username: email,
        password,
        doNotRedirect: true,
      }, true));

      if (loginError) {
        throw Error('Login failede');
      }
    }

    async function getPromise() {
      await checkCreditLimit();
      await sendRegistrationAndSignIn();

      await dispatch(calculator.fetchCalculatorWithOffer());
      await dispatch(application.createApplication());

      dispatch(form.clearFormProperty('registration', 'apiError'));
      dispatch(updatePath(routesList.accountAboutYou));

      return true;
    }

    return {
      type: SUBMIT_REGISTRATION,
      payload: getPromise(),
    };
  };
}

export function submitAboutYou({ ownerShip, ...data }) {
  return ({ dispatch, getApiResponse, getState }) => {
    const getPromise = async () => {
      const { amount, term } = getState().calculator;
      const { totalPrincipal: offerAmount, newPrincipal: offerAmountNew, term: offerTerm } = await getApiResponse(['fetch', 'client', 'application', 'offerByAmountAndTerm'], { dynamicKeys: [amount.toString(), term.toString()] });
      const { amount: applicationAmount, term: applicationTerm } = await getApiResponse(['fetch', 'client', 'application']);

      if ((offerAmount || offerAmountNew) !== applicationAmount || offerTerm !== applicationTerm) {
        await dispatch(application.createApplication());
      }

      checkForApiError({
        response: await dispatch(api.patchClientAddress(serializeAboutYou(data))),
        formName: 'otherDetails',
        dispatch,
      });

      checkForApiError({
        response: await dispatch(api.patchClientHousehold({ ownerShip })),
        formName: 'otherDetails',
        dispatch,
      });

      dispatch(updatePath(routesList.accountOtherDetails));

      return true;
    };

    return {
      type: SUBMIT_ABOUT_YOU,
      payload: getPromise(),
    };
  };
}

export function submitOtherDetails({ employmentStatus, totalIncome, loansExpenses, loanPurpose }) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      dispatch(checkForCurrentApplicationChanges());

      checkForApiError({
        response: await dispatch(api.patchClientEmployer({ employmentStatus, name: 'N/A' })), // TODO: what about employer name?
        formName: 'otherDetails',
        dispatch,
      });

      checkForApiError({
        response: await dispatch(api.patchClientBudget({ totalIncome, loansExpenses })),
        formName: 'otherDetails',
        dispatch,
      });

      checkForApiError({
        response: await dispatch(api.patchClientApplicationLoanPurpose({ loanPurpose })),
        formName: 'otherDetails',
        dispatch,
      });

      dispatch(updatePath(routesList.accountBankProvider));
    };

    return {
      type: SUBMIT_OTHER_DETAILS,
      payload: getPromise(),
    };
  };
}

export function updateCityAndProvince() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const { postalCode: postalCodeError } = extractPropertyFromState(getState(), 'aboutYou', 'error');

      if (postalCodeError) {
        return false;
      }

      const { postalCode } = extractPropertyFromState(getState(), 'aboutYou', 'value');
      const { payload: { status, results } } = await dispatch(fetchCityFromPostalCode(postalCode));

      if (status !== 'OK') {
        return false;
      }

      const addressComponents = ((results || [])[0] || {}).address_components;

      if (!addressComponents) {
        return false;
      }

      const city = (addressComponents.find(({ types }) =>
        types.includes('locality') || types.includes('administrative_area_level_3')
      ) || {}).long_name;

      const province = (addressComponents.find(({ types }) =>
        types.includes('administrative_area_level_2')
      ) || {}).long_name;

      dispatch(form.setFieldError('aboutYou', 'city', null));
      dispatch(form.setFieldError('aboutYou', 'province', null));

      dispatch(form.setFieldValue('aboutYou', 'city', city || ''));
      dispatch(form.setFieldValue('aboutYou', 'province', province || ''));

      return true;
    };

    return {
      type: 'UPDATE_CITY_FROM_POSTAL_CODE',
      payload: getPromise(),
    };
  };
}

function checkForCurrentApplicationChanges() {
  return ({ dispatch, getApiResponse, getState }) => {
    const getPromise = async () => {
      const { amount, term } = getState().calculator;
      const { totalPrincipal: offerAmount, newPrincipal: offerAmountNew, term: offerTerm } = await getApiResponse(['fetch', 'client', 'application', 'offerByAmountAndTerm'], { dynamicKeys: [amount.toString(), term.toString()] });
      const { amount: applicationAmount, term: applicationTerm } = await getApiResponse(['fetch', 'client', 'application']);

      if ((offerAmount || offerAmountNew) !== applicationAmount || offerTerm !== applicationTerm) {
        await dispatch(application.createApplication());
      }
    };

    return {
      type: 'CHECK_CURRENT_APPLICATION',
      payload: getPromise(),
    };
  };
}

function fetchCityFromPostalCode(postalCode) {
  const getPromise = async () => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?region=ES&language=ES&components=country:ES|postal_code:${postalCode}`);

    return await response.json().catch(() => {});
  };

  return {
    type: 'FETCH_CITY_FROM_POSTAL_CODE',
    payload: getPromise(),
  };
}

export function prefetchAboutYouFormData() {
  return ({ getState, dispatch, getApiResponse }) => {
    const getPromise = async () => {
      const household = await getApiResponse(['fetch', 'client', 'household']);

      await getApiResponse(['fetch', 'client']); // eslint-disable-line
      const address = clientSelectors.deserializedAddressSelector(getState());

      dispatch(form.setMultipleFields('aboutYou', 'value', { ...address, ...household }));
    };

    return {
      type: 'PREFETCH_ABOUT_YOU_FORM_DATA',
      payload: getPromise(),
    };
  };
}

export function prefetchOtherDetailsFormData() {
  return ({ dispatch, getApiResponse }) => {
    const getPromise = async () => {
      const employer = await getApiResponse(['fetch', 'client', 'employer']);
      const budget = await getApiResponse(['fetch', 'client', 'budget']);
      const loanPurpose = await getApiResponse(['fetch', 'client', 'application', 'loanPurpose']);

      dispatch(form.setMultipleFields('otherDetails', 'value', { ...budget, ...loanPurpose, employmentStatus: employer.employmentStatus }));
    };

    return {
      type: 'PREFETCH_OTHER_DETAILS_FORM_DATA',
      payload: getPromise(),
    };
  };
}

export function fillAddressFields(address = {}) {
  const values = Object.keys(address).reduce((acc, item) => ({
    ...acc,
    [item]: true,
  }), {});

  return ({ dispatch }) => {
    dispatch(form.setMultipleFields('aboutYou', 'liveValidation', values));
    dispatch(form.setMultipleFields('aboutYou', 'value', address));

    return {
      type: 'FILL_ADDRESS_FIELDS',
    };
  };
}

