import * as calculator from './calculator/selectors';
import * as client from './client/selectors';
import * as faqs from './faqs/selectors';
import * as payment from './payment/selectors';

export default {
  calculator,
  client,
  faqs,
  payment,
};
