import FontProximaNova from './FontProximaNova.react';
import React from 'react';
import reset from './reset';
import { Style } from 'radium';
import { StyledComponent } from '4finance-components';

export default class AppStyles extends StyledComponent {
  static styleRoot = 'AppStyles'

  render() {
    return (
      <div>
        <Style rules={reset} />
        <FontProximaNova />
        <Style rules={this.getStyle()} />
      </div>
    );
  }

  static style = ({ colors }) => ({
    html: {
      height: '100%',
      fontSize: '10px',
    },

    body: {
      fontFamily: '"ProximaNova", "Lucida Grande", "sans-serif"',
      fontSize: '1.6rem',
      fontWeight: '300',
      lineHeight: '1.2',
      color: colors.darkFont,
      backgroundColor: colors.background,
      height: '100%',
      margin: 0,
    },

    a: {
      color: colors.primary,
      textDecoration: 'underline',
    },

    'a:hover': {
      textDecoration: 'none',
      color: colors.primaryDarker,
    },

    '#app': {
      height: '100%',
    },

    strong: {
      fontWeight: 700,
    },

    '.bold-type': {
      fontWeight: 900,
    },

    '.line-break': {
      display: 'block',
    },

    'input::-ms-clear': {
      display: 'none',
    },

    'input::-ms-reveal': {
      display: 'none',
    },
  })
}
