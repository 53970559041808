import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

@Radium
export default class ValueLoader extends StyledComponent {
  static styleRoot = 'ValueLoader'

  static propTypes = {
    isLoading: RPT.bool,
    value: RPT.any,
    id: RPT.string,
  }

  static defaultProps = {
    isLoading: false,
    value: '',
  }

  render() {
    const { isLoading, children, value, id } = this.props;
    const style = this.getStyle();

    return (
      <span id={id} style={[style.value, isLoading && style.value.isLoading]}>
        {value || children}
      </span>
    );
  }

  static style = {
    value: {
      filter: 'none',
      transition: 'all .15s',
      isLoading: {
        filter: 'blur(3px)',
      },
    },
  };
}
