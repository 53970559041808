import marked from 'marked';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StaticPageLayout from '../../layouts/StaticPage.react';
import Title from '../components/Title.react';
import { StyledComponent, Container, Row, Column } from '4finance-components';

@Radium
export default class Archive extends StyledComponent {
  static styleRoot = 'Archive'

  static propTypes = {
    page: RPT.object.isRequired,
  }

  renderLinks(link, style) {
    const { sys: { id }, fields: { url, heading } } = link;

    return (
      <li style={style.listItem} key={id}>
        <a style={style.link} href={url} dangerouslySetInnerHTML={{ __html: marked(heading) }} />
      </li>
    );
  }

  render() {
    const { page: { fields } } = this.props;
    const { url, heading, archiveLink } = fields;
    const style = this.getStyle();

    return (
      <StaticPageLayout location={{ pathname: url }} >
        <Style scopeSelector=".archive-page" rules={style.archiveStyles} />
        <Title decorated text={heading} />

        <Container>
          <Row>
            <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
              <article className="archive-page">
                <ul style={style.list}>
                  {archiveLink.map((link) => this.renderLinks(link, style))}
                </ul>
              </article>
            </Column>
          </Row>
        </Container>
      </StaticPageLayout>
    );
  }
  static style = () => ({
    archiveStyles: {
      p: {
        display: 'inline',
      },
    },

    list: {
      margin: 0,
    },

    listItem: {
      margin: 0,
    },

    link: {
      fontSize: '20px',
      textDecoration: 'none',
    },
  })
}
