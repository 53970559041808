import RPT from 'prop-types';
import { createSelector } from '4finance-connect';
import { fromJS, Map } from 'immutable';

const isUserLoggedIn = (state) => state.authentication.get('isLoggedIn');
const connectApiFetchState = (state) => state.api.fetch;
const connectCalculatorState = (state) => fromJS(state.calculator);
const connectLoanState = (state) => state.loans;

const FAKE_AMOUNTINTERVAL = new Map({
  defaultValue: 800,
  max: 2500,
  min: 0,
  step: 10,
});

const FAKE_TERMINTERVAL = new Map({
  defaultValue: 24,
  max: 24,
  min: 3,
  step: 1,
});

export const RPTcalculatorSlidersSelector = RPT.shape({
  amount: RPT.number.isRequired,
  amountInterval: RPT.object.isRequired,
  disabled: RPT.bool.isRequired,
  term: RPT.number.isRequired,
  termInterval: RPT.object.isRequired,
}).isRequired;

export const RPTcalculatorOfferSelector = RPT.shape({
  loan: RPT.object.isRequired,
  offer: RPT.object.isRequired,
  offerFetched: RPT.bool.isRequired,
  nextPayment: RPT.object,
});

export const calculatorSlidersSelector = createSelector(
  [isUserLoggedIn, connectCalculatorState, connectApiFetchState],
  (isLoggedIn, calculatorState, apiFetchState) => {
    const constraints = isLoggedIn
      ? apiFetchState.getIn(['client', 'application', 'constraints', 'query'])
      : apiFetchState.getIn(['application', 'constraints', 'query']);

    const amountLimits = calculatorState.get('amountLimits');
    const disabled = constraints.get('errors') || !constraints.get('fetched');
    const termLimits = calculatorState.get('termLimits');
    const maxAmount = constraints.getIn(['data', 'loanLimits', '1']);

    return {
      amount: calculatorState.get('amount'),
      amountInterval: disabled ? FAKE_TERMINTERVAL : constraints.getIn(['data', 'amountInterval']).merge((maxAmount ? { max: maxAmount } : {})).merge(amountLimits),
      disabled,
      term: calculatorState.get('term'),
      termInterval: disabled ? FAKE_AMOUNTINTERVAL : constraints.getIn(['data', 'termInterval']).merge(termLimits),
    };
  }
);

export const calculatorOfferSelector = createSelector(
  [isUserLoggedIn, connectCalculatorState, connectApiFetchState, connectLoanState],
  (isLoggedIn, calculatorState, apiFetchState, loanState) => {
    const amount = calculatorState.get('amount').toString();
    const term = calculatorState.get('term').toString();
    const path = isLoggedIn
      ? apiFetchState.getIn(['client', 'application', 'offerByAmountAndTerm', amount, term])
      : apiFetchState.getIn(['application', 'firstLoanOfferForTerm', '0', '0', amount, term]);
    const loan = apiFetchState.getIn(['client', 'loans', 'latest', 'data']) || new Map({});

    if (path && !path.get('pending')) {
      return {
        loan,
        offer: path.get('data'),
        offerFetched: path.get('fetched'),
        nextPayment: loanState.get('nextPayment'),
      };
    }

    return {
      loan,
      offer: new Map(),
      offerFetched: false,
      nextPayment: loanState.get('nextPayment'),
    };
  }
);
