import debounce from 'debounce-promise';

export const CALCULATOR_SET_AMOUNT_LIMIT = 'CALCULATOR_SET_AMOUNT_LIMIT';
export const CALCULATOR_SET_DEFAULT_VALUES = 'CALCULATOR_SET_DEFAULT_VALUES';
export const CALCULATOR_SET_LIMIT = 'CALCULATOR_SET_LIMIT';
export const CALCULATOR_TOGGLE = 'CALCULATOR_TOGGLE';
export const CALCULATOR_RESET_DEFAULTS = 'CALCULATOR_RESET_DEFAULTS';
export const CALCULATOR_VALUE_CHANGE = 'CALCULATOR_VALUE_CHANGE';
export const CALCULATOR_VALUE_OVERFLOW = 'CALCULATOR_VALUE_OVERFLOW';
export const CALCULATOR_SET_SWIPING = 'CALCULATOR_SET_SWIPING';
export const CALCULATOR_SET_TOUCHED = 'CALCULATOR_SET_TOUCHED';

function isLoggedIn(getState) {
  return getState().authentication.isLoggedIn;
}

export const toggleCalculatorOpened = () => ({ type: CALCULATOR_TOGGLE });
export const setCalculatorTouched = (touched: boolean) => ({ type: CALCULATOR_SET_TOUCHED, touched });
export const setCalculatorSwiping = (swiping: boolean) => ({ type: CALCULATOR_SET_SWIPING, swiping });


export function fetchCalculatorWithOffer() {
  return ({ dispatch, getApiResponse, getState }) => {
    const getPromise = async () => {
      await fetchConstraints({ dispatch, getApiResponse, getState }); // eslint-disable-line no-unused-expressions
      await fetchNonDebouncedOffer({ getApiResponse, getState }); // eslint-disable-line no-unused-expressions
    };

    return {
      type: 'CALCULATOR_WITH_OFFER',
      payload: getPromise(),
    };
  };
}

export function onAmountTermChange(amount, term, debounceOffer) {
  return ({ dispatch, getState, getApiResponse }) => {
    const getPromise = async () => {
      const {
        calculator: {
          amount: requestAmount = amount,
          term: requestTerm = term,
        },
      } = getState();

      dispatch(setCalculatorTouched(true));
      dispatch({ type: CALCULATOR_VALUE_CHANGE, amount, term });

      await fitCalculatorLimits({ dispatch, getApiResponse, getState }); // eslint-disable-line no-unused-expressions
      await dispatch(fetchOffer({ requestAmount, requestTerm, debounceOffer })); // eslint-disable-line no-unused-expressions
    };

    return {
      type: 'CALCULATOR_CHANGE',
      payload: getPromise(),
    };
  };
}

async function fitCalculatorLimits({ dispatch, getApiResponse, getState }) {
  const { calculator: { amount, term, termLimits: { min: minTermLimit, max: maxTermLimit } } } = getState();
  const { amountInterval, termLimits } = await getApiResponse(
    isLoggedIn(getState)
      ? ['fetch', 'client', 'application', 'constraints']
      : ['fetch', 'application', 'constraints']
  );
  const { termTo, termFrom } = termLimits.filter(limit => amount >= limit.amountFrom && amount <= limit.amountTo)[0] || {};

  if (amount > amountInterval.max) {
    dispatch({ type: CALCULATOR_VALUE_OVERFLOW, field: 'amount', value: amountInterval.max });
  }

  if (amount < amountInterval.min) {
    dispatch({ type: CALCULATOR_VALUE_OVERFLOW, field: 'amount', value: amountInterval.min });
  }

  if (minTermLimit !== termFrom) {
    dispatch({ type: CALCULATOR_SET_LIMIT, field: 'min', value: termFrom });
  }

  if (maxTermLimit !== termTo) {
    dispatch({ type: CALCULATOR_SET_LIMIT, field: 'max', value: termTo });
  }

  if (term < termFrom) {
    dispatch({ type: CALCULATOR_VALUE_OVERFLOW, field: 'term', value: termFrom });
  }

  if (term > termTo) {
    dispatch({ type: CALCULATOR_VALUE_OVERFLOW, field: 'term', value: termTo });
  }
}

async function fetchConstraints({ dispatch, getApiResponse, getState }) {
  const userLoggedIn = isLoggedIn(getState);
  const { calculator: { amount, term } } = getState();
  const { amountInterval, termInterval } = await getApiResponse(
    userLoggedIn
      ? ['fetch', 'client', 'application', 'constraints']
      : ['fetch', 'application', 'constraints']
  );

  if (amount < 0 || term < 0) {
    dispatch({
      type: CALCULATOR_SET_DEFAULT_VALUES,
      amount: userLoggedIn ? amountInterval.max : amountInterval.defaultValue,
      term: termInterval.defaultValue,
    });

    return;
  }

  await fitCalculatorLimits({ dispatch, getApiResponse, getState }); // eslint-disable-line no-unused-expressions
}

export function fetchOffer({ amount, term, debounceOffer = true }) {
  return ({ getApiResponse, getState }) => {
    const getPromise = async () => {
      if (debounceOffer) {
        await fetchDebouncedOffer({ getApiResponse, getState, amount, term }); // eslint-disable-line no-unused-expressions
      } else {
        await fetchNonDebouncedOffer({ getApiResponse, getState, amount, term }); // eslint-disable-line no-unused-expressions
      }
    };

    return {
      type: 'FETCH_OFFER',
      payload: getPromise(),
    };
  };
}

export const fetchDebouncedOffer = debounce(fetchNonDebouncedOffer, 100);

async function fetchNonDebouncedOffer({ getApiResponse, getState, amount, term }) {
  const { calculator: { amount: stateAmount, term: stateTerm } } = getState();
  const requestAmount = amount || stateAmount;
  const requestTerm = term || stateTerm;

  const path = isLoggedIn(getState)
    ? ['fetch', 'client', 'application', 'offerByAmountAndTerm']
    : ['fetch', 'application', 'firstLoanOfferForTerm'];

  const dynamicKeys = isLoggedIn(getState)
    ? [requestAmount.toString(), requestTerm.toString()]
    : ['0', '0', requestAmount.toString(), requestTerm.toString()];

  await getApiResponse( // eslint-disable-line no-unused-expressions
    path, { dynamicKeys }
  );
}
