import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import React, { Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectPrefetch, connectSelectors } from '../../../browser/connect';
import { RPTcalculatorSlidersSelector } from '../../../common/calculator/selectors';
import { SliderWithControls, Swiper } from '4finance-components';

@connectActions({
  onAmountTermChange: ['calculator', 'onAmountTermChange'],
  setCalculatorSwiping: ['calculator', 'setCalculatorSwiping'],
})
@connectSelectors({
  calculatorSlidersSelector: ['calculator', 'calculatorSlidersSelector'],
})
@connectPrefetch([
  ['calculator', 'fetchCalculatorWithOffer'],
])
@translate
@listenWindowResize
export default class CalculatorSlider extends Component {
  static propTypes = {
    calculatorSlidersSelector: RPTcalculatorSlidersSelector,
    device: RPT.instanceOf(Device).isRequired,
    embedded: RPT.bool,
    msg: RPT.func.isRequired,
    setCalculatorSwiping: RPT.func.isRequired,
    numberToCurrency: RPT.func.isRequired,
    onAmountTermChange: RPT.func.isRequired,
    style: RPT.object,
  }

  static defaultProps = {
    embedded: false,
    style: {},
  }

  render() {
    const { amount, term, amountInterval, termInterval, disabled } = this.props.calculatorSlidersSelector;
    const { embedded, onAmountTermChange, numberToCurrency, device, msg, style, setCalculatorSwiping } = this.props;
    const isScreenWidthUnderTablet = device.atMost('tablet');

    return (
      <div>
        {isScreenWidthUnderTablet ?
          <div>
            <Swiper
              heading={msg('calculator.amountSlider.heading')}
              defaultValue={amountInterval.get('max')}
              max={amountInterval.get('max')}
              min={amountInterval.get('min')}
              step={amountInterval.get('step')}
              snap={100}
              onBeforeChange={setCalculatorSwiping}
              onChange={(newAmount) => onAmountTermChange(newAmount, term)}
            />
            <Swiper
              heading={msg('calculator.term.heading')}
              defaultValue={termInterval.get('defaultValue')}
              max={termInterval.get('max')}
              min={termInterval.get('min')}
              step={termInterval.get('step')}
              snap={60}
              onBeforeChange={setCalculatorSwiping}
              onChange={(newTerm) => onAmountTermChange(amount, newTerm)}
            />
          </div>
          :
          <div>
            <SliderWithControls
              defaultValue={amountInterval.get('max')}
              formater={numberToCurrency}
              heading={msg('calculator.amountSlider.heading')}
              kind="amount"
              max={amountInterval.get('max')}
              min={amountInterval.get('min')}
              onChange={(newAmount) => onAmountTermChange(newAmount, term, false)}
              selectFormater={numberToCurrency}
              step={amountInterval.get('step')}
              unit={msg('calculator.amountSlider.unit')}
              editableInput={false}
              value={amount}
              shakySteps
              disabled={disabled}
              blackVersion={embedded}
              style={style}
            />
            <SliderWithControls
              defaultValue={termInterval.get('defaultValue')}
              formater={(value) => value}
              heading={msg('calculator.term.heading')}
              kind="term"
              max={termInterval.get('max')}
              min={termInterval.get('min')}
              onChange={(newTerm) => onAmountTermChange(amount, newTerm, false)}
              selectFormater={(value) => value}
              step={termInterval.get('step')}
              unit={msg('calculator.term.unit')}
              value={term}
              editableInput={false}
              shakySteps
              disabled={disabled}
              blackVersion={embedded}
              style={style}
            />
          </div>
        }
      </div>
    );
  }
}
