import Calculator from './Calculator.react';
import CalculatorOffer from './CalculatorOffer.react';
import Icon from '../Icon.react';
import Radium from 'radium';
import React from 'react';
import CalculatorResponsiveOffer from './CalculatorResponsiveOffer.react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { Button, StyledComponent } from '4finance-components';
import { connectState, connectActions } from '../../connect';

@connectActions({
  toggleOpened: ['calculator', 'toggleCalculatorOpened'],
  setTouched: ['calculator', 'setCalculatorTouched'],
})
@connectState({
  opened: ['calculator', 'isCalculatorOpened'],
})
@translate
@Radium
export default class CalculatorEditableResponsive extends StyledComponent {
  static styleRoot = 'CalculatorEditableResponsive'

  static propTypes = {
    opened: RPT.bool.isRequired,
    toggleOpened: RPT.func,
  }

  @autobind
  toggle() {
    const { toggleOpened, setTouched } = this.props;

    toggleOpened();
    setTouched(false);
  }

  render() {
    const { opened } = this.props;
    const style = this.getStyle();

    const iconType = opened ? 'check' : 'loan-edit'; // eslint-disable-line no-nested-ternary

    return (
      <div style={style.wrapper}>
        <div style={style.header}>
          <CalculatorResponsiveOffer />

          <Button
            id="editToggleButton"
            size="small"
            kind="white"
            noMargin
            onClick={this.toggle}
            inheritedStyle={style.toggleButton.base}
          >
            <Icon
              kind={iconType}
              size={16}
              wrapperStyle={style.toggleButton.icon}
              style={{ fill: 'black' }}
            />
          </Button>
        </div>

        {opened &&
          <div style={[style.calc.base]}>
            <div style={style.calc.wrapper}>
              <Calculator toggled embedded />
            </div>

            <div style={style.offerWrapper}>
              <CalculatorOffer />
            </div>
          </div>
        }
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundColor: colors.secondary,
      position: 'relative',
      marginBottom: '30px',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '5px',
      padding: '10px 20px',
      backgroundColor: colors.secondary,
      boxShadow: 'rgba(0, 0, 0, 0.15) 0 1px 4px',

      [mediaQueries.breakpointLaptopSmall]: {
        marginTop: '1px',
      },

      [mediaQueries.breakpointTablet]: {
        marginTop: 0,
      },
    },

    toggleButton: {
      base: {
        padding: '0 8px',
        height: '35px',
        boxShadow: 'inset 0 -1px 1px 0 rgba(0,0,0,0.11), 0 2px 2px 0 rgba(0,0,0,0.12)',
        borderRadius: '3px',
        border: 0,

        [mediaQueries.breakpointTablet]: {
          width: '34px',
          display: 'inline-block',
        },
      },

      icon: {
        display: 'inline-block',
        marginTop: '8px',
      },
    },

    calc: {
      wrapper: {
        overflow: 'hidden',
      },

      base: {
        maxHeight: '900px',
        overflow: 'hidden',
        transition: 'max-height .4s',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0 1px 4px',
      },
    },

    offerWrapper: {
      padding: '10px 20px',
      background: colors.white,
    },
  })
}
