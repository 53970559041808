/* eslint-disable no-unused-expressions */
import { actions as api } from '../api';
import * as client from '../client/actions';

export const PREFETCH_CARD_PAYMENT_DATA = 'PREFETCH_CARD_PAYMENT_DATA';
export const PREFETCH_CARD_PAYMENT_DATA_SUCCESS = 'PREFETCH_CARD_PAYMENT_DATA_SUCCESS';

export const PREFETCH_PAYMENT_DETAILS = 'PREFETCH_PAYMENT_DETAILS';
export const PREFETCH_PAYMENT_DETAILS_SUCCESS = 'PREFETCH_PAYMENT_DETAILS_SUCCESS';
export const PREFETCH_PAYMENT_DETAILS_ERROR = 'PREFETCH_PAYMENT_DETAILS_ERROR';

export const MAKE_CARD_PAYMENT = 'MAKE_CARD_PAYMENT';
export const MAKE_CARD_PAYMENT_SUCCESS = 'MAKE_CARD_PAYMENT_SUCCESS';
export const MAKE_CARD_PAYMENT_ERROR = 'MAKE_CARD_PAYMENT_ERROR';

export const DISMISS_PAYMENT_NOTIFICATION = 'DISMISS_PAYMENT_NOTIFICATION';


export function prefetchPaymentDetails() {
  return ({ dispatch, getApiResponse, getState }) => {
    const getPromise = async () => {
      getApiResponse(['fetch', 'client']);
      getApiResponse(['fetch', 'client', 'banks']);

      await dispatch(client.fetchClientActiveCard());

      const clientData = getState().api.getIn(['fetch', 'client', 'query', 'data']);

      return {
        clientData,
      };
    };

    return {
      type: PREFETCH_PAYMENT_DETAILS,
      payload: getPromise(),
    };
  };
}

export function makePayment(amount, cardId) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const params = {
        loanNumber: getState().api.getIn(['fetch', 'client', 'loans', 'latest', 'data', 'loanNumber']),
        amount,
        currency: 'EUR',
        applicationName: 'Zaplo.es',
        username: getState().api.getIn(['fetch', 'client', 'query', 'data', 'fullName']),
        id: cardId,
      };

      await dispatch(api.sendPaymentGatewayReissuePaymentWithStoredDataByChannelcode(params, 'mymoid'));
    };

    return {
      type: MAKE_CARD_PAYMENT,
      payload: getPromise(),
    };
  };
}

export function dismissPaymentNotification() {
  return {
    type: DISMISS_PAYMENT_NOTIFICATION,
  };
}
