import Notification from '../../components/Notification.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectState } from '../../connect';

@connectState({
  paymentStatus: ['payment', 'paymentStatus'],
  paymentNotificationVisible: ['payment', 'paymentNotificationVisible'],
})
@connectActions({
  dismissPaymentNotification: ['payment', 'dismissPaymentNotification'],
})
@translate
export default class PaymentNotification extends Component {
  static propTypes = {
    dismissPaymentNotification: RPT.func,
    msg: RPT.func,
    paymentNotificationVisible: RPT.bool,
    paymentStatus: RPT.string,
  }

  render() {
    const { msg, paymentStatus, paymentNotificationVisible, dismissPaymentNotification } = this.props;

    return (
      <Notification
        body={msg(`payments.notification.${paymentStatus}`)}
        type={paymentStatus}
        onClose={dismissPaymentNotification}
        visible={paymentNotificationVisible}
      />
    );
  }
}
