import { actions as apiActions, reducer as apiReducer } from '4finance-zaploes-api'; // eslint-disable-line import/named

export function patchClientPaymentPreferencesAutoRepay(params) {
  return ({ httpPut }) => ({
    type: 'PATCH_CLIENT_PAYMENT_PREFERENCES_AUTO_REPAY',
    payload: {
      promise: httpPut(
        '/client/payment-preferences/auto-repay',
        params,
      ),
    },
  });
}

export function sendPaymentGatewayReissuePaymentWithStoredDataByChannelcode(params, channelCode) {
  return ({ httpPost }) => ({
    type: 'SEND_PAYMENT_GATEWAY_REISSUE_PAYMENT_WITH_STORED_DATA_BY_CHANNELCODE',
    meta: { params, channelCode },
    payload: httpPost(
      `/payment-gateway/${channelCode}/reissue-payment-with-stored-data`,
      params,
      {
        Accept: 'application/vnd.payment-gateway.v1+json',
        'Content-Type': 'application/vnd.payment-gateway.v1+json',
      }
    ),
  });
}

export function fetchPaymentGatewayCardsByChannelcode(channelCode) {
  return ({ httpGet }) => ({
    type: 'FETCH_PAYMENT_GATEWAY_CARDS_BY_CHANNELCODE',
    meta: { channelCode },
    payload: httpGet(`/payment-gateway/${channelCode}/cards`,
      null,
      {
        Accept: 'application/vnd.payment-gateway.v1+json',
        'Content-Type': 'application/vnd.payment-gateway.v1+json',
      }
    ),
  });
}

export const actions = {
  ...apiActions,
  fetchPaymentGatewayCardsByChannelcode,
  patchClientPaymentPreferencesAutoRepay,
  sendPaymentGatewayReissuePaymentWithStoredDataByChannelcode,
};

export const reducer = apiReducer;

export default {
  actions,
  reducer: apiReducer,
};
