import AcceptNewsNotification from '../dashboard/components/AcceptNewsNotification.react';
import ClientBlockedNotification from '../dashboard/components/ClientBlockedNotification.react';
import LoanInProgressNotification from '../dashboard/components/LoanInProgressNotification.react';
import OverdueNotification from '../dashboard/components/OverdueNotification.react';
import PaymentNotification from '../payment/components/PaymentNotification.react';
import React, { PureComponent as Component } from 'react';

export default class Dashboard extends Component {
  render() {
    return (
      <div>
        <AcceptNewsNotification />
        <ClientBlockedNotification />
        <LoanInProgressNotification />
        <OverdueNotification />
        <PaymentNotification />
      </div>
    );
  }
}
