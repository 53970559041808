import CoinsDecoration from './CoinsDecoration.react';
import FeaturesStepBlock from './FeaturesStepBlock.react';
import Icon from '../../components/Icon.react';
import Radium, { Style } from 'radium';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import RPT from 'prop-types';
import { Column, Container, Heading, StyledComponent } from '4finance-components';

export const BG_COLOR_BLUE = 'BLUE';
export const BG_COLOR_TRANSPARENT = 'TRANSPARENT';
export const BG_COLOR_WHITE = 'WHITE';
export const BG_COLOR_YELLOW = 'YELLOW';

@Radium
export default class FeaturesContentBlock extends StyledComponent {
  static styleRoot = 'FeaturesContentBlock';

  static propTypes = {
    backgroundColor: RPT.oneOf([BG_COLOR_BLUE, BG_COLOR_WHITE, BG_COLOR_YELLOW, BG_COLOR_TRANSPARENT]),
    source: RPT.object.isRequired,
    sourceSimple: RPT.array,
  };

  static defaultProps = {
    backgroundColor: BG_COLOR_TRANSPARENT,
  }

  renderStepBlocks({ fields: source, sys }) {
    return <FeaturesStepBlock source={source} key={sys.id} />;
  }

  render() {
    const { source, backgroundColor, sourceSimple } = this.props;
    const style = this.getStyle();

    const hasCoins = ~source.contentTemplate.indexOf('Coins');
    const coinsKind = ~source.contentTemplate.indexOf('2') ? 'payment' : 'additional';

    const hasIcon = source.contentTemplate === 'Blue';

    return (
      <div style={[style.wrapper, style.wrapper[backgroundColor], style.wrapper[source.contentTemplate]]}>
        <Container>
          <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
            <Heading kind="h2" inheritedStyle={[style.title, style.title[source.contentTemplate]]}>
              <ReactMarkdown source={source.title} />
            </Heading>
            <Style scopeSelector=".content-block" rules={style.scope[source.contentTemplate]} />
            <div className="content-block" style={style.content[source.contentTemplate]}>
              <ReactMarkdown source={source.content} />
            </div>
            {!!hasCoins && <CoinsDecoration kind={coinsKind} />}
            {sourceSimple
              && (Array.isArray(sourceSimple)
                ? sourceSimple.map(this.renderStepBlocks)
                : this.renderStepBlocks(sourceSimple))
            }
            {hasIcon &&
              <div style={style.iconBlue}>
                <Icon kind="about-us-badge" size={210} />
              </div>
            }
          </Column>
        </Container>
      </div>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    wrapper: {
      padding: '24px 0',

      [BG_COLOR_WHITE]: {
        backgroundColor: colors.white,
      },
      [BG_COLOR_YELLOW]: {
        backgroundColor: colors.secondary,
      },
      [BG_COLOR_TRANSPARENT]: {
        backgroundColor: 'transparent',
      },
      [BG_COLOR_BLUE]: {
        backgroundColor: colors.primary,
      },

      Default: {
        padding: '50px 0 20px',
      },
    },

    scope: {
      Blue: {
        h1: {
          fontSize: '60px',
          fontWeight: '200',
        },
        mediaQueries: {
          [mediaQueries.breakpointTabletScope]: {
            h1: {
              fontSize: '32px',
            },
          },
        },
      },
    },

    iconBlue: {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
    },

    content: {
      Default: {
        maxWidth: '600px',
        fontSize: '20px',
        lineHeight: 1.414,
        margin: '30px 0 35px',
        [mediaQueries.breakpointTablet]: {
          margin: '16px 0 35px',
        },
      },

      Blue: {
        color: colors.white,
        maxWidth: '600px',
        fontSize: '20px',
        lineHeight: 1.414,
        margin: '30px 0 35px',
        [mediaQueries.breakpointTablet]: {
          margin: '16px 0 35px',
        },
      },
    },

    title: {
      fontSize: '60px',
      fontWeight: '200',
      marginTop: '26px',
      marginLeft: 0,
      marginRight: 0,
      marginBottom: '25px',
      maxWidth: '80%',

      [mediaQueries.breakpointTablet]: {
        maxWidth: '100%',
      },

      Blue: {
        color: colors.white,
      },
    },
  });
}
