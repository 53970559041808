import Footer from '../app/Footer.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';

export default class HomeLayout extends Component {
  static propTypes = {
    children: RPT.any,
  };

  render() {
    const { children } = this.props;

    return (
      <div style={{ backgroundColor: 'white' }}>
        {children}
        <Footer />
      </div>
    );
  }
}
