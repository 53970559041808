import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import PageContainer from '../../components/PageContainer.react';
import PageHeader from '../../components/PageHeader.react';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectActions, connectPrefetch, connectSelectors } from '../../connect';
import { Panel, StyledComponent } from '4finance-components';
import StopZaplo from '../components/StopZaplo.react';

@connectActions({
  createApplication: ['application', 'createApplication'],
})
@connectSelectors({
  isFintonicOrPrestaloProduct: ['client', 'isFintonicOrPrestaloProduct'],
  hadLoans: ['client', 'hadLoans'],
})
@connectPrefetch([['calculator', 'fetchCalculatorWithOffer']])
@listenWindowResize
@translate
export default class NoActiveLoan extends StyledComponent {
  static styleRoot = 'NoActiveLoan';

  static propTypes = {
    createApplication: RPT.func.isRequired,
    device: RPT.instanceOf(Device).isRequired,
    isFintonicOrPrestaloProduct: RPT.bool,
    hadLoans: RPT.bool,
  };

  applyNewLoan = () => {
    const { createApplication, hadLoans } = this.props;

    createApplication(
      hadLoans ? routesList.newApplication : routesList.accountAboutYou
    );
  };

  render() {
    const { isFintonicOrPrestaloProduct, msg } = this.props;
    const style = this.getStyle();

    if (isFintonicOrPrestaloProduct) {
      return (
        <PageContainer width="widest">
          <PageHeader
            title={msg('dashboard.fintonicClient.noLoan.title')}
            textAlign="left"
          />
          {msg('dashboard.fintonicClient.noLoan.text')}
        </PageContainer>
      );
    }

    return (
      <div>
        <Panel noBorder inheritedStyle={style.panel}>
          <StopZaplo />
        </Panel>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    greenSpace: {
      height: '240px',
      backgroundColor: 'green',
    },

    panel: {
      borderRadius: '5px',
      overflow: 'hidden',

      [mediaQueries.breakpointTablet]: {
        margin: '40px 0 0 0',
      },
    },
    sliderWrapper: {
      calculator: colors.secondary,
      promotion: colors.primary,
      promotionMobile: 'transparent',
    },
  });
}
