import { StyledComponent } from '4finance-components';
import { Submit } from '../../forms/fields';
import React from 'react';
import RPT from 'prop-types';

export default class ModalSubmit extends StyledComponent {
  static styleRoot = 'ModalSubmit'
  static propTypes = {
    children: RPT.node.isRequired,
  }

  render() {
    const { children, ...props } = this.props;
    const style = this.getStyle();

    return (
      <Submit
        kind="primary"
        type="submit"
        inheritedStyle={style.button}
        {...props}
      >
        {children}
      </Submit>
    );
  }

  static style = ({ font, colors }) => ({
    button: {
      borderRadius: '3px',
      boxShadow: `0 2px 2px 0 ${colors.lighterGray}`,
      fontSize: '18px',
      fontWeight: font.weight.normal,
      lineHeight: '22px',
      marginTop: '20px',
      marginBottom: '25px',
      marginLeft: 0,
      marginRight: 0,
      padding: '19px 0',
      textTransform: 'uppercase',
      width: '100%',
      minWidth: '0 !important',
    },
  });
}
