import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import ValueLoader from '../ValueLoader.react';
import { connectSelectors } from '../../../browser/connect';
import { StyledComponent } from '4finance-components';
import { fromJS } from 'immutable';
import { RPTcalculatorOfferSelector } from '../../../common/calculator/selectors';

const FAKE_OFFER = fromJS({
  agreementEndDate: new Date(),
  annualPercentageRate: 460.95,
  interestRate: 390.99,
  isFake: true,
  totalRepayableAmount: 1800.55,
});

const FAKE_LOAN = fromJS({
  schedulePayment: 450.55,
});

@connectSelectors({
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@translate
export default class CalculatorOfferRepaymentInfo extends StyledComponent {
  constructor(props, context) {
    super(props, context);
    this.blurInterestCalculator(props);
  }

  static styleRoot = 'CalculatorOfferRepaymentInfo'

  static propTypes = {
    calculatorOfferSelector: RPTcalculatorOfferSelector,
    hasAgreementEndDate: RPT.bool,
    hasInterestRateAndAnnualPercentageRate: RPT.bool,
    hasTotalRepayableAmount: RPT.bool,
    hasCurrentTotalRepayableAmount: RPT.bool,
    homePage: RPT.bool,
  }

  static defaultProps = {
    homePage: false,
  }

  offer = FAKE_OFFER;
  loan = FAKE_LOAN;

  blurInterestCalculator(props) {
    const { calculatorOfferSelector: { offer, loan } } = props;

    if (offer.size) this.offer = offer;
    if (loan.size) this.loan = loan;
  }

  shouldComponentUpdate = (nextProps) => {
    const { calculatorOfferSelector: { offerFetched: nextFetched, offer, loan } } = nextProps;
    const { calculatorOfferSelector: { offerFetched: thisFetched } } = this.props;

    if (!nextFetched && !thisFetched) return false;

    if (offer.size) this.offer = offer;
    if (loan.size) this.loan = loan;

    return true;
  }

  render() {
    const {
      calculatorOfferSelector: { offerFetched },
      formatDate,
      hasAgreementEndDate,
      hasInterestRateAndAnnualPercentageRate,
      hasTotalRepayableAmount,
      hasCurrentTotalRepayableAmount,
      msg,
      numberToPercentage,
      preciseNumberToCurrency,
      homePage,
    } = this.props;
    const offer = this.offer;
    const loan = this.loan;
    const isLoading = !!(!offerFetched || offer.get('isFake'));
    const style = this.getStyle();


    const agreementEndDate = formatDate(offer.get('agreementEndDate'));
    const annualPercentageRate = numberToPercentage(offer.get('annualPercentageRate'));
    const interestRate = numberToPercentage(offer.get('interestRate'));
    const totalRepayableAmount = preciseNumberToCurrency(offer.get('totalRepayableAmount'));

    const currentTotalRepayableAmount = preciseNumberToCurrency(loan.get('schedulePayment') * loan.getIn(['loanTerm', 'value']));

    const tinValue = homePage ? `${msg('calculator.offer.tin')}` : interestRate;
    const taeValue = homePage ? `${msg('calculator.offer.tae')}` : annualPercentageRate;


    return (
      <div style={style.wrapper}>
        {hasAgreementEndDate && <div>
          {`${msg('calculator.offer.agreementEndDate')} `}
          <ValueLoader id="agreement-end-date" value={agreementEndDate} isLoading={isLoading} />
        </div>}
        {hasInterestRateAndAnnualPercentageRate && <div>
          {`${msg('calculator.offer.interestRate')} `}
          <ValueLoader id="interest-rate" value={tinValue} isLoading={isLoading} />{', '}
          {`${msg('calculator.offer.annualPercentageRate')} `}
          <ValueLoader id="annual-percentage-rate" value={taeValue} isLoading={isLoading} />
        </div>}
        {(hasTotalRepayableAmount && !homePage) && <div>
          {`${msg('calculator.offer.totalRepayableAmount')} `}
          <ValueLoader id="total-repayable-amount" value={totalRepayableAmount} isLoading={isLoading} />
        </div>}
        {(hasCurrentTotalRepayableAmount && !homePage) && <div>
          {`${msg('calculator.offer.totalRepayableAmount')} `}
          <ValueLoader id="current-total-repayable-amount" value={currentTotalRepayableAmount} isLoading={isLoading} />
        </div>}
      </div>
    );
  }

  static style = {
    wrapper: {
      marginTop: '10px',
      fontSize: '15px',
      lineHeight: '18px',
      opacity: '0.56',
    },
  };
}
