import Jumbotron from '../components/Jumbotron';
import listenWindowResize, { Device } from '../lib/listenWindowResize.react';
import PageContainer from '../components/PageContainer.react';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import RandomFill from '../components/RandomFill.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { OTHER_OPTION } from '../components/BankRadioInputGroup.react';
import { StyledComponent } from '4finance-components';
import { connectActions, connectPrefetch } from '../connect';
import { Form } from '4finance-onion-form';

import {
  Bank,
  Submit,
} from './fields';

const FORM_NAME = 'chooseBank';

@connectPrefetch([
  ['api', 'fetchClientApplication'],
])
@connectActions({
  randomFill: ['randomFill', 'applyRandomFill'],
  submitBank: ['application', 'submitBank'],
})
@translate
@listenWindowResize
@Radium
export default class ChooseBank extends StyledComponent {
  static styleRoot = 'ChooseBank'

  static propTypes = {
    device: RPT.instanceOf(Device).isRequired,
    cnt: RPT.func,
    msg: RPT.func,
    submitBank: RPT.func.isRequired,
    randomFill: RPT.func.isRequired,
  }

  onSubmit = ({ values: { bank } }) => {
    const { submitBank } = this.props;
    const bankValue = bank === OTHER_OPTION ? '' : bank;

    submitBank(bankValue); // eslint-disable-line no-unused-expressions
  }

  addFormRef = ref => { this.form = ref; };

  submitRandomFill = () => this.form && this.form.submit(); // eslint-disable-line no-underscore-dangle

  randomFill = () => {
    const { randomFill } = this.props;

    randomFill(FORM_NAME);
  }

  render() {
    const { cnt, device, msg, pending } = this.props;
    const style = this.getStyle();

    const isScreenWidthUnderPhone = device.atMost('phone');

    return (
      <PageContainer width="wide">
        <PageHeader
          title={msg('registration.chooseBank.title')}
          subtitle={cnt('registration.chooseBank.subtitle')}
        />

        <Jumbotron
          inheritedStyle={style.container}
        >
          <Form
            ref={this.addFormRef}
            name={FORM_NAME}
            onSubmit={this.onSubmit}
          >
            <div style={style.form}>
              <Bank
                isPhone={isScreenWidthUnderPhone}
                onSubmit={this.onSubmit}
              />
            </div>
            {!isScreenWidthUnderPhone &&
              <div>
                <Submit
                  disabled={pending}
                  loading={pending}
                  loadingText={msg('common.loading')}
                  inheritedStyle={style.button}
                >
                  {msg('common.continue')}
                </Submit>
              </div>
            }
          </Form>
        </Jumbotron>

        <RandomFill
          pending={pending}
          submit={this.submitRandomFill}
          randomFill={this.randomFill}
          style={style.randomFill}
        />
      </PageContainer>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      margin: '0 -15px',

      [mediaQueries.breakpointSmall]: {
        backgroundColor: 'inherit',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },

    form: {
      backgroundColor: colors.white,

      [mediaQueries.breakpointSmall]: {
        marginBottom: 0,
      },
    },

    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '25px',
      marginBototm: 0,

      [mediaQueries.breakpointSmall]: {
        width: '90%',
      },
      [mediaQueries.breakpointTablet]: {
        minWidth: '300px',
      },
    },

    link: {
      display: 'block',
      textAlign: 'center',
      textDecoration: 'underline',
      fontSize: '18px',
      marginTop: '-10px',
      cursor: 'pointer',
    },

    randomFill: {
      container: {
        display: 'block',
        textAlign: 'center',
        marginBottom: '0',
        [mediaQueries.breakpointSmall]: {
          padding: '5%',
        },
      },
    },
  });
}
