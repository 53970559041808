import { actions as api } from '../api';
import * as actions from './actions';
import setFormApiError from '../lib/setFormApiError';

export const setResendPhoneCountdown = (countdown) => ({
  type: actions.SET_PHONE_CHANGE_COUNTDOWN,
  payload: countdown,
});

export const setPhoneRateLimitCountdown = (countdown) => ({
  type: actions.SET_PHONE_RATE_COUNTDOWN,
  payload: countdown,
});

export const setPhoneChangeDone = () => ({
  type: actions.PROCESS_SET_PHONE_CHANGE_DONE,
});

export const submitPhoneChange = (phone) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.sendClientPhone({ mobilePhone: phone }));

    if (error) {
      dispatch(setFormApiError('changePhone', payload));
      throw Error('Change phone failed');
    }

    dispatch(setResendPhoneCountdown(actions.setTimeCountdown()));
  };

  return {
    type: actions.PROCESS_SUBMIT_PHONE_CHANGE,
    payload: getPromise(),
  };
};

export const resendPhoneVerification = (phone) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.sendClientPhone({ mobilePhone: phone }));

    if (error) {
      dispatch(setFormApiError('confirmPhone', payload));
      throw Error('Resend phone verification code failed');
    }

    dispatch(setResendPhoneCountdown(actions.setTimeCountdown()));
  };

  return {
    type: actions.PROCESS_RESEND_PHONE_VERIFICATION,
    payload: getPromise(),
  };
};


export const submitPhoneVerification = (verificationCode) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.patchClientPhoneConfirmation({ verificationCode }));

    if (error) {
      dispatch(setFormApiError('confirmPhone', payload));
      throw Error('Submit phone verification code failed');
    }

    dispatch(api.fetchClientPhone());

    const { payload: { rateLimitResetTime } } = await dispatch(api.fetchClientPhoneRateLimitStatus());

    dispatch(setPhoneRateLimitCountdown(actions.setTimeCountdown(rateLimitResetTime)));
  };

  return {
    type: actions.PROCESS_SUBMIT_PHONE_VERIFICATION,
    payload: getPromise(),
  };
};
