import React from 'react';
import RPT from 'prop-types';
import { StyledComponent, Heading } from '4finance-components';

export default class ModalHeading extends StyledComponent {
  static styleRoot = 'ModalHeading'

  static propTypes = {
    children: RPT.node.isRequired,
  }

  render() {
    const { children } = this.props;
    const style = this.getStyle();

    return <Heading kind="h3" inheritedStyle={style.heading}>{children}</Heading>;
  }

  static style = ({ font, colors }) => ({
    heading: {
      color: colors.darkFont,
      fontSize: '26px',
      fontWeight: font.weight.bold,
      lineHeight: '28px',
      marginTop: '25px',
      marginLeft: 0,
      marginRight: 0,
      marginBottom: '35px',
      textAlign: 'center',
    },
  });
}
