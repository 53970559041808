import { Record } from 'immutable';
import * as actions from './actions';

const InitialState = Record({
  onSuccessUrl: null,
});
const initialState = new InitialState;

export default function loanReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case actions.SET_REDIRECT_URL_ON_SUCCESS: {
      const { onSuccessUrl } = action.payload;

      return state
        .set('onSuccessUrl', onSuccessUrl);
    }
  }

  return state;
}
