import CardInfo from '../../components/CardInfo.react.js';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import routesList from '../../routesList';
import { Link, withRouter } from 'react-router';
import { connectState, connectActions, connectSelectors } from '../../connect';
import { GrayBox as JumbotronGrayBox } from '../../components/Jumbotron';
import { StyledComponent, Button } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  payment: ['api', 'send', 'paymentGateway', 'reissuePaymentWithStoredData', ({}).toString(), 'mymoid'],
  activeCard: ['client', 'activeCard'],
  isPaymentProcessingLoan: ['payment', 'isPaymentProcessing'],
  nextPayment: ['loans', 'nextPayment'],
})
@connectActions({
  makePayment: ['payment', 'makePayment'],
  toggleAutoRepaymentsForLatestLoan: ['settings', 'toggleAutoRepaymentsForLatestLoan'],
  setMoidUrlOnSuccess: ['mymoid', 'setUrlOnSuccess'],
  updatePath: ['routing', 'updatePath'],
})
@connectSelectors({
  cardExpired: ['client', 'cardExpired'],
  autoRepayment: ['client', 'getAutoRepaymentStatus'],
})
@translate
@withRouter
@Radium
export default class PaymentCard extends StyledComponent {
  static styleRoot = 'PaymentCard';

  static propTypes = {
    cards: RPT.object,
    cnt: RPT.func,
    isPaymentProcessingLoan: RPT.bool,
    loan: RPT.object,
    payment: RPT.object,
    type: RPT.oneOf(['repayment', 'extension', 'autoRepayment', 'applicationCardConfirm']),
    description: RPT.string,
    toggleAutoRepaymentsForLatestLoan: RPT.func,
    cardExpired: RPT.bool,
  }

  static defaultProps = {
    loan: new Map(),
    type: 'repayment',
  }

  makePayment = (card) => {
    const { makePayment, amount } = this.props;

    makePayment(amount, card.uuid);
  }

  enableAutoRepaymentsForLatestLoan = () => {
    const { router, cardExpired, toggleAutoRepaymentsForLatestLoan } = this.props;

    if (cardExpired) {
      router.push(routesList.cardExpired);
    } else {
      toggleAutoRepaymentsForLatestLoan(true);
      router.push(routesList.autoRepaymentEnabled);
    }
  }

  enableAutoRepaymentsAndRedirect = () => {
    const { router, toggleAutoRepaymentsForLatestLoan } = this.props;

    toggleAutoRepaymentsForLatestLoan(true);
    router.push(routesList.approvalCompleted);
  }

  handleAddCardButton = () => {
    const { router, setMoidUrlOnSuccess, location: { pathname } } = this.props;

    setMoidUrlOnSuccess(pathname);
    router.push({
      pathname: routesList.settingsCardAdd,
      state: {
        fromPaymentPage: true,
      },
    });
  }

  skipAutoRepayment = () => {
    const { updatePath, toggleAutoRepaymentsForLatestLoan } = this.props;

    toggleAutoRepaymentsForLatestLoan(false);

    updatePath(routesList.approvalCompleted);
  }

  renderMainButton() {
    const { cnt, msg, isPaymentProcessingLoan, type, activeCard, autoRepayment } = this.props;
    const payment = this.props.payment && this.props.payment.toJS();
    const canMakePayment = !payment || !payment.data || payment.data.status !== 'OK';
    const isPaymentProcessing = payment && payment.pending;
    const disableButtons = !canMakePayment || isPaymentProcessing;

    const payButton = (
      <Button disabled={disableButtons || isPaymentProcessingLoan} kind="primary" onClick={() => this.makePayment(activeCard)}>
        {msg('payments.card.action.pay')}
      </Button>
    );

    const addCard = (
      <Button kind="primary" onClick={this.handleAddCardButton}>
        {msg('payments.card.action.addCard')}
      </Button>
    );

    const autoRepaymentButton = !autoRepayment && (
      <Button kind="primary" onClick={this.enableAutoRepaymentsForLatestLoan}>
        {cnt('payments.card.action.autoRepayment.disabled')}
      </Button>
    );

    const applicationCardConfirm = (
      <Button kind="primary" onClick={this.enableAutoRepaymentsAndRedirect}>
        {cnt('payments.card.action.autoRepayment.disabled')}
      </Button>
    );

    if (activeCard) {
      if (type === 'autoRepayment') {
        return autoRepaymentButton;
      }

      if (type === 'applicationCardConfirm') {
        return applicationCardConfirm;
      }

      return payButton;
    }

    return addCard;
  }

  render() {
    const { cnt, msg, loan, type, description, autoRepayment } = this.props;
    const contractNumber = loan.get('loanNumber');
    const style = this.getStyle();

    return (
      <div>
        <p style={style.description}>
          {description || cnt(`payments.card.description.${type}`, { contractNumber })}
        </p>

        <JumbotronGrayBox hasPadding textAlign="left" inheritedStyle={style.jumbotron}>
          <p style={style.title}>{msg('payments.card.yourCard')}</p>
          <CardInfo
            noWrapper
            noDetails={type === 'autoRepayment' || type === 'applicationCardConfirm' || autoRepayment}
            addCardHandle={this.handleAddCardButton}
          />
        </JumbotronGrayBox>

        <div style={style.actions}>
          {this.renderMainButton()}
          {type === 'applicationCardConfirm' &&
            <div style={style.skip}>
              <Link onClick={this.skipAutoRepayment}>
                {msg('common.skip')}
              </Link>
            </div>
          }
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    jumbotron: {
      [mediaQueries.breakpointTablet]: {
        backgroundColor: colors.white,
        padding: '15px',
      },
    },

    description: {
      margin: '25px 0 35px',
      fontSize: '14px',
      lineHeight: '20px',

      [mediaQueries.breakpointTablet]: {
        margin: '25px 0 0',
      },
    },

    title: {
      fontSize: '18px',
      fontWeight: 600,
      marginLeft: '20px',

      [mediaQueries.breakpointTablet]: {
        marginLeft: 0,
        marginBottom: '10px',
      },
    },

    actions: {
      textAlign: 'center',
      margin: '20px 0',
    },

    skip: {
      marginTop: '20px',
      cursor: 'pointer',
    },
  });
}
