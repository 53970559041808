import PaymentCard from '../payment/components/PaymentCard.react';
import PaymentWrapper from '../payment/components/PaymentWrapper.react';
import React from 'react';
import translate from '4finance-translate';
import { connectPrefetch } from '../connect';
import { StyledComponent } from '4finance-components';

@connectPrefetch([
  ['payment', 'prefetchPaymentDetails'],
  ['api', 'fetchClientCardsActive'],
])
@translate
export default class CardConfirm extends StyledComponent {
  static styleRoot = 'CardConfirm';

  render() {
    const { msg } = this.props;

    return (
      <PaymentWrapper
        type="full"
        title={msg('payments.applicationCardConfirm.title')}
      >
        <PaymentCard
          type="applicationCardConfirm"
        />
      </PaymentWrapper>
    );
  }

  static style = {
    heading: {
      width: '60%',
      lineHeight: '1.8em',
    },
  };
}
