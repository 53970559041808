import Icon from '../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { StyledComponent, Container, Heading, Row, Column } from '4finance-components';
import { connectActions } from '../connect';

const CONTENT_ITEMS = [
  'hp-time',
  'hp-seguro',
  'hp-sencilo',
];

@connectActions({
  showZaploClosingPopup: ['settings', 'showZaploClosingPopup'],
})
@translate
@Radium
export default class HowItWorks extends StyledComponent {
  static styleRoot = 'HowItWorks'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    showZaploClosingPopup: RPT.func.isRequired,
  }

  @autobind
  renderItem(name, key) {
    const { msg, cnt, showZaploClosingPopup } = this.props;
    const style = this.getStyle();
    const isLastItem = key === CONTENT_ITEMS.length - 1;

    return (
      <Column
        phone={12}
        tablet={12}
        small={4}
        medium={4}
        large={4}
        style={[style.item, !isLastItem && style.item.borderStyle]}
        key={name}
      >
        <Heading inheritedStyle={style.item.heading} noMargin bold kind="h3">
          {msg(`home.how_it_works.content_items.${key + 1}.title`)}
        </Heading>
        <Icon
          kind={name}
          style={style.item.icon}
          size={82}
        />
        <p style={style.item.text}>
          {key === 0 && <span style={style.contentLink} onClick={showZaploClosingPopup}>{cnt(`home.how_it_works.content_items.${key + 1}.contentLink`)} </span>}
          {cnt(`home.how_it_works.content_items.${key + 1}.content`)}
        </p>
      </Column>
    );
  }

  render() {
    const { cnt } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper} id="howItWorks">
        <Container style={style.container}>
          <Heading inheritedStyle={style.heading} noMargin bold kind="h2">
            {cnt('home.how_it_works.title')}
          </Heading>
          <Row inheritedStyle={style.itemsWrapper}>
            {CONTENT_ITEMS.map(this.renderItem)}
          </Row>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      [mediaQueries.breakpointTablet]: {
        marginLeft: '-20px',
        marginRight: '-20px',
      },
    },

    wrapper: {
      backgroundColor: colors.lighterBackground,
      padding: '64px 0',
      textAlign: 'center',
      [mediaQueries.breakpointTablet]: {
        padding: '30px 30px',
      },
    },

    heading: {
      color: colors.darkFont,
    },

    contentLink: {
      cursor: 'pointer',
    },

    itemsWrapper: {
      marginTop: '100px',
      display: 'flex',
      alignItems: 'center',

      [mediaQueries.breakpointTablet]: {
        marginTop: '0px',
        flexDirection: 'column',
      },
    },

    item: {
      [mediaQueries.breakpointTablet]: {
        borderRight: 'none',
        marginTop: '50px',
      },

      borderStyle: {
        borderRight: '1px solid',
        borderRightColor: colors.grayLighter,
        [mediaQueries.breakpointTablet]: {
          borderRight: '0px',
        },
      },

      text: {
        fontWeight: 200,
        marginTop: '44px',
        fontSize: '17px',
        color: colors.black,

        [mediaQueries.breakpointTablet]: {
          marginTop: '20px',
        },
      },

      icon: {
        marginTop: '33px',
      },
    },
  })
}
