export const routes = {
  base: '/',
  newApplication: '/new/account',
  newApplicationConfirm: '/new/application/confirm',
  accountAboutYou: '/account/about-you',
  accountBankProvider: '/account/bank-provider',
  accountOtherDetails: '/account/other-details',
  accountRegistration: '/account/registration',
  additionalAmount: '/additional-amount',
  application: '/application',
  applicationBankAccount: '/application/bank-account',
  applicationCard: '/application/card',
  applicationCardAdd: '/application/card/add',
  applicationCardAddOptional: '/application/card/add(/:variant)',
  applicationCardConfirm: '/application/card/confirm',
  applicationCardProcessing: '/application/card/processing',
  applicationCardProcessingCompleted: '/application/card/processing/completed',
  applicationCardProcessingOptional: '/application/card/processing(/:completed)',
  applicationCardProcessingAutoRepayment: '/application/card/processing/auto-repayment',
  applicationConfirm: '/application/confirm',
  applicationIdentification: '/application/identification',
  applicationIdentificationDocumentUpload: '/application/identification/document-upload',
  applicationIdentificationEmailOrWhatsapp: '/application/identification/email-or-whatsapp',
  applicationIdentificationInstantor: '/application/identification/instantor',
  applicationIdentificationInstantorBank: '/application/identification/instantor/bank',
  applicationIdentificationInstantorConsent: '/application/identification/instantor-consent',
  applicationIdentificationProcessing: '/application/identification/processing',
  applicationIdentificationProcessingInstantor: '/application/identification/processing/instantor',
  applicationIdentificationProcessingOptional: '/application/identification/processing(/:instantor)',
  applicationInstantorError: '/application/identification/instantor-error',
  applicationInstantorErrorOptional: '/application/identification/instantor-error(/:error)',
  applicationInstantorOptional: '/application/identification/instantor(/:testdouble)',
  approval: '/approval',
  approvalCompleted: '/approval/completed',
  approvalDocumentUpload: '/approval/document-upload',
  approvalDocumentUploadWay: '/approval/document-upload-way',
  approvalDocumentUploadWayProposal: '/approval/document-upload-way-proposal',
  approvalEmailOrWhatsapp: '/approval/email-or-whatsapp',
  approvalPreApproved: '/approval/pre-approved',
  approvalProcessing: '/approval/processing',
  approvalProposal: '/approval/proposal',
  approvalRejected: '/approval/rejected',
  archive: '/archivo',
  autoRepayment: '/auto-repayment',
  cardExpired: '/auto-repayment/card-expired',
  autoRepaymentEnabled: '/auto-repayment/enabled',
  blog: '/blog/',
  callback: '/callback/:type',
  contentAboutUs: '/prestamos-rapidos-online',
  contentAgreement: '/condiciones-prestamo',
  contentCookies: '/politica-cookies',
  contentFaq: '/faqs',
  contentFeatures: '/como-solicitar-prestamos',
  contentPrivacyPolicy: '/politica-privacidad',
  dashboard: '/dashboard',
  extension: '/extension',
  homepage: '/',
  identificationApproveApplication: '/dashboard/approve-application',
  passwordChange: '/password-change',
  passwordRecovery: '/password-recovery',
  passwordRecoveryOptional: '/password-recovery(/:email)',
  passwordReset: '/password-reset',
  passwordResetOptional: '/password-reset(/:email)',
  payment: '/payment',
  paymentExtension: '/payment/extension',
  paymentFull: '/payment/full',
  processing: '/processing',
  registrationUnfinished: '/registration/unfinished',
  settings: '/settings',
  settingsCard: '/settings/card',
  settingsCardAdd: '/settings/card/add',
  settingsCardAddOptional: '/settings/card/add(/:variant)',
  settingsCardProcessing: '/settings/card/processing',
  settingsCardProcessingCompleted: '/settings/card/processing/completed',
  settingsCardProcessingOptional: '/settings/card/processing(/:status)',
  settingsCardRefresh: '/settings/card/refresh',
  signin: '/signin',
  signinOptional: '/signin(/:option)',
  signinUnauthorized: '/signin/unauthorized',
  signout: '/signout',
  signoutUnauthorized: '/signout/unauthorized',
  sitemap: '/sitemap',

  // static pages
  applyForPersonalLoans: '/solicitar-prestamos-personales-zaplo',
  contentLegal: '/legal',
  contentOffers: '/ofertas',
  contentContact: '/contacto',
  contentPreliminary: '/preliminary',
  fastPersonalLoansOnline: '/prestamos-personales-online-rapidos',
  onlinePaydayLoans: '/prestamos-online-plazos',
  urgentOnlinePaydayLoans: '/prestamos-urgentes-online',
};

export function getPath(name, options) {
  if (!options) { return routes[name]; }

  return Object.keys(options).reduce((prev, curr) =>
    prev.replace(`:${curr}`, options[curr]),
  routes[name]
  );
}

export const FUNNEL_ROUTES = [
  routes.applicationIdentification,
  routes.applicationIdentificationEmailOrWhatsapp,
  routes.applicationIdentificationDocumentUpload,
  routes.applicationIdentificationInstantorBank,
  routes.applicationIdentificationInstantorConsent,
  routes.applicationIdentificationInstantor,
  routes.applicationIdentificationProcessing,
  routes.applicationIdentificationProcessingOptional,
  routes.approvalDocumentUploadWay,
  routes.approvalEmailOrWhatsapp,
  routes.approvalRejected,
  routes.approvalPreApproved,
  routes.applicationInstantorErrorOptional,
  routes.approvalProposal,
  routes.applicationBankAccount,
  routes.applicationCardAddOptional,
  routes.applicationCardConfirm,
  routes.approvalCompleted,
  routes.approvalProcessing,
];

export const APPLICATION_PREFIXES = {
  NEW: '/new',
};

export const getNewApplicationUrl = (url) => `${APPLICATION_PREFIXES.NEW}${url}`;

export default routes;
