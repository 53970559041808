import * as paymentSelectors from '../payment/selectors';
import RPT from 'prop-types';
import { createSelector } from '4finance-connect';
import { employmentValueMapping } from '../../browser/registration/fields/data/employment';

const apiSelector = state => state.api;
const settingsSelector = state => state.settings;
const connectApiFetchState = state => state.api.fetch;
const identificationSelector = state => state.identification;

export const additionalAmountAvailable = createSelector(
  [apiSelector, paymentSelectors.paymentPending],
  (api, paymentPending) => {
    const additionalAmount = api.getIn(['fetch', 'client', 'query', 'data', 'additionalAmount']);

    return additionalAmount && !paymentPending;
  }
);

export const RPTdeserializedAddressSelector = RPT.shape({
  city: RPT.string,
  fullAddress: RPT.string,
  postalCode: RPT.string,
  province: RPT.string,
  roadType: RPT.string,
  streetAppartment: RPT.string,
  streetFloor: RPT.string,
  streetName: RPT.string,
}).isRequired;

export const deserializedAddressSelector = createSelector(
  [connectApiFetchState],
  (api) => {
    const addressData = api.getIn(['client', 'query', 'data', 'actualAddress']);

    const [streetFloor, streetAppartment] = addressData.get('location5').split(', ');
    const [roadType, ...streetName] = addressData.get('location3').split(' ');

    return {
      city: addressData.get('location2'),
      fullAddress: addressData.get('fullAddress'),
      postalCode: addressData.get('postalCode'),
      province: addressData.get('location1'),
      roadType,
      streetAppartment: streetAppartment ? streetAppartment.split(': ')[1] : '',
      streetFloor: streetFloor ? streetFloor.split(': ')[1] : '',
      streetName: streetName ? streetName.join(' ') : '',
      streetNumber: addressData.get('location4'),
    };
  }
);

export const isClientValidated = createSelector(
  [apiSelector],
  (api) => {
    const status = api.getIn(['fetch', 'client', 'query', 'data', 'status']);

    return status === 'VALIDATED';
  }
);

export const isFintonicOrPrestaloProduct = createSelector(
  [apiSelector],
  (api) => {
    const clientInfo = api.getIn(['fetch', 'client', 'query']);
    const productNumber = clientInfo.get('fetched') ? clientInfo.getIn(['data', 'productNumber']) : 0;

    return ['3', '4', '5', '6', '8', '9'].includes(productNumber);
  }
);

export const isPrestaloProduct = createSelector(
  [apiSelector],
  (api) => {
    const clientInfo = api.getIn(['fetch', 'client', 'query']);
    const productNumber = clientInfo.get('fetched') ? clientInfo.getIn(['data', 'productNumber']) : 0;

    return productNumber === '8';
  }
);

export const hasBankAccountNumber = createSelector(
  [apiSelector],
  (api) => {
    const bankAccountNumber = api.getIn(['fetch', 'client', 'bankAccountNumber', 'query', 'data', 'bankAccountNumber']);

    return Boolean(bankAccountNumber);
  }
);

export const hasCardNumber = createSelector(
  [apiSelector],
  (api) => !api.getIn(['fetch', 'client', 'cards', 'active', 'query', 'errors'])
);

export const hasIdUploaded = createSelector(
  [apiSelector],
  (api) => {
    const attachmentsList = api.getIn(['fetch', 'client', 'attachments', 'query', 'data', 'attachments']);
    const isNewClient = api.getIn(['fetch', 'client', 'query', 'data', 'newClient']);
    const attachments = attachmentsList ? attachmentsList.toJS() : [];
    const idFiles = attachments.filter(((file) => file.type === 'backSideDni' || file.type === 'frontSideDni'));

    return isNewClient ? !!idFiles.length : true;
  }
);

export const applicationPending = createSelector(
  [apiSelector],
  (api) => {
    const application = api.getIn(['fetch', 'client', 'application', 'query', 'data']);

    return application && application.get('status') === 'OPEN' && application.get('resolution') && application.get('resolution') !== 'REJECTED';
  }
);

export const hasProposal = createSelector(
  [apiSelector],
  (api) => {
    const applicationData = api.getIn(['fetch', 'client', 'application', 'query', 'data']);
    const proposal = api.getIn(['fetch', 'client', 'application', 'proposal', 'query', 'data']);

    return applicationData && applicationData.get('resolution') === 'REJECTED' && applicationData.get('resolutionDetail') === 'SOLVENCY' && proposal && !applicationData.get('expired');
  }
);

export const isClientBlacklisted = createSelector(
  [apiSelector],
  (api) => api.getIn(['fetch', 'client', 'query', 'data', 'validationResolutionDetail']) === 'HAS_RECENT_BLACKLISTED_APPLICATION'
);

export const hadLoans = createSelector(
  [apiSelector],
  (api) => {
    const clientInfo = api.getIn(['fetch', 'client', 'query']);
    const loans = api.getIn(['fetch', 'client', 'loans', 'query', 'data']);

    if (clientInfo.get('fetched') && loans) {
      return loans.get('clientLoanCount') > 0 && !loans.getIn(['_links', 'latestLoan']);
    }

    return false;
  }
);

export const clientEmploymentKey = createSelector(
  [apiSelector],
  (api) => {
    const employment = api.getIn(['fetch', 'client', 'employer', 'query', 'data', 'employmentStatus']);

    const employmentKey = Object
      .keys(employmentValueMapping)
      .filter(key => employmentValueMapping[key] === employment)[0];

    return employmentKey;
  }
);

export const showUploadLink = createSelector(
  [identificationSelector, apiSelector],
  (identification, api) => {
    const clientInfo = api.getIn(['fetch', 'client', 'query']);
    const riskRuleResults = identification.getIn(['riskRuleResults', 'upload']);

    return clientInfo.get('fetched') && clientInfo.getIn(['data', 'identifiedBy']) !== 'FINTONIC' && riskRuleResults && riskRuleResults.size > 0;
  }
);

export const showMarketingConsent = createSelector(
  [apiSelector, settingsSelector],
  (api, settings) => {
    const { fetched, data } = api.getIn(['fetch', 'client', 'settings', 'query']);
    const acceptNews = fetched ? data.get('acceptNews') : true;
    const acceptNewsGrantedDate = fetched ? data.get('acceptNewsGrantedDate') : new Date();
    const dismissConsentPopup = settings.changeConsentOptions.get('dismissConsentPopup');

    return !dismissConsentPopup
      && !acceptNews
      && !acceptNewsGrantedDate;
  }
);

export const getAutoRepaymentStatus = createSelector(
  [apiSelector],
  (api) => {
    const latestLoanPaymentPreference = api.getIn(['fetch', 'client', 'loans', 'latest', 'data', 'autoRepay']);
    const paymentPreference = api.getIn(['fetch', 'client', 'paymentPreferences', 'query', 'data', 'autoRepay']);

    return (latestLoanPaymentPreference === undefined) ? paymentPreference : latestLoanPaymentPreference;
  }
);

export const cardExpired = createSelector(
  [apiSelector],
  (api) => {
    const activeCard = api.getIn(['fetch', 'client', 'cards', 'active', 'query', 'data']);

    return activeCard && new Date(activeCard.get('expiryDate')) < new Date();
  }
);

export const unfinishedApplication = createSelector(
  [apiSelector, applicationPending, hasBankAccountNumber, hasCardNumber, cardExpired, hasIdUploaded],
  (api, isApplicationPending, hasBankAccount, hasCard, isCardExpired, uploadedID) => isApplicationPending && (!hasBankAccount || !hasCard || isCardExpired || !uploadedID)
);
