/* eslint-disable no-unused-expressions */
import { actions as api } from '../api';
import { fetchLatestLoan } from '../loans/actions';
import { setEmailRateLimitCountdown } from './changeEmail.actions';
import { setPhoneRateLimitCountdown } from './changePhone.actions';

export const PROCESS_FETCH_SETTINGS = 'PROCESS_FETCH_SETTINGS';

export const PROCESS_RESEND_EMAIL_VERIFICATION = 'PROCESS_RESEND_EMAIL_VERIFICATION';
export const PROCESS_SET_EMAIL_CHANGE_DONE = 'PROCESS_SET_EMAIL_CHANGE_DONE';
export const PROCESS_SUBMIT_EMAIL_CHANGE = 'PROCESS_SUBMIT_EMAIL_CHANGE';
export const PROCESS_SUBMIT_EMAIL_VERIFICATION = 'PROCESS_SUBMIT_EMAIL_VERIFICATION';
export const SET_EMAIL_CHANGE_COUNTDOWN = 'SET_RESEND_EMAIL_COUNTDOWN';
export const SET_EMAIL_RATE_COUNTDOWN = 'SET_EMAIL_RATE_COUNTDOWN';

export const PROCESS_RESEND_PHONE_VERIFICATION = 'PROCESS_RESEND_PHONE_VERIFICATION';
export const PROCESS_SET_PHONE_CHANGE_DONE = 'PROCESS_SET_PHONE_CHANGE_DONE';
export const PROCESS_SUBMIT_PHONE_CHANGE = 'PROCESS_SUBMIT_PHONE_CHANGE';
export const PROCESS_SUBMIT_PHONE_VERIFICATION = 'PROCESS_SUBMIT_PHONE_VERIFICATION';
export const SET_PHONE_CHANGE_COUNTDOWN = 'SET_PHONE_CHANGE_COUNTDOWN';
export const SET_PHONE_RATE_COUNTDOWN = 'SET_PHONE_RATE_COUNTDOWN';

export const PROCESS_SET_PASSWORD_CHANGE_DONE = 'PROCESS_SET_PASSWORD_CHANGE_DONE';
export const PROCESS_SUBMIT_PASSWORD_CHANGE = 'PROCESS_SUBMIT_PASSWORD_CHANGE';

export const PROCESS_TOGGLE_CONSENTS_OPTIONS_CHANGE = 'PROCESS_TOGGLE_CONSENTS_OPTIONS_CHANGE';
export const UPDATE_CLIENT_CONSENTS = 'UPDATE_CLIENT_CONSENTS';
export const UPDATE_ACCEPT_NEWS_CONSENT = 'UPDATE_ACCEPT_NEWS_CONSENT';
export const DISMISS_CONSENT_POPUP = 'DISMISS_CONSENT_POPUP';
export const TOGGLE_CLOSING_POPUP = 'TOGGLE_CLOSING_POPUP';

const COUNTDOWN_TIME = 60;

export const setTimeCountdown = (time = COUNTDOWN_TIME) => new Date(new Date().getTime() + (parseInt(time, 0) * 1000));

export {
  resendEmailVerification,
  setEmailChangeDone,
  setResendEmailCountdown,
  submitEmailChange,
  submitEmailVerification,
} from './changeEmail.actions';

export {
  resendPhoneVerification,
  setPhoneChangeDone,
  setResendPhoneCountdown,
  submitPhoneChange,
  submitPhoneVerification,
} from './changePhone.actions';

export {
  setPasswordChangeDone,
  submitPasswordChange,
} from './changePassword.actions';

export {
  toggleConsentsOptions,
  updateSettings,
  updateAcceptNewsConsent,
  dismissConsentPopup,
} from './changeConsentOptions.actions';

export const dismissZaploClosingPopup = () => ({
  type: TOGGLE_CLOSING_POPUP,
  payload: true,
});

export const showZaploClosingPopup = () => ({
  type: TOGGLE_CLOSING_POPUP,
  payload: false,
});

export const toggleAutoRepaymentsForLatestLoan = (isEnable) => ({ dispatch, getApiResponse }) => {
  async function getPromise() {
    await dispatch(isEnable
      ? api.patchClientPaymentPreferencesAutoRepay({ autoRepay: true })
      : api.destroyClientPaymentPreferencesAutoRepay()
    );
    await getApiResponse(['fetch', 'client', 'paymentPreferences'], { forceFetch: true });
    await dispatch(fetchLatestLoan());
  }

  return {
    type: 'TOGGLE_AUTO_REPAYMENTS',
    payload: getPromise(),
  };
};

export const fetchRateLimitStatus = () => ({ dispatch }) => {
  async function getPromise() {
    const { payload: { rateLimitResetTime: rateLimitEmail } } = await dispatch(api.fetchClientEmailRateLimitStatus());
    const { payload: { rateLimitResetTime: rateLimitPhone } } = await dispatch(api.fetchClientPhoneRateLimitStatus());

    dispatch(setEmailRateLimitCountdown(setTimeCountdown(rateLimitEmail)));
    dispatch(setPhoneRateLimitCountdown(setTimeCountdown(rateLimitPhone)));
  }

  return {
    type: 'FETCH_RATE_LIMIT_STATUS',
    payload: getPromise(),
  };
};

export const fetchSettings = () => ({ getApiResponse, dispatch }) => {
  async function getPromise() {
    await getApiResponse(['fetch', 'client']);
    await getApiResponse(['fetch', 'client', 'bankAccountNumber']);
    await getApiResponse(['fetch', 'client', 'cardsActive']);
    await getApiResponse(['fetch', 'client', 'email']);
    await getApiResponse(['fetch', 'client', 'phone']);
    await getApiResponse(['fetch', 'client', 'settings']);
    await getApiResponse(['fetch', 'client', 'paymentPreferences']);
    await dispatch(fetchRateLimitStatus());
    await dispatch(fetchLatestLoan());
  }

  return {
    type: PROCESS_FETCH_SETTINGS,
    payload: getPromise(),
  };
};
