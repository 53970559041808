/* eslint-disable max-len */
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

@Radium
export default class Logo extends StyledComponent {
  static styleRoot = 'Logo'

  static propTypes = {
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.array]),
    inverseColors: RPT.bool,
  }

  render() {
    const { inheritedStyle, inverseColors } = this.props;
    const style = this.getStyle();

    return (
      <svg
        style={[style.logo, inheritedStyle && inheritedStyle, inverseColors && style.inverse]}
        viewBox="0 0 1003.3 178.6"
      >
        <g>
          <g>
            <path
              style={[style.path.st0]}
              d="M117.3,115.4h-0.5c-2.6,4.1-5.9,7.1-9.9,8.9c-4,1.9-8.4,2.8-13.3,2.8c-3.3,0-6.5-0.5-9.7-1.4s-6-2.2-8.4-4.1
              c-2.5-1.8-4.4-4.1-5.9-6.8s-2.3-5.9-2.3-9.5c0-3.9,0.7-7.2,2.1-10s3.3-5.1,5.6-6.9c2.4-1.9,5.1-3.3,8.2-4.5
              c3.1-1.1,6.3-1.9,9.7-2.5c3.4-0.5,6.7-0.9,10.2-1.1c3.4-0.1,6.6-0.2,9.6-0.2h4.5v-2c0-4.5-1.6-7.9-4.7-10.2
              c-3.1-2.3-7.1-3.4-11.9-3.4c-3.8,0-7.4,0.7-10.7,2s-6.2,3.2-8.6,5.5l-9.5-9.5c4-4.1,8.7-7.1,14.1-8.9c5.4-1.8,10.9-2.7,16.6-2.7
              c5.1,0,9.4,0.6,12.9,1.7s6.4,2.6,8.7,4.4s4.1,3.9,5.3,6.2c1.3,2.4,2.2,4.8,2.7,7.2c0.6,2.4,0.9,4.7,1,7s0.2,4.3,0.2,6v41.5H117
              v-9.5H117.3z M116.2,93h-3.8c-2.5,0-5.3,0.1-8.4,0.3c-3.1,0.2-6,0.7-8.8,1.4c-2.8,0.8-5.1,1.9-7,3.3c-1.9,1.5-2.9,3.5-2.9,6.1
              c0,1.7,0.4,3.1,1.1,4.3c0.7,1.2,1.7,2.1,2.9,2.9c1.2,0.8,2.6,1.4,4.1,1.7s3,0.5,4.5,0.5c6.2,0,10.8-1.5,13.8-4.4
              c3-3,4.5-7,4.5-12.1L116.2,93L116.2,93z"
            />
            <path
              style={[style.path.st0]}
              d="M145.1,53.1h17.2v10.8h0.3c2.6-4.4,6.1-7.6,10.5-9.6s9.2-3,14.3-3c5.5,0,10.5,1,14.9,2.9
              c4.4,2,8.2,4.6,11.3,8c3.1,3.4,5.5,7.4,7.1,12.1c1.6,4.6,2.4,9.6,2.4,14.9s-0.8,10.3-2.6,14.9c-1.7,4.6-4.1,8.6-7.1,12
              c-3.1,3.4-6.7,6.1-11.1,8.1c-4.3,2-9,2.9-14.1,2.9c-3.4,0-6.4-0.4-9-1.1c-2.6-0.7-4.9-1.6-6.9-2.7c-2-1.1-3.7-2.3-5.1-3.5
              c-1.4-1.3-2.6-2.5-3.5-3.7h-0.4v43.3h-18.2V53.1z M205,89.2c0-2.8-0.5-5.5-1.4-8.1c-1-2.6-2.4-4.9-4.2-6.9c-1.8-2-4.1-3.6-6.7-4.8
              c-2.6-1.2-5.6-1.8-8.9-1.8c-3.3,0-6.3,0.6-8.9,1.8c-2.6,1.2-4.8,2.8-6.7,4.8s-3.3,4.3-4.2,6.9c-1,2.6-1.4,5.3-1.4,8.1
              s0.5,5.5,1.4,8.1c1,2.6,2.4,4.9,4.2,6.9c1.9,2,4.1,3.6,6.7,4.8s5.6,1.8,8.9,1.8s6.3-0.6,8.9-1.8s4.8-2.8,6.7-4.8s3.3-4.3,4.2-6.9
              C204.6,94.8,205,92.1,205,89.2"
            />
            <rect
              x="230.9"
              y="19"
              style={[style.path.st0]}
              width="18.1"
              height="106.4"
            />
            <path
              style={[style.path.st0]}
              d="M256.6,89.2c0-5.7,1-10.9,3.1-15.6s4.8-8.6,8.4-12c3.5-3.3,7.7-5.9,12.5-7.7c4.8-1.8,9.9-2.7,15.3-2.7
              s10.5,0.9,15.4,2.7c4.8,1.8,9,4.4,12.5,7.7c3.5,3.3,6.3,7.3,8.3,12c2.1,4.7,3.1,9.9,3.1,15.6s-1,10.9-3.1,15.6
              c-2.1,4.7-4.8,8.7-8.3,12s-7.7,5.9-12.5,7.7s-9.9,2.7-15.4,2.7c-5.4,0-10.5-0.9-15.3-2.7s-9-4.4-12.5-7.7s-6.3-7.3-8.4-12
              C257.7,100.2,256.6,95,256.6,89.2 M274.7,89.2c0,2.8,0.5,5.5,1.4,8.1s2.4,4.9,4.2,6.9c1.9,2,4.1,3.6,6.7,4.8s5.6,1.8,8.9,1.8
              s6.3-0.6,8.9-1.8s4.8-2.8,6.7-4.8c1.9-2,3.3-4.3,4.2-6.9c1-2.6,1.4-5.3,1.4-8.1s-0.5-5.5-1.4-8.1c-1-2.6-2.4-4.9-4.2-6.9
              c-1.9-2-4.1-3.6-6.7-4.8s-5.6-1.8-8.9-1.8s-6.3,0.6-8.9,1.8c-2.6,1.2-4.9,2.8-6.7,4.8c-1.9,2-3.3,4.3-4.2,6.9
              C275.2,83.7,274.7,86.4,274.7,89.2"
            />
            <path
              style={[style.path.st0]}
              d="M356.5,118.1c0,2.1-0.7,4-2.2,5.6c-1.5,1.7-3.5,2.5-5.9,2.5s-4.4-0.8-5.9-2.5s-2.3-3.5-2.3-5.6s0.7-4,2.3-5.6
              c1.5-1.7,3.5-2.5,5.9-2.5s4.4,0.8,5.9,2.5C355.8,114.2,356.5,116,356.5,118.1"
            />
            <polygon
              style={[style.path.st0]}
              points="0,108.7 38.7,67.9 38.7,67.6 1.8,67.6 1.8,53.1 60.5,53.1 60.5,69.5 21.3,110.9 62.3,110.9
              62.3,125.4 0,125.4"
            />
            <rect
              x="542.3"
              style={[style.path.st1]}
              width="371.9"
              height="19"
            />
            <rect
              x="542.3"
              y="26.6"
              style={[style.path.st2]}
              width="151.7"
              height="19"
            />
            <rect
              x="542.3"
              y="53.2"
              style={[style.path.st3]}
              width="399.3"
              height="19"
            />
            <rect
              x="542.3"
              y="79.8"
              style={[style.path.st0]}
              width="461"
              height="19"
            />
            <rect
              x="542.3"
              y="106.4"
              style={[style.path.st3]}
              width="199.6"
              height="19"
            />
            <rect
              x="542.3"
              y="133"
              style={[style.path.st2]}
              width="331.4"
              height="19"
            />
            <rect
              x="542.3"
              y="159.6"
              style={[style.path.st1]}
              width="258.8"
              height="19"
            />
          </g>
        </g>
        <g>
          <path
            style={[style.path.st0]}
            d="M379.7,91.9c0.3,3.6,1.2,7,2.6,10.2s3.3,6,5.7,8.3c2.4,2.3,5.1,4.1,8.2,5.5c3.1,1.3,6.4,2,10,2
            c5.5,0,10.2-1.3,14.1-3.8c4-2.5,7-5.4,9-8.7l7.9,6.5c-4.3,5.5-9.1,9.4-14.3,11.8c-5.2,2.4-10.8,3.6-16.8,3.6
            c-5.4,0-10.3-1-14.9-2.9s-8.5-4.6-11.8-8s-5.9-7.4-7.8-12.1c-1.9-4.6-2.9-9.7-2.9-15.2s0.9-10.5,2.8-15.2c1.9-4.6,4.4-8.7,7.7-12.1
            c3.3-3.4,7.2-6.1,11.6-8s9.2-2.9,14.4-2.9c5.5,0,10.4,1,14.7,2.9c4.3,2,8,4.6,10.9,7.8c2.9,3.2,5.2,7.1,6.7,11.4
            c1.5,4.4,2.3,9,2.3,14v4.6h-60.1V91.9z M428.9,83.6c0-6.9-2.1-12.5-6.2-16.8s-9.9-6.4-17.3-6.4c-3.3,0-6.5,0.6-9.6,1.9
            s-5.8,3-8.1,5.2c-2.3,2.2-4.2,4.6-5.6,7.4s-2.1,5.7-2.1,8.7H428.9z"
          />
          <path
            style={[style.path.st0]}
            d="M489.8,70.1c-1.5-3-3.6-5.4-6.2-7.1c-2.6-1.8-5.7-2.6-9.4-2.6c-1.8,0-3.5,0.2-5.3,0.6s-3.4,1.1-4.9,1.9
            c-1.4,0.9-2.6,2-3.5,3.3s-1.3,3-1.3,4.9c0,3.4,1.2,5.9,3.6,7.4c2.4,1.5,5.9,2.9,10.7,4l10.4,2.5c5,1.1,9.3,3.4,12.6,6.9
            s5,7.8,5,12.9c0,3.9-0.8,7.3-2.4,10.1c-1.6,2.8-3.7,5.2-6.3,7s-5.6,3.1-9,3.9s-6.9,1.2-10.4,1.2c-5.6,0-10.7-1.1-15.5-3.2
            c-4.8-2.1-8.9-5.7-12.3-10.9l8.8-6c2.1,3.3,4.7,5.9,7.8,7.9s6.9,2.9,11.2,2.9c2.1,0,4.1-0.2,6.2-0.7c2.1-0.5,3.9-1.2,5.5-2.2
            s2.9-2.2,3.9-3.8c1-1.5,1.5-3.3,1.5-5.4c0-3.6-1.3-6.2-4-7.8s-5.9-2.9-9.7-3.8l-9.9-2.3c-1.2-0.3-2.9-0.8-5-1.5s-4.2-1.8-6.2-3.2
            s-3.7-3.3-5.2-5.6c-1.4-2.3-2.2-5.2-2.2-8.6c0-3.7,0.7-7,2.2-9.7c1.5-2.8,3.5-5,6-6.8s5.4-3.1,8.6-3.9c3.2-0.9,6.5-1.3,9.9-1.3
            c5,0,9.7,1,14.1,2.9c4.3,2,7.7,5.3,10,10L489.8,70.1z"
          />
        </g>
      </svg>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    logo: {
      width: '200px',
      height: '35px',
      verticalAlign: 'middle',
      [mediaQueries.breakpointSmall]: {
        width: '140px',
      },
    },

    path: {
      st0: {
        fill: 'inherit',
      },
      st1: {
        fill: '#FFD400',
      },
      st2: {
        fill: '#00B2D5',
      },
      st3: {
        fill: '#E31F27',
      },
    },

    inverse: {
      fill: colors.white,
    },
  });
}
