import FeaturesContentBlock, { BG_COLOR_YELLOW, BG_COLOR_BLUE } from '../components/FeaturesContentBlock.react';
import FeaturesStepBlock from '../components/FeaturesStepBlock.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StaticPage from '../../layouts/StaticPage.react';
import translate from '4finance-translate';
import { Row, StyledComponent } from '4finance-components';

@translate
@Radium
export default class AboutUs extends StyledComponent {
  static styleRoot = 'AboutUs'

  static propTypes = {
    page: RPT.object,
    fields: RPT.object,
  }

  renderStepBlocks({ fields: source }, key, { length }) {
    const isLast = length === (key + 1);

    return <FeaturesStepBlock source={source} isLast={isLast} index={key} />;
  }

  renderContentBlocks = ({ fields: source, sys: { id } }) => { // eslint-disable-line
    const { page: { fields: { simpleContent } } } = this.props;

    return <FeaturesContentBlock source={source} backgroundColor={BG_COLOR_YELLOW} key={id} sourceSimple={simpleContent} />;
  }

  renderSecondContentBlocks = ({ fields: source, sys: { id } }) => { // eslint-disable-line
    return <FeaturesContentBlock source={source} backgroundColor={BG_COLOR_BLUE} key={id} />;
  }

  render() {
    const { msg, page: { fields: { url, pageHeading, pageSubHeading, contentReference, secondContentReference, footerHeading, footerSubHeading } } } = this.props;
    const style = this.getStyle();

    return (
      <StaticPage location={{ pathname: url }} hasPaddingBottom={false} hasMenuAbsolute>
        <div style={[style.wrapperSticky, style.bgImage]}>
          <h1 style={style.bgHeading}>
            {pageHeading}
            <span style={style.bgSubHeading}>{pageSubHeading}</span>
          </h1>
        </div>
        <div style={[style.wrapperSticky, style.contentReference, style.yellowTopStick]}>
          <Row>
            {contentReference && this.renderContentBlocks(contentReference)}
          </Row>
        </div>
        <div style={[style.wrapperSticky, style.contentReference]}>
          <Row>
            {secondContentReference && this.renderSecondContentBlocks(secondContentReference)}
          </Row>
        </div>
        <div style={[style.wrapperSticky, style.bgFooterImage]}>
          <div style={style.footerHeading}>{footerHeading}</div><br />
          <div style={style.footerSubHeading}>{footerSubHeading}</div><br />
          <div style={style.footerRibon}>{msg('contentful.aboutUsPage.footer')}</div>
        </div>
      </StaticPage>
    );
  }

  static style = ({ colors, mediaQueries }, { page: { fields: { pageHeadingImage: { fields: { file: { url } } }, footerImage: { fields: { file: { url: urlFooterImage } } } } } }) => ({
    bgImage: {
      backgroundImage: `url(${url})`,
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100vh',
      height: '100vh',
    },

    bgFooterImage: {
      backgroundImage: `url(${urlFooterImage})`,
      backgroundPosition: '50%',
      backgroundSize: 'auto 400px',
      backgroundRepeat: 'repeat-x',
      height: '400px',
      textAlign: 'center',
    },

    footerHeading: {
      display: 'inline-block',
      fontSize: '60px',
      lineHeight: '48px',
      color: colors.white,
      backgroundColor: colors.primary,
      padding: '10px',
      textAlign: 'center',
      margin: '60px auto 40px',
      fontWeight: '200',
      maxWidth: '800px',
      [mediaQueries.breakpointTablet]: {
        fontSize: '50px',
      },
      [mediaQueries.breakpointSmall]: {
        margin: '50px auto 30px',
        fontSize: '40px',
        lineHeight: '38px',
      },
    },

    footerSubHeading: {
      display: 'inline-block',
      fontSize: '60px',
      lineHeight: '48px',
      color: colors.white,
      backgroundColor: '#d60020',
      padding: '10px',
      textAlign: 'center',
      margin: '0px auto',
      fontWeight: '600',
      [mediaQueries.breakpointTablet]: {
        fontSize: '50px',
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '40px',
        lineHeight: '38px',
      },
    },

    footerRibon: {
      position: 'absolute',
      bottom: 0,
      backgroundColor: 'rgb(48, 58, 61)',
      color: colors.white,
      display: 'inline-block',
      fontSize: '21px',
      padding: '20px',
      transform: 'translateX(-50%)',

      [mediaQueries.breakpointTabletSmall]: {
        fontSize: '18px',
        padding: '14px',
      },
    },

    bgSubHeading: {
      display: 'block',
      fontWeight: '200',
    },

    contentReference: {
      padding: '0 15px',
    },

    bgHeading: {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      color: colors.white,
      textAlign: 'center',
      padding: '0 40px',
      margin: '0 auto',
      fontSize: '55px',
      lineHeight: '60px',

      [mediaQueries.breakpointSmall]: {
        lineHeight: '40px',
        fontSize: '36px',
      },
    },

    wrapperSticky: {
      position: 'sticky',
      top: '0',
    },
    yellowTopStick: {
      [mediaQueries.breakpointSmall]: {
        top: '-65%',
      },
    },
  })
}
