import PaymentBank from './PaymentBank.react';
import PaymentCard from './PaymentCard.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import Tabs from '../../components/Tabs.react';
import translate from '4finance-translate';

@translate
export default class PaymentSelect extends Component {
  static propTypes = {
    type: RPT.oneOf(['repayment', 'extension']).isRequired,
    amount: RPT.number,
    msg: RPT.func,
  }

  render() {
    const { type, amount, msg } = this.props;
    const tabsList = [
      {
        key: 'card_payment',
        name: msg('payments.card.title'),
        content: <PaymentCard type={type} amount={amount} />,
      },
      {
        key: 'bank_payment',
        name: msg('payments.bankPayment.title'),
        content: <PaymentBank type={type} amount={amount} />,
      },
    ];


    return (
      <Tabs tabs={tabsList} />
    );
  }
}
