import routesList from '../../browser/routesList';
import { isFintonicOrPrestaloProduct } from '../client/selectors';

export default async function forbidFintonic({ getApiResponse, replace, getState, dispatch }) {
  await getApiResponse(['fetch', 'client']);

  const isFintonic = isFintonicOrPrestaloProduct(getState());

  if (isFintonic) {
    dispatch({ type: 'GUARD_FORBID_FINTONIC_FAILED' });

    replace(routesList.dashboard);

    return false;
  }

  return true;
}
