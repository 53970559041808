import DifferentMethod from '../identification/components/DifferentMethod.react';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import routesList from '../routesList';
import translate from '4finance-translate';
import UploadBox from '../components/upload';
import { autobind } from 'core-decorators';
import { connectActions, connectPrefetch, connectState } from '../connect';
import { List } from 'immutable';
import { StyledComponent } from '4finance-components';

@connectPrefetch([
  ['identification', 'getRiskRules'],
])
@connectState({
  uploadFields: ['identification', 'riskRuleResults', 'upload'],
  uploadedFiles: ['customDocumentUpload', 'currentFiles'],
})
@connectActions({
  finishUploadingFiles: ['identification', 'submitDocumentUpload'],
  uploadIdentificationFiles: ['documentUpload', 'uploadIdentificationFiles'],
})
@translate
@Radium
export default class DocumentUpload extends StyledComponent {
  static styleRoot = 'DocumentUpload'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    uploadFields: RPT.oneOfType([RPT.array, RPT.object]),
    finishUploadingFiles: RPT.func,
    uploadIdentificationFiles: RPT.func,
  }

  static defaultProps = {
    uploadFields: new List(),
  }

  @autobind
  async handleSubmit() {
    const { uploadIdentificationFiles, finishUploadingFiles } = this.props;

    await uploadIdentificationFiles(); // eslint-disable-line no-unused-expressions
    await finishUploadingFiles(routesList.applicationBankAccount); // eslint-disable-line no-unused-expressions
  }

  render() {
    const { msg, uploadFields } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <PageHeader
          title={msg('identification.documentUpload.title')}
          textAlign="center"
          style={style.heading}
        />

        <UploadBox
          fields={uploadFields.toJS()}
          onClick={this.handleSubmit}
          heading={msg('identification.documentUpload.title')}
        />

        <DifferentMethod
          types={['approvalWhatsapp']}
        />
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      padding: '0 40px',

      [mediaQueries.breakpointTablet]: {
        padding: '40px 20px',
      },

      [mediaQueries.breakpointSmall]: {
        padding: 0,
      },
    },

    heading: {
      wrapper: {
        [mediaQueries.breakpointSmall]: {
          display: 'none',
        },
      },
    },
  });
}
