import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { DateField } from '4finance-components';

@translate
export default class VerboseDateField extends Component {
  static propTypes = {
    msg: RPT.func.isRequired,
  }

  render() {
    const { msg } = this.props;

    return (
      <div>
        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{msg('form.registration.dateOfBirth.title')}</span>
        <DateField
          {...this.props}
          style={dateFieldStyle}
          hasIndicator={false}
          errorWithLabel={false}
          bordered={false}
          label=""
          placeholder={[
            msg('form.registration.dateOfBirth.label.year'),
            msg('form.registration.dateOfBirth.label.month'),
            msg('form.registration.dateOfBirth.label.day'),
          ]}
        />
      </div>
    );
  }
}

const dateFieldStyle = {
  wrapper: {
    full: {
      backgroundColor: 'transparent',
      borderBottom: 0,
    },
    error: {
      borderBottom: 0,
    },
    focused: {
      borderBottom: 0,
    },
    padded: {
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};
