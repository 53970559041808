import routesList from '../../browser/routesList';

export default function forbidAuth({ replace, getState, dispatch }) {
  const { authentication: { isLoggedIn } } = getState();

  if (!isLoggedIn) {
    return true;
  }

  dispatch({ type: 'GUARD_FORBID_AUTH_FAILED' });
  replace(routesList.dashboard);

  return false;
}
