import Icon from '../Icon.react';
import ModalComponent from './Modals';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState, connectPending } from '../../connect';
import { FILE_UPLOAD_MULTIPLE_FILE_UPLOAD } from '4finance-redux-file-upload/lib/actions';
import { FileUpload } from '4finance-redux-file-upload';
import { getImageForType } from './consts';
import { Loader, StyledComponent } from '4finance-components';

@connectState({
  documents: ['customDocumentUpload', 'currentFiles'],
})
@connectPending({
  pendingUpload: FILE_UPLOAD_MULTIPLE_FILE_UPLOAD,
})
@translate
@Radium
export default class UploadField extends StyledComponent {
  static styleRoot = 'UploadField'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
    name: RPT.string,
    documents: RPT.object,
    pendingUpload: RPT.bool.isRequired,
    onFileSelect: RPT.func,
    opened: RPT.bool,
    optional: RPT.bool,
    onClick: RPT.func,
  }

  state = {
    modalShownFirstTime: false,
    showModal: false,
    uploaded: false,
  };

  componentDidUpdate({ pendingUpload: nextPendingUpload }) {
    const { pendingUpload } = this.props;

    if (!pendingUpload && nextPendingUpload) {
      this.onUpdateState();
    }
  }

  onUpdateState() {
    this.setState({ showModal: false });
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  onSuccessUpload = () => {
    const { onFileSelect } = this.props;

    this.setState({
      uploaded: true,
      modalShownFirstTime: true,
    }, onFileSelect);
  }

  getFileUploadProps = () => {
    const { name } = this.props;
    const style = this.getStyle();

    return {
      allowedFileTypes: ['jpg', 'jpeg', 'png', 'pdf'],
      autoUpload: false,
      data: { type: name },
      dropzoneActiveStyle: style.dropzone.active,
      dropzoneId: name,
      dropzoneStyle: style.dropzone,
      multiple: false,
      onChange: this.onSuccessUpload,
      replace: true,
      url: '/webapi/client/attachments',
    };
  }

  displayModal = (event) => {
    event.stopPropagation();
    this.setState({ showModal: true });
  }

  renderUploadFieldContent(currentDocument) {
    const { msg, pendingUpload } = this.props;
    const style = this.getStyle();
    const { dataURL } = currentDocument || {};

    return (
      <div style={style.card} onClick={this.displayModal}>
        <div style={[style.card.image, currentDocument && { backgroundImage: `url(${dataURL || '/assets/img/pdf.png'})` }]} />
        {pendingUpload
          ? <Loader inheritedStyle={style.card.loader} />
          : <div style={style.card.icon}>
            <Icon kind="camera" />
            <span style={style.card.icon.label}>
              {msg('common.select')}
            </span>
          </div>
        }
      </div>
    );
  }

  renderModalBeforeUpload(documents) {
    const { name } = this.props;

    return (
      <div id={`${name}-file-upload`}>
        {this.renderUploadFieldContent(documents)}
        <ModalComponent
          isModalOpen={this.state.showModal}
          fileUploadProps={this.getFileUploadProps()}
          onClose={() => { this.setState({ showModal: false }); }}
          kind={name}
        />
      </div>
    );
  }

  renderFileUpload(currentDocument) {
    return (
      <FileUpload
        {...this.getFileUploadProps()}
      >
        {this.renderUploadFieldContent(currentDocument)}
      </FileUpload>
    );
  }

  renderUploaded() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.uploaded}>
        <span style={style.uploaded.label}>{msg('identification.documentUpload.uploaded')}</span>
        <Icon
          kind="uploaded"
          size={18}
          wrapperStyle={style.uploaded.icon}
        />
      </div>
    );
  }

  render() {
    const { documents, msg, name, onClick, opened, optional } = this.props;
    const style = this.getStyle();
    const file = documents.get(name);

    return (
      <div style={style.wrapper} onClick={onClick} id={`${name}-wrapper`}>
        <div style={style.header}>
          <span>
            {msg(`identification.documentUpload.items.${name}`)}
            {optional && <span style={style.optional}>
             ({msg('identification.documentUpload.optional')})
            </span>}
          </span>
          {this.state.uploaded && this.renderUploaded()}
        </div>

        <div style={[style.container, !opened && style.container.closed]}>
          {this.state.modalShownFirstTime
            ? this.renderFileUpload(file)
            : this.renderModalBeforeUpload(file)
          }

          <div style={style.allowedTypes}>
            {msg('identification.documentUpload.allowedTypes')}
          </div>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { isLast, name }) => ({
    wrapper: {
      paddingLeft: '30px',
      paddingRight: '30px',
      borderBottom: isLast ? 0 : `1px solid ${colors.lightestFont}`,
      marginLeft: '30px',
      marginRight: '30px',

      [mediaQueries.breakpointTablet]: {
        paddingLeft: '0px',
        paddingRight: '0px',
        marginLeft: 0,
        marginRight: 0,
      },
    },

    header: {
      fontSize: '20px',
      lineHeight: '26px',
      paddingTop: '20px',
      cursor: 'pointer',
      position: 'relative',
      width: '100%',
      textAlign: 'center',
    },

    uploaded: {
      position: 'absolute',
      top: '25px',
      right: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      color: colors.primary,

      [mediaQueries.breakpointTablet]: {
        right: '10px',
      },

      label: {
        lineHeight: '12px',
        fontWeight: 700,
        marginTop: '3px',

        [mediaQueries.breakpointTablet]: {
          display: 'none',
        },
      },

      icon: {
        marginLeft: '5px',
        width: '18px',
        height: '18px',
      },
    },

    optional: {
      fontSize: '13px',
      lineHeight: '13px',
      marginTop: '3px',
      display: 'block',
      color: colors.lightFont,
    },

    dropzone: {
      backgroundColor: 'inherit',
      display: 'block',
      position: 'relative',
      textAlign: 'center',
    },

    allowedTypes: {
      color: colors.lightFont,
      fontSize: '13px',
      lineHeight: '20px',
      width: '80%',
      [mediaQueries.breakpointSmall]: {
        fontSize: '12px',
        lineHeight: '15px',
        width: '85%',
      },
    },

    container: {
      height: '300px',
      transition: 'height 0.2s ease-in',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
      marginTop: '20px',

      closed: {
        height: 0,
        visibility: 'hidden',
        marginBottom: 0,
      },
    },

    card: {
      padding: '10px',
      height: '192px',
      width: '300px',
      border: `2px dashed ${colors.secondary}`,
      borderRadius: '5px',
      marginBottom: '30px',
      transition: 'height 0.2s ease-out',
      position: 'relative',
      cursor: 'pointer',

      [mediaQueries.breakpointSmall]: {
        marginBottom: '15px',
      },

      image: {
        backgroundImage: `url(/assets/img/${getImageForType(name)}.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0 0',
        borderRadius: '10px',
        height: '100%',
        width: '100%',
      },

      loader: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
      },

      icon: {
        position: 'absolute',
        left: '90px',
        top: '36px',
        height: '120px',
        width: '120px',
        borderRadius: '60px',
        background: colors.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        color: colors.primary,
        filter: 'alpha(opacity=80)',
        opacity: 0.8,
        transition: 'all 0.2s ease-out',

        ':hover': {
          boxShadow: '0 0 15px rgba(0, 0, 0, .2)',
        },

        label: {
          marginTop: '10px',
        },
      },
    },
  });
}
