import Icon from '../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

@Radium
export default class ResponsiveMenuHeader extends StyledComponent {
  static styleRoot = 'ResponsiveMenuHeader'

  static propTypes = {
    fullName: RPT.string,
    msg: RPT.func.isRequired,
  }

  render() {
    const { fullName, msg } = this.props;
    const style = this.getStyle();

    return (
      <div id="responsiveMenuHeader" style={style.heading}>
        {fullName && <p style={style.title}>{fullName}</p>}

        <a style={style.link} href={routesList.signout}>
          <Icon kind="logout" style={style.icon} size={14} />
          {msg('account_header.logout')}
        </a>
      </div>
    );
  }

  static style = ({ colors }) => ({
    heading: {
      color: colors.darkFont,
      padding: '25px',
      fontSize: '20px',
      fontWeight: 500,
      backgroundColor: colors.secondary,
      position: 'relative',
    },

    title: {
      paddingRight: '40%',
      marginBottom: '15px',
    },

    link: {
      fontSize: '14px',
      fontWeight: '600',
    },

    icon: {
      marginBottom: '-2px',
      marginRight: '5px',
      display: 'inline',
    },
  })
}
