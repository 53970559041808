export default ({ colors }) => ({
  value: {
    base: {
      fontSize: '35px',
      fontWeight: 'bold',
      lineHeigth: '45px',
      color: colors.black,
    },
    unit: {
      fontSize: '15px',
      lineHeight: '25px',
      color: colors.black,
    },
  },

  label: {
    fontSize: '18px',
    fontWeight: '300',
  },
});
