import Icon from './Icon.react';
import PageContainer from './PageContainer.react';
import React from 'react';
import Radium from 'radium';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class FormMessage extends StyledComponent {
  static styleRoot = 'FormMessage'

  static propTypes = {
    body: RPT.string.isRequired,
    cnt: RPT.func.isRequired,
    heading: RPT.string.isRequired,
    msg: RPT.func.isRequired,
    type: RPT.oneOf(['error', 'warning']), // TODO: for now only one error type, no information about another types for now
    icon: RPT.string,
  }

  static defaultProps = {
    type: 'error',
  };

  render() {
    const { cnt, body, icon, heading, type } = this.props;
    const style = this.getStyle();

    return (
      <PageContainer
        width="widest"
        inheritedStyle={{ ...style.wrapper.common, ...style.wrapper[type] }}
        mobileFullScreen
      >
        <p style={style.header}>
          <Icon style={[style.icon, style.icon[type]]} kind={icon} size={32} />
          {cnt(heading)}
        </p>
        <div style={style.content}>
          {cnt(body)}
        </div>
      </PageContainer>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      common: {
        backgroundColor: colors.grayDark,
        color: colors.white,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '50px',
        padding: '20px 20px 0 20px',
        width: '100%',
        marginBottom: '30px',
        marginTop: '-50px',

        [mediaQueries.breakpointTablet]: {
          marginBottom: '15px',
          marginTop: '-30px',
          paddingTop: '15px',
        },

        container: {
          paddingLeft: '15px',
          paddingRight: '15px',

          [mediaQueries.breakpointTablet]: {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },

        },

      },

      error: {
        backgroundColor: colors.white,
        color: colors.alternativeErrorMessage,
      },

      warning: {
        backgroundColor: colors.secondary,
        color: colors.black,
      },
    },

    container: {
      [mediaQueries.breakpointTablet]: {
        margin: '0',
      },
    },

    content: {
      marginTop: '10px',
      marginBottom: '20px',
      fontSize: '14px',
      lineHeight: '20px',
    },

    header: {
      width: '100%',
      textAlign: 'left',
      fontSize: '18px',
      lineHeight: '20px',
      margin: 0,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
    },

    icon: {
      marginRight: '15px',
      flexShrink: 0,

      error: {
        fill: colors.errorMessage,
      },
    },
  })
}
