import { createSelector } from '4finance-connect';
import { Map } from 'immutable';

const apiSelector = state => state.contentful;

export const orderedFaqsSelector = createSelector(
  [apiSelector],
  (contentful) => {
    const faqs = contentful.getIn(['entries', 'faq', 'data']) || new Map();

    return faqs.sortBy((faq) => faq.fields.order).toArray();
  }
);
