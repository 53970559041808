import Icon from '../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { autobind } from 'core-decorators';
import { StyledComponent, Container, Heading, Row, Column } from '4finance-components';

const CONTENT_ITEMS = [
  'hp-control',
  'hp-facil',
  'hp-pago',
];

@translate
@Radium
export default class Services extends StyledComponent {
  static styleRoot = 'Services'

  static propTypes = {
    cnt: RPT.func,
    msg: RPT.func,
  }

  @autobind
  renderItem(name, key) {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <Column
        phone={12}
        tablet={12}
        small={4}
        medium={4}
        large={4}
        style={style.item}
        key={name}
      >
        <Icon
          kind={name}
          size={65}
        />
        <Heading inheritedStyle={style.item.heading} bold kind="h3">
          {msg(`home.services.content_items.${key + 1}.title`)}
        </Heading>
        <p style={style.item.text}>
          {msg(`home.services.content_items.${key + 1}.content`)}
        </p>
      </Column>
    );
  }

  render() {
    const style = this.getStyle();

    return (
      <div style={style.wrapper} id="services">
        <Container style={style.container}>
          <Row inheritedStyle={style.itemsWrapper}>
            {CONTENT_ITEMS.map(this.renderItem)}
          </Row>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      [mediaQueries.breakpointTablet]: {
        marginLeft: '-20px',
        marginRight: '-20px',
      },
    },

    wrapper: {
      padding: '14px 0 64px',
      textAlign: 'center',
      [mediaQueries.breakpointTablet]: {
        padding: '30px 30px',
      },
    },

    itemsWrapper: {
      marginTop: '70px',
      [mediaQueries.breakpointTablet]: {
        marginTop: 0,
      },
    },

    item: {
      [mediaQueries.breakpointTablet]: {
        marginTop: '50px',
      },

      text: {
        fontWeight: 200,
        marginTop: '40px',
        fontSize: '17px',
        lineHeight: '21px',
        color: colors.black,

        [mediaQueries.breakpointTablet]: {
          marginTop: '20px',
        },
      },
    },
  })
}
