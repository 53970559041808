import { Button, TextField, Checkbox } from '4finance-components';
import { connectSubmit } from '4finance-onion-form';
import { isRequired, email as isEmail } from '4finance-validation';
import translatedField from '../../../lib/translatedField';

export { default as PhoneField } from './PhoneField';
export { default as VerificationField } from './VerificationField';
export { PasswordField, NewPasswordField, UserNameField } from './PasswordField';

export const Submit = connectSubmit(Button);

export const AcceptNewsField = translatedField('acceptNews', {
  style: {
    checkboxLabel: {
      lineHeight: '20px',
    },
  },
})(Checkbox);

export const AcceptMarketingCallsField = translatedField('acceptMarketingCalls', {
  style: {
    container: {
      padding: '10px 0 0 70px',
      marginBottom: '15px',
    },
    checkboxLabel: {
      lineHeight: '20px',
    },
  },
})(Checkbox);

export const AcceptMarketingEmailsField = translatedField('acceptMarketingEmails', {
  style: {
    container: {
      marginTop: '15px',
      padding: '0 0 0 70px',
    },
    checkboxLabel: {
      lineHeight: '20px',
    },
  },
})(Checkbox);

export const AcceptMarketingSmsField = translatedField('acceptMarketingSms', {
  style: {
    container: {
      padding: '10px 0 0 70px',
    },
    checkboxLabel: {
      lineHeight: '20px',
    },
  },
})(Checkbox);

export const EmailField = translatedField('email', {
  width: 'full',
  bottomMargin: false,
  type: 'email',
  autoFocus: true,
  style: {
    marginTop: '0',
    marginBottom: '0',
    padding: '0',
    wrapper: { margin: '0', padding: '0', background: 'black' },
    hint: { fontSize: '12px' },
  },
}, [isRequired(), isEmail()])(TextField);

export const AcceptSharingConsent = translatedField('acceptDataSharing', {
  style: {
    container: {
      marginTop: '25px',
    },
    checkboxLabel: {
      lineHeight: '20px',
    },
  },
})(Checkbox);
