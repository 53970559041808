import AdditionalImportantInfo from '../components/AdditionalImportantInfo.react';
import CalculatorSliders from '../../components/Calculator/CalculatorSliders.react';
import Radium from 'radium';
import React from 'react';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';
import { YellowBox } from '../../components/Jumbotron';

@translate
@Radium
export default class AdditionalCalculator extends StyledComponent {
  static styleRoot = 'AdditionalCalculator';

  render() {
    const { msg } = this.props;
    const style = this.getStyle();

    return (
      <YellowBox
        hasPadding
        inheritedStyle={style.yellowBoxStyle}
        title={msg('additionalAmount.heading.title')}
      >
        <div style={style.calculatorWrapper}>
          <CalculatorSliders />
        </div>
        <AdditionalImportantInfo />
      </YellowBox>
    );
  }

  static style = ({ mediaQueries }) => ({
    headingWrapper: {
      display: 'none',
      padding: '24px',

      [mediaQueries.breakpointTablet]: {
        display: 'block',
      },
    },

    heading: {
      maxWidth: '220px',
    },

    calculatorWrapper: {
      margin: '0 0 56px',

      [mediaQueries.breakpointTablet]: {
        margin: 0,
      },
    },

    yellowBoxStyle: {
      padding: '24px 40px 60px',
      [mediaQueries.breakpointTablet]: {
        padding: 0,
      },
      title: {
        [mediaQueries.breakpointTablet]: {
          display: 'none',
        },
      },
    },
  });
}
