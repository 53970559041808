import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StylizedAmount from '../../components/StylizedAmount.react';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';
import { YellowBox as JumbotronYellowBox } from '../../components/Jumbotron';

@translate
@Radium
export default class PaymentHeader extends StyledComponent {
  static styleRoot = 'PaymentHeader';

  static propTypes = {
    type: RPT.oneOf(['monthly', 'full', 'extension']).isRequired,
    paymentDescription: RPT.string,
    amount: RPT.number,
  }

  static defaultProps = {
    amount: 0,
  }

  render() {
    const { children, amount, paymentDescription } = this.props;
    const style = this.getStyle();

    return (
      <JumbotronYellowBox
        isFullWidth
        hasPadding
      >
        <div className="top-panel" style={style.wrapper}>
          <Style scopeSelector=".top-panel" rules={style.amount} />

          <div style={style.content}>{children}</div>

          <div className="stylized-amount">
            <StylizedAmount amount={amount} size="small" inheritedStyle={style.amount} />
            <p>{paymentDescription}</p>
          </div>
        </div>
      </JumbotronYellowBox>
    );
  }

  static style = ({ mediaQueries, colors }) => ({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',

      [mediaQueries.breakpointTablet]: {
        display: 'block',
      },
    },

    content: {
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
    },

    amount: {
      '.stylized-amount': {
        backgroundColor: colors.white,
        padding: '17px 10px 5px 10px',
        borderRadius: '5px',
        position: 'relative',
        height: '78px',
      },
      '.stylized-amount > *': {
        position: 'relative',
        zIndex: '1',
      },
      '.stylized-amount > p': {
        color: colors.monotone3,
        fontSize: '15px',
        marginTop: '3px',
        marginBottom: 0,
      },
      '.stylized-amount:before': {
        content: '""',
        display: 'block',
        width: '47px',
        height: '47px',
        position: 'absolute',
        backgroundColor: colors.white,
        borderRadius: '5px',
        transform: 'translate(-31px, -1px) rotate(55deg) skewX(23deg) scale(1, 0.95)',
        zIndex: '0',
      },
      mediaQueries: {
        '(max-width: 768px)': {
          '.stylized-amount': {
            position: 'absolute',
            top: '-130px',
            right: '-24px',
            padding: '17px 20px 5px 10px',
            borderRadius: '5px 0 0 5px',
          },
        },
      },
    },
  });
}
