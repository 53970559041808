import LoanDownloadLinks from '../../components/LoanDownloadLinks.react';
import LoanOverview from '../../components/LoanOverview.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../../connect';
import {
  Column,
  Heading,
  Row,
  StyledComponent,
} from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
})
@translate
export default class LoanDetails extends StyledComponent {
  static styleRoot = 'LoanDetails'

  static propTypes = {
    loan: RPT.object,
    productNumber: RPT.string,
  }

  static defaultProps = {
    loan: new Map(),
  }

  render() {
    const { loan, msg } = this.props;
    const style = this.getStyle();

    return (
      <Row style={style.wrapper}>
        <Column large={4} medium={12} small={12} tablet={12} phone={12} inheritedStyle={style.column}>
          <Heading kind="h1" inheritedStyle={style.heading} >
            {msg('loan.summary')}
          </Heading>
        </Column>
        <Column large={8} medium={12} small={12} tablet={12} phone={12} inheritedStyle={style.column}>
          <LoanOverview loan={loan} />
          <LoanDownloadLinks />
        </Column>
      </Row>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    heading: {
      fontSize: '16px',
      textTransform: 'uppercase',
      paddingRight: '50%',
      lineHeight: '25px',
      marginTop: 0,

      [mediaQueries.breakpointLaptopSmall]: {
        borderBottom: `3px solid ${colors.grayLighter}`,
        paddingBottom: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        fontSize: '14px',
      },

      [mediaQueries.breakpointTablet]: {
        marginLeft: '-20px',
        marginRight: '-20px',
      },
    },

    wrapper: {
      paddingTop: '20px',
      marginTop: '30px',
      borderTop: `3px solid ${colors.grayLighter}`,
      marginRight: 0,
      marginLeft: 0,

      [mediaQueries.breakpointLaptopSmall]: {
        borderTop: 'none',
        paddingTop: 0,
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },

    column: {
      [mediaQueries.breakpointStartLaptop]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

  })
}
