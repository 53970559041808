import getExtension from '../lib/getExtension';
import dayjs from 'dayjs';
import PaymentHeader from './components/PaymentHeader.react';
import PaymentSelect from './components/PaymentSelect.react';
import PaymentWrapper from './components/PaymentWrapper.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState, connectPrefetch } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  extensions: ['api', 'fetch', 'client', 'loans', 'extensions', 'root', 'latest', 'data'],
})
@connectPrefetch([
  ['client', 'fetchClientLatestLoanExtensions'],
])
@translate
export default class PaymentExtension extends StyledComponent {
  static styleRoot = 'PaymentExtension';

  static propTypes = {
    loan: RPT.object.isRequired,
    extensions: RPT.object.isRequired,
  }

  static defaultProps = {
    loan: new Map(),
    extensions: new Map(),
  }

  renderHeader() {
    const { msg, formatDate, loan } = this.props;
    const style = this.getStyle();

    return (
      <ul style={style.heading}>
        <li style={style.row}>
          {msg('payments.extension.currentDate')}
          <strong style={style.value}>
            {formatDate(loan.get('nearestPaymentDate'))}
          </strong>
        </li>
        <li style={style.row}>
          {msg('payments.extension.newDate')}
          <strong style={style.value}>
            {formatDate(dayjs(loan.get('nearestPaymentDate')).add(1, 'months'))}
          </strong>
        </li>
      </ul>
    );
  }

  render() {
    const { msg } = this.props;
    const extension = getExtension(this.props.extensions);

    if (!extension) {
      return null;
    }

    return (
      <PaymentWrapper
        type="full"
        title={msg('payments.extension.title')}
      >
        <PaymentHeader
          amount={extension.fee}
          paymentDescription={msg('payments.extension.amountInfo')}
          type="full"
        >
          {this.renderHeader()}
        </PaymentHeader>
        <PaymentSelect type="extension" amount={extension.fee} />
      </PaymentWrapper>
    );
  }

  static style = {
    heading: {
      lineHeight: '1.8em',
      listStyle: 'none',
    },
    row: {
      display: 'flex',
    },
    value: {
      marginLeft: '5px',
    },
  };
}
