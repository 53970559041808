import Icon from '../../components/Icon.react';
import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, Heading, StyledComponent } from '4finance-components';
import { Link } from 'react-router';

@listenWindowResize
@translate
@Radium
export default class Card extends StyledComponent {
  static styleRoot = 'Card'

  static propTypes = {
    title: RPT.string,
    subtitle: RPT.string,
    button: RPT.string,
    link: RPT.string,
    estimate: RPT.string,
    recommended: RPT.bool,
    icon: RPT.string,
    msg: RPT.func.isRequired,
    device: RPT.instanceOf(Device).isRequired,
  }

  renderContent() {
    const { msg, title, subtitle, button, link, estimate, recommended, icon, onClick } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        {recommended &&
          <span style={style.recommended}>
            {msg('identification.cards.recommended')}
          </span>
        }

        <div style={style.estimate}>
          <Icon
            kind="loan-time"
            size={12}
            style={style.estimate.icon}
          />
          <span>{estimate}</span>
        </div>

        <Icon
          kind={icon}
          style={style.icon}
        />

        <Heading bold kind="h2" inheritedStyle={style.heading}>
          {title}
        </Heading>

        <p style={style.subtitle}>
          {subtitle}
        </p>

        <ButtonLink
          size="small"
          to={link}
          inheritedStyle={style.button}
          wrapperStyle={style.button.wrapper}
          onClick={onClick}
        >
          {button}
        </ButtonLink>

        <Icon
          kind="arrow-back"
          style={style.arrow}
        />
      </div>
    );
  }

  render() {
    const { device, link, onClick } = this.props;
    const style = this.getStyle();
    const isScreenWidthUnderTablet = device.atMost('tablet');

    return isScreenWidthUnderTablet
      ? <Link to={link} onClick={onClick} style={style.wrapper.link}>{this.renderContent()}</Link>
      : this.renderContent();
  }

  static style = ({ colors, mediaQueries }, { recommended }) => ({
    wrapper: {
      background: colors.white,
      textAlign: 'center',
      paddingBottom: '25px',
      display: 'flex',
      flexDirection: 'column',
      transition: 'box-shadow 0.3s',

      ':hover': {
        boxShadow: '0 0 11px 0 rgba(0,0,0,0.18)',
      },

      link: {
        textDecoration: 'none',
        color: 'inherit',
        order: recommended ? -1 : 1,
      },

      [mediaQueries.breakpointStartTablet]: {
        flexGrow: 1,
        flexBasis: 1,
        margin: `${recommended ? '0' : '20px'} 14px`,
        borderRadius: '5px',
        alignItems: 'center',
        paddingLeft: '30px',
        paddingRight: '30px',
      },

      [mediaQueries.breakpointTablet]: {
        position: 'relative',
        flexBasis: 'auto',
        paddingLeft: '75px',
        paddingRight: '43px',
        paddingTop: recommended ? '32px' : '21px',
        textAlign: 'left',
        marginBottom: '2px',
        order: recommended ? -1 : 1,
      },
    },

    heading: {
      lineHeight: '30px',
      textTransform: 'uppercase',
      color: colors.darkFont,

      [mediaQueries.breakpointStartTablet]: {
        fontSize: recommended ? '22px' : '16px',
        marginTop: recommended ? '38px' : '20px',
      },

      [mediaQueries.breakpointTablet]: {
        fontSize: '16px',
        lineHeight: '25px',
        marginTop: '5px',
      },
    },

    subtitle: {
      fontSize: '13px',
      lineHeight: '20px',
      minHeight: '60px',
      color: colors.lightFont,

      [mediaQueries.breakpointStartTablet]: {
        fontWeight: 'bold',
      },

      [mediaQueries.breakpointTablet]: {
        fontSize: '14px',
      },
    },

    recommended: {
      paddingLeft: '14px',
      paddingRight: '14px',
      background: colors.secondary,
      color: colors.white,
      top: '6px',
      lineHeight: '21px',
      height: '20px',
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 'bold',

      [mediaQueries.breakpointTablet]: {
        position: 'absolute',
        top: 0,
      },
    },

    estimate: {
      fontSize: '12px',
      color: colors.lightestFont,
      display: 'flex',
      alignItems: 'center',
      lineHeight: 0,

      icon: {
        marginRight: '5px',
        marginBottom: '2px',
      },

      [mediaQueries.breakpointStartTablet]: {
        marginTop: '15px',
        marginBottom: '10px',
        justifyContent: 'center',
      },
    },

    icon: {
      width: '114px',
      height: '114px',

      [mediaQueries.breakpointTablet]: {
        width: '35px',
        height: '35px',
        position: 'absolute',
        left: '24px',
        top: recommended ? '32px' : '21px',
      },
    },

    button: {
      width: '100%',
      marginRight: 0,
      textTransform: 'uppercase',

      [mediaQueries.breakpointLarge]: {
        minWidth: '100%',
      },

      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },

      wrapper: {
        marginTop: 'auto',
      },
    },

    arrow: {
      transform: 'scale(-1, 1)',
      width: '14px',
      height: '14px',
      position: 'absolute',
      right: '22px',
      top: recommended ? '37px' : '27px',

      [mediaQueries.breakpointStartTablet]: {
        display: 'none',
      },
    },
  });
}
