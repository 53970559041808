import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent as Component } from '4finance-components';
import routesList from '../../routesList';
import PageHeader from '../../components/PageHeader.react';
import PageContainer from '../../components/PageContainer.react';
import Jumbotron from '../../components/Jumbotron';
import Mymoid from '../../components/Mymoid.react';
import { connectState } from '../../connect';

@connectState({
  activeCard: ['client', 'activeCard'],
})

@translate
@Radium
export default class CardAdd extends Component {
  static styleRoot = 'CardAdd';

  static propTypes = {
    cnt: RPT.func.isRequired,
    msg: RPT.func.isRequired,
    router: RPT.object.isRequired,
    activeCard: RPT.bool,
  }

  render() {
    const { cnt, msg, activeCard, router: { location: { state: { fromPaymentPage } = {} } = {} } } = this.props;
    const style = this.getStyle();
    const hasCardLabel = activeCard ? 'settings' : 'application';

    return (
      <PageContainer width="narrowest" mobileFullScreen style={style.container}>
        <PageHeader
          title={msg(`${hasCardLabel}CardAdd.title`)}
          subtitle={cnt(`${hasCardLabel}CardAdd.content`)}
          padded
        />
        <Jumbotron hasPadding hasWrapper inheritedStyle={style.iframeWrapper}>
          <Mymoid root={routesList.settings} customRedirectUrl={fromPaymentPage && routesList.applicationCardProcessingAutoRepayment} />
        </Jumbotron>
      </PageContainer>
    );
  }

  static style = ({ mediaQueries }) => ({
    container: {
      wrapper: {
        [mediaQueries.breakpointTablet]: {
          marginTop: '40px',
        },
      },
    },

    iframeWrapper: {
      [mediaQueries.breakpointTablet]: {
        padding: '15px',
      },
    },
  });
}
