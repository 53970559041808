import Icon from '../../components/Icon.react';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent } from '4finance-components';

export default class CoinsDecoration extends StyledComponent {
  static styleRoot = 'CoinsDecoration';

  static propTypes = {
    kind: RPT.oneOf(['additional', 'payment']),
  }

  static defaultProps = {
    kind: 'additional',
  }

  getIconsByKind = () => {
    const { kind } = this.props;

    const iconPalette = {
      additional: ['coins-3', 'coins-2', 'coins-3', 'coins-4'],
      payment: ['coins-4', 'coins-3', 'coins-2', 'coins-4'],
    };

    return iconPalette[kind] || iconPalette.payment;
  }

  render() {
    const style = this.getStyle();
    const icons = this.getIconsByKind();

    return (
      <div style={style.wrapper}>
        <div style={style.block}>
          <Icon kind={icons[0]} size={45} />
        </div>
        <div style={style.block}>
          <Icon kind={icons[1]} size={45} />
        </div>
        <div style={style.block}>
          <Icon kind={icons[2]} size={45} />
        </div>
        <div style={style.block}>
          <Icon kind={icons[3]} size={45} />
        </div>
      </div>
    );
  }

  static style = ({ colors }) => ({
    wrapper: {
      textAlign: 'center',
      margin: '25px 0',
    },

    block: {
      backgroundColor: colors.white,
      textAlign: 'center',
      margin: '20px',
      borderRadius: '99px',
      paddingTop: '26px',
      width: '90px',
      height: '90px',
      display: 'inline-block',
      boxShadow: `0 3px 6px ${colors.lightestFont}`,
    },
  });
}
