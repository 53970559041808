import Icon from '../../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column, FooterLink, Row, StyledComponent } from '4finance-components';
import { connectState } from '../../connect';

@connectState({
  isLoggedIn: ['authentication', 'isLoggedIn'],
})
@translate
@Radium
export default class Links extends StyledComponent {
  static styleRoot = 'Links'

  static propTypes = {
    msg: RPT.func,
    isLoggedIn: RPT.bool,
  }

  render() {
    const { msg, isLoggedIn } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Row inheritedStyle={style.firstRow}>
          <Column large={9} medium={9} small={9} phone={12} inheritedStyle={style.links}>
            <div style={style.group}>
              <FooterLink to={routesList.contentAboutUs} inheritedStyle={style.link}>
                {msg('footer.links.about_us')}
              </FooterLink>

              <FooterLink to={routesList.contentFeatures} inheritedStyle={style.link}>
                {msg('footer.links.features')}
              </FooterLink>

              {!isLoggedIn &&
                <FooterLink to={routesList.signin} inheritedStyle={style.link}>
                  {msg('footer.links.sign_in')}
                </FooterLink>
              }
            </div>

            <div style={style.group}>
              <FooterLink to={routesList.contentPrivacyPolicy} inheritedStyle={style.link}>
                {msg('footer.links.privacy_policy')}
              </FooterLink>

              <FooterLink to={routesList.contentAgreement} inheritedStyle={style.link}>
                {msg('footer.links.general_terms')}
              </FooterLink>

              <FooterLink to={routesList.contentCookies} inheritedStyle={style.link}>
                {msg('footer.links.cookies_policy')}
              </FooterLink>
            </div>

            <div style={style.group}>
              <FooterLink to={routesList.contentFaq} inheritedStyle={style.link}>
                {msg('footer.links.faq')}
              </FooterLink>

              <a href={routesList.blog} style={style.link}>
                {msg('footer.links.blog')}
              </a>

              <FooterLink to={routesList.contentContact} inheritedStyle={style.link}>
                {msg('footer.links.contact')}
              </FooterLink>
            </div>
          </Column>

          <Column large={3} medium={3} small={3} phone={12}>
            <ul style={style.socialLinks}>
              <li style={[style.socialLinksItem, style.firstLink]}>
                <a href="https://www.facebook.com/ZaploSpain" target="_blank" style={style.socialLinksIcon} key="facebook">
                  <Icon kind="facebook-letter-logo" size={25} />
                </a>
              </li>
              <li style={style.socialLinksItem}>
                <a href="https://twitter.com/Zaplo_spain" target="_blank" style={style.socialLinksIcon} key="twitter">
                  <Icon kind="twitter-logo-silhouette" size={27} />
                </a>
              </li>
              <li style={style.socialLinksItem}>
                <a href="https://www.linkedin.com/company/4financespain" target="_blank" style={style.socialLinksIcon} key="linkedin">
                  <Icon kind="linkedin-logo" size={25} />
                </a>
              </li>
            </ul>
          </Column>
        </Row>
        <div style={style.partners.wrapper}>
          <ul style={style.partners.group}>
            <li style={style.partners.item}>
              <a href="https://www.confianzaonline.es/" target="_blank">
                <Icon kind="footer-confianza" size={40} style={style.partners.icon} />
              </a>
            </li>
            <li style={style.partners.item}>
              <a href="https://www.instantor.com/" target="_blank">
                <Icon kind="footer-instantor" size={100} style={style.partners.icon} />
              </a>
            </li>
          </ul>
          <p style={style.finance}>{msg('footer.partners.finance')}</p>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    firstRow: {
      marginTop: '25px',
    },

    links: {
      display: 'flex',
      alignItems: 'flex-start',
      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    group: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: '60px',
      listStyle: 'none',
      [mediaQueries.breakpointTablet]: {
        marginRight: '30px',
      },
    },

    link: {
      color: colors.white,
      fontSize: '16px',
      textDecoration: 'none',
      borderBottomStyle: 'none',
      fontWeight: '200',
      marginBottom: '15px',
    },

    socialLinks: {
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'flex-end',

      [mediaQueries.breakpointTablet]: {
        justifyContent: 'space-between',
        maxWidth: '450px',
        width: '100%',
        margin: '0 auto 10px',
      },
    },

    socialLinksItem: {
      marginLeft: '25px',
    },

    socialLinksIcon: {
      ':hover': {
        opacity: '0.8',
      },
    },

    firstLink: {
      marginLeft: 0,
    },

    partners: {
      wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '30px 0 10px',

        [mediaQueries.breakpointTablet]: {
          display: 'block',
          textAlign: 'center',
        },
      },
      group: {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'flex-start',

        [mediaQueries.breakpointTablet]: {
          display: 'none',
        },
      },
      icon: {
        height: '50px',
      },
      item: {
        marginRight: '50px',

        [mediaQueries.breakpointTablet]: {
          marginRight: '30px',
        },
      },
    },
    finance: {
      color: colors.grayLighter,
    },
  })
}
