import InlineFonts from './InlineFonts.react.js';
import React, { PureComponent as Component } from 'react';

const fonts = [
  { weight: 100, name: '2DA8B6_14_0' },
  { weight: 200, name: '2DA8B6_11_0' },
  { weight: 300, name: '2DA8B6_13_0' },
  { weight: 500, name: '2DA8B6_17_0' },
  { weight: 600, name: '2DA8B6_12_0' },
  { weight: 700, name: '2DA8B6_3_0' },
  { weight: 900, name: '2DA8B6_6_0' },
];

export default class FontProximaNova extends Component {
  render() {
    return (
      <InlineFonts fonts={fonts} fontFamily="ProximaNova" formats={['woff2', 'woff', 'ttf']} />
    );
  }
}
