import Icon from '../Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { StyledComponent as Component, Heading } from '4finance-components';

@Radium
export default class JumbotronYellowBox extends Component {
  static styleRoot = 'JumbotronYellowBox';

  static propTypes = {
    children: RPT.node,
    isFullWidth: RPT.bool,
    hasPadding: RPT.bool,
    inheritedIconStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
    inheritedStyle: RPT.oneOfType([RPT.object, RPT.arrayOf(RPT.object)]),
    title: RPT.string,
    icon: RPT.string,
    iconSize: RPT.number,
  }

  static defaultProps = {
    isFullWidth: false,
    hasPadding: false,
    inheritedIconStyle: {},
    inheritedStyle: {},
    title: null,
    icon: null,
    iconSize: 40,
  }

  render() {
    const {
      icon,
      iconSize,
      children,
      isFullWidth,
      hasPadding,
      inheritedStyle,
      inheritedIconStyle,
      title,
    } = this.props;
    const style = this.getStyle();

    return (
      <div
        style={[
          style.wrapper,
          hasPadding && style.wrapper.hasPadding,
          isFullWidth && style.wrapper.isFullWidth,
          inheritedStyle,
        ]}
      >
        <div style={[icon && style.wrapper.hasIcon, icon && style.title.wrapper]}>
          {icon &&
            <Icon
              kind={icon}
              size={iconSize}
              style={[style.icon, inheritedIconStyle]}
            />
          }
          {title && <Heading kind="h3" inheritedStyle={[style.title, inheritedStyle.title]}>
            {title}
          </Heading>}
        </div>
        <div style={[icon && style.children.hasIcon, inheritedStyle && inheritedStyle.container]}>
          {children}
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { iconSize }) => ({
    wrapper: {
      backgroundColor: colors.secondary,
      textAlign: 'left',

      hasPadding: {
        padding: '24px',

        [mediaQueries.breakpointSmall]: {
          padding: '15px',
        },
      },
      isFullWidth: {
        marginLeft: '-24px',
        marginRight: '-24px',
        marginTop: '-25px',

        [mediaQueries.breakpointSmall]: {
          marginLeft: '-15px',
          marginRight: '-15px',
        },
      },
      hasIcon: {
        display: 'flex',
        alignItems: 'center',
      },
    },

    icon: {
      display: 'block',
      flexShrink: 0,
      marginRight: '30px',

      [mediaQueries.breakpointSmall]: {
        marginRight: '10px',
      },
    },

    title: {
      fontWeight: 600,
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      lineHeight: '28px',

      wrapper: {
        marginTop: '6px',
        marginBottom: '20px',

        [mediaQueries.breakpointSmall]: {
          marginBottom: '5px',
        },
      },
    },

    children: {
      hasIcon: {
        marginTop: '15px',
        marginLeft: `${iconSize + 30}px`,

        [mediaQueries.breakpointSmall]: {
          marginTop: '5px',
          marginLeft: `${iconSize + 10}px`,
        },
      },
    },
  });
}
