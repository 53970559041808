/* eslint-disable no-unused-expressions */
import * as application from '../application/actions';
import * as loans from '../loans/actions';
import checkForApiError from '../lib/checkForApiError';
import dayjs from 'dayjs';
import routesList from '../../browser/routesList';
import { actions as api } from '../api';
import { actions as form, extractPropertyFromState } from '4finance-onion-form';
import { deserializedAddressSelector, hadLoans } from './selectors';
import { push as updatePath } from 'react-router-redux';
import { serializeAboutYou } from '../registration/serializer';

export const PROCESS_SUBMIT_ADDRESS_CHANGE = 'PROCESS_SUBMIT_ADDRESS_CHANGE';
export const PROCESS_SUBMIT_BUDGET_CHANGE = 'PROCESS_SUBMIT_BUDGET_CHANGE';
export const PROCESS_SUBMIT_BUDGET_EXPENSES_CHANGE = 'PROCESS_SUBMIT_BUDGET_EXPENSES_CHANGE';
export const PROCESS_SUBMIT_BUDGET_INCOME_CHANGE = 'PROCESS_SUBMIT_BUDGET_INCOME_CHANGE';
export const PROCESS_SUBMIT_EMPLOYMENT_CHANGE = 'PROCESS_SUBMIT_EMPLOYMENT_CHANGE';
export const PROCESS_SUBMIT_HOUSEHOLD_CHANGE = 'PROCESS_SUBMIT_HOUSEHOLD_CHANGE';
export const PROCESS_SUBMIT_PURPOSE_CHANGE = 'PROCESS_SUBMIT_PURPOSE_CHANGE';

export const FETCH_CLIENT_ACTIVE_CARD = 'FETCH_CLIENT_ACTIVE_CARD';
export const FETCH_CLIENT_ACTIVE_CARD_SUCCESS = 'FETCH_CLIENT_ACTIVE_CARD_SUCCESS';
export const FETCH_CLIENT_ACTIVE_CARD_ERROR = 'FETCH_CLIENT_ACTIVE_CARD_ERROR';

export const FETCH_CLIENT_INFO = 'FETCH_CLIENT_INFO';

export function applyForLoan() {
  return ({ dispatch, getState }) => {
    const state = getState();
    const { api: { fetch }, calculator: { amount: calculatorAmount, term: termAmount } } = state;
    const { amount, term } = fetch.getIn(['client', 'application', 'query', 'data']).toJS();
    const additionalAmount = fetch.getIn(['client', 'query', 'data', 'additionalAmount']);
    const { loanPurpose: formLoanPurpose } = extractPropertyFromState(getState(), 'applicationDetails', 'value');
    const apiLoanPurpose = fetch.getIn(['client', 'application', 'loanPurpose', 'query', 'data', 'loanPurpose']);

    const applyLoanConfirmationChanges = async () => {
      if (amount !== calculatorAmount || term !== termAmount) {
        await dispatch(application.createApplication());
      }

      await dispatch(api.patchClientApplicationLoanPurpose({
        loanPurpose: formLoanPurpose || apiLoanPurpose,
      }));
    };

    const getPromise = async () => {
      if (hadLoans(state)) {
        applyLoanConfirmationChanges();
        await dispatch(application.generateWebcode());
        dispatch(updatePath(routesList.newApplicationConfirm));

        return;
      }

      // TODO: SMS confirmation if is Acc older than 6 month? Wikipage
      if (additionalAmount) {
        applyLoanConfirmationChanges();
        dispatch(updatePath(routesList.applicationIdentification));

        return;
      }

      await dispatch(application.createApplication());
    };

    return {
      type: 'APPLY_FOR_LOAN',
      payload: getPromise(),
    };
  };
}

export function fetchClientActiveCard() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      await dispatch(api.fetchPaymentGatewayCardsByChannelcode('mymoid'));
      const { api: { fetch } } = getState();
      const cards = fetch.getIn(['paymentGateway', 'cards', 'mymoid', 'data', 'cards']);
      const cardsArray = cards ? cards.toJS() : [];
      const activeCard = cardsArray.find(card => card.active);

      return activeCard && {
        ...activeCard,
        expiryDate: dayjs(activeCard.expiryDate, 'DD/MM/YYYY'),
      };
    };

    return {
      type: FETCH_CLIENT_ACTIVE_CARD,
      payload: getPromise(),
    };
  };
}

export function fetchClientInfo() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      await dispatch(api.fetchClient());
      await dispatch(api.fetchClientApplication());
      await dispatch(loans.fetchLatestLoan());
      await dispatch(api.fetchClientSettings());
      await dispatch(api.fetchClientBankAccountNumber());
      await dispatch(api.fetchClientCardsActive());
      await dispatch(fetchClientActiveCard());
    };

    return {
      type: FETCH_CLIENT_INFO,
      payload: getPromise(),
    };
  };
}

export function fetchClientLatestLoanExtensions() {
  return ({ getState, dispatch }) => {
    const getPromise = async () => {
      if (getState().authentication.get('isLoggedIn')) {
        await dispatch(api.fetchClientLoansExtensionsByLoanid('latest'));
      }
    };

    return {
      type: 'FETCH_CLIENT_LATEST_LOAN_EXTENSIONS',
      payload: getPromise(),
    };
  };
}

// @TODO same action in Settings actions
export function acceptNews() {
  return ({ dispatch }) => {
    const getPromise = async () => {
      await dispatch(api.patchClientSettings({
        acceptNews: true,
      }));
      dispatch(api.fetchClientSettings());
    };

    return {
      type: 'CLIENT_NOTIFICATION_ACCEPT_NEWS',
      payload: getPromise(),
    };
  };
}

export function submitClientBudgetIncomeChange({ totalIncome }, formName) {
  return ({ dispatch }) => ({
    type: PROCESS_SUBMIT_BUDGET_INCOME_CHANGE,
    payload: dispatch(submitClientBudgetChange({ totalIncome }, formName)),
  });
}

export function submitClientBudgetExpensesChange({ loansExpenses }, formName) {
  return ({ dispatch }) => ({
    type: PROCESS_SUBMIT_BUDGET_EXPENSES_CHANGE,
    payload: dispatch(submitClientBudgetChange({ loansExpenses }, formName)),
  });
}

export function submitClientBudgetChange({ totalIncome, loansExpenses }, formName) {
  return ({ dispatch, getApiResponse }) => {
    const getPromise = async () => {
      const { totalIncome: stateTotalIncome, loansExpenses: stateLoansExpenses } = await getApiResponse(['fetch', 'client', 'budget']);

      checkForApiError({
        response: await dispatch(api.patchClientBudget({
          totalIncome: totalIncome || stateTotalIncome,
          loansExpenses: loansExpenses || stateLoansExpenses,
        })),
        formName,
        dispatch,
      });

      dispatch(api.fetchClientBudget());
    };

    return {
      type: PROCESS_SUBMIT_BUDGET_CHANGE,
      payload: getPromise(),
    };
  };
}

export function submitClientLoanPurposeChange({ loanPurpose }, formName) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      checkForApiError({
        response: await dispatch(api.patchClientApplicationLoanPurpose({
          loanPurpose,
        })),
        formName,
        dispatch,
      });

      dispatch(api.fetchClientApplicationLoanPurpose());
    };

    return {
      type: PROCESS_SUBMIT_PURPOSE_CHANGE,
      payload: getPromise(),
    };
  };
}

export function submitClientEmploymentChange({ employmentStatus }, formName) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      checkForApiError({
        response: await dispatch(api.patchClientEmployer({
          employmentStatus,
          name: 'N/A',
        })),
        formName,
        dispatch,
      });

      dispatch(api.fetchClientEmployer());
    };

    return {
      type: PROCESS_SUBMIT_EMPLOYMENT_CHANGE,
      payload: getPromise(),
    };
  };
}


export function submitClientHouseholdChange({ ownerShip }, formName) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      checkForApiError({
        response: await dispatch(api.patchClientHousehold({
          ownerShip,
        })),
        formName,
        dispatch,
      });

      dispatch(api.fetchClientHousehold());
    };

    return {
      type: PROCESS_SUBMIT_HOUSEHOLD_CHANGE,
      payload: getPromise(),
    };
  };
}

export function submitClientAddressChange(data, formName) {
  return ({ dispatch }) => {
    const getPromise = async () => {
      checkForApiError({
        response: await dispatch(api.patchClientAddress(serializeAboutYou(data))),
        formName,
        dispatch,
      });

      dispatch(api.fetchClient());
    };

    return {
      type: PROCESS_SUBMIT_ADDRESS_CHANGE,
      payload: getPromise(),
    };
  };
}

export function prefetchChangeAddressForm() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const addressData = deserializedAddressSelector(getState());

      dispatch(form.setMultipleFields('changeAddress', 'value', { ...addressData }));
    };

    return {
      type: 'PREFETCH_FORM_ADDRESS_CHANGE',
      payload: getPromise(),
    };
  };
}

export function prefetchChangeEmploymentForm() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const employmentStatus = getState().api.getIn(['fetch', 'client', 'employer', 'query', 'data', 'employmentStatus']);

      dispatch(form.setMultipleFields('changeEmployment', 'value', { employmentStatus }));
    };

    return {
      type: 'PREFETCH_FORM_EMPLOYMENT_CHANGE',
      payload: getPromise(),
    };
  };
}

export function prefetchChangeHouseholdForm() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const ownerShip = getState().api.getIn(['fetch', 'client', 'household', 'query', 'data', 'ownerShip']);

      dispatch(form.setMultipleFields('changeHousehold', 'value', { ownerShip }));
    };

    return {
      type: 'PREFETCH_FORM_HOUSEHOLD_CHANGE',
      payload: getPromise(),
    };
  };
}

export function prefetchChangeLoanPurposeForm() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const loanPurpose = getState().api.getIn(['fetch', 'client', 'application', 'loanPurpose', 'query', 'data', 'loanPurpose']);

      dispatch(form.setMultipleFields('applicationDetails', 'value', { loanPurpose }));
    };

    return {
      type: 'PREFETCH_FORM_LOAN_PURPOSE_CHANGE',
      payload: getPromise(),
    };
  };
}

export function prefetchChangeLoansExpensesForm() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const loansExpenses = getState().api.getIn(['fetch', 'client', 'budget', 'query', 'data', 'loansExpenses']);

      dispatch(form.setMultipleFields('changeLoansExpenses', 'value', { loansExpenses }));
    };

    return {
      type: 'PREFETCH_FORM_LOANS_EXPENSES_CHANGE',
      payload: getPromise(),
    };
  };
}

export function prefetchChangeTotalIncomeForm() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const totalIncome = getState().api.getIn(['fetch', 'client', 'budget', 'query', 'data', 'totalIncome']);

      dispatch(form.setMultipleFields('changeTotalIncome', 'value', { totalIncome }));
    };

    return {
      type: 'PREFETCH_FORM_TOTAL_INCOME_CHANGE',
      payload: getPromise(),
    };
  };
}
