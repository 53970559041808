import { actions as api } from '../api';
import * as actions from './actions';
import setFormApiError from '../lib/setFormApiError';

export const toggleConsentsOptions = ({ name, value }) => ({ dispatch, getState }) => {
  const getPromise = async () => {
    const emailValue = getState().onionForm.getIn(['fields', 'changeConsentOptions', 'acceptMarketingEmails', 'value']);
    const smsValue = getState().onionForm.getIn(['fields', 'changeConsentOptions', 'acceptMarketingSms', 'value']);
    const callValue = getState().onionForm.getIn(['fields', 'changeConsentOptions', 'acceptMarketingCalls', 'value']);
    const dataSharingValue = getState().onionForm.getIn(['fields', 'changeConsentOptions', 'acceptDataSharing', 'value']);

    const isAcceptNewsClicked = name === 'acceptNews';
    const isAcceptNews = emailValue || smsValue || callValue;
    const acceptNews = isAcceptNewsClicked ? value : isAcceptNews;

    dispatch(updateSettings({
      acceptNews,
      ...acceptNews && isAcceptNews && {
        acceptMarketingEmails: emailValue,
        acceptMarketingSms: smsValue,
        acceptMarketingCalls: callValue,
      },
      acceptDataSharing: dataSharingValue,
    }, 'changeConsentOptions'));
  };

  return {
    type: actions.PROCESS_TOGGLE_CONSENTS_OPTIONS_CHANGE,
    payload: getPromise(),
  };
};

export const updateSettings = (values, formName) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.patchClientSettings(values));

    if (error) {
      dispatch(setFormApiError(formName, payload));
      throw Error('Change receive news failed');
    }

    dispatch(api.fetchClientSettings());
  };

  return {
    type: actions.UPDATE_CLIENT_CONSENTS,
    payload: getPromise(),
  };
};

export const updateAcceptNewsConsent = (values, formName) => ({ dispatch }) => {
  const getPromise = async () => {
    const { payload, error } = await dispatch(api.patchClientSettingsAcceptNews(values));

    if (error) {
      dispatch(setFormApiError(formName, payload));
      throw Error('Change accept news consent failed');
    }

    dispatch(api.fetchClientSettings());
  };

  return {
    type: actions.UPDATE_ACCEPT_NEWS_CONSENT,
    payload: getPromise(),
  };
};

export const dismissConsentPopup = (value = true) => ({
  type: actions.DISMISS_CONSENT_POPUP,
  payload: value,
});
