import authentication from './authentication/reducer';
import calculator from './calculator/reducer';
import client from './client/reducer';
import customDocumentUpload from './documentUpload/reducer';
import device from './lib/device/reducer';
import identification from './identification/reducer';
import loans from './loans/reducer';
import mymoid from './mymoid/reducer';
import passwordRecovery from './passwordRecovery/reducer';
import payment from './payment/reducer';
import persistence from './lib/persistence/reducer';
import application from './application/reducer';
import settings from './settings/reducer';
import ui from './ui/reducer';

import { reducer as api } from './api';
import { reducer as apiUrls } from '4finance-http';
import { reducer as contentful } from '4finance-contentful';
import { reducer as documentUpload } from '4finance-redux-file-upload';
import { reducer as onionForm } from '4finance-onion-form';
import { reducer as pendingActions } from '4finance-pending-actions';
import { reducer as translate } from '4finance-translate';
import { routerReducer as routing } from 'react-router-redux';
import { zopimReducer as zopim } from '4finance-zopim';

const appReducers = {
  api,
  apiUrls,
  application,
  authentication,
  calculator,
  client,
  contentful,
  customDocumentUpload,
  device,
  documentUpload,
  identification,
  loans,
  mymoid,
  onionForm,
  passwordRecovery,
  payment,
  pendingActions,
  persistence,
  routing,
  settings,
  translate,
  ui,
  zopim,
};

export default appReducers;
