import EmailOrWhatsappBox from './components/EmailOrWhatsappBox.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import routesList from '../../routesList';
import { connectFunnels } from '../../connect';

@connectFunnels
export default class EmailOrWhatsapp extends Component {
  static propTypes = {
    funnelPaths: RPT.object.isRequired,
  }

  render() {
    const { funnelPaths } = this.props;

    return (<EmailOrWhatsappBox
      to={funnelPaths[routesList.applicationIdentificationProcessing]}
      differentMethods={['instantor', 'upload']}
      type="incomeValidation"
    />);
  }
}
