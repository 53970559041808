import Radium from 'radium';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { ButtonLink, Column, Container, StyledComponent } from '4finance-components';
import { connectActions } from '../../connect';

@connectActions({
  showZaploClosingPopup: ['settings', 'showZaploClosingPopup'],
})
@translate
@Radium
export default class RequestLoan extends StyledComponent {
  static styleRoot = 'RequestLoan';

  static propTypes = {
    source: RPT.string.isRequired,
    showZaploClosingPopup: RPT.func.isRequired,
  };

  render() {
    const { source, msg, showZaploClosingPopup } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <Container>
          <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
            <div style={style.row}>
              <ReactMarkdown source={source} />
            </div>
            <div style={style.row}>
              <ButtonLink onClick={showZaploClosingPopup}>
                {msg('contentful.featuresPage.requestLoanButton')}
              </ButtonLink>
            </div>
          </Column>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      backgroundColor: colors.secondary,
      fontSize: '50px',
      fontWeight: '200',
      lineHeight: '1.1',
      padding: '25px 0',
      textAlign: 'center',

      [mediaQueries.breakpointSmall]: {
        fontSize: '32px',
      },
    },

    row: {
      margin: '25px 0',
    },
  });
}
