import { default as api } from '../actions';

export const PROCESS_FETCH_FAQS = 'PROCESS_FETCH_FAQS';

export const prefetchFaqs = () => ({ dispatch, getState }) => {
  async function getPromise() {
    const fetched = getState().contentful.getIn(['entries', 'faq', 'fetched']);
    const pending = ~getState().pendingActions.indexOf(PROCESS_FETCH_FAQS);

    if (!fetched && !pending) {
      dispatch(api.contentful.fetchContentfulFaqs());
    }
  }

  return {
    type: PROCESS_FETCH_FAQS,
    payload: getPromise(),
  };
};
