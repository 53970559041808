import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import { Heading, StyledComponent } from '4finance-components';

@Radium
export default class PageHeader extends StyledComponent {
  static styleRoot = 'PageHeader';

  static propTypes = {
    title: RPT.oneOfType([
      RPT.string,
      RPT.object,
    ]).isRequired,
    subtitle: RPT.oneOfType([
      RPT.string,
      RPT.array,
      RPT.node,
    ]),
    textAlign: RPT.oneOf(['left', 'center', 'right']),
    inheritedStyle: RPT.object,
    size: RPT.oneOf(['small', 'big']),
    padded: RPT.bool,
    kind: RPT.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
    decorated: RPT.bool,
    decorationStyle: RPT.oneOfType([RPT.arrayOf(RPT.object), RPT.object]),
    hideTitleOnTablet: RPT.bool,
  }

  static defaultProps = {
    textAlign: 'center',
    subtitle: null,
    size: 'big',
    padded: false,
    kind: 'h1',
    decorated: false,
    decorationStyle: {},
  }

  render() {
    const { decorated, decorationStyle, kind, textAlign, title, size, subtitle, inheritedStyle } = this.props;
    const style = this.getStyle();

    const subtitleArray = (subtitle && Array.isArray(subtitle) ? subtitle : [subtitle]) || [];

    return (
      <div style={[style.wrapper, style.wrapper[size], style.wrapper[textAlign], inheritedStyle]}>
        <Heading bold kind={kind} inheritedStyle={style.heading} decorated={decorated} decorationStyle={decorationStyle}>
          {title}
        </Heading>

        {subtitle && subtitleArray.map((subtitleContent, index) =>
          <div style={style.subtitle} key={`subtitle_${index}`}>
            {subtitleContent}
          </div>
        )}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { padded, textAlign, hideTitleOnTablet }) => ({
    wrapper: {
      [mediaQueries.breakpointStartLaptop]: {
        marginTop: '-10px',
      },

      right: {
        textAlign: 'right',
      },

      left: {
        textAlign: 'left',
      },

      center: {
        textAlign: 'center',

        [mediaQueries.breakpointSmall]: {
          textAlign: 'left',
        },
      },

      small: {
        [mediaQueries.breakpointStartLaptop]: {
          width: '500px',
        },
      },
    },

    heading: {
      width: '100%',
      fontSize: '47px',
      color: colors.darkFont,
      lineHeight: '46px',
      marginLeft: 0,
      marginBottom: '1em',
      marginTop: 0,

      [mediaQueries.breakpointTablet]: {
        display: hideTitleOnTablet ? 'none' : 'block',
        fontSize: '30px',
        lineHeight: '35px',
        width: padded ? '95%' : '100%',
        paddingLeft: padded ? '5%' : 0,
        textAlign,
        marginTop: 0,
      },

      [mediaQueries.breakpointSmall]: {
        fontSize: '26px',
        lineHeight: '28px',
        width: '90%',
        textAlign: 'left',
        marginBottom: '0.7em',
      },
    },

    subtitle: {
      width: '100%',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '25px',
      color: colors.grayFont,
      marginBottom: '30px',

      [mediaQueries.breakpointTablet]: {
        textAlign: 'left',
        width: '95%',
        paddingLeft: padded ? '5%' : 0,
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  });
}
