import Jumbotron from '../components/Jumbotron';
import Radium from 'radium';
import React from 'react';
import translate from '4finance-translate';
import { Heading, StyledComponent } from '4finance-components';

@translate
@Radium
export default class ApprovalRejected extends StyledComponent {
  static styleRoot = 'ApprovalRejected';

  render() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (
      <Jumbotron
        icon="resolution-sadface"
        title={cnt('approval.rejected.title')}
        textAlign="center"
        kind="big"
        inheritedStyle={style.jumbotron}
      >
        <div style={style.innerWrapper}>
          <Heading kind="h3" inheritedStyle={style.heading}>
            {msg('approval.rejected.reason.title')}
          </Heading>


          <Heading kind="h3" inheritedStyle={style.heading}>
            {msg('approval.rejected.contact.title')}
          </Heading>
          <p style={style.paragraph}>
            {msg('approval.rejected.contact.description')}
          </p>
        </div>
      </Jumbotron>
    );
  }

  static style = ({ mediaQueries }) => ({
    jumbotron: {
      paddingBottom: '50px',

      [mediaQueries.breakpointSmall]: {
        paddingBottom: '30px',
      },

      title: {
        fontWeight: 'bold',
      },
    },

    heading: {
      fontSize: '18px',
      marginBottom: '15px',
    },

    list: {
      list: {
        marginBottom: '30px',
        fontSize: '14px',
      },

      item: {
        marginTop: 0,
      },
    },

    paragraph: {
      fontSize: '14px',
      lineHeight: '20px',
    },

    innerWrapper: {
      textAlign: 'left',
      paddingLeft: '15px',
      paddingRight: '15px',

      [mediaQueries.breakpointStartTablet]: {
        paddingLeft: '50px',
        paddingRight: '50px',
      },
    },
  })
}
