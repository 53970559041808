import CalculatorLoanInfoLink from './CalculatorLoanInfoLink.react';
import CalculatorOffer from './CalculatorOffer.react';
import CalculatorSliders from './CalculatorSliders.react';
import listenWindowResize, { Device } from '../../lib/listenWindowResize.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Button, StyledComponent } from '4finance-components';
import { connectPrefetch, connectSelectors } from '../../../browser/connect';
import { RPTcalculatorOfferSelector } from '../../../common/calculator/selectors';

@connectPrefetch([
  ['calculator', 'fetchCalculatorWithOffer'],
])
@connectSelectors({
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@translate
@listenWindowResize
@Radium
export default class Calculator extends StyledComponent {
  static styleRoot = 'Calculator'

  static propTypes = {
    calculatorOfferSelector: RPTcalculatorOfferSelector,
    device: RPT.instanceOf(Device).isRequired,
    embedded: RPT.bool,
    noBorderRadius: RPT.bool,
    numberToCurrency: RPT.func.isRequired,
    onApply: RPT.func,
    toggled: RPT.bool,
    homePage: RPT.bool,
  }

  static defaultProps = {
    toggled: false,
    noBorderRadius: false,
    homePage: false,
  }

  onApply = (event) => {
    event.preventDefault();
    const { onApply } = this.props;

    onApply();

    return false;
  }

  renderSubmitButton = () => {
    const { msg, calculatorOfferSelector: { offerFetched }, onApply, toggled } = this.props;
    const style = this.getStyle();

    return (!toggled && onApply &&
      <div style={style.applyButtonWrapper}>
        <Button
          disabled={!offerFetched}
          fullWidth
          id="applyButton"
          kind="primary"
          loading={!offerFetched}
          onClick={onApply}
          inheritedStyle={style.applyButton}
        >
          {msg('home.get_loan.apply_now')}
        </Button>
      </div>
    );
  }

  render() {
    const { device, embedded, toggled, homePage } = this.props;
    const style = this.getStyle();

    const isScreenWidthUnderTablet = device.atMost('tablet');

    return (
      <div className="calculator" style={style.outerWrapper}>
        <div style={style.innerWrapper}>
          <div style={style.sliderWrapper}>
            <CalculatorSliders embedded={embedded} />
          </div>
          {isScreenWidthUnderTablet &&
            this.renderSubmitButton()
          }

          <div style={style.offerWrapper}>
            {!toggled &&
              <CalculatorOffer hasInterestRateAndAnnualPercentageRate hasTotalRepayableAmount homePage={homePage} />
            }
            {!isScreenWidthUnderTablet &&
              this.renderSubmitButton()
            }
            {!toggled && <CalculatorLoanInfoLink style={style.loanInfoLink} />}
          </div>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }, { noBorderRadius, embedded }) => ({
    outerWrapper: {
      position: 'relative',
    },

    innerWrapper: {
      borderRadius: noBorderRadius ? 0 : '8px',
      boxShadow: !embedded && '0 1px 4px rgba(0,0,0,.15)',
      overflow: 'hidden',
      backgroundColor: !embedded && colors.white,
      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
      },
    },

    sliderWrapper: {
      backgroundColor: embedded ? 'transparent' : colors.white,
      padding: embedded ? '15px' : '15px 24px 5px 24px',
      [mediaQueries.breakpointTablet]: {
        padding: 0,
      },
    },

    offerWrapper: {
      backgroundColor: colors.secondary,
      padding: embedded ? '0 25px' : '12px 24px 0',
      [mediaQueries.breakpointTablet]: {
        backgroundColor: colors.white,
      },
    },

    applyButtonWrapper: {
      width: '100%',
      padding: '8px 0 0',
      backgroundColor: colors.secondary,

      [mediaQueries.breakpointTablet]: {
        backgroundColor: colors.white,
        padding: '4px 22px 15px',
      },
    },

    applyButton: {
      height: '60px',
    },

    loanInfoLink: {
      wrapper: {
        padding: '15px 0',
      },

      link: {
        fontSize: '14px',
        color: colors.darkFont,
      },
    },
  })
}
