import dayjs from 'dayjs';
import Notification from '../../components/Notification.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../../connect';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  nextPayment: ['loans', 'nextPayment'],
  hasLatestLoan: ['loans', 'hasLatestLoan'],
})
@translate
export default class OverdueNotification extends Component {
  static propTypes = {
    msg: RPT.func,
    loan: RPT.object,
    nextPayment: RPT.object,
    hasLatestLoan: RPT.bool,
  }

  static defaultProps = {
    loan: new Map(),
  }

  render() {
    const { msg, loan, nextPayment, hasLatestLoan } = this.props;
    const paymentDate = nextPayment.date;
    const overDueDays = loan.get('overDueDays');
    const daysLeft = Math.ceil(dayjs(paymentDate).diff(new Date(), 'days', true));

    return (
      <Notification
        body={overDueDays ? msg('messages.overdue.text', { days: overDueDays }) : msg('messages.overdue.warning', { days: daysLeft })}
        noClose
        type={overDueDays ? 'error' : 'warning'}
        visible={hasLatestLoan && paymentDate && daysLeft <= 3}
      />
    );
  }
}
