import CommonLayout from '../layouts/Common.react';
import ConsentPopup from './consentPopup/ConsentPopup.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';
import { connectPrefetch } from '../connect';

@connectPrefetch([
  ['client', 'fetchClientInfo'],
])
export default class Dashboard extends Component {
  static styleRoot = 'Dashboard';

  static propTypes = {
    children: RPT.object.isRequired,
    location: RPT.object.isRequired,
  }

  render() {
    const { children, location } = this.props;

    return (
      <CommonLayout location={location}>
        <ConsentPopup />
        {children}
      </CommonLayout>
    );
  }
}
