import config from '4finance-configuration';
import * as random from './definitions';
import { actions as fileUpload } from '4finance-redux-file-upload';
import { actions as form } from '4finance-onion-form';

export const RANDOM_FILL = 'RANDOM_FILL';

export function applyRandomFill(formName) {
  return ({ dispatch }) => {
    if (!config.faker.enabled) {
      return { type: 'DISABLED_RANDOM_FILL' };
    }

    const randomFillFunction = random[formName] || (() => ({})); // eslint-disable-line import/namespace

    dispatch(form.setMultipleFields(formName, 'value', randomFillFunction()));
    dispatch(form.clearFormProperty(formName, 'error'));

    return { type: RANDOM_FILL };
  };
}

export function uploadDocument(fields, changeOpenedField) {
  return ({ dispatch }) => {
    const promise = () => Promise.map(fields, async (field, key) => { // eslint-disable-line no-unused-expressions
      const randomImage = await random.document();

      changeOpenedField(key + 1, true)();

      return dispatch(fileUpload.addUploadingImages(field, [randomImage]));
    });

    return {
      type: 'RANDOM_FILL_DOCUMENT_UPLOAD',
      payload: promise(),
    };
  };
}
