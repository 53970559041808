import DifferentMethod from '../../components/DifferentMethod.react';
import Jumbotron, { YellowBox } from '../../../components/Jumbotron';
import listenWindowResize, { Device } from '../../../lib/listenWindowResize.react';
import PageContainer from '../../../components/PageContainer.react';
import PageHeader from '../../../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import SendDocuments from './SendDocuments.react';
import translate from '4finance-translate';
import { ButtonLink, StyledComponent } from '4finance-components';

@listenWindowResize
@translate
@Radium
export default class EmailOrWhatsappBox extends StyledComponent {
  static styleRoot = 'EmailOrWhatsappBox'

  static propTypes = {
    cnt: RPT.func.isRequired,
    device: RPT.instanceOf(Device).isRequired,
    msg: RPT.func.isRequired,
    differentMethods: RPT.array,
    to: RPT.string,
    type: RPT.oneOf(['identification', 'incomeValidation']),
  }

  render() {
    const { cnt, device, differentMethods, msg, to, type } = this.props;
    const style = this.getStyle();
    const isScreenWidthUnderTablet = device.atMost('tablet');

    return (
      <PageContainer width="wide" style={style.container}>
        <PageHeader
          title={msg('identification.emailOrWhatsapp.title')}
          subtitle={cnt(isScreenWidthUnderTablet
            ? `identification.emailOrWhatsapp.box.description.${type}`
            : `identification.emailOrWhatsapp.description.${type}`
          )}
        />

        <Jumbotron hasPadding={false} inheritedStyle={style.jumbotron}>
          <YellowBox
            icon="arrowup"
            iconSize={59}
            title={msg('identification.emailOrWhatsapp.box.title')}
            hasPadding
            style={style.yellowBox}
          >
            <div style={style.description}>
              {cnt(`identification.emailOrWhatsapp.box.description.${type}`)}
            </div>
          </YellowBox>
          <SendDocuments documentType={type} />
        </Jumbotron>

        {isScreenWidthUnderTablet &&
          <div style={style.button.description}>
            {cnt('identification.emailOrWhatsapp.button.description')}
          </div>
        }

        <ButtonLink
          to={to}
          wrapperStyle={style.button.wrapper}
          inheritedStyle={style.button}
        >
          {msg('common.send')}
        </ButtonLink>

        <DifferentMethod
          types={differentMethods}
        />

      </PageContainer>
    );
  }

  static style = ({ mediaQueries }) => ({
    container: {
      wrapper: {
        [mediaQueries.breakpointTablet]: {
          marginBottom: '20px',
        },
      },
    },

    jumbotron: {
      [mediaQueries.breakpointTablet]: {
        marginLeft: '-20px',
        marginRight: '-20px',
      },
    },

    yellowBox: {
      wrapper: {
        [mediaQueries.breakpointTablet]: {
          display: 'none',
        },
      },
    },

    button: {
      marginTop: 0,
      height: '60px',
      lineHeight: '45px',

      [mediaQueries.breakpointLaptop]: {
        height: '60px',
        lineHeight: '45px',
      },

      [mediaQueries.breakpointLarge]: {
        minWidth: '300px',
      },

      [mediaQueries.breakpointTablet]: {
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
      },

      wrapper: {
        width: '100%',
        textAlign: 'center',
        marginTop: '46px',

        [mediaQueries.breakpointTablet]: {
          marginTop: '15px',
        },
      },

      description: {
        paddingLeft: '5%',
        paddingRight: '5%',
        fontSize: '14px',
        marginTop: '20px',
      },
    },
  });
}
