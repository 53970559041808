import { TextField } from '4finance-components';
import { isRequired, minLength, password as isPassword } from '4finance-validation';
import translatedField from '../../../lib/translatedField';

const passwordFieldProps = {
  bottomMargin: false,
  type: 'password',
  width: 'full',
  style: {
    marginTop: '0',
    marginBottom: '0',
    padding: '0',
    wrapper: { margin: '0', padding: '0', background: 'black' },
    hint: { fontSize: '12px' },
  },
};

export const NewPasswordField = translatedField('newPassword', {
  autocomplete: 'new-password',
  topMargin: false,
  ...passwordFieldProps,
}, [isPassword(), isRequired(), minLength(6)])(TextField);

export const PasswordField = translatedField('password', {
  autocomplete: 'current-password',
  ...passwordFieldProps,
}, [isPassword(), isRequired(), minLength(6)])(TextField);

/*
  Amazing Forms
  https://developers.google.com/web/fundamentals/design-and-ux/input/forms/#recommended_input_name_and_autocomplete_attribute_values
*/
export const UserNameField = translatedField('username', {
  type: 'text',
  autocomplete: 'username',
  style: {
    wrapper: {
      display: 'none',
      visibility: 'hidden',
    },
  },
})(TextField);
