import Icon from './Icon.react';
import Link from './Link.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState, connectSelectors } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
})
@connectSelectors({
  isFintonicOrPrestaloProduct: ['client', 'isFintonicOrPrestaloProduct'],
})
@translate
@Radium
export default class LoanDownloadLinks extends StyledComponent {
  static styleRoot = 'LoanDownloadLinks'

  static propTypes = {
    loan: RPT.object,
    msg: RPT.func,
    isFintonicOrPrestaloProduct: RPT.bool,
  }

  static defaultProps = {
    loan: new Map(),
  }

  render() {
    const { msg, loan, isFintonicOrPrestaloProduct } = this.props;
    const style = this.getStyle();
    const loanJson = loan.toJS();

    return (
      <div style={style.wrapper}>
        {!loanJson.debtCollectLoanAgreement && loanJson.payableAmount !== 0 && !loanJson.overDueDays &&
          <Link inheritedStyle={style.link} to="/webapi/client/loans/latest/invoice" target="_blank">
            <Icon kind="pdf" size={20} style={style.icon} />
            {msg('dashboard.loanDetails.invoiceDownloadTitle')}
          </Link>
        }
        {!loanJson.debtCollectLoanAgreement && !isFintonicOrPrestaloProduct &&
          <Link to="/webapi/client/loans/latest/agreement" target="_blank" inheritedStyle={[style.link, style.nextLink]}>
            <Icon kind="pdf" size={20} style={style.icon} />
            {msg('dashboard.loanDetails.agreementDownloadTitle')}
          </Link>
        }
      </div>
    );
  }

  static style = ({ mediaQueries }) => ({
    wrapper: {
      fontSize: '14px',
      marginTop: '20px',
    },

    link: {
      marginRight: '10px',
    },

    nextLink: {
      [mediaQueries.breakpointSmall]: {
        display: 'block',
        marginLeft: 0,
        marginTop: '15px',
      },
    },

    icon: {
      verticalAlign: 'bottom',
      marginBottom: '-2px',
      marginRight: '5px',
    },
  })
}
