export const employmentValueMapping = {
  EMPLOYMENT_AGRICULTURE: 'Employee in agriculture',
  EMPLOYMENT_HOUSWIFE: 'Housewife',
  EMPLOYMENT_MERCHANT: 'Street merchants',
  EMPLOYMENT_MILITARY: 'Military, police, civil guard',
  EMPLOYMENT_NON_PROFIT: 'Working in non-profit organization',
  EMPLOYMENT_PENSIONER: 'Pensioner',
  EMPLOYMENT_PERMANENT_CONTRACT_PRIVATE: 'Permanent contract (private sector)',
  EMPLOYMENT_PERMANENT_CONTRACT_PUBLIC: 'Permanent contract (public sector)',
  EMPLOYMENT_PRE_RETIRED: 'Pre retired',
  EMPLOYMENT_RETIRED: 'Retired',
  EMPLOYMENT_SELF: 'Self-employed',
  EMPLOYMENT_STUDENT: 'Student',
  EMPLOYMENT_TERM_CONTRACT_PRIVATE: 'Term contract (private sector)',
  EMPLOYMENT_TERM_CONTRACT_PUBLIC: 'Term contract (public sector)',
  EMPLOYMENT_UNEMPLOYED_NO_PROVISION: 'Unemployed without benefits',
  EMPLOYMENT_UNEMPLOYED_PROVISION: 'Unemployed with provision',
};

export default [
  'EMPLOYMENT_AGRICULTURE',
  'EMPLOYMENT_HOUSWIFE',
  'EMPLOYMENT_MERCHANT',
  'EMPLOYMENT_MILITARY',
  'EMPLOYMENT_NON_PROFIT',
  'EMPLOYMENT_PENSIONER',
  'EMPLOYMENT_PERMANENT_CONTRACT_PRIVATE',
  'EMPLOYMENT_PERMANENT_CONTRACT_PUBLIC',
  'EMPLOYMENT_PRE_RETIRED',
  'EMPLOYMENT_RETIRED',
  'EMPLOYMENT_SELF',
  'EMPLOYMENT_STUDENT',
  'EMPLOYMENT_TERM_CONTRACT_PRIVATE',
  'EMPLOYMENT_TERM_CONTRACT_PUBLIC',
  'EMPLOYMENT_UNEMPLOYED_NO_PROVISION',
  'EMPLOYMENT_UNEMPLOYED_PROVISION',
];
