import StylizedAmount from '../../components/StylizedAmount.react';
import dayjs from 'dayjs';
import Radium from 'radium';
import routesList from '../../routesList';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../../connect';
import { StyledComponent, ButtonLink } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  nextPayment: ['loans', 'nextPayment'],
})
@translate
@Radium
export default class MonthlyPayment extends StyledComponent {
  static styleRoot = 'MonthlyPayment'

  static propTypes = {
    loan: RPT.object.isRequired,
    nextPayment: RPT.object.isRequired,
    formatDate: RPT.func,
    msg: RPT.func,
    paymentAvailable: RPT.bool,
  }

  static defaultProps = {
    loan: new Map(),
    nextPayment: {},
  }

  renderPaymentDayInfo() {
    const { loan, msg, nextPayment, formatDate } = this.props;
    const style = this.getStyle();
    const overDueDays = loan.get('overDueDays');
    const paymentDate = nextPayment.date;
    const brokenLoan = loan.get('debtCollectLoanAgreement');
    const daysLeft = Math.ceil(dayjs(paymentDate).diff(new Date(), 'days', true));

    return (
      <div style={[style.dueDateWrapper, (daysLeft < 2 || brokenLoan) && style.errorMessage]}>
        <p>{msg('loan.due_date.title')}</p>
        <p>
          <strong>{formatDate(paymentDate)} </strong>
          ({overDueDays ?
            msg('loan.due_date.days_overdue', { days: overDueDays })
            :
            msg('loan.due_date.days_left', { days: daysLeft })
          })
        </p>
      </div>
    );
  }

  render() {
    const { msg, paymentAvailable, nextPayment } = this.props;
    const style = this.getStyle();
    const hasPenalty = !!nextPayment.penalty;

    return (
      <div style={[style.container]}>
        <div style={[style.row, style.mainInfo]}>
          <div style={[style.monthlyAmount, hasPenalty && style.errorMessage]}>
            <StylizedAmount amount={nextPayment.total} />
          </div>

          {!!nextPayment.date && this.renderPaymentDayInfo()}
        </div>

        <div style={style.row}>
          <ButtonLink style={style.payButton} to={routesList.payment} disabled={!paymentAvailable}>
            {msg('loan.actions.make_payment')}
          </ButtonLink>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    container: {
      [mediaQueries.breakpointLaptopSmall]: {
        marginBottom: '40px',
      },
    },

    gray: {
      fill: colors.monotone2,
    },

    errorMessage: {
      color: colors.error,
    },

    disabled: {
      color: colors.monotone2,
      pointerEvents: 'none',
    },

    mainInfo: {
      marginBottom: '25px',
    },

    hidden: {
      visibility: 'hidden',
    },

    row: {
      display: 'flex',
      alignItems: 'center',

      [mediaQueries.breakpointLaptopSmall]: {
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      },
    },

    column: {
      display: 'flex',
      flexDirection: 'column',
    },

    monthlyAmount: {
      margin: '15px 0',
    },

    profileLink: {
      fontSize: '19px',
      lineHeight: '18px',

      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
    },

    payButton: {
      base: {
        margin: 0,
        minWidth: '310px',
      },

      wrapper: {
        base: {
          marginRight: '20px',
          marginTop: 0,
          width: 'auto',

          [mediaQueries.breakpointLaptopSmall]: {
            marginRight: 0,
          },
        },
      },
    },

    dueDateWrapper: {
      marginLeft: '50px',
      fontWeight: '100',
      [mediaQueries.breakpointLaptopSmall]: {
        marginLeft: 0,
      },
    },

    calendar: {
      marginRight: '10px',
      marginBottom: '-5px',
    },
  });
}
