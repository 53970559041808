import CommonLayout from '../layouts/Common.react';
import Radium from 'radium';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';

@Radium
export default class ProcessingPage extends Component {
  static styleRoot = 'ProcessingPage';

  static propTypes = {
    children: RPT.node.isRequired,
    location: RPT.object.isRequired,
  }

  render() {
    const { children, location } = this.props;

    return (
      <CommonLayout location={location}>
        {children}
      </CommonLayout>
    );
  }
}
