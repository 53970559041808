import Icon from '../../../components/Icon.react';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class Contact extends StyledComponent {
  static styleRoot = 'Contact';

  static propTypes = {
    msg: RPT.func.isRequired,
    type: RPT.oneOf(['mail', 'whatsapp']).isRequired,
    value: RPT.string.isRequired,
  };

  render() {
    const { msg, type, value } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper} className="contact-icon-wrapper">
        <Icon
          kind={type}
          style={style.icon}
          size={45}
        />
        <Style
          scopeSelector=".contact-icon-wrapper"
          rules={style.scope}
        />
        <div style={style.title}>{msg(`identification.emailOrWhatsapp.method.${type}`)}</div>
        <div>
          {type === 'whatsapp' && `${msg('identification.emailOrWhatsapp.method.whatsapp')}: `}
          <a href={`${type === 'mail' ? 'mailto:' : 'https://api.whatsapp.com/send?phone='}${value.replace(/\s+/g, '')}`}>
            {value}
          </a>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    scope: {
      'svg g path:first-of-type': {
        stroke: colors.primary,
      },

      'svg g path:last-of-type': {
        fill: colors.primary,
      },

      mediaQueries: {
        [mediaQueries.breakpointTabletScope]: {
          'svg g path:first-of-type': {
            stroke: colors.secondary,
          },

          'svg g path:last-of-type': {
            fill: colors.secondary,
          },
        },
      },
    },

    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      [mediaQueries.breakpointTablet]: {
        marginTop: '25px',
      },
    },

    title: {
      marginBottom: '30px',
      marginTop: '30px',
      textTransform: 'uppercase',
      fontSize: '16px',
      lineHeight: '25px',
      fontWeight: 'bold',

      [mediaQueries.breakpointTablet]: {
        fontSize: '22px',
        textTransform: 'none',
        marginTop: '20px',
        marginBottom: '20px',
      },
    },
  });
}
