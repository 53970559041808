import FaqLinkItems from '../components/FaqLinkItems.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, StyledComponent, Heading } from '4finance-components';
import { Link } from 'react-router';

@translate
@Radium
export default class NeedHelp extends StyledComponent {
  static styleRoot = 'NeedHelp'

  static propTypes = {
    msg: RPT.func.isRequired,
  }

  render() {
    const { msg } = this.props;
    const styles = this.getStyle();

    return (
      <div style={styles.wrapper}>
        <Container>
          <Heading inheritedStyle={styles.heading} bold kind="h2" noMargin>
            {msg('home.need_help.title')}
          </Heading>
          <Heading inheritedStyle={styles.subheading} kind="h3" noMargin>
            {msg('home.need_help.subtitle')}
          </Heading>
          <ul style={styles.list}>
            <FaqLinkItems limit={3} style={styles.faqLinkItems} />
          </ul>
          <p style={styles.findMore}>
            {`${msg('home.need_help.find_more.first_part')} `}
            <Link style={styles.findMore.link} to={routesList.contentFaq}>{msg('home.need_help.find_more.link_part')}</Link>
          </p>
        </Container>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    wrapper: {
      padding: '30px 0 40px',
      textAlign: 'center',
      background: colors.primary,
      color: colors.white,

    },

    icon: {
      height: '50px',
    },

    heading: {
      color: colors.white,
      marginTop: '15px',
      fontSize: '45px',
      [mediaQueries.breakpointTablet]: {
        fontSize: '35px',
      },
      [mediaQueries.breakpointSmall]: {
        fontSize: '32px',
      },
    },

    subheading: {
      color: colors.white,
      fontSize: '21px',
      fontWeight: 100,
      marginTop: '10px',
      [mediaQueries.breakpointTablet]: {
        fontSize: '15px',
        marginTop: '10px',
      },
    },

    button: {
      padding: '14px',
      width: '310px',
    },

    faqLinkItems: {
      item: {
        fontSize: '19px',
        lineHeight: '19px',
        fontWeight: 100,
        [mediaQueries.breakpointTablet]: {
          marginTop: '5px',
          lineHeight: '18px',
          fontSize: '18px',
          padding: '5px 0',
        },
        [mediaQueries.breakpointSmall]: {
          lineHeight: '18px',
          fontSize: '17px',
        },
      },
      link: {
        color: colors.white,
      },
    },

    list: {
      listStyleType: 'none',
      marginTop: '30px',
      padding: 0,

      [mediaQueries.breakpointSmall]: {
        marginTop: '20px',
      },
    },

    findMore: {
      marginTop: '40px',
      fontSize: '16px',
      fontWeight: 400,
      marginBottom: 0,

      link: {
        color: colors.white,
      },

      [mediaQueries.breakpointSmall]: {
        marginTop: '30px',
      },
    },
  })
}
