import Icon from '../components/Icon.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { StyledComponent } from '4finance-components';

@translate
@Radium
export default class AlternativeProposal extends StyledComponent {
  static styleRoot = 'AlternativeProposal';

  static propTypes = {
    from: RPT.shape({
      value: RPT.oneOfType([RPT.string, RPT.number]),
      unit: RPT.string,
    }),
    to: RPT.shape({
      value: RPT.oneOfType([RPT.string, RPT.number]),
      unit: RPT.string,
    }),
  }

  render() {
    const { from: { value: valueFrom, unit: unitFrom }, to: { value: valueTo, unit: unitTo } } = this.props;
    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <span style={[style.value, style.from]}>
          {valueFrom}
          {unitFrom}
        </span>
        <Icon
          kind="arrow"
          size={25}
          style={style.icon}
        />
        <span style={style.value}>
          {valueTo}
          {unitTo}
        </span>
      </div>
    );
  }

  static style = {

    wrapper: {
      fontSize: '40px',
      fontWeight: 'bold',
      letterSpacing: '-2px',
      display: 'flex',
      alignItems: 'center',
    },

    from: {
      opacity: '0.41',
    },

    value: {
      display: 'flex',
    },

    icon: {
      marginLeft: '15px',
      marginRight: '15px',
    },
  }
}
