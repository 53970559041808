import setFormApiError from './setFormApiError';

export default function checkForApiError({ response, dispatch, formName, mapping = {}, callback }) {
  if (response.error) {
    dispatch(setFormApiError(formName, response.payload, mapping));
    if (callback) {
      callback(response.payload);
    }
    throw Error(`Submit ${formName} failed`);
  }
}

export function checkForApiErrors({ responses, dispatch, formName }) {
  responses.forEach(response => checkForApiError({ response, dispatch, formName }));
}
