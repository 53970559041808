import CommonLayout from '../layouts/Common.react';
import ProgressBar from '../components/ProgressBar.react';
import React, { PureComponent as Component } from 'react';
import RPT from 'prop-types';

export default class Page extends Component {
  static propTypes = {
    children: RPT.object.isRequired,
    location: RPT.object.isRequired,
  }

  render() {
    const { children, location } = this.props;

    return (
      <CommonLayout location={location} isRegistration>
        <ProgressBar step={2} />
        {children}
      </CommonLayout>
    );
  }
}
