import Logo from '../../components/Logo.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { AgreeGetNews, Submit } from './fields';
import { connectActions, connectSelectors, connectPrefetch } from '../../connect';
import { Form } from '4finance-onion-form';
import { Icon, Modal, StyledComponent } from '4finance-components';

const FORM_NAME = 'marketingConsent';

@connectPrefetch([
  ['api', 'fetch', 'client', 'settings'],
])
@connectSelectors({
  showMarketingConsent: ['client', 'showMarketingConsent'],
})
@connectActions({
  dismissConsentPopup: ['settings', 'dismissConsentPopup'],
  updateAcceptNewsConsent: ['settings', 'updateAcceptNewsConsent'],
})
@translate
@Radium
export default class ConsentPopup extends StyledComponent {
  static styleRoot = 'ConsentPopup';

  static propTypes = {
    showMarketingConsent: RPT.bool,
    updateAcceptNewsConsent: RPT.func.isRequired,
  };

  onClose = () => {
    const { dismissConsentPopup } = this.props;

    dismissConsentPopup();
  }

  onSubmit = () => {
    const { dismissConsentPopup, updateAcceptNewsConsent } = this.props;

    updateAcceptNewsConsent({
      acceptNews: true,
    }, FORM_NAME);
    dismissConsentPopup();
  }

  renderContent() {
    const { cnt, msg } = this.props;
    const style = this.getStyle();

    return (<div>
      <div style={style.wrapper}>
        <div style={style.backgroundSquare}></div>
        <Icon
          kind="close-thin"
          size={16}
          style={style.close}
          onClick={this.onClose}
        />
        <div style={style.content}>
          <div style={style.logoWrapper}>
            <Logo />
          </div>
          <div style={style.topContent}>
            <div style={style.leftContent}>
              <div style={style.leftContent.text}>{cnt('dashboard.marketingConsent.text1')}</div>
            </div>
            <div style={style.airplaneWrapper}>
              <img style={style.airplane} src="/assets/img/airplane.png" alt="" />
            </div>
          </div>
          <div style={style.agreement}>
            <div style={style.agreement.text}>
              <Form
                name={FORM_NAME}
                onSubmit={this.onSubmit}
              >
                <AgreeGetNews
                  style={style.checkbox}
                  label={cnt('form.marketingConsent.agreeGetNews.label')}
                />
                <Submit
                  inheritedStyle={style.button}
                  fullWidth
                >
                  {msg('common.accept')}
                </Submit>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }

  render() {
    const { showMarketingConsent } = this.props;
    const style = this.getStyle();

    return (
      <Modal
        isModalOpen={showMarketingConsent}
        blurSelector="#app"
        style={style.modal}
        onClose={this.onClose}
      >
        {this.renderContent()}
      </Modal>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    modal: {
      innerWrapper: {
        padding: 0,
      },
    },

    wrapper: {
      position: 'relative',
      margin: '0px',
      padding: '15px 20px',
      backgroundColor: colors.white,
      maxHeight: '90vh',
      height: '500px',
      color: colors.black,
      overflowX: 'hidden',
      overflowY: 'scroll',

      [mediaQueries.breakpointStartTablet]: {
        padding: '20px 40px',
        maxHeight: '90vh',
        minWidth: '390px',
        width: '583px',
        height: '500px',
        overflowX: 'hidden',
        overflowY: 'hidden',
      },
    },

    leftInformation: {
      width: '50%',
    },

    marketingConsentText: {
      fontFamily: '\'ProximaNova\', \'Lucida Grande\', \'sans-serif\'',
      fontSize: '11px',
      fontWeight: '600',
      color: colors.black,
      margin: '0 10px',

      [mediaQueries.breakpointStartTablet]: {
        paddingLeft: '32%',
        margin: '0 10px',
        marginTop: '-20px',
        paddingRight: '30px',
      },
    },

    backgroundSquare: {
      transform: 'rotate(-17deg)',
      backgroundColor: colors.secondary,
      height: '1000px',
      position: 'absolute',
      width: '1000px',
      top: '-795px',
      left: '-200px',

      [mediaQueries.breakpointExtraSmall]: {
        top: '-810px',
      },

      [mediaQueries.breakpointTablet]: {
        top: '-830px',
      },

      [mediaQueries.breakpointStartTablet]: {
        top: '-760px',
      },
    },

    logoWrapper: {
      display: 'none',

      [mediaQueries.breakpointStartTablet]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '5px',
        width: '100%',
      },
    },

    airplaneWrapper: {
      display: 'flex',
      justifyContent: 'center',

      [mediaQueries.breakpointStartTablet]: {
        paddingTop: '5px',
        marginLeft: '20px',
        marginRight: 'unset',
        display: 'unset',
        justifyContent: 'unset',
      },
    },

    airplane: {
      marginLeft: 'unset',
      width: '135px',
      height: '89px',

      [mediaQueries.breakpointTablet]: {
        width: '140px',
        height: '92px',
      },

      [mediaQueries.breakpointStartTablet]: {
        width: '210px',
        height: '139px',
        marginLeft: '-90px',
        marginRight: '10px',
      },
    },

    topContent: {
      display: 'flex',
      flexDirection: 'column',

      [mediaQueries.breakpointStartTablet]: {
        flexDirection: 'row',
        width: 'auto',
      },
    },

    leftContent: {
      padding: '30px 10px 0 20px',
      fontSize: '16px',
      lineHeight: '1',
      display: 'flex',
      flexDirection: 'column',

      text: {
        marginBottom: '18px',

        [mediaQueries.breakpointSmall]: {
          marginBottom: '15px',
        },
      },

      [mediaQueries.breakpointStartTablet]: {
        padding: '30px 0 0',
        fontSize: '22px',
      },
    },

    content: {
      position: 'absolute',
      paddingRight: '30px',
      marginLeft: '-10px',
    },

    agreement: {
      marginTop: '75px',
      marginLeft: '20px',

      [mediaQueries.breakpointStartTablet]: {
        marginTop: '110px',
        marginLeft: '35%',
      },
    },

    checkbox: {
      checkboxLabel: {
        fontSize: '12px',
      },

      base: {
        borderTop: `solid 1px ${colors.inputBorder}`,
        borderLeft: `solid 1px ${colors.inputBorder}`,
        borderRight: `solid 1px ${colors.inputBorder}`,
      },
    },

    button: {
      minWidth: '150px',
      height: '50px',
    },

    close: {
      position: 'fixed',
      right: '20px',
      top: '20px',
      cursor: 'pointer',
      zIndex: 2,
    },

  });
}
