import marked from 'marked';
import Radium, { Style } from 'radium';
import React from 'react';
import RPT from 'prop-types';
import StaticPageLayout from '../../layouts/StaticPage.react';
import Title from '../components/Title.react';
import translate from '4finance-translate';
import { StyledComponent, Column, Row, Container } from '4finance-components';

@translate
@Radium
export default class Policy extends StyledComponent {
  static styleRoot = 'Policy'

  static propTypes = {
    msg: RPT.func,
    page: RPT.object.isRequired,
  }

  render() {
    const { page: { fields } } = this.props;
    const { url, heading, textBlock } = fields;
    const style = this.getStyle();

    return (
      <StaticPageLayout location={{ pathname: url }} >
        <Style scopeSelector=".policy-page" rules={{ ...style.policyStyles, ...style[url.substring(1)] }} />
        <Title decorated text={heading} />

        <Container>
          <Row>
            <Column large={10} medium={12} small={12} phone={12} lgOffset={1}>
              <article className="policy-page" dangerouslySetInnerHTML={{ __html: marked(textBlock) }} />
            </Column>
          </Row>
        </Container>
      </StaticPageLayout>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    archive: {
      float: 'right',
      margin: '0 0 50px 50px',

      [mediaQueries.breakpointSmall]: {
        display: 'none',
      },
    },

    'politica-privacidad-23-03-2017': {
      mediaQueries: {
        '(max-width: 768px)': {
          ol: {
            paddingLeft: '20px !important',
          },
          'ol>li>ol': {
            paddingLeft: '0 !important',
          },
        },
      },
    },

    'politica-privacidad': {
      '>ol>li': {
        fontSize: '14px !important',
        fontWeight: 300,
      },
    },

    policyStyles: {
      mediaQueries: {
        '(max-width: 768px)': {
          ol: {
            paddingLeft: '0 !important',
          },
        },
      },

      'p, ol, li': {
        color: colors.grayDarker,
        fontSize: '14px',
      },

      a: {
        color: colors.primary,
      },

      'a:hover': {
        color: colors.primaryDarker,
      },

      '>ol>li': {
        fontSize: '24px',
        fontWeight: 'bold',
      },
      '>div>li': {
        fontSize: '24px',
        fontWeight: 'bold',
      },

      ul: {
        listStyleType: 'disc',
        padding: '0 0 10px 35px',
      },
      'ul li': {
        fontWeight: 300,
      },

      'ol>li': {
        content: 'counters(item,".") ". "',
        counterIncrement: 'item',
      },

      ol: {
        paddingLeft: '50px',
      },
      'ol li': {
        padding: '3px 0',
      },
      'ol li table': {
        margin: '10px 0',
        borderCollapse: 'collapse',
        tableLayout: 'auto',
        backgroundColor: colors.white,
      },
      'ol li table tr td': {
        border: `1px solid ${colors.grayLighter}`,
        padding: '7px',
        background: colors.white,
      },
      'ol li table tr th': {
        border: `1px solid ${colors.grayLighter}`,
        padding: '7px',
        background: colors.white,
        textAlign: 'center',
      },
      'ol li p': {
        fontWeight: 300,
      },
      'ol li ol': {
        fontWeight: 300,
        padding: '0 0 10px 0',
        listStyle: 'none',
        counterReset: 'item',
      },
      'ol li ol > li:before': {
        content: 'counters(item,".") ". "',
        fontWeight: 'bold',
      },
    },
  })
}
