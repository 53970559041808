export default [
  'PURPOUSE_LEASURE',
  'PURPOUSE_EDUCATION',
  'PURPOUSE_HOUSHOLD',
  'PURPOUSE_MEDICAL',
  'PURPOUSE_VEHICLE_REPAIRS',
  'PURPOUSE_DEBTS',
  'PURPOUSE_CAR',
  'PURPOUSE_MOTOCYCLE',
  'PURPOUSE_CELEBRATIONS',
  'PURPOUSE_OTHER',
];
