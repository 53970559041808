const ERROR = 'invalid_iban';

export default function customIbanEs() {
  return value => {
    if (!value.startsWith('ES')) {
      return ERROR;
    }

    return null;
  };
}
