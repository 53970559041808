import * as actions from './actions';
import { Record } from 'immutable';

export const InitialState = Record({
  credentialsExists: false,
  error: null,
  isLoggedIn: false,
});
const initialState = new InitialState;

export default function authenticationReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.AUTHENTICATION_LOGIN_START: {
      return state.set('error', null);
    }

    case actions.AUTHENTICATION_LOGIN_ERROR: {
      const { name } = action.payload;
      const error = name === 'ForbiddenError' ? 'forbidden' : 'unauthorized';

      return state.set('error', error);
    }

    case actions.AUTHENTICATION_SET_IS_LOGGED_IN: {
      return state.set('isLoggedIn', action.isLoggedIn);
    }
  }

  return state;
}
