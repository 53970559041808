import React from 'react';
import RPT from 'prop-types';
import Radium from 'radium';
import translate from '4finance-translate';
import config from '4finance-configuration';
import { Heading, StyledComponent } from '4finance-components';
import Icon from '../../components/Icon.react';

@translate
@Radium
export default class StopZaplo extends StyledComponent {
  static styleRoot = 'StopZaplo';

  static propTypes = {
    msg: RPT.func,
    cnt: RPT.func,
  };

  render() {
    const { msg, cnt } = this.props;

    const style = this.getStyle();

    return (
      <div style={style.wrapper}>
        <div style={style.container}>
          <Heading inheritedStyle={style.title} bold kind="h2">
            {msg('zaplo_close.title')}
          </Heading>
          <p style={style.pharagrafStopZaplo}>{msg('zaplo_close.subtitle')}</p>
          <p style={[style.pharagrafStopZaplo, style.marginP]}>
            {msg('zaplo_close.subtitle2')}
          </p>
        </div>
        <div style={style.greenSpace}>
          <div style={style.triangle}></div>
          <div style={style.logoWrapper}>
            <a href={config.vivusRedirectUrl} target="_blank">
              <Icon kind="vivus-sign" style={style.logoVivus} />
            </a>
          </div>
          <Heading kind="h2" inheritedStyle={[style.title, style.whiteTitle]}>
            {msg('zaplo_close.title2')}
          </Heading>
          <p style={[style.pharagrafStopZaplo, style.whiteSubtitle]}>
            {cnt('zaplo_close.subtitle3')}
          </p>
          <a href={config.vivusRedirectUrl} style={style.button}>
            {msg('zaplo_close.button')}
          </a>
        </div>
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    button: {
      display: 'block',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '330px',
      marginTop: '20px',
      marginBottom: '15px',
      backgroundColor: '#116c3a',
      width: '280px',
      borderRadius: '4px',
      textDecoration: 'none',
      color: colors.white,
      padding: '15px 20px 12px',
      textAlign: 'center',
      [mediaQueries.breakpointTablet]: {
        width: '100%',
      },

      ':hover': {
        backgroundColor: '#014720',
      },
    },

    logoVivus: {
      marginTop: '80px',
      width: '145px',
    },

    logoWrapper: {
      textAlign: 'center',
      marginTop: '47px',
      [mediaQueries.breakpointTablet]: {
        marginTop: '30px',
      },
    },

    wrapper: {
      width: '100%',
    },

    greenSpace: {
      height: '412px',
      backgroundColor: '#a7cc46',
      padding: '0 22px',
    },

    pharagrafStopZaplo: {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '27px',
      marginTop: '25px',
      color: '#585858',
      fontWeight: '300',
      padding: '0 26px',
      maxWidth: '442px',
      margin: '0 auto',
    },

    marginP: {
      marginTop: '10px',
    },

    container: {
      padding: '0 20px',
      fontWeight: 100,
      height: '164px',
      [mediaQueries.breakpointLaptopSmall]: {
        padding: '0 30px',
      },
    },
    triangle: {
      position: 'absolute',
      left: 'calc(50% - 225px)',
      width: '0',
      height: '0',
      borderLeft: '225px solid transparent',
      borderRight: '225px solid transparent',
      borderTop: '38px solid #F3F3F3',
      [mediaQueries.breakpointSmall]: {
        borderLeft: '190px solid transparent',
        borderRight: '190px solid transparent',
        left: 'calc(50% - 190px)',
      },
    },

    title: {
      textAlign: 'center',
      color: '#484848',
      fontSize: '28px',
      marginTop: '60px',
      fontWeight: 'bold',
      [mediaQueries.breakpointTablet]: {
        color: colors.darkFont,
        marginTop: 0,
        marginLeft: '20px',
      },
      [mediaQueries.breakpointSmall]: {
        textAlign: 'center',
        fontSize: '28px',
        fontWeight: 'bold',
        marginTop: '20px',
        marginLeft: 0,
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },

    whiteTitle: {
      color: colors.white,
      marginTop: '30px',
      fontSize: '30px',
      [mediaQueries.breakpointTablet]: {
        color: colors.white,
        marginTop: '30px',
      },
    },

    whiteSubtitle: {
      color: colors.white,
      marginTop: '13px',
    },

    icon: {
      fill: colors.secondary,
      marginTop: '-5px',
      marginRight: '10px',
    },
  });
}

