import routesList from '../../browser/routesList';
import { nextApplicationFunnelPath } from '../application/actions';

export default async function forbidCardAdded({ replace, dispatch, getApiResponse }) {
  const { _embedded = { cards: [] } } = await getApiResponse(['fetch', 'client', 'cards'], { forceFetch: true });
  const { autoRepay } = await getApiResponse(['fetch', 'client', 'paymentPreferences']);
  const { cards } = _embedded;
  const activeCards = cards.filter(card => card.status === 'ACTIVE');
  const isExpired = activeCards.filter(card => new Date(card.expiryDate) < new Date()).length > 0;

  if (activeCards.length > 0 && !isExpired) {
    if (autoRepay) {
      dispatch({ type: 'GUARD_FORBID_ADDED_CARD_FAILED' });
      const path = dispatch(nextApplicationFunnelPath(routesList.approvalCompleted)).payload;

      replace(path);
    } else {
      dispatch({ type: 'GUARD_FORBID_DISABLED_AUTO_REPAY_CARD_FAILED' });
      const path = dispatch(nextApplicationFunnelPath(routesList.applicationCardConfirm)).payload;

      replace(path);
    }

    return false;
  }

  return true;
}
