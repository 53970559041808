import { Record } from 'immutable';
import * as actions from './actions';

const InitialState = Record({
  activeCard: null,
});
const initialState = new InitialState;

export default function clientReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case actions.FETCH_CLIENT_ACTIVE_CARD_SUCCESS: {
      return state
        .set('activeCard', action.payload);
    }
  }

  return state;
}
