import * as Detail from './components/Detail';
import CardInfo from '../components/CardInfo.react';
import PageContainer from '../components/PageContainer.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Column, Heading, Row, StyledComponent } from '4finance-components';
import { connectState } from '../connect';
import { fromJS, Map } from 'immutable';
import {
  ChangeEmail,
  ChangePassword,
  ChangePhone,
  ChangeConsentOptions,
  ConfirmEmail,
  ConfirmPhone,
} from './forms';

@connectState({
  changeEmail: ['settings', 'changeEmail'],
  changePhone: ['settings', 'changePhone'],
  clientAcceptNews: ['api', 'fetch', 'client', 'settings', 'query', 'data', 'acceptNews'],
  clientCardActiveNumber: ['api', 'fetch', 'client', 'cards', 'active', 'query', 'data', 'number'],
  clientData: ['api', 'fetch', 'client', 'query', 'data'],
  clientEmail: ['api', 'fetch', 'client', 'email', 'query', 'data', 'email'],
  clientLoans: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  clientPhone: ['api', 'fetch', 'client', 'phone', 'query', 'data', 'mobilePhone'],
})
@translate
@Radium
export default class SettingsOverview extends StyledComponent {
  static styleRoot = 'SettingsOverview'

  static propTypes = {
    changeEmail: RPT.object,
    changePhone: RPT.object,
    clientAcceptNews: RPT.bool,
    clientCardActiveNumber: RPT.string,
    clientData: RPT.object,
    clientEmail: RPT.string,
    clientLoans: RPT.object,
    clientPhone: RPT.string,
  }

  static defaultProps = {
    changeEmail: new Map(),
    changePhone: new Map(),
    clientAcceptNews: false,
    clientCardActiveNumber: null,
    clientData: new Map(),
    clientEmail: null,
    clientLoans: new Map(),
    clientPhone: null,
  }

  render() {
    const {
      changeEmail,
      changePhone,
      clientData,
      clientEmail,
      clientPhone,
      msg,
    } = this.props;
    const { verification: hasEmailVerification, processed: isEmailProcessed } = fromJS(changeEmail);
    const { verification: hasPhoneVerification, processed: isPhoneProcessed } = fromJS(changePhone);
    const clientBankAccount = clientData.get('bankAccount') || '';
    const clientDateOfBirth = clientData.get('dateOfBirth');
    const clientFullName = clientData.get('fullName');
    const showEmailChange = !hasEmailVerification && !isEmailProcessed;
    const showPhoneChange = !hasPhoneVerification && !isPhoneProcessed;
    const style = this.getStyle();

    return (
      <PageContainer>
        <Row>
          <Column large={12} medium={12} small={12} phone={12}>
            <Heading kind="h2" inheritedStyle={style.heading}>
              {clientFullName}
            </Heading>
            <hr style={style.line} />
            <Row>
              <Column large={5} medium={5} small={12} phone={12}>
                <Heading kind="h4" inheritedStyle={style.subHeading}>
                  {msg('overview.detail.heading')}
                </Heading>
              </Column>
              <Column large={7} medium={7} small={12} phone={12} inheritedStyle={style.detailList}>
                <Detail.Container translateNamespace="overview.detail.fields">
                  <Detail.Row label="name" value={clientFullName} withTooltip />
                  <Detail.Row label="date_of_birth" value={new Date(clientDateOfBirth)} pattern="date" withTooltip />
                  <Detail.Row label="bank_account_number" value={clientBankAccount} pattern="bank" withTooltip />
                  <Detail.Row label="email_address" value={clientEmail}>
                    {showEmailChange ? <ChangeEmail /> : <ConfirmEmail />}
                  </Detail.Row>
                  <Detail.Row label="phone_number" value={clientPhone} pattern="phone">
                    {showPhoneChange ? <ChangePhone /> : <ConfirmPhone />}
                  </Detail.Row>
                  <Detail.Row label="password" value="" pattern="password" >
                    <ChangePassword />
                  </Detail.Row>
                  <Detail.Row label="consent_options" >
                    <ChangeConsentOptions />
                  </Detail.Row>
                </Detail.Container>
              </Column>
            </Row>
            <hr style={style.line} />
            <Row>
              <Column large={5} medium={5} small={12} phone={12}>
                <Heading kind="h4" inheritedStyle={style.subHeading}>
                  {msg('overview.card.heading')}
                </Heading>
              </Column>
              <Column large={7} medium={7} small={12} phone={12} inheritedStyle={style.card}>
                {/* @TODO: Finish logic of card states */}
                <CardInfo />
              </Column>
            </Row>
          </Column>
        </Row>
      </PageContainer>
    );
  }

  static style = ({ font, colors, mediaQueries }) => ({
    line: {
      borderWidth: 0,
      borderTop: `3px ${colors.monotone2} solid`,
      margin: '20px 0 30px',
      [mediaQueries.breakpointTablet]: {
        borderWidth: 0,
        margin: 0,
      },
    },

    heading: {
      color: colors.darkFont,
      fontSize: '46px',
      fontWeight: font.weight.bold,
      lineHeight: '40px',
      marginTop: '10px',

      [mediaQueries.breakpointTablet]: {
        fontSize: '40px',
        marginBottom: '5px',
        marginTop: 0,
      },
    },

    subHeading: {
      color: colors.darkFont,
      fontSize: '16px',
      fontWeight: font.weight.bold,
      lineHeight: '25px',
      marginTop: '2rem',
      textTransform: 'uppercase',
    },

    detailList: {
      [mediaQueries.breakpointTablet]: {
        backgroundColor: colors.white,
      },
    },

    card: {
      paddingRight: '5px',

      [mediaQueries.breakpointTablet]: {
        paddingRight: '15px',
      },
    },
  });
}
