import PaymentHeader from './components/PaymentHeader.react';
import PaymentSelect from './components/PaymentSelect.react';
import PaymentWrapper from './components/PaymentWrapper.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState } from '../connect';
import { StyledComponent } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  nextPayment: ['loans', 'nextPayment'],
  remainingInstalments: ['loans', 'remainingInstalments'],
})
@translate
export default class PaymentMonthly extends StyledComponent {
  static styleRoot = 'PaymentMonthly';

  static propTypes = {
    location: RPT.object.isRequired,
    nextPayment: RPT.object.isRequired,
  }

  static defaultProps = {
    loan: new Map(),
  }

  renderHeader() {
    const { msg, preciseNumberToCurrency, nextPayment, remainingInstalments, formatDate } = this.props;
    const style = this.getStyle();

    return (
      <ul style={style.list}>
        <li style={style.row}>
          <span style={style.value}>{msg('payments.monthly.date')}</span>
          <strong>{formatDate(nextPayment.date)}</strong>
        </li>
        <li style={style.row}>
          <span style={style.value}>{msg('payments.monthly.type')}</span>
          <strong>
            {preciseNumberToCurrency(nextPayment.total)}
          </strong>
        </li>
        <li style={style.row}>
          <span style={style.value}>{msg('payments.monthly.quotas')}</span>
          <strong>{remainingInstalments}x</strong>
        </li>
      </ul>
    );
  }

  render() {
    const { cnt, msg, nextPayment } = this.props;
    const amountToPay = nextPayment.total;

    return (
      <PaymentWrapper
        type="monthly"
        title={cnt('payments.monthly.title')}
      >
        <PaymentHeader
          amount={amountToPay}
          paymentDescription={msg('payments.monthly.amountInfo')}
          type="monthly"
        >
          {this.renderHeader()}
        </PaymentHeader>
        <PaymentSelect type="repayment" amount={amountToPay} />
      </PaymentWrapper>
    );
  }

  static style = ({ mediaQueries }) => ({
    list: {
      width: '100%',
    },

    row: {
      listStyle: 'none',
      marginBottom: '5px',
    },

    value: {
      display: 'inline-block',
      width: '200px',

      [mediaQueries.breakpointTablet]: {
        width: '55%',
      },
    },
  });
}
