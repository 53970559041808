/* eslint-disable no-nested-ternary */
import Icon from './Icon.react';
import Link from '../components/Link.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { withRouter } from 'react-router';
import { connectSelectors, connectState, connectActions } from '../connect';
import { StyledComponent } from '4finance-components';

const BULL = String.fromCharCode(0x2022);
const getCardNumberPostfix = (cardNumber) => `${cardNumber}`.slice(-4);

@connectState({
  clientCardActiveExpirationDate: ['api', 'fetch', 'client', 'cards', 'active', 'query', 'data', 'expiryDate'],
  clientCardActiveNumber: ['api', 'fetch', 'client', 'cards', 'active', 'query', 'data', 'number'],
  clientLoans: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
})
@connectActions({
  toggleAutoRepaymentsForLatestLoan: ['settings', 'toggleAutoRepaymentsForLatestLoan'],
})
@connectSelectors({
  autoRepayment: ['client', 'getAutoRepaymentStatus'],
  hasExpired: ['client', 'cardExpired'],
})
@translate
@withRouter
@Radium
export default class CardInfo extends StyledComponent {
  static styleRoot = 'CardInfo'

  static propTypes = {
    addCardHandle: RPT.func,
    clientCardActiveExpirationDate: RPT.string,
    clientCardActiveNumber: RPT.string,
    clientLoans: RPT.object,
    toggleAutoRepaymentsForLatestLoan: RPT.func.isRequired,
    formatDate: RPT.func,
    msg: RPT.func,
    noDetails: RPT.bool,
    noWrapper: RPT.bool,
    autoRepayment: RPT.bool,
    hasExpired: RPT.bool,
  }

  static defaultProps ={
    clientLoans: new Map(),
  }

  handleEnableAutoRepayment = () => {
    const { router, hasExpired, autoRepayment, toggleAutoRepaymentsForLatestLoan } = this.props;

    if (autoRepayment) {
      toggleAutoRepaymentsForLatestLoan(false);
    } else if (hasExpired) {
      router.push(routesList.cardExpired);
    } else {
      toggleAutoRepaymentsForLatestLoan(true);
      router.push(routesList.autoRepaymentEnabled);
    }
  }

  render() {
    const { autoRepayment, msg, clientCardActiveNumber, clientLoans, clientCardActiveExpirationDate, formatDate, noDetails, addCardHandle, hasExpired } = this.props;
    const cardNumberPostfix = getCardNumberPostfix(clientCardActiveNumber);
    const nextPaymentDate = clientLoans.get('nearestPaymentDate');
    const autoRepayStatus = autoRepayment ? 'enabled' : 'disabled';
    const linkLabel = msg(clientCardActiveNumber ? (hasExpired && autoRepayment ? 'overview.card.cardInfo.cardAdd' : 'overview.card.cardInfo.cardSettings') : 'payments.card.action.addCard');

    const style = this.getStyle();

    const cardManagment = (
      <div style={style.nextPayment}>
        {(nextPaymentDate && !hasExpired && autoRepayment) &&
          <div>
            <div style={style.nextPayment.arrow} />
            {msg('overview.card.cardInfo.nextPayment')}
            <span style={style.date}>
              {formatDate(nextPaymentDate)}
            </span>
          </div>
        }

        {(hasExpired && autoRepayment) &&
          <div style={style.nextPayment.hasExpired}>
            <div style={style.nextPayment.arrow} />
            {msg('overview.card.cardInfo.cardExpired', { date: formatDate(clientCardActiveExpirationDate) })}
            <Link to={routesList.settingsCardAdd}>
              {msg('overview.card.cardInfo.cardSettings')}
            </Link>.
          </div>
        }

        <div style={[!autoRepayment && style.nextPayment.hasAutoRepaymentOff]}>
          <div style={style.nextPayment.arrow} />
          <strong style={style.nextPayment.strong}>{
            msg(`overview.card.cardInfo.autorepayment.${autoRepayStatus}`)}
          </strong>
          <Link
            inheritedStyle={style.autoRepaymentButton} onClick={this.handleEnableAutoRepayment}
          >
            {msg(`overview.card.cardInfo.autorepaymentButton.${autoRepayStatus}`)}
          </Link>
        </div>
      </div>
    );

    return (
      <div style={[style.wrapper, hasExpired && style.wrapper.hasExpired]}>

        <div style={style.info}>
          <div style={style.info.container}>
            <div style={style.infoBlock}>
              <Icon size={49} kind="card" style={style.iconStyle} />
            </div>
            <div style={style.infoBlock}>
              {clientCardActiveNumber ?
                <div>
                  <div style={style.cardNumberText}>
                    {msg('overview.card.cardInfo.cardNumber')}
                  </div>
                  <div style={style.cardNumber}>
                    {`${BULL}${BULL}${BULL}${BULL} ${BULL}${BULL}${BULL}${BULL} ${BULL}${BULL}${BULL}${BULL}`} {cardNumberPostfix}
                  </div>
                </div>
                :
                <div style={style.addCard}>
                  {msg('overview.card.addCard.message')}
                </div>
              }
            </div>
          </div>

          <div style={[style.infoBlockSettings, !clientCardActiveNumber && style.addCardButton]}>
            {!addCardHandle
              ? <Link to={routesList.settingsCardAdd}>
                {linkLabel}
              </Link>
              : <Link onClick={addCardHandle}>
                {linkLabel}
              </Link>}
          </div>
        </div>

        {(clientCardActiveNumber && !noDetails) && cardManagment}
      </div>
    );
  }

  static style = ({ font, colors, mediaQueries }, { noWrapper }) => ({
    autoRepaymentButton: {
      height: 'auto',
      lineHeight: '20px',
      fontSize: '16px',
      verticalAlign: 'baseline',
    },

    iconStyle: {
      margin: '-6px 0',
    },

    date: {
      marginLeft: '5px',
      fontWeight: font.weight.bold,
    },

    wrapper: {
      backgroundColor: !noWrapper && colors.white,
      borderLeftWidth: '8px',
      borderLeftStyle: noWrapper ? 'none' : 'solid',
      borderLeftColor: colors.secondary,
      borderRadius: '3px',
      boxShadow: `0 2px 2px 0 ${colors.lighterGray}`,
      marginBottom: noWrapper ? 0 : '20px',
      marginRight: '8px',
      padding: '13px 20px 0px',
      hasExpired: {
        borderLeftColor: colors.error,
        borderLeftStyle: 'solid',
      },
      [mediaQueries.breakpointTablet]: {
        backgroundColor: 'transparent',
        border: 0,
        boxShadow: 'none',
        marginRight: 0,
        padding: 0,
      },
    },

    info: {
      marginBottom: '.5em',
      paddingBottom: '0.8em',
      verticalAlign: 'top',

      [mediaQueries.breakpointTablet]: {
        borderBottom: 0,
      },

      container: {
        display: 'inline-block',
      },
    },

    addCard: {
      paddingBottom: '10px',
    },

    cardNumberText: {
      color: colors.monotone4,
      fontWeight: '14px',
      lineHeight: '20px',
    },

    cardNumber: {
      color: colors.monotone4,
      fontSize: '18px',
      fontWeight: '600',
      letterSpacing: '.1em',
      lineHeight: '22px',
      [mediaQueries.breakpointTablet]: {
        fontSize: '16px',
      },
    },

    cardSettings: {
      display: 'inline-block',
      verticalAlign: 'bottom',
    },

    infoBlock: {
      marginRight: '15px',
      display: 'inline-block',
      maxWidth: '70%',
      verticalAlign: 'top',
    },

    infoBlockSettings: {
      display: 'block',
      verticalAlign: 'bottom',
      color: colors.primary,
      fontSize: '16px',
      lineHeight: '20px',
      paddingBottom: '2px',
      whiteSpace: 'nowrap',

      [mediaQueries.breakpointTablet]: {
        margin: '10px 0 0',
        fontSize: '14px',
      },
    },

    addCardButton: {
      alignSelf: 'flex-start',
    },

    nextPayment: {
      borderTop: `1px ${colors.monotone2} solid`,
      color: colors.darkFont,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '8px 0',
      [mediaQueries.breakpointTablet]: {
        backgroundColor: colors.secondary,
        borderRadius: '3px',
        borderTop: '0',
        padding: '12px 22px',
        position: 'relative',
      },
      hasExpired: {
        color: colors.warningMessage,
        [mediaQueries.breakpointTablet]: {
          color: colors.darkFont,
        },
      },
      hasAutoRepaymentOff: {
        padding: '10px 0',
        color: colors.warningMessage,
        [mediaQueries.breakpointTablet]: {
          color: colors.darkFont,
          textAlign: 'center',
        },
      },
      arrow: {
        [mediaQueries.breakpointTablet]: {
          position: 'absolute',
          width: '12px',
          height: '12px',
          backgroundColor: colors.secondary,
          top: '-6px',
          left: '22px',
          transform: 'rotate(45deg)',
        },
      },

      strong: {
        marginRight: '15px',
      },
    },
  });
}
