import * as persistence from '../persistence/actions';
import { actions as api } from '../api';
import { getPath } from '../../browser/routesList';
import { replace } from 'react-router-redux';

export const MYMOID_ERROR = 'MYMOID_ERROR';

export const MYMOID_CARD_ADD = 'MYMOID_CARD_ADD';
export const MYMOID_CARD_ADD_SUCCESS = 'MYMOID_CARD_ADD_SUCCESS';
export const MYMOID_CARD_ADD_ERROR = 'MYMOID_CARD_ADD_ERROR';

export const SET_STORE_CARD_TASK_ID = 'SET_STORE_CARD_TASK_ID';
export const GET_STORE_CARD_STATUS = 'GET_STORE_CARD_STATUS';

export const SET_REDIRECT_URL_ON_SUCCESS = 'SET_REDIRECT_URL_ON_SUCCESS';

export const prefetchMymoid = () => ({ dispatch, getApiResponse, getState }) => {
  dispatch(api.sendPaymentGatewayCardsByChannelcodeReset({}, 'mymoid', true));

  async function getPromise() {
    await getApiResponse(['fetch', 'client']); // eslint-disable-line
    const clientData = getState().api.getIn(['fetch', 'client', 'query', 'data']);
    const hostName = `${window.location.protocol}//${window.location.host}`;

    return await dispatch(sendPaymentGatewayCardsByChannelcode({
      clientNumber: clientData.get('number'),
      username: clientData.get('fullName'),
      applicationName: 'Zaplo.es',
      successUrl: `${hostName}${getPath('callback', { type: MYMOID_CARD_ADD_SUCCESS })}`,
      failureUrl: `${hostName}${getPath('callback', { type: MYMOID_CARD_ADD_ERROR })}`,
    }, 'mymoid'));
  }

  return {
    type: MYMOID_CARD_ADD,
    payload: getPromise(),
  };
};

export const sendPaymentGatewayCardsByChannelcode = (params, channelCode) => ({ httpPost }) => ({
  type: 'SEND_PAYMENT_GATEWAY_CARDS_BY_CHANNELCODE',
  meta: { params, channelCode },
  payload:
    httpPost(
      `/payment-gateway/${channelCode}/cards`,
      params,
      {
        Accept: 'application/vnd.payment-gateway.v2+json',
        'Content-Type': 'application/vnd.payment-gateway.v2+json',
      }
    ),
});

export const submitMymoidCard = (root, customRedirect, error) => ({ dispatch }) => {
  async function getPromise() {
    if (error) {
      dispatch(replace(`${root}/card/refresh`));
      dispatch(replace(`${root}/card/add/${error}`));
      dispatch(prefetchMymoid());

      throw Error('Submiting Mymoid card failed');
    }
    dispatch(setStoreCardTaskId());
    dispatch(replace(`${root}/card/refresh`));
    dispatch(replace(customRedirect || `${root}/card/processing`));
  }

  return {
    type: 'SUBMIT_MYMOID_CARD',
    payload: getPromise(),
  };
};

export const setStoreCardTaskId = () => ({ dispatch, getState }) => {
  async function getPromise() {
    const taskId = getState().api.getIn(['send', 'paymentGateway', 'cards', ({}).toString(), 'mymoid', 'data', 'taskId']);

    dispatch(persistence.set('storeCardTaskId', taskId, { expires: new Date(Date.now() + 10 * 60 * 1000) }));
  }

  return {
    type: SET_STORE_CARD_TASK_ID,
    payload: getPromise(),
  };
};

export const getStoreCardStatus = () => ({ dispatch, getState }) => {
  async function getPromise() {
    let storeCardStatus = 'IN_PROGRESS';
    const storeCardTaskId = getState().persistence.get('storeCardTaskId');

    if (storeCardTaskId) {
      const tasksAction = await dispatch(fetchPaymentGatewayTasks());
      const { tasks } = tasksAction.payload;

      storeCardStatus = (tasks || []).reduce((status, task) => {
        if (task.type === 'STORE_CARD' && task.id === storeCardTaskId) {
          return task.status;
        }

        return status;
      }, storeCardStatus);
    }

    return storeCardStatus;
  }

  return {
    type: GET_STORE_CARD_STATUS,
    payload: getPromise(),
  };
};

export const fetchPaymentGatewayTasks = () => ({ httpGet }) => ({
  type: 'FETCH_PAYMENT_GATEWAY_TASKS',
  payload: httpGet(
    '/payment-gateway/tasks',
    null,
    {
      Accept: 'application/vnd.payment-gateway.v1+json',
      'Content-Type': 'application/vnd.payment-gateway.v1+json',
    }),
});

export const processStoreCardTaskId = (successUrl, failureUrl, status) => ({ dispatch, getState }) => {
  async function getPromise() {
    const { onSuccessUrl } = getState().mymoid;

    dispatch(persistence.set('storeCardTaskId', ''));

    switch (status) {
      case 'COMPLETED':
        dispatch(replace(onSuccessUrl || successUrl));
        dispatch(setUrlOnSuccess(null));
        break;
      default:
        dispatch(replace(failureUrl));
        throw Error('Process of store card task id failed');
    }
  }

  return {
    type: 'PROCESS_STORE_CARD_TASK_ID',
    payload: getPromise(),
  };
};


export const setUrlOnSuccess = (onSuccessUrl) => () => ({
  type: SET_REDIRECT_URL_ON_SUCCESS,
  payload: {
    onSuccessUrl,
  },
});
