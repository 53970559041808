import * as Detail from '../settings/components/Detail';
import BankAccountNumber from './components/BankAccountNumber.react';
import CalculatorEditable from '../components/Calculator/CalculatorEditable.react';
import ChangeEmployment from './forms/ChangeEmployment.react';
import ChangeHousehold from './forms/ChangeHousehold.react';
import ChangeLoansExpenses from './forms/ChangeLoansExpenses.react';
import ChangeTotalIncome from './forms/ChangeTotalIncome.react';
import PageContainer from '../components/PageContainer.react';
import PageHeader from '../components/PageHeader.react';
import Radium from 'radium';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { AcceptedLoanAgreementField } from './forms/AcceptedLoanAgreementField';
import { Column, Row, StyledComponent, Heading } from '4finance-components';
import { connectActions, connectPending, connectPrefetch, connectSelectors, connectState } from '../connect';
import { Form } from '4finance-onion-form';
import { LoanPurpose, Submit } from '../registration/fields';
import {
  PROCESS_SUBMIT_PURPOSE_CHANGE,
  PROCESS_SUBMIT_HOUSEHOLD_CHANGE,
  PROCESS_SUBMIT_BUDGET_INCOME_CHANGE,
  PROCESS_SUBMIT_EMPLOYMENT_CHANGE,
  PROCESS_SUBMIT_BUDGET_EXPENSES_CHANGE,
} from '../../common/client/actions';

const FORM_NAME = 'applicationDetails';

@connectPending({
  pendingChangePurpose: PROCESS_SUBMIT_PURPOSE_CHANGE,
  pendingChangeHousehold: PROCESS_SUBMIT_HOUSEHOLD_CHANGE,
  pendingChangeBudgetIncome: PROCESS_SUBMIT_BUDGET_INCOME_CHANGE,
  pendingChangeBudgetExpenses: PROCESS_SUBMIT_BUDGET_EXPENSES_CHANGE,
  pendingChangeEmployment: PROCESS_SUBMIT_EMPLOYMENT_CHANGE,
})
@connectState({
  acceptedLoanAgreement: ['onionForm', 'fields', 'applicationDetails', 'acceptedLoanAgreement', 'value'],
  amount: ['calculator', 'amount'],
  clientAddress: ['api', 'fetch', 'client', 'query', 'data', 'actualAddress', 'fullAddress'],
  clientBankAccount: ['api', 'fetch', 'client', 'query', 'data', 'bankAccount'],
  clientBudget: ['api', 'fetch', 'client', 'budget', 'query', 'data'],
  clientHouseholdType: ['api', 'fetch', 'client', 'household', 'query', 'data', 'ownerShip'],
  identified: ['api', 'fetch', 'client', 'query', 'data', 'identified'],
  term: ['calculator', 'term'],
})
@connectActions({
  applyForLoan: ['client', 'applyForLoan'],
})
@connectPrefetch([
  ['api', 'fetchClient'],
  ['api', 'fetchClientBudget'],
  ['api', 'fetchClientApplicationLoanPurpose'],
  ['api', 'fetchClientEmployer'],
  ['api', 'fetchClientHousehold'],
  ['identification', 'fetchClientApplicationWithOffer'],
  ['client', 'prefetchChangeLoanPurposeForm'],
])
@connectSelectors({
  clientEmploymentKey: ['client', 'clientEmploymentKey'],
  calculatorOfferSelector: ['calculator', 'calculatorOfferSelector'],
})
@translate
@Radium
export default class IdentificationApplicationDetails extends StyledComponent {
  static styleRoot = 'IdentificationApplicationDetails';

  static propTypes = {
    applyForLoan: RPT.func.isRequired,
    calculatorOfferSelector: RPT.object,
    clientEmploymentKey: RPT.string,
    submitClientLoanPurposeChange: RPT.func.isRequired,
  };

  state = {
    isBancAccountModalOpen: false,
  }

  handleBankAccoutModalToggle = () => {
    const { isBancAccountModalOpen } = this.state;

    this.setState({ isBancAccountModalOpen: !isBancAccountModalOpen });
  }

  render() {
    const { cnt, msg, acceptedLoanAgreement, applyForLoan, calculatorOfferSelector: { offer }, clientAddress, clientBankAccount, clientBudget, clientEmploymentKey, clientHouseholdType,
      pendingChangeBudgetExpenses,
      pendingChangeBudgetIncome,
      pendingChangeEmployment,
      pendingChangeHousehold,
      pendingChangePurpose,
    } = this.props;
    const style = this.getStyle();

    const totalIncome = clientBudget && clientBudget.get('totalIncome');
    const loansExpenses = clientBudget && clientBudget.get('loansExpenses');

    const isSubmitDisabled = !acceptedLoanAgreement || pendingChangePurpose;
    const { term, newPrincipal } = offer.toJS();

    return (
      <PageContainer mobileFullScreen>
        <Form name={FORM_NAME}>
          <Row inheritedStyle={style.windowWrapper}>
            <Column phone={12} inheritedStyle={style.titleWrapper}>
              <PageHeader
                title={msg('approveApplication.title')}
                padded
              />
            </Column>
            <Column large={7} medium={7} small={12} phone={12} inheritedStyle={style.contentWrapper}>
              <Heading kind="h3" noMargin inheritedStyle={[style.heading, style.heading.inHead]}>{msg('approveApplication.headingDetailInformation')}</Heading>
              <section style={style.wrapper}>
                <div style={style.blockWhite}>
                  <Heading kind="h3" noMargin inheritedStyle={[style.heading, style.heading.inSection]}>{msg('approveApplication.headingDetailInformation')}</Heading>
                  <p style={style.warning}> {msg('approveApplication.warningMessage')} </p>
                  <BankAccountNumber value={clientBankAccount} />

                  <div style={style.blockGrey}>
                    <Detail.Container translateNamespace="approveApplication.detail.fields">
                      <LoanPurpose width="full" style={style.loanPurpose} />
                      <Detail.Row label="address" value={clientAddress} isApproveApplication withTooltip>
                      </Detail.Row>
                      <Detail.Row label="housing" value={msg(`form.changeHousehold.ownerShip.options.${clientHouseholdType}`)} isApproveApplication isLoading={pendingChangeHousehold}>
                        <ChangeHousehold />
                      </Detail.Row>
                      <Detail.Row label="profession" value={msg(`form.otherDetails.employmentStatus.options.${clientEmploymentKey}`)} isApproveApplication isLoading={pendingChangeEmployment}>
                        <ChangeEmployment />
                      </Detail.Row>
                      <Detail.Row label="income" pattern="currency" value={totalIncome} isApproveApplication isLoading={pendingChangeBudgetIncome}>
                        <ChangeTotalIncome />
                      </Detail.Row>
                      <Detail.Row label="monthlyExpenses" pattern="currency" value={loansExpenses} isApproveApplication isLoading={pendingChangeBudgetExpenses}>
                        <ChangeLoansExpenses />
                      </Detail.Row>
                    </Detail.Container>
                  </div>
                </div>
              </section>
            </Column>
            <Column large={5} medium={5} small={12} phone={12} inheritedStyle={style.contentWrapper}>
              <section style={style.wrapper}>
                <CalculatorEditable style={style.offer} showInfoLink={false}>
                  <div style={[style.blockWhite, style.blockWhite.agreement]}>
                    <AcceptedLoanAgreementField onChange={this.handleChange} >
                      {cnt('form.loanAgreement.acceptedLoanAgreement.label', { amount: newPrincipal, term })}
                    </AcceptedLoanAgreementField>
                    <Submit fullWidth disabled={isSubmitDisabled} onClick={applyForLoan} style={style.button}>{msg('approveApplication.completeButton')}</Submit>
                  </div>
                </CalculatorEditable>
              </section>
            </Column>
          </Row>
        </Form>
      </PageContainer>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    offer: {
      wrapper: {
        marginTop: 0,
        boxShadow: 0,

        [mediaQueries.breakpointLaptop]: {
          maxHeight: '650px',
        },

        [mediaQueries.breakpointTablet]: {
          borderRadius: 0,
        },
      },
      header: {
        wrapper: {
          [mediaQueries.breakpointTablet]: {
            borderRadius: 0,
          },
        },
      },
    },

    titleWrapper: {
      [mediaQueries.breakpointTablet]: {
        display: 'none',
      },
    },

    contentWrapper: {
      [mediaQueries.breakpointTablet]: {
        padding: 0,
      },
    },

    windowWrapper: {
      [mediaQueries.breakpointTablet]: {
        paddingTop: '30px',
        marginLeft: 0,
        marginRight: 0,
      },
    },

    wrapper: {
      marginBottom: '16px',

      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
        marginBottom: 0,
      },
    },

    loanPurpose: {
      wrapper: {
        [mediaQueries.breakpointSmall]: {
          borderLeft: `solid 1px ${colors.inputBorder}`,
          borderRight: `solid 1px ${colors.inputBorder}`,
          borderTop: `solid 1px ${colors.inputBorder}`,
          borderBottom: `solid 1px ${colors.inputBorder}`,
        },
      },
    },

    warning: {
      color: colors.lightFont,
      padding: '24px 0',

      [mediaQueries.breakpointTablet]: {
        color: colors.darkFont,
        padding: '0 0 16px 0',
      },
    },

    blockGrey: {
      backgroundColor: colors.lighterBackground,
      padding: '20px',

      [mediaQueries.breakpointSmall]: {
        backgroundColor: 'transparent',
        padding: 0,
      },
    },

    blockYellow: {
      backgroundColor: colors.secondary,
      padding: '24px',

      [mediaQueries.breakpointSmall]: {
        backgroundColor: 'transparent',
      },
    },

    blockWhite: {
      borderRadius: '8px 8px',
      backgroundColor: colors.white,
      padding: '24px',

      [mediaQueries.breakpointTablet]: {
        borderRadius: 0,
      },

      agreement: {
        padding: '5px 24px 24px 24px',

        [mediaQueries.breakpointSmall]: {
          backgroundColor: 'transparent',
        },
      },
    },

    heading: {
      marginBottom: '24px',
      maxWidth: '85%',

      inSection: {
        display: 'block',

        [mediaQueries.breakpointTablet]: {
          display: 'none',
        },
      },

      inHead: {
        display: 'none',

        [mediaQueries.breakpointTablet]: {
          marginLeft: '24px',
          marginRight: '24px',
          display: 'block',
        },
      },
    },

    certificateInfo: {
      wrapper: {
        paddingBottom: '24px',

        [mediaQueries.breakpointSmall]: {
          backgroundColor: 'transparent',
        },
      },
    },

    modal: {
      innerWrapper: {
        maxWidth: '340px',
      },
    },

    button: {
      base: {
        [mediaQueries.breakpointLarge]: {
          minWidth: 'auto',
        },
      },
    },
  });
}
