import * as actions from './actions';
import { fromJS, Record } from 'immutable';

const InitialState = Record({
  isTestDouble: false,
  riskRuleResults: fromJS({}),
  currentMethod: null,
});
const initialState = new InitialState;

export default function identificationReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case actions.TOGGLE_TEST_DOUBLE: {
      return state.update('isTestDouble', isTestDouble => !isTestDouble);
    }

    case actions.CHECK_FOR_TEST_DOUBLE: {
      return state.set('isTestDouble', action.payload);
    }

    case actions.RISK_RULES_SUCCESS: {
      return state.set('riskRuleResults', fromJS(action.payload));
    }

    case actions.CHANGE_METHOD: {
      return state.set('currentMethod', action.payload);
    }
  }

  return state;
}
