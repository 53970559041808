// import config from '../../config';
import config from '4finance-configuration';
import contentfulClient from '../common/lib/contentful';

import * as application from './application/actions';
import * as approval from './approval/actions';
import * as authentication from './authentication/actions';
import * as calculator from './calculator/actions';
import * as client from './client/actions';
import * as device from './lib/device/actions';
import * as documentActions from './documentUpload/actions';
import * as faqs from './faqs/actions';
import * as identification from './identification/actions';
import * as loans from './loans/actions';
import * as mymoid from './mymoid/actions';
import * as passwordRecovery from './passwordRecovery/actions';
import * as payment from './payment/actions';
import * as persistence from './lib/persistence/actions';
import * as randomFill from './randomFill/actions';
import * as registration from './registration/actions';
import * as settings from './settings/actions';
import * as ui from './ui/actions';

import { actions as api } from './api';
import { actions as documentUpload } from '4finance-redux-file-upload';
import { actions as onionForm } from '4finance-onion-form';
import { actions as translate } from '4finance-translate';
import { createActions as createContentfulActions } from '4finance-contentful';
import { createZopimActions } from '4finance-zopim';
import { push as updatePath, push, replace, go, goBack, goForward } from 'react-router-redux';

const routing = { updatePath, push, replace, go, goBack, goForward };

const actions = {
  api,
  application,
  approval,
  authentication,
  calculator,
  client,
  contentful: createContentfulActions(contentfulClient),
  device,
  documentUpload: { ...documentActions, ...documentUpload },
  faqs,
  identification,
  loans,
  mymoid,
  onionForm,
  payment,
  passwordRecovery,
  persistence,
  randomFill,
  registration,
  routing,
  settings,
  translate,
  ui,
  zopim: createZopimActions(config.zopimAPIKey),
};

export default actions;
