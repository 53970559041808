import * as actions from './actions';
import { Record } from 'immutable';

const InitialState = Record({
  processed: false,
});

const initialState = new InitialState;

export default function settingReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) {
    return initialState.mergeDeep(state);
  }

  switch (action.type) {
    case `${actions.PROCESS_SUBMIT_PASSWORD_CHANGE}_SUCCESS`: {
      return state.setIn(['processed'], true);
    }

    case actions.PROCESS_SET_PASSWORD_CHANGE_DONE: {
      return state.setIn(['processed'], false);
    }
  }

  return state;
}
