import AlternativeProposal from '../../components/AlternativeProposal.react';
import getExtension from '../../lib/getExtension';
import Jumbotron, { YellowBox } from '../../components/Jumbotron';
import dayjs from 'dayjs';
import PageContainer from '../../components/PageContainer.react';
import PageHeader from '../../components/PageHeader.react';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { connectState, connectPrefetch } from '../../connect';
import { StyledComponent, ButtonLink } from '4finance-components';

@connectState({
  loan: ['api', 'fetch', 'client', 'loans', 'latest', 'data'],
  extensions: ['api', 'fetch', 'client', 'loans', 'extensions', 'root', 'latest', 'data'],
  nextPayment: ['loans', 'nextPayment'],
})
@connectPrefetch([
  ['client', 'fetchClientLatestLoanExtensions'],
])
@translate
export default class Extension extends StyledComponent {
  static styleRoot = 'Extension';

  static propTypes = {
    loan: RPT.object.isRequired,
    extensions: RPT.object.isRequired,
    nextPayment: RPT.object.isRequired,
  }

  static defaultProps = {
    loan: new Map(),
    extensions: new Map(),
    nextPayment: new Map(),
  }

  render() {
    const { cnt, msg, preciseNumberToCurrency, nextPayment } = this.props;
    const style = this.getStyle();
    const extension = getExtension(this.props.extensions);
    const fromDate = dayjs(nextPayment.date).format('DD.MM');
    const toDate = dayjs(nextPayment.date).add(1, 'month').format('DD.MM');

    return (
      <PageContainer width="narrow" mobileFullScreen>
        <PageHeader title={msg('extension.title')} textAlign="left" inheritedStyle={style.heading} />
        <Jumbotron>
          <YellowBox isFullWidth hasPadding inheritedStyle={style.panel}>
            <AlternativeProposal from={{ value: fromDate }} to={{ value: toDate }} />
            <div style={style.description}>
              {cnt('extension.description', { fee: extension.fee })}
            </div>
          </YellowBox>
          <div style={style.container}>
            <div style={style.row}>
              <p>{msg('extension.fee')}</p>
              <strong>{preciseNumberToCurrency(extension.fee)}</strong>
            </div>
          </div>
          <ButtonLink to={routesList.paymentExtension} centered>
            {msg('extension.action')}
          </ButtonLink>
        </Jumbotron>
      </PageContainer>
    );
  }

  static style = ({ colors, mediaQueries }) => ({
    panel: {
      container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        [mediaQueries.breakpointTablet]: {
          display: 'block',
        },
      },
    },

    heading: {
      marginTop: '25px',
      paddingLeft: '15px',
    },

    container: {
      maxWidth: '500px',
      margin: '25px auto',
      fontSize: '18px',
    },

    description: {
      width: '58%',
      fontSize: '18px',
      lineHeight: '29px',
      [mediaQueries.breakpointTablet]: {
        width: '100%',
        fontSize: '16px',
        lineHeight: '20px',
        marginTop: '10px',
      },
    },

    row: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '15px 0 10px',
    },

    bordered: {
      borderBottom: `2px solid ${colors.monotone1}`,
    },
    value: {
      marginLeft: '5px',
    },
  });
}
