import GetLoan from './GetLoan.react';
import HomeLayout from '../layouts/Home.react';
import HowItWorks from './HowItWorks.react';
import NeedHelp from './NeedHelp.react';
import Proposition from './Proposition.react';
import React, { PureComponent as Component } from 'react';
import Services from './Services.react';

export default class Home extends Component {
  render() {
    return (
      <HomeLayout>
        <Proposition />
        <HowItWorks />
        <Services />
        <GetLoan />
        <NeedHelp />
      </HomeLayout>
    );
  }
}
