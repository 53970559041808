/* eslint-disable no-nested-ternary */
import InfoCard from '../../components/InfoCard.react';
import Link from '../../components/Link.react';
import MonthlyPayment from './MonthlyPayment.react';
import Radium from 'radium';
import React from 'react';
import routesList from '../../routesList';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Heading, Row, Column, StyledComponent } from '4finance-components';

@translate
@Radium
export default class LoanManagmentDesktop extends StyledComponent {
  static styleRoot = 'LoanManagmentDesktop'

  static propTypes = {
    formatDate: RPT.func,
    msg: RPT.func,
    paymentAvailable: RPT.bool,
    nextPayment: RPT.object,
    extension: RPT.object,
    loan: RPT.object,
    activeCard: RPT.object,
    isFintonicOrPrestaloProduct: RPT.bool,
    cardExpired: RPT.bool,
    autoRepayment: RPT.bool,
  }

  static defaultProps = {
    creditLimit: 300,
    loan: new Map(),
  }

  renderCards() {
    const { msg, extension } = this.props;
    const style = this.getStyle();

    return (
      <div style={[style.row, style.cards]}>
        <InfoCard
          icon="postpone"
          title={msg('loan.defer_payment.title')}
          content={extension ? msg('loan.defer_payment.text', { fee: (extension || {}).fee }) : msg('loan.defer_payment.text_disabled')}
          link={extension ? msg('loan.defer_payment.link') : msg('loan.defer_payment.link_disabled')}
          disabled={!extension}
          linkTo={routesList.extension}
        />
      </div>
    );
  }

  renderLinks() {
    const { msg, activeCard, cardExpired, autoRepayment } = this.props;
    const style = this.getStyle();

    if (autoRepayment && activeCard) {
      if (cardExpired) {
        return (<Link kind="primary" bold inheritedStyle={style.profileLink} to={routesList.settingsCardAdd}>
          {msg('overview.card.cardInfo.cardAdd')}
        </Link>)
      } else {
        return (<Link kind="primary" bold inheritedStyle={style.profileLink} to={routesList.settingsCardAdd}>
          {msg('overview.card.cardInfo.cardSettings')}
        </Link>
        )
      }
    } else {
      return (<Link kind="primary" bold inheritedStyle={style.profileLink} to={routesList.autoRepayment}>
        {msg('loan.automaticPayment.activate')}
      </Link>
      )
    }
  }

  render() {
    const { msg, loan, paymentAvailable, nextPayment, formatDate, activeCard, cardExpired, isFintonicOrPrestaloProduct, autoRepayment } = this.props;
    let autoRepayStatus;
    if (cardExpired) {
      autoRepayStatus = 'expired'
    } else {
      if (loan.get('autoRepay') && activeCard) {
        autoRepayStatus = 'enabled'
      } else {
        autoRepayStatus = 'disabled'
      }
    }
    const style = this.getStyle();
    const brokenLoan = loan.get('debtCollectLoanAgreement');

    return (
      <div>
        <Row inheritedStyle={style.topPanel}>
          <Column large={7} medium={7} small={12} phone={12} inheritedStyle={style.borderColumn}>
            <MonthlyPayment paymentAvailable={paymentAvailable} />
          </Column>

          {!brokenLoan &&
            <Column large={4} medium={4} lgOffset={1} mdOffset={1} inheritedStyle={style.desktopOnly}>
              <Heading kind="h2" inheritedStyle={style.heading}>
                {autoRepayment && cardExpired ? msg('loan.automaticPayment.enabled.expired.title') : msg(`loan.automaticPayment.${autoRepayStatus}.title`)}
              </Heading>
              <p style={style.description}>
                {autoRepayment && cardExpired ? msg('loan.automaticPayment.enabled.expired.text') : msg(`loan.automaticPayment.${autoRepayStatus}.text`, { date: formatDate(nextPayment.date) })}
              </p>
              {this.renderLinks()}
            </Column>
          }
        </Row>
        {(!brokenLoan && !isFintonicOrPrestaloProduct) && this.renderCards()}
      </div>
    );
  }

  static style = ({ colors, mediaQueries }) => ({

    desktopOnly: {
      [mediaQueries.breakpointLaptopSmall]: {
        display: 'none',
      },
    },

    heading: {
      fontSize: '18px',
      textTransform: 'uppercase',
      lineHeight: '23px',
      marginTop: 0,
    },

    topPanel: {
      marginTop: '20px',
      marginBottom: '50px',
    },

    row: {
      display: 'flex',
      alignItems: 'center',
    },

    description: {
      padding: '10px 0',
    },

    column: {
      display: 'flex',
      flexDirection: 'column',
    },

    cards: {
      justifyContent: 'space-between',
    },

    borderColumn: {
      borderRight: `1px solid ${colors.grayLighter}`,
    },
  });
}

// @Todo: Add diferent replies for Action Panel (Additional amount, Extend)
