import config from '4finance-configuration';
import CommonLayout from '../layouts/Common.react';
import Notification from '../components/Notification.react';
import React from 'react';
import RPT from 'prop-types';
import translate from '4finance-translate';
import { Container, StyledComponent } from '4finance-components';
import { connectActions, connectState } from '../connect';

@connectState({
  passwordRecoveryNotification: ['passwordRecovery', 'notificationShown'],
  isLoggedIn: ['authentication', 'isLoggedIn'],
})
@connectActions({
  dismissPasswordRecoveryNotification: ['passwordRecovery', 'dismissPasswordRecoveryNotification'],
  login: ['authentication', 'login'],
})
@translate
export default class AuthenticationPage extends StyledComponent {
  static styleRoot = 'AuthenticationPage'

  static propTypes = {
    dismissPasswordRecoveryNotification: RPT.func,
    location: RPT.object.isRequired,
    params: RPT.object.isRequired,
    passwordRecoveryNotification: RPT.bool,
    isLoggedIn: RPT.bool,
    cnt: RPT.func,
    msg: RPT.func,
  }

  componentWillUnmount() {
    this.props.dismissPasswordRecoveryNotification();
  }

  componentDidMount() {
    config.faker.enabled && this.automaticLoginTestUsers(); // eslint-disable-line
  }

  automaticLoginTestUsers() {
    const { params: { option }, login } = this.props;

    if (option && ~option.indexOf('@')) {
      login({ username: option, password: 'parole123' });
    }
  }

  renderMessages() {
    const {
      dismissPasswordRecoveryNotification,
      params: { option },
      isLoggedIn,
      passwordRecoveryNotification,
      msg,
    } = this.props;

    return (
      <div>
        <Notification
          body={msg('messages.login.unauthorized')}
          visible={option === 'unauthorized'}
        />
        <Notification
          heading={msg('messages.password_recovery.title')}
          body={msg('messages.password_recovery.text')}
          onClose={dismissPasswordRecoveryNotification}
          visible={!isLoggedIn && passwordRecoveryNotification}
          type="success"
        />
      </div>
    );
  }

  render() {
    const { location, children } = this.props;
    const style = this.getStyle();

    return (
      <CommonLayout location={location}>
        {this.renderMessages()}
        <Container>
          <div style={style.container}>
            {children}
          </div>
        </Container>
      </CommonLayout>
    );
  }

  static style = {
    container: {
      maxWidth: '320px',
      minHeight: '400px',
      margin: '50px auto 0',
    },
  }
}
