import CommonLayout from '../layouts/Common.react';
import React, { PureComponent as Component } from 'react';
import { Container, Column, Loader, Row } from '4finance-components';

export default class Loading extends Component {
  render() {
    return (
      <CommonLayout>
        <Container>
          <Row>
            <Column large={12} medium={12} small={12} phone={12}>
              <Loader />
            </Column>
          </Row>
        </Container>
      </CommonLayout>
    );
  }
}
