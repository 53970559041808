import * as actions from './actions';
import { Record } from 'immutable';

const InitialState = Record({
  timeToResendWebcode: new Date(),
});
const initialState = new InitialState;

export default function applicationReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

  switch (action.type) {
    case `${actions.GENERATE_WEBCODE}_SUCCESS`:
    case `${actions.CHECK_WEBCODE}_SUCCESS`: {
      const { canBeResendAfter } = action.payload;
      const time = new Date();

      time.setSeconds(time.getSeconds() + canBeResendAfter);

      return state.set('timeToResendWebcode', time);
    }
  }

  return state;
}
