export default ({ mediaQueries }) => ({
  block: {
    [mediaQueries.breakpointTablet]: {
      padding: 0,
    },
    [mediaQueries.breakpointSmall]: {
      padding: 0,
    },
  },
});
