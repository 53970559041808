import { gtmRouteHandler } from './helpers';

export function watchRouteChange({ action, getState }) {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { pathname } = action.payload;

    // wait for information about client to fetch
    setTimeout(() => {
      gtmStoreEvent(gtmRouteHandler(getState, pathname));
    }, 1500);
  }
}

function gtmStoreEvent(data) {
  if (process.env.IS_BROWSER && window.dataLayer && data) {
    window.dataLayer.push(data);
  }
}
